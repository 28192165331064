import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import Title from 'components/atoms/Title/PageTitle';
import { MainContainerNoMargin } from 'components/styles';
import { Container, WrapItem } from './styles';
import { Caption1, Caption2, SubHeadline } from 'styles/typography';
import Row from 'components/atoms/Row';
import Divider from 'components/atoms/Divider';
import { colors } from 'styles/colors';
import Button from 'components/atoms/Button/Button';
import { handleChatClick } from 'utils/common';
import ImgKAKAO from 'assets/images/img_kakao.png';
import { shareStore } from 'stores/store/Share/shareStore';
import { formatPriceWithCommas } from 'utils/number';
import { Spinner } from 'components/atoms/LoadingSpinner/LoadingSpinnerImage/styles';

const OperationInfoPage = observer(() => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { productUID } = state;

  const { productDetailInfo, isLoading } = shareStore;

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  useEffect(() => {
    // 데이터를 가져오는 함수 정의
    const fetchData = async () => {
      try {
        await shareStore.fetchGetProductDetailOperInfo(productUID);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // 데이터 가져오기 호출
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '50vh',
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <MainContainerNoMargin>
      <Title
        title="운영 정보"
        leftArrowButtonVisible
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      <Container>
        <WrapItem>
          <SubHeadline weight={'bold'} color={'black3'}>
            상품 정보
          </SubHeadline>
          <Row justifyContent={'space-between'} style={{ marginTop: 16 }}>
            <Caption1 weight={'regular'} color={'gray06'}>
              평일
            </Caption1>
            <Caption1 weight={'medium'} color={'gray09'}>
              {productDetailInfo?.weeklyOperTime}
            </Caption1>
          </Row>
          <Row justifyContent={'space-between'} style={{ marginTop: 16 }}>
            <Caption1 weight={'regular'} color={'gray06'}>
              주말
            </Caption1>
            <Caption1 weight={'medium'} color={'gray09'}>
              {productDetailInfo?.weekendOperTime}
            </Caption1>
          </Row>
          {/*<Row justifyContent={'space-between'} style={{ marginTop: 16 }}>*/}
          {/*  <Caption1 weight={'regular'} color={'gray06'}>*/}
          {/*    공휴일*/}
          {/*  </Caption1>*/}
          {/*  <Caption1 weight={'medium'} color={'gray09'}>*/}
          {/*    {productDetailInfo?.holidayIsOpen === 'YES' ? '운영함' : '운영안함'}*/}
          {/*  </Caption1>*/}
          {/*</Row>*/}
        </WrapItem>

        <Divider style={{ height: 8, backgroundColor: colors.gray01, marginBottom: 18 }} />
        <WrapItem>
          <SubHeadline weight={'bold'} color={'black3'}>
            가격
          </SubHeadline>
          <Row justifyContent={'space-between'} style={{ marginTop: 16 }}>
            <Caption1 weight={'regular'} color={'gray06'}>
              기본 수익금
            </Caption1>
            <Caption1 weight={'medium'} color={'gray09'}>
              {formatPriceWithCommas(productDetailInfo?.settlement ?? 0)}원
            </Caption1>
          </Row>
          {productDetailInfo?.depositInfo ? (
            <Row justifyContent={'space-between'} style={{ marginTop: 16 }}>
              <Caption1 weight={'regular'} color={'gray06'}>
                보증금
              </Caption1>
              <Row>
                <Caption1 weight={'medium'} color={'gray09'}>
                  {productDetailInfo?.depositInfo}
                </Caption1>
              </Row>
            </Row>
          ) : null}
        </WrapItem>

        <Divider style={{ height: 8, backgroundColor: colors.gray01, marginBottom: 18 }} />
        <WrapItem>
          <SubHeadline weight={'bold'} color={'black3'}>
            상품 이용방법
          </SubHeadline>

          <Caption1
            weight={'medium'}
            color={'gray08'}
            style={{
              whiteSpace: 'pre-line',
              wordBreak: 'keep-all',
              marginTop: 16,
            }}
          >
            {productDetailInfo?.useSlotGuide ? productDetailInfo?.useSlotGuide : '-'}
          </Caption1>
        </WrapItem>

        <Divider style={{ height: 8, backgroundColor: colors.gray01, marginBottom: 18 }} />
        <WrapItem>
          <SubHeadline weight={'bold'} color={'black3'}>
            상세 정보
          </SubHeadline>
          <Row justifyContent={'space-between'} style={{ marginTop: 16 }}>
            <Caption1 weight={'regular'} color={'gray06'}>
              차량 등록
            </Caption1>
            <Caption1 weight={'medium'} color={'gray09'}>
              {productDetailInfo?.vehicleRegistration}
            </Caption1>
          </Row>
          <Row justifyContent={'space-between'} style={{ marginTop: 16 }}>
            <Caption1 weight={'regular'} color={'gray06'}>
              자리 유형
            </Caption1>
            <Caption1 weight={'medium'} color={'gray09'}>
              {productDetailInfo?.slotType}
            </Caption1>
          </Row>
          <Row justifyContent={'space-between'} style={{ marginTop: 16 }}>
            <Caption1 weight={'regular'} color={'gray06'}>
              주차 방식
            </Caption1>
            <Caption1 weight={'medium'} color={'gray09'}>
              {productDetailInfo?.parkingMethod}
            </Caption1>
          </Row>
          <Row justifyContent={'space-between'} style={{ marginTop: 16 }}>
            <Caption1 weight={'regular'} color={'gray06'}>
              이용가능 차종
            </Caption1>
            <Caption1 weight={'medium'} color={'gray09'}>
              {productDetailInfo?.parkingAvailCar}
            </Caption1>
          </Row>
          <Row justifyContent={'space-between'} style={{ marginTop: 16 }}>
            <Caption1 weight={'regular'} color={'gray06'}>
              특이사항
            </Caption1>
            <Caption1 weight={'medium'} color={'gray09'}>
              {productDetailInfo?.parkingLotType ? productDetailInfo?.parkingLotType : '-'}
            </Caption1>
          </Row>
        </WrapItem>

        <Divider style={{ height: 8, backgroundColor: colors.gray01, marginBottom: 18 }} />
        <WrapItem>
          <Row justifyContent={'space-between'} style={{ marginTop: 16 }}>
            <Caption1
              weight={'medium'}
              color={'gray09'}
              style={{ whiteSpace: 'pre-line' }}
            >{`정보 수정이 필요하면\n주만사로 문의해 주세요.`}</Caption1>
            <Button
              style={{
                height: 32,
              }}
              borderColor={colors.gray04}
              horizontal={12}
              radius={16}
              onClick={() => {
                handleChatClick();
              }}
            >
              <Row>
                <img
                  style={{
                    width: '16px',
                    height: '16px',
                    marginRight: '4px',
                  }}
                  src={ImgKAKAO}
                  alt={'카카오 아이콘'}
                />
                <Caption2 weight={'semibold'} color={'gray07'}>
                  주만사 채널
                </Caption2>
              </Row>
            </Button>
          </Row>
        </WrapItem>
      </Container>
    </MainContainerNoMargin>
  );
});

export default OperationInfoPage;
