import React from 'react';
import { MainContainer } from 'components/styles';
import Title from '../../../components/atoms/Title/PageTitle';
import { useNavigate } from 'react-router-dom';
import { SubHeadline, TermsDetail } from 'styles/typography';
import { DetailContainer, TopContainer } from 'pages/Policy/styles';

const PolicyPersonalSupplyPage = () => {
  const navigate = useNavigate();

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const supplyChapter1 = '주식회사 주만사와 주차 공유 사업 협약 된 지방자치단체 및 공공기관';

  const supplyChapter2 =
    '거주자 우선 주차장 배정자 정보 확인 및 적립 된 포인트 관리 목적, 고객 민원 처리 및 관리 목적';

  const supplyChapter3 = '개인식별정보(휴대폰, 차량번호)';

  const supplyChapter4 = '거주자우선주차장 배정 기간, 주차 공유 사업 참여 종료 시';

  const supplyChapter5 = '동의를 거부할 권리 및 동의를 거부할 경우의 불이익';

  return (
    <MainContainer>
      <Title
        title="개인정보 제공 및 위탁안내"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
        titleColor={'darkPurple'}
        showSpacer={true}
      />
      <TopContainer>
        <SubHeadline weight={'semibold'}>개인정보를 제공 받는 자</SubHeadline>
        <TermsDetail color={'gray09'} weight={'regular'} style={{ marginTop: '10px' }}>
          {supplyChapter1}
        </TermsDetail>
        <SubHeadline
          style={{
            marginTop: '40px',
          }}
          weight={'semibold'}
        >
          개인정보를 제공 받는자의 이용 목적
        </SubHeadline>
        <TermsDetail color={'gray09'} weight={'regular'} style={{ marginTop: '10px' }}>
          {supplyChapter2}
        </TermsDetail>

        <SubHeadline
          style={{
            marginTop: '40px',
          }}
          weight={'semibold'}
        >
          제공하는 개인정보의 항목
        </SubHeadline>
        <TermsDetail color={'gray09'} weight={'regular'} style={{ marginTop: '10px' }}>
          {supplyChapter3}
        </TermsDetail>

        <SubHeadline
          style={{
            marginTop: '40px',
          }}
          weight={'semibold'}
        >
          개인정보를 제공 받는 자의 개인정보 보유 및 이용기간
        </SubHeadline>
        <TermsDetail color={'gray09'} weight={'regular'} style={{ marginTop: '10px' }}>
          {supplyChapter4}
        </TermsDetail>

        <SubHeadline
          style={{
            marginTop: '40px',
          }}
          weight={'semibold'}
        >
          동의를 거부할 권리 및 동의를 거부할 경우의 불이익
        </SubHeadline>
        <TermsDetail color={'gray09'} weight={'regular'} style={{ marginTop: '10px' }}>
          {supplyChapter5}
        </TermsDetail>
      </TopContainer>
    </MainContainer>
  );
};

export default PolicyPersonalSupplyPage;
