import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Container, } from './styles';
import Row from 'components/atoms/Row';
import { Body, Caption1, Caption2, Caption3, Caption4, Headline, SubHeadline } from 'styles/typography';
import CustomToggle from 'components/atoms/CustomToggle';


interface Props {
  isToggle: boolean | null;
  onToggle: (state: boolean) => void;
}

const ParkingAreaShare: React.FC<Props> = ({ isToggle, onToggle  }) => {
  const navigate = useNavigate()

  if (isToggle === null) return <></>;

  return (
    <Container>
      <Row justifyContent={'space-between'}>

        <Body weight={'semibold'} color={'black1'}>주차면 공유</Body>
        <CustomToggle isOn={isToggle} onToggle={onToggle}/>
      </Row>
    </Container>

  )
}

export default ParkingAreaShare;