import React, { useState, useEffect, MouseEvent } from 'react';
import { ModalContainer, Overlay, ListItem, ConfirmButton } from './styles';
import { Caption2, Caption4, Headline, SubHeadline } from 'styles/typography';
import Row from 'components/atoms/Row';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import { IcPlusSVG } from 'assets/ic';
import { useNavigate } from 'react-router-dom'; // styled components를 import 합니다.

interface BottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  data: Car[];
}

interface Car {
  carNumber: string;
  isMain: boolean;
}

const CarSelectModal: React.FC<BottomModalProps> = ({ isVisible, onClose, data }) => {
  const navigate = useNavigate();

  const [selected, setSelected] = useState<number | null>(null);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isVisible]);

  if (!isVisible) return null;

  const handleListItemClick = (event: MouseEvent<HTMLDivElement>, item: number) => {
    event.stopPropagation();
    setSelected(item);
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Overlay onClick={handleClose} />
      <ModalContainer isVisible={isVisible} isScroll={data.length > 4}>
        <Headline weight={'medium'} color={'black1'} style={{ textAlign: 'center' }}>
          이용하실 차량을 선택해 주세요
        </Headline>
        <div
          style={{
            flex: 1,
            overflowY: 'auto',
            paddingBottom: '16px',
            marginBottom: '28px',
            height: data.length > 4 ? 240 : 'auto',
            marginTop: 16,
          }}
        >
          {data.map((el, index) => (
            <ListItem
              key={index}
              isSelected={selected === index}
              onClick={event => handleListItemClick(event, index)}
            >
              <Row>
                {el.isMain ? (
                  <Button
                    radius={0}
                    borderColor={colors.primary}
                    style={{
                      margin: '1px 4px',
                      left: 20,
                      position: 'absolute',
                    }}
                  >
                    <Caption4 weight={'bold'} color={'primary'}>
                      대표차량
                    </Caption4>
                  </Button>
                ) : null}
                {el.carNumber}
              </Row>
            </ListItem>
          ))}
        </div>

        <Button
          backgroundColor={colors.white}
          radius={4}
          style={{
            height: 46,
            width: 'calc(100%)',
          }}
          onClick={() => {
            onClose();
            navigate('/carRegistration');
          }}
        >
          <SubHeadline weight={'semibold'} color={'primary'}>
            + 차량등록
          </SubHeadline>
        </Button>
      </ModalContainer>
    </>
  );
};
export default CarSelectModal;
