import React from 'react';
import {
  ModalOverlay,
  PurpleTwoButtonCheckModalContainer,
  PurpleTwoButtonContainer, PurpleTwoButtonContentsContainer, PurpleTwoButtonLeftButton,
  PurpleTwoButtonRightButton, PurpleTwoButtonTitleContainer,
} from './styles';
import {SubHeadline} from "styles/typography";

interface PurpleOneButtonCheckModal {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  content?: React.ReactNode;
  leftButtonText: string;
  rightButtonText: string;
  rightClick: () => void;
}

const PurpleOneButtonCheckModal: React.FC<PurpleOneButtonCheckModal> = ({ isVisible,
                                                                          onClose,
                                                                          title,
                                                                          content,
                                                                          leftButtonText,
                                                                          rightButtonText,
                                                                          rightClick  }) => {

  if (!isVisible) return null

  return (
    <>
      <ModalOverlay onClick={onClose}>
        <PurpleTwoButtonCheckModalContainer onClick={(e) => e.stopPropagation()}>
          <PurpleTwoButtonTitleContainer hasContent={!!content}>
            <SubHeadline weight={'medium'} color={'black3'}>
              {title}
            </SubHeadline>
          </PurpleTwoButtonTitleContainer>
          <PurpleTwoButtonContentsContainer>
            {content}
          </PurpleTwoButtonContentsContainer>
          <PurpleTwoButtonContainer>
            <PurpleTwoButtonLeftButton onClick={onClose}>
              {leftButtonText}
            </PurpleTwoButtonLeftButton>
            <PurpleTwoButtonRightButton
              onClick={() => {
                rightClick()
                onClose()
              }}
            >
              {rightButtonText}
            </PurpleTwoButtonRightButton>
          </PurpleTwoButtonContainer>
        </PurpleTwoButtonCheckModalContainer>

      </ModalOverlay>
    </>
  )
}

export default PurpleOneButtonCheckModal