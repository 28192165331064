import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, WrapItem, TipWrap } from './styles';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import Row from 'components/atoms/Row';
import { Body, Caption1, Caption2, Caption3, Headline, SubHeadline } from 'styles/typography';
import Divider from 'components/atoms/Divider';
import { pxToRem } from 'utils/common';
import { IcTooltipHelpSVG } from 'assets/ic';
import { IShareResidentSlotDetailInfoData } from 'models/Share/shareResidentSlotDetailInfo';
import { formatCommaDateTimeKoreanUnit } from 'utils/dateUtils';
import ExitExpectedTooltip from 'containers/share/ExitExpectedTooltip';

interface Props {
  onClick: (index: number) => void;
  data: IShareResidentSlotDetailInfoData;
}

const ResidentParkingUsing: React.FC<Props> = ({ onClick, data }) => {
  const navigate = useNavigate();

  const [isTooltip, setIsTooltip] = useState(false);

  return (
    <Container>
      <Row justifyContent={'space-between'} alignItems={'center'}>
        <Body weight={'semibold'} color={'black1'}>
          이용중
        </Body>
        {data.usingDriverList.length === 0 ? (
          <Caption2 weight={'medium'} color={'gray06'}>
            현재 이용중인 운전자가 없어요
          </Caption2>
        ) : null}
      </Row>
      {data.usingDriverList.map((el, index) => {
        return (
          <WrapItem key={index}>
            <Caption2 weight={'medium'} color={'primary'}>
              {formatCommaDateTimeKoreanUnit(el.parkingStartTime)} ~
            </Caption2>
            <Row justifyContent={'space-between'} style={{ marginTop: 10 }} alignItems={'center'}>
              <Headline weight={'semibold'} color={'black3'}>
                *** {el.carNumber.slice(-4)} 차량
              </Headline>
              <Button
                backgroundColor={colors.gray01}
                radius={17}
                horizontal={16}
                vertical={8}
                style={{
                  whiteSpace: 'nowrap',
                }}
                onClick={() => {
                  onClick(index);
                }}
              >
                <Caption1 weight={'medium'} color={'gray08'}>
                  출차 요청
                </Caption1>
              </Button>
            </Row>
            <Divider style={{ margin: '16px 0px' }} />
            <Row alignItems={'flex-start'} style={{ position: 'relative' }}>
              <Caption3
                weight={'regular'}
                color={'gray06'}
                style={{ width: pxToRem(50), marginRight: 8 }}
              >
                출차 예정
              </Caption3>
              <Caption3 weight={'medium'} color={'gray07'} style={{ marginRight: 4 }}>
                {el.reserveOutTime}
              </Caption3>
              <div
                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                  setIsTooltip(prev => !prev);
                  event.stopPropagation();
                }}
              >
                <IcTooltipHelpSVG width={16} height={16} />
                {isTooltip ? (
                  <div style={{ position: 'absolute', left: 32, top: 'calc(100%)' }}>
                    <ExitExpectedTooltip onClick={setIsTooltip} isOn={isTooltip} />
                  </div>
                ) : null}
              </div>
            </Row>
            <TipWrap>
              <Caption3 weight={'semibold'} color={'primary'} style={{ marginBottom: 8 }}>
                Tip.
              </Caption3>
              <Caption2 weight={'regular'} color={'gray07'} style={{ wordBreak: 'keep-all' }}>
                출차 요청 할 때 도착 30분 전에 미리 요청해야 원할한 출차가 가능해요.
              </Caption2>
            </TipWrap>
          </WrapItem>
        );
      })}
    </Container>
  );
};

export default ResidentParkingUsing;
