import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  padding: '20px 25px',
});

export const WrapDropdown = styled('div', {
  width: 152,
  height: 30,
  padding: '4px 10px',
  border: '1px solid #E6E6E6',
  borderRadius: 5,
  position: 'relative',
  boxSizing: 'border-box',
});

export const WrapCarNumber = styled('div', {
  width: 152,
  height: 30,
  padding: '0px 0px 0px 10px',
  border: '1px solid #E6E6E6',
  borderRadius: 5,
  position: 'relative',
  boxSizing: 'border-box',
});
