import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Tooltip } from './styles';

import { Caption1, Caption3 } from 'styles/typography';
import { IcCloseDarkGraySVG } from 'assets/ic';

interface Props {
  onClick: React.Dispatch<React.SetStateAction<boolean>>;
  isOn: boolean;
}

const ExitExpectedTooltip: React.FC<Props> = ({ onClick, isOn }) => {
  const navigate = useNavigate();

  return (
    <Tooltip>
      <IcCloseDarkGraySVG
        style={{
          position: 'absolute',
          top: 8,
          right: 8,
          padding: 8.3,
        }}
        onClick={e => {
          e.stopPropagation();
          onClick(false);
        }}
      />

      <Caption1 weight={'bold'} color={'primary'}>
        출차 예정 시간이란?
      </Caption1>
      <Caption3 weight={'regular'} color={'gray08'} style={{ marginTop: 8 }}>
        운전자가 알려준 예정 시간이에요.
        <br />이 시간은 출차 예정 시간으로, 확정된 출차 시간이 아니에요
      </Caption3>
    </Tooltip>
  );
};

export default ExitExpectedTooltip;
