import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Container, WrapTime, } from './styles';
import Row from 'components/atoms/Row';
import { Body, Caption1, Caption2, Caption3, Caption4, Headline, SubHeadline } from 'styles/typography';
import { IcArrowDown16SVG } from 'assets/ic';


interface Props {
  onClick: () => void;
  hour: string;
  minute: string;
}

const ShareStopTime: React.FC<Props> = ({ onClick, hour, minute }) => {
  const navigate = useNavigate()

  return (
    <Container>
      <Body weight={'semibold'} color={'black1'}>공유 중단 시간</Body>

      <WrapTime onClick={onClick}>
          {hour ? (<Caption1 weight={'semibold'} color={'black3'}>{hour}시 {minute}분</Caption1>) : (
            <Caption1 weight={'semibold'} color={'gray05'}>시간을 선택해 주세요.</Caption1>
          )}
          <IcArrowDown16SVG />
      </WrapTime>
      <Caption2 weight={'medium'} color={'primary'} style={{ marginBottom : 10}}>변경된 시간은 오늘만 적용됩니다.</Caption2>
      <Caption2 weight={'regular'} color={'gray09'}>• 내가 선택한 시간까지만 공유되어요.</Caption2>
      <Caption2 weight={'regular'} color={'gray09'}>• 설정한 시간은 바꿀 수 없으며, 오늘 지나면 초기화돼요</Caption2>
      <Caption2 weight={'regular'} color={'gray09'} style={{ whiteSpace : 'pre-wrap' }}>{`• 궁금하신 점이 있다면 고객센터로 문의해 주세요.\n(1666-6248)`}</Caption2>
    </Container>

  )
}

export default ShareStopTime;