import { styled } from '@stitches/react';

export const Container = styled('div', {
  backgroundColor: '#fff',
  position: 'relative',
  padding: '20px 0px 0px 0px',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

export const PhoneNumberContainer = styled('div', {
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  marginLeft: '25px',
  marginRight: '25px',
  position: 'relative',
  zIndex: 1,
});

// 전화번호와 레이블을 가로로 정렬
export const PhoneNumberHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: '#868686',
});

// 전화번호 오른쪽 정렬
export const PhoneNumber = styled('div', {
  fontSize: '18px',
  textAlign: 'right',
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  fontFamily: 'Pretendard',
  textSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  color: '#8B63BB',
});

// 연락처 안내 정보
export const ContactInfo = styled('div', {
  fontSize: '12px',
  color: '#6B6B6B',
  display: 'flex',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  marginTop: '7px',
  justifyContent: 'flex-end',
});

export const SearchContainer = styled('div', {
  height: '45px',
  background: '#F8F8FA',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: '13px',
  paddingRight: '13px',
  marginTop: '32px',
  color: '#868686',
});

export const SearchIcon = styled('img', {
  width: '20px',
  height: '20px',
});

export const WrapMap = styled('div', {
  marginTop: '20px',
  flexGrow: 1,
});

export const AdviceButton = styled('div', {
  width: 'calc(100% - 40px)',
  height: '46px',
  background: '#F2F2F2',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  color: '#8B63BB',
  position: 'fixed',
  bottom: '30px',
  left: 20,
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontWeight: '400',
});
