import { styled } from '@stitches/react';

// Dialog - 다이얼로그
export const Dialog = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: '85%', // 다이얼로그의 높이 조절
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  borderRadius: '20px 20px 0px 0px',
  zIndex: 10000,
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' },
      false: { transform: 'translateY(100%)' },
    },
  },
});

export const WrapMap = styled('div', {
  flexGrow: 1,
});

export const Title = styled('div', {
  padding: '28px 6px 12px 20px',
});
