import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  backgroundColor: '#F3F4F6',
  padding: '28px 20px 28px 20px',
});

export const WrapItem = styled('div', {
  backgroundColor: '#fff',
  padding: '20px 16px',
  marginTop: '20px'
});