import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  backgroundColor: '#fff',
  padding: '28px 20px 28px 20px',
});

export const WrapItem = styled('div', {
  backgroundColor: '#fff',
  marginTop: '20px'
});

export const WrapInfo = styled('div', {
  backgroundColor: colors.primaryBackground,
  padding: '10px',
  borderRadius: '8px',
  marginTop: '10px'
});