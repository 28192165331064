import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Container } from './styles';
import { Caption1, Caption4 } from 'styles/typography';
import Row from 'components/atoms/Row';
import { colors } from 'styles/colors';
import Divider from 'components/atoms/Divider';

const GeojuSuccessUseInfo = ({ type = '' }: { type?: string }) => {
  const navigate = useNavigate();

  const contents = [
    {
      title: '차량번호',
      desc: '123가5678',
    },
    {
      title: '자리번호',
      desc: '06-23 주차장 1-1 구획',
    },
    {
      title: '주차 시작 시간',
      desc: '2023-07-13 14:52:11',
    },
    {
      title: '주차 종료 시간',
      desc: '2023-07-13 14:52:11',
    },
  ];

  return (
    <Container>
      <Caption1 weight={'bold'} color={'gray07'} style={{ marginBottom: 10 }}>
        이용 정보
      </Caption1>

      {contents.map((el, index) => {
        return (
          <Row key={index} justifyContent={'space-between'} style={{ marginTop: 10 }}>
            <Caption4 weight={'regular'} color={'gray06'}>
              {el.title}
            </Caption4>

            <Row>
              <Caption4 weight={'regular'} color={'black1'}>
                {el.desc}
              </Caption4>
            </Row>
          </Row>
        );
      })}
      <Divider style={{ backgroundColor: colors.gray03, marginTop: 20 }} />
    </Container>
  );
};

export default GeojuSuccessUseInfo;
