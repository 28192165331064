import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  padding: '20px 25px',
});

export const ParkingIcon = styled('img', {
  marginRight: '8px',
});
