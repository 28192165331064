import { styled } from '@stitches/react';

export const Container = styled('div', {
  backgroundColor: '#fff',
  position: 'relative',
  margin: '20px 0px',
});

export const PhoneNumberContainer = styled('div', {
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  marginLeft: '25px',
  marginRight: '25px',
});

export const SearchContainer = styled('div', {
  height: '45px',
  background: '#F8F8FA',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: '13px',
  paddingRight: '13px',
  marginTop: '16px',
  color: '#868686',
});

export const SearchIcon = styled('img', {
  width: '20px',
  height: '20px',
})

export const WrapMap = styled('div', {
  marginTop: '20px'
})



