import BaseService from '../baseService';
import BaseError from '../../models/baseError';
import AxiosClient from '../../utils/axiosClient';
import ErrorHandler from '../../utils/error/errorHandler';
import {IMyParkingUseHistory, IMyParkingUseHistoryResponse} from "models/MyParking/myParkingUseHistoryModel";

interface MyParkingUseHistoryEndpoint {
  MY_PARKING_USE_HISTORY: string;
}

class MyParkingUseHistoryService extends BaseService<MyParkingUseHistoryEndpoint> {
  apiEndpoints: MyParkingUseHistoryEndpoint = {
    MY_PARKING_USE_HISTORY: 'getUseHistory',
  }

  getUseHistory = async (
    requestBody: IMyParkingUseHistory,
    pageNum: number
  ): Promise<IMyParkingUseHistoryResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .url(`${this.apiEndpoints.MY_PARKING_USE_HISTORY}/${requestBody.mLinkUID}`)
        .params({ pageNum: pageNum })
        .build<IMyParkingUseHistoryResponse>()

      if (response.data.res_code === 200) {
        return response.data
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError()
      }
    } catch (error) {
      console.error(error)
      return ErrorHandler.create(error).getError()
    }
  }
}

export default new MyParkingUseHistoryService()
