import { styled } from '@stitches/react';

export const Container = styled('div', {
  backgroundColor: '#fff',
  position: 'relative',
});

export const WrapItem = styled('div', {
  margin: '10px 20px 28px 20px'
});
