import { styled } from '@stitches/react';
import { colors } from 'styles/colors';
import { pxToRem } from 'utils/common';

export const Tooltip = styled('button', {
  borderRadius: '8px',
  padding: '16px',
  border: `1px solid ${colors.gray03}`,
  background: '#fff',
  boxShadow: '1px 2px 16px 0px rgba(0, 0, 0, 0.16)',
  wordBreak: 'keep-all',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  textAlign: 'left',
  width: pxToRem(240),
});
