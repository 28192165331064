import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

import Title from 'components/atoms/Title/PageTitle';
import { MainContainer, MainContainerNoMargin } from 'components/styles';
import imgZoomansaLarge from "assets/images/img_zoomansa_large.svg";
import { UserInfoInputContainer, LogoContainer } from "./styles";
import { useForm, SubmitHandler } from "react-hook-form";
import { Caption2, SubHeadline } from 'styles/typography';
import PopupModal from 'components/atoms/Modal/PopupModal';

// Define the form values type
interface IFormInputs {
  userId: string;
  userPassword: string;
}

const ZMSLogin: React.FC = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, watch } = useForm<IFormInputs>();

  const [isPopup, setIsPopup] = useState(false)

  const userId = watch('userId', '');
  const userPassword = watch('userPassword', '');

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {

    // 가입된 유저 정보가 없을때
    setIsPopup(true)

    console.log(data);
    console.log('로그인 화면 이동')
  };

  const searchUserIdAndPwClick = () => {
    navigate('/searchMyInfo')
  }

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const buttonStyle = {
    padding: '0px',
    border: '0px',
    width: 'calc(100% - 50px)',
    marginLeft: '25px',
    height: '46px',
    color: userId && userPassword ? 'white' : '#8B63BB',
    backgroundColor: userId && userPassword ? '#8B63BB' : '#F2F2F2',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: 'normal',
    borderRadius: '5px',
    cursor: userId && userPassword ? 'pointer' : 'not-allowed',
  };

  return (
      <MainContainerNoMargin>
        <Title
            title="아이디 로그인"
            leftArrowButtonVisible={true}
            leftOnClick={handleLeftOnClick}
            rightTextButtonVisible={false}
        />
        <LogoContainer>
          <img src={imgZoomansaLarge} alt="Zoomansa Logo" />
        </LogoContainer>

        <UserInfoInputContainer style={{ marginTop: '57px' }}>
          <input
              type="text"
              id="userId"
              placeholder="아이디"
              {...register('userId', { required: '필수 입력 항목입니다.' })}
              style={{
                padding: '5px',
                border: '0px',
                width: 'calc(100% - 60px)',
                marginLeft: 25,
                outline: 'none',
                borderBottom: '1px solid #e6e6e6',
                fontWeight: 700,
                color: '#868686',
                fontSize: '16px'
              }}
          />
        </UserInfoInputContainer>

        <UserInfoInputContainer style={{ marginTop: '41px' }}>
          <input
              type="password"
              id="userPassword"
              placeholder="비밀번호"
              {...register('userPassword', { required: '필수 입력 항목입니다.' })}
              style={{
                padding: '5px',
                border: '0px',
                width: 'calc(100% - 60px)',
                outline: 'none',
                marginLeft: 25,
                borderBottom: '1px solid #e6e6e6',
                fontWeight: 700,
                color: '#eac6c6',
                fontSize: '16px'
              }}
          />
        </UserInfoInputContainer>

        <UserInfoInputContainer style={{ marginTop: '38px' }}>
          <button
              style={buttonStyle}
              onClick={handleSubmit(onSubmit)}
              disabled={!userId || !userPassword}
          >
            <SubHeadline weight={'regular'} color={!userId || !userPassword ? 'primary' : 'white'}>
              로그인
            </SubHeadline>
          </button>
        </UserInfoInputContainer>

        <div
          style={{
            marginTop: 15,
            textAlign: 'center', alignItems: 'center'
          }}
             onClick={() => {
          searchUserIdAndPwClick()
        }}
        >
          <Caption2 weight={'regular'} color={'gray07'}>
            아이디 · 비밀번호 찾기
          </Caption2>
        </div>

        <PopupModal
          isVisible={isPopup}
          onClose={() => {
            setIsPopup(false)
          }}
          title1={'가입된 정보가 없습니다\n회원가입을 진행할까요?'}
          leftClick={() => {
            setIsPopup(false)
          }}
          leftText={'취소'}
          rightText={'확인'}
          rightClick={() => {
            setIsPopup(false)
          }}

        />
      </MainContainerNoMargin>
  );
};

export default ZMSLogin;
