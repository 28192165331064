import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CarInfoContentsText,
  CarInfoEditContainer,
  CarInfoNotifyContainer,
  CarNumberContainer,
  CarNumberSpan,
  CarNumberTextSpan,
  CarNumberToggleContainer,
  CarRepresentTextContainer,
  ModifyDeleteContainer,
  ModifyDeleteTextSpan,
  ToggleButtonContainer,
  ViewDivideContainer,
} from './styles';
import TwoButtonModal from '../../../../components/atoms/Modal/TwoButtonModal';
import Divider from 'components/atoms/Divider';
import { colors } from 'styles/colors';

interface CarInfoListProps {
  carNumber: string;
  status: string;
  isLast?: boolean;
  isToggled: boolean;
  onToggle: () => void; // onToggle prop 추가
}

const CarInfoList: React.FC<CarInfoListProps> = ({
  carNumber,
  status,
  isLast,
  isToggled,
  onToggle,
}) => {
  const navigate = useNavigate();
  const [isCarNumberDeleteModal, setIsCarNumberDeleteModal] = useState(false);

  const carInfoDeleteModal = () => {
    setIsCarNumberDeleteModal(true);
  };

  return (
    <div style={{ paddingBottom: isLast ? '0px' : '16px' }}>
      <CarInfoEditContainer isToggled={isToggled}>
        <CarNumberToggleContainer>
          <CarNumberContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CarNumberTextSpan>차량번호</CarNumberTextSpan>
              {isToggled && <CarRepresentTextContainer>대표차량</CarRepresentTextContainer>}
            </div>
            <CarNumberSpan>{carNumber}</CarNumberSpan>
            <span
              style={{
                marginTop: '8px',
                color: '#8B63BB',
                fontSize: '13px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '18px',
              }}
            >
              {status}
            </span>
          </CarNumberContainer>
          <ToggleButtonContainer isToggled={isToggled} onClick={onToggle} />{' '}
          {/* onToggle 함수 호출 */}
        </CarNumberToggleContainer>
        <Divider style={{ backgroundColor: colors.gray02 }} />
        <ModifyDeleteContainer>
          <ModifyDeleteTextSpan onClick={carInfoDeleteModal}>삭제</ModifyDeleteTextSpan>
          <span style={{ height: '100%', color: colors.gray02 }}>|</span>
          <ModifyDeleteTextSpan onClick={() => navigate('/carInfoEdit')}>수정</ModifyDeleteTextSpan>
        </ModifyDeleteContainer>
      </CarInfoEditContainer>

      {isLast && (
        <CarInfoNotifyContainer>
          <CarInfoContentsText>대표차량은 한대만 등록 가능합니다.</CarInfoContentsText>
          <CarInfoContentsText>주차장 이용 시 대표차량이 기본으로 선택 됩니다.</CarInfoContentsText>
        </CarInfoNotifyContainer>
      )}

      <TwoButtonModal
        isVisible={isCarNumberDeleteModal}
        onClose={() => setIsCarNumberDeleteModal(false)}
        title={'차량 번호를 삭제하시겠습니까?'}
        content={''}
        leftButtonText={'취소'}
        rightButtonText={'삭제'}
        rightClick={() => {
          navigate('/carRegistration');
        }}
      />
    </div>
  );
};

export default CarInfoList;
