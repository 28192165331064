import React, { ReactNode } from 'react';
import { styled } from '@stitches/react';

interface RowProps {
  children: ReactNode;
  gap?: number; // 자식 요소 간의 간격 (픽셀 단위)
  justifyContent?: 'flex-start' | 'center' | 'space-between' | 'space-evenly' | 'flex-end'; // 가로 정렬
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch'; // 세로 정렬
  style?: React.CSSProperties; // 추가적인 스타일
}

// Row 컴포넌트 정의
const RowWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center', // 기본 세로 정렬을 중앙으로 설정
  variants: {
    justifyContent: {
      'flex-start': { justifyContent: 'flex-start' },
      center: { justifyContent: 'center' },
      'space-between': { justifyContent: 'space-between' },
      'space-evenly': { justifyContent: 'space-evenly' },
      'flex-end': { justifyContent: 'flex-end' },
    },
    alignItems: {
      'flex-start': { alignItems: 'flex-start' },
      center: { alignItems: 'center' },
      'flex-end': { alignItems: 'flex-end' },
      stretch: { alignItems: 'stretch' },
    },

  },
  defaultVariants: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});

const Row: React.FC<RowProps> = ({
     children,
     justifyContent = 'flex-start',
     alignItems = 'center',
     style,
 }) => {
  return (
    <RowWrapper
      justifyContent={justifyContent}
      alignItems={alignItems}
      style={style}
    >
      {children}
    </RowWrapper>
  );
};

export default Row;
