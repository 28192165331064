import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, WrapMap } from './styles';
import { Body2, Caption1, Caption2 } from 'styles/typography';
import Row from 'components/atoms/Row';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import Divider from 'components/atoms/Divider';
import { formatPriceWithCommas } from 'utils/number';

const GeojuPaymentInfo = ({ type = '' }: { type?: string }) => {
  const navigate = useNavigate();

  const contents = [
    {
      title: '주차요금',
      desc: '1,200원',
    },
    {
      title: '장애인 감면(50%)',
      desc: '-600원',
    },
    {
      title: '쿠폰 적용',
      desc: '-0원',
    },
  ];

  return (
    <Container>
      <Caption1 weight={'bold'} color={'gray07'} style={{ marginBottom: 10 }}>
        이용 정보
      </Caption1>

      {contents.map((el, index) => {
        return (
          <Row key={index} justifyContent={'space-between'} style={{ marginTop: 10 }}>
            <Caption1 weight={'bold'} color={'gray05'}>
              {el.title}
            </Caption1>

            <Row>
              <Caption1 weight={'regular'} color={'black3'}>
                {el.desc}
              </Caption1>
            </Row>
          </Row>
        );
      })}
      <Divider style={{ margin: '10px 0px', backgroundColor: colors.gray02 }} />
      <Row justifyContent={'space-between'} style={{ marginTop: 10 }}>
        <Caption1 weight={'bold'} color={'primary'}>
          결제금액
        </Caption1>

        <Row>
          <Body2 weight={'bold'} color={'primary'} style={{ marginRight: 6 }}>
            {formatPriceWithCommas('6000')}원
          </Body2>
        </Row>
      </Row>
    </Container>
  );
};

export default GeojuPaymentInfo;
