import React, { useState, useEffect } from 'react';

import { Modal } from './styles';
import Alexander from 'assets/images/alexander.png';
import ParkImg from 'assets/images/img_park.png';
import { Caption2, Headline, SubHeadline } from 'styles/typography';
import Row from 'components/atoms/Row';
import { IcClose48SVG, IcTooltipAlertSVG, IcTooltipHelpSVG } from 'assets/ic';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import TimePicker from 'components/atoms/TimePicker';
import { useToast } from 'components/atoms/Toast/ToastProvider';

interface BottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  rightClick: (hour: string, minute: string) => void;
  shareEndTime?: string;
  title?: string;
}

const ShareStopModal: React.FC<BottomModalProps> = ({
  isVisible,
  onClose,
  rightClick,
  shareEndTime,
  title = '',
}) => {
  const { showToast } = useToast();

  const [selectedHour, setSelectedHour] = useState<string>('99');
  const [selectedMinute, setSelectedMinute] = useState<string>('0');

  if (!isVisible) return null;

  const onChange = (hour: string, minute: string) => {
    setSelectedHour(hour);
    setSelectedMinute(minute);
  };

  let nowHours;
  const endHour = Number(shareEndTime?.slice(0, 2)); // 기본 종료 시간은 23시

  const createTimeArray = (): string[] => {
    const now = new Date();

    const utc = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
    const koreaTimeDiff = 9 * 60 * 60 * 1000;
    const koreaTime = new Date(utc + koreaTimeDiff);

    // 30분을 넘지 않으면 그대로 현재 시간, 30분을 넘으면 다음 시간으로 설정
    const currentHour = koreaTime.getHours();
    const currentMinute = koreaTime.getMinutes();
    // 30분을 넘었으면 한 시간 올림

    nowHours = now.getHours();

    if (currentMinute >= 30) {
      now.setHours(currentHour + 1); // 한 시간 증가
    } else {
      now.setMinutes(0); // 분은 00으로 설정
    }

    const hoursArray: string[] = [];

    let hours = now.getHours();

    // 시작 시간부터 23시까지 시간만 표시 (1시간 간격)
    while (hours <= endHour) {
      hoursArray.push(String(hours));
      hours = hours + 1;
    }

    // 마지막에 빈 문자열 2개 추가
    hoursArray.push('', '');

    return hoursArray;
  };

  const hours = createTimeArray();

  // 사용하는 시간 및 분 배열
  const minutes = ['00', '30', '', ''];
  const disabled =
    String(nowHours) === hours[0] && selectedHour === hours[0] && selectedMinute === '00';
  const notSelected = endHour === Number(selectedHour) && String(selectedMinute) === '30';

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 9999,
        }}
        onClick={onClose}
      />
      <Modal isVisible={isVisible}>
        <Headline weight={'medium'} color={'black1'}>
          {title}
        </Headline>

        <TimePicker onChange={onChange} minutes={minutes} hours={hours} />
        <Row style={{ gap: '10px' }}>
          <Button
            backgroundColor={colors.gray01}
            radius={4}
            style={{
              height: 46,
              width: 'calc(100%)',
              marginTop: 28,
            }}
            onClick={() => {
              onClose();
            }}
          >
            <Caption2 weight={'semibold'} color={'gray06'}>
              취소
            </Caption2>
          </Button>
          <Button
            backgroundColor={notSelected ? colors.gray05 : colors.primary}
            radius={4}
            style={{
              height: 46,
              width: 'calc(100%)',
              marginTop: 28,
            }}
            onClick={() => {
              if (disabled) {
                showToast('지난 시간은 설정할 수 없어요.');
              } else {
                onClose();
                rightClick(selectedHour, selectedMinute);
              }
            }}
            disabled={notSelected}
          >
            <Caption2 weight={'semibold'} color={'white'}>
              확인
            </Caption2>
          </Button>
        </Row>
      </Modal>
    </>
  );
};

export default ShareStopModal;
