import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import Title from 'components/atoms/Title/PageTitle';
import { MainContainerNoMargin } from 'components/styles';
import MinganParkingInfo from 'containers/share/MinganParkingInfo';
import MinganMonthParkInfo from 'containers/share/MinganMonthParkInfo';
import Divider from 'components/atoms/Divider';
import { colors } from 'styles/colors';
import Button from 'components/atoms/Button/Button';
import { Caption1 } from 'styles/typography';
import { Container } from 'pages/Share/MinganMyParkingPage/styles';
import OperatingTimeModal from 'components/atoms/Modal/OperatingTimeModal';
import CustomBottomModal from 'components/atoms/Modal/CustomBottomModal';
import { shareStore } from 'stores/store/Share/shareStore';
import { parkingLotInfo, parkingLots } from 'models/Share/shareHistoryModel';
import { handleChatClick, moveJSP } from 'utils/common';
import { useFetchUserInfo } from 'hooks/useFetchUserInfo';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import { Spinner } from 'components/atoms/LoadingSpinner/LoadingSpinnerImage/styles';

const MinganMyParkingPage = observer(() => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { slotUID } = state;

  const [isOperating, setIsOperating] = useState(false);
  const [isStop, setIsStop] = useState(false);
  const [selectedSlotInfo, setSelectedSlotInfo] = useState<parkingLots | null>(null);

  const { productList, existUsing, parkingLotInfo, isLoading } = shareStore;

  const UsedUserModalText = {
    title: '현재 이용중인 운전자가 있어요.',
    description:
      '운전자와의 공유를 중단하시나요? 주차장 공유 중지는  “주만사 월주차” 카톡 채널로 문의 후 중지할 수 있어요.',
  };

  const UnUsedUserModalText = {
    title: `No.${selectedSlotInfo?.slotUID} 주차장을\n판매 중지하시나요?`,
    description: '주차장 판매 중지는  “주만사 월주차” 카톡 채널로 문의 후 중지할 수 있어요.',
  };

  const SaleRestartModalText = {
    title: `No.${selectedSlotInfo?.slotUID} 주차장\n판매를 다시 시작하나요?`,
    description: '주차장 판매 재개는 “주만사 월주차” 카톡 채널로 문의 후 시작할 수 있어요.',
  };

  const isSale: boolean = selectedSlotInfo?.shareYN === 'YES';
  const isUsedUser: boolean = existUsing?.existUsingDriversYN ?? false;
  const ModalText = !isSale
    ? SaleRestartModalText
    : isUsedUser
      ? UsedUserModalText
      : UnUsedUserModalText;

  useEffect(() => {
    if (parkingLotInfo?.parkingLotInfo && slotUID) {
      // slotUID가 일치하는 항목 찾기
      const matchingSlot = parkingLotInfo.parkingLotInfo.parkingLots.find(
        lot => lot.slotUID === slotUID,
      );

      // 상태 업데이트
      setSelectedSlotInfo(matchingSlot ?? null);
    }
  }, [parkingLotInfo, slotUID]);

  useEffect(() => {
    // 데이터를 가져오는 함수 정의
    const fetchData = async () => {
      try {
        await shareStore.fetchGetLotProductList(slotUID);
        await shareStore.fetchExitAnyUsing(slotUID);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // 데이터 가져오기 호출
  }, []);

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const onCloseStopModal = () => {
    setIsStop(false);
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '50vh',
        }}
      >
        <Spinner />
      </div>
    );
  }
  return (
    <MainContainerNoMargin>
      <Title
        title="내 주차장"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
        rightTitle={'운영정보'}
        rightOnClick={() => {
          setIsOperating(true);
        }}
      />
      <Container>
        {selectedSlotInfo ? (
          <MinganParkingInfo isSale={isSale} data={selectedSlotInfo} productList={productList} />
        ) : null}
        {productList?.monthlyProductInfo && productList?.monthlyProductInfo.length ? (
          <MinganMonthParkInfo
            isSale={isSale}
            type={'월정기권'}
            data={productList?.monthlyProductInfo}
            slotUID={slotUID}
          />
        ) : null}
        {productList?.dailyProductInfo && productList?.dailyProductInfo.length ? (
          <>
            <Divider style={{ height: 8, backgroundColor: colors.gray01 }} />
            <MinganMonthParkInfo
              isSale={isSale}
              type={'시간권'}
              data={productList?.dailyProductInfo}
              slotUID={slotUID}
            />
          </>
        ) : null}

        <Button
          style={{
            height: 40,
            boxShadow: '0px 5px 20px 0px rgba(0, 0, 0, 0.25)',
            position: 'fixed',
            bottom: 28,
            left: '50%',
            transform: 'translateX(-50%)',
            whiteSpace: 'nowrap',
          }}
          horizontal={16}
          radius={20}
          borderColor={colors.gray09}
          onClick={() => setIsStop(true)}
        >
          <Caption1 weight={'medium'} color={'gray09'}>
            {isSale ? '주차장 판매 중지하기' : '주차장 판매를 다시 시작할까요?'}
          </Caption1>
        </Button>
      </Container>

      <OperatingTimeModal
        isVisible={isOperating}
        onClose={() => setIsOperating(false)}
        data={productList?.operTimeInfo ?? null}
      />
      <CustomBottomModal
        isVisible={isStop}
        onClose={onCloseStopModal}
        title1={ModalText.title}
        description={ModalText.description}
        leftClick={onCloseStopModal}
        leftText={'취소'}
        rightClick={() => {
          handleChatClick();
          onCloseStopModal();
        }}
        rightText={'문의하러 가기'}
      />
    </MainContainerNoMargin>
  );
});

export default MinganMyParkingPage;
