import Title from '../../../components/atoms/Title/PageTitle';
import {
  AddressContainer,
  BottomButtonContainer,
  BottomDifferentParkingTicketButton,
  BottomLeftButton,
  BottomRightButton,
  CarNumberParkingDateSpan,
  CarParkingInfoContainer,
  ParkingInfoContainer,
  PaymentInfoContentContainer,
  PaymentItemContainer,
  PaymentValue,
  ReceiptContainer,
  ReceiptIc,
  ReceiptSpan,
  TerraceTowerContainer,
  TicketTimeInfoContainer,
  TicketTimeInfoDetailContainer,
  ViewDivide1,
  ViewDivide3,
} from '../styles';
import icReceipt from 'assets/ic/ic_receip.svg';
import { JSP_URL } from 'constants/baseConstants';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { copyToClipboard } from 'utils/clipboardUtils';
import icSuccessCheckGreen from 'assets/ic/ic_success_check_green.svg';
import icFailCheckRed from 'assets/ic/ic_fail_check_red.svg';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import { Caption1, Caption2, Caption3, Headline, SubHeadline } from 'styles/typography';
import { terraceTowerUsageHistoryDetailStore } from 'stores/store/TerraceTower/terraceTowerUsageHistoryDetailStore';
import RefundBottomModal from 'components/atoms/Modal/RefundBottomModal';
import { showWebBrowser } from 'utils/deviceUtils';
import LoadingSpinnerScreen from 'components/atoms/LoadingSpinner/LoadingSpinnerScreen';
import { observer } from 'mobx-react';
import { MainContainer, MainContainerNoMargin } from 'components/styles';
import { Container } from 'pages/Share/ShareHistoryPage/styles';

// 결제 취소만 세팅
const TerraceTowerPaymentCancelPage = observer(() => {
  const { showToast } = useToast();
  const navigate = useNavigate();
  const [isRefundBottomModal, setIsRefundBottomModal] = useState(false);

  const {
    usageStatus,
    lotName,
    address,
    productName,
    carNumber,
    parkingAvailTime,
    paymentMethod,
    paymentAmount,
    paymentDate,
    receiptURL,
    refundReqDate,
  } = terraceTowerUsageHistoryDetailStore.usageHistoryDetail || {};

  const ticketInfoList = [
    // { label: '차량정보', value: carNumber },
    // { label: '주차가능 일시', value: '2023-11-11 18:00~23:59' },
    // usageStatus === '결제취소' ? { label: '취소일시', value: '2023-11-11 18:20' } :
    //   usageStatus === '이용완료' ? { label: '이용완료', value: '2023-11-11 18:20' }
    //   : null,

    { label: '차량정보', value: carNumber },
    { label: '주차가능 일시', value: parkingAvailTime },
    { label: '취소일시', value: refundReqDate },
  ];

  const paymentInfo = [
    // { label: '결제수단', value: '카드결제' },
    // { label: '결제금액', value: '7,000원' },
    // { label: '결제일시', value: '2024-11-11 12:43' },
    // usageStatus === '결제취소' ? { label: '취소일시', value: '2024-11-11 15:50' } : null,
    { label: '결제수단', value: paymentMethod },
    { label: '결제금액', value: paymentAmount },
    { label: '결제일시', value: paymentDate },
    { label: '취소일시', value: refundReqDate },
  ];

  const saleHistoryUID = sessionStorage.getItem('saleHistoryUID') || '';

  if (saleHistoryUID !== null) {
    console.log(`불러온 값: ${saleHistoryUID}`);
  } else {
    console.log('세션스토리지에 값이 없습니다.');
  }

  useEffect(() => {
    const fetchTerraceTowerUsageHistoryDetail = async () => {
      await terraceTowerUsageHistoryDetailStore.fetchTerraceTowerUsageHistoryDetail(saleHistoryUID);
    };
    fetchTerraceTowerUsageHistoryDetail().catch(error => {
      console.log('테라스 타워 주차정 정보 가져오는 중 에러 발생', error);
    });
  }, []);

  const moveJSP = () => {
    window.location.href = JSP_URL;
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      moveJSP();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  if (terraceTowerUsageHistoryDetailStore.isLoading) {
    return <LoadingSpinnerScreen />;
  }

  return (
    <MainContainerNoMargin>
      <Title
        title="상세내역"
        leftArrowButtonVisible={true}
        leftOnClick={() => {
          moveJSP();
        }}
        rightTextButtonVisible={false}
      />

      <TerraceTowerContainer>
        <Headline weight={'bold'} style={{ color: '#868686' }}>
          {usageStatus}
        </Headline>
        <SubHeadline weight={'semibold'} color={'black1'} css={{ marginTop: 6 }}>
          {lotName}
        </SubHeadline>
        <AddressContainer
          onClick={() => {
            if (address) {
              copyToClipboard(address, showToast, icSuccessCheckGreen, icFailCheckRed);
            } else {
              console.error('주소가 없습니다.');
            }
          }}
        >
          <Caption2 weight={'regular'} css={{ color: '#6b6b6b', marginRight: 4 }}>
            {address}
          </Caption2>
          <Caption2 weight={'medium'} color={'blue'}>
            복사
          </Caption2>
        </AddressContainer>
      </TerraceTowerContainer>
      <ViewDivide1 />
      <TicketTimeInfoContainer style={{ margin: '28px 20px' }}>
        <SubHeadline weight={'semibold'} css={{ color: '#333' }}>
          {productName}
        </SubHeadline>
        <ViewDivide3 />
        <CarParkingInfoContainer>
          {ticketInfoList.map((ticketItem, index) => {
            if (ticketItem)
              return (
                <ParkingInfoContainer key={index} style={{ marginTop: index === 0 ? 0 : '10px' }}>
                  <Caption1 weight={'regular'} css={{ color: '#868686' }}>
                    {ticketItem.label}
                  </Caption1>
                  <Caption1 weight={'medium'} css={{ color: '#555555' }}>
                    {ticketItem.value}
                  </Caption1>
                </ParkingInfoContainer>
              );
          })}
        </CarParkingInfoContainer>
      </TicketTimeInfoContainer>

      <ViewDivide1 />
      <TerraceTowerContainer>
        <PaymentInfoContentContainer>
          <SubHeadline weight={'bold'}>결제정보</SubHeadline>
          <ReceiptContainer
            onClick={() => {
              if (!receiptURL) {
                console.error('receiptURL이 정의되지 않음');
                return;
              }
              showWebBrowser(receiptURL);
            }}
          >
            <ReceiptIc src={icReceipt} alt={'영수증 아이콘'} />
            <Caption3 weight={'medium'} css={{ color: '#555555', marginLeft: 4 }}>
              영수증
            </Caption3>
          </ReceiptContainer>
        </PaymentInfoContentContainer>

        {paymentInfo.map((info, index) => {
          if (info)
            return (
              <PaymentItemContainer key={index} isFirst={index === 0}>
                <Caption1 weight={'regular'} style={{ color: '#868686' }}>
                  {info.label}
                </Caption1>
                <PaymentValue isUsePayment={index === 0}>{info.value}</PaymentValue>
              </PaymentItemContainer>
            );
        })}
      </TerraceTowerContainer>
      <BottomButtonContainer>
        <BottomDifferentParkingTicketButton
          onClick={() => {
            window.location.href = JSP_URL;
          }}
        >
          다른 주차권 보기
        </BottomDifferentParkingTicketButton>
      </BottomButtonContainer>
      <RefundBottomModal
        isVisible={isRefundBottomModal}
        onClose={() => {
          setIsRefundBottomModal(false);
        }}
        usageStatus={usageStatus ?? ''}
      />
    </MainContainerNoMargin>
  );
});
export default TerraceTowerPaymentCancelPage;
