import { userInfoStore } from '../stores/store/UserInfo/userInfoStore';

import { IUserInfo } from '../models/UserInfo/userInfoModel';
import useUserInfo from '../hooks/useUserInfo';
import { encTextWord, getRandomString } from '../utils/cryptoJS';

export const useFetchUserInfo = () => {
  const { memberID, memberPW, loginType } = useUserInfo(); // useUserInfo 훅 호출

  const fetchUserInfo = async (): Promise<void> => {
    const { iv, salt } = getRandomString()
    const memberEncPw = encTextWord(memberPW, iv, salt)

    // 테스트 후 변경 필요
    const requestUserInfo: IUserInfo = {
      memberID: memberID,
      memberPW: memberEncPw,
      loginType: loginType,
    }
    try {
      if (requestUserInfo.memberID && requestUserInfo.memberPW && requestUserInfo.loginType) {
        await userInfoStore.fetchUserInfoSearch(requestUserInfo, iv, salt)
      }

    } catch (error) {
      console.log('유저정보 가져오기 중 오류가 발생했습니다.', error)
      throw error
    }
  }
  return { fetchUserInfo }
}
