import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, Image, ImageButton, ItemContainer, WrapImage } from './styles';
import { Body, Caption1, Caption2 } from 'styles/typography';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import { IcBellSVG, IcPhotoAddSVG, IcPhotoUpload18SVG } from 'assets/ic';
import Row from 'components/atoms/Row';
import Divider from 'components/atoms/Divider';
import { parkingLots } from 'models/Share/shareHistoryModel';
import { IShareProductListData } from 'models/Share/shareProductListModel';
import { showWebBrowser } from 'utils/deviceUtils';
import ParkingPhotoExpansionModal from 'components/atoms/Modal/ParkingPhotoExpansionModal';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import { formatDateDay, formatDateRange } from 'utils/dateUtils';

interface Props {
  isSale: boolean;
  data: parkingLots;
  productList: IShareProductListData | null;
}

const MinganParkingInfo: React.FC<Props> = ({ isSale, data, productList }) => {
  const navigate = useNavigate();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [startIndex, setStartIndex] = useState<number>(0);
  const [currentImages, setCurrentImages] = useState<string[]>([]);

  const { phoneNumber, memberData } = userInfoStore;

  const openModal = (images: string[], index: number) => {
    setCurrentImages(images);
    setStartIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentImages([]);
  };

  const images = productList?.slotImageList.map(el => el.imagePath);

  return (
    <Container>
      <ItemContainer>
        <Row justifyContent={'space-between'}>
          {isSale ? (
            <Caption1 weight={'medium'} color={data.completeDriversCnt ? 'primary' : 'gray06'}>
              {data.completeDriversCnt}대 이용중
            </Caption1>
          ) : (
            <Caption1 weight={'medium'} color={'gray06'}>
              판매 중지일 : {formatDateDay(data.saleEndDate.split(' ')[0]).split('(')[0]}
            </Caption1>
          )}

          <Button
            backgroundColor={isSale ? colors.primarySubSub : colors.gray03}
            vertical={3}
            horizontal={8}
            radius={4}
          >
            <Row>
              <Caption2 weight={'medium'} color={isSale ? 'primary' : 'gray07'}>
                {isSale ? '판매중' : '판매중지'}
              </Caption2>
            </Row>
          </Button>
        </Row>

        <Body weight={'medium'} color={'black1'} style={{ marginTop: 8 }}>
          {data.roadLotAddr}
        </Body>
        <Row style={{ marginTop: productList?.slotImageList.length || isSale ? 28 : 0 }}>
          {productList?.slotImageList.slice(0, 5).map((el, index) => {
            return (
              <WrapImage key={index} onClick={() => openModal([el.imagePath], index)}>
                <Image src={el.imagePath} alt={'주차장'} />
              </WrapImage>
            );
          })}
          {isSale ? (
            <ImageButton
              onClick={() => {
                showWebBrowser(
                  `https://parking.zoomansa.com/mobileAPI/service/uploadSlotImage.jsp?authID=${phoneNumber}`,
                );
              }}
            >
              <IcPhotoUpload18SVG />
            </ImageButton>
          ) : null}
        </Row>
        {isSale && data.waitingDriversCnt !== 0 ? (
          <Button
            backgroundColor={'#F2EEF8'}
            style={{
              width: 'calc(100%)',
              justifyContent: 'flex-start',
              marginTop: 8,
            }}
            vertical={8}
            horizontal={10}
            radius={4}
          >
            <Row>
              <IcBellSVG width={20} height={20} fill={colors.primary} />
              <Caption2
                weight={'medium'}
                color={'primaryDark'}
                style={{ marginLeft: 4, marginRight: 4 }}
              >
                승인 대기 중인 운전자가
              </Caption2>
              <Caption2 weight={'bold'} color={'primaryDark'}>
                {data.waitingDriversCnt}
              </Caption2>
              <Caption2 weight={'medium'} color={'primaryDark'}>
                명 있어요
              </Caption2>
            </Row>
          </Button>
        ) : null}
      </ItemContainer>
      <ParkingPhotoExpansionModal
        isOpen={modalIsOpen}
        startIndex={startIndex}
        images={images || []}
        onRequestClose={closeModal}
      />
    </Container>
  );
};

export default MinganParkingInfo;
