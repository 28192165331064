import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import { Caption2, } from 'styles/typography';

interface Props {
  startIndex?: number;
  length?: number
}

const ParkingPhotoExpansionModal: React.FC<Props> = ({ startIndex = 0, length }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setCurrentIndex(startIndex)
  }, [startIndex]);


  return (
    <Container>
      <Caption2 weight={'regular'} color={'white'}>{currentIndex} / {length}</Caption2>
    </Container>
  );
};

export default ParkingPhotoExpansionModal;
