import Title from 'components/atoms/Title/PageTitle';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {MainContainerNoMargin} from 'components/styles';
import MyParkingPastList from "containers/myParking/MyParkingPastList";

const MyParkingPastHistoryPage = () => {

  const navigate = useNavigate();

  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight)

  useEffect(() => {
    window.scrollTo(0, 0)

    const handleResize = () => {
      setWindowHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  const handleBackPress = () => {
    navigate(-1)
  }

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleBackPress()
    }
    return () => {
      delete window.onBtnBackClicked
    }
  }, [])

  return (
    <MainContainerNoMargin style={{ height: `${windowHeight}px`}}>
      <Title
        title="지난 내역"
        leftArrowButtonVisible={true}
        leftOnClick={ handleBackPress }
        rightTextButtonVisible={false}
        titleColor={'darkPurple'}
      />
      <MyParkingPastList />
    </MainContainerNoMargin>
  );
};

export default MyParkingPastHistoryPage;
