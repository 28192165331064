import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  backgroundColor: '#fff',
  padding: '28px 20px 28px 20px',
});

export const WrapItem = styled('div', {
  backgroundColor: '#fff',
  padding: '16px',
  marginTop: '16px',
  borderRadius: '8px',
  border: `2px solid #EBEBFB`
});

export const TipWrap = styled('div', {
  flexDirection: 'column',
  borderRadius: '8px',
  padding: '10px 16px',
  marginTop: '16px',
  backgroundColor: colors.primaryBackground,

});