import { styled } from '@stitches/react';
import { pxToRem } from 'utils/common';

export const CarReductionBottomModal = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: 'auto',
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  transform: 'translateY(100%)',
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  boxSizing: 'border-box',
  borderRadius: '20px 20px 0px 0px',
  zIndex: 10000,
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' },
      false: { transform: 'translateY(100%)' },
    },
  },
});

export const ParkingButtonListContainer = styled('div', {
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  margin: '28px 0px 0px 0px',
});


export const ParkingButtonContainer = styled('div', {
  padding: '16px',
  backgroundColor: '#F3F4F6',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'space-between',

  variants: {
    isFirst: {
      true: { marginTop: 0 },
      false: { marginTop: 20 },
    },
  },
});

export const Description = styled('div', {
  display: 'flex',
  // flex: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export const Image = styled('img', {
  borderRadius: '5px',
  width: pxToRem(120),
  height: pxToRem(90)
})