import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  padding: '30px 25px',
});

export const WrapInput = styled('div', {
  marginTop: 13,
  border: '1px solid #E6E6E6',
  borderRadius: 5,
  padding: '0px 0px 0px 10px',
  position: 'relative',
  height: 30,
});

export const Input = styled('input', {
  border: '0px',
  outline: 'none',
  fontWeight: 400,
  fontSize: '15px',
  color: '#1A1A1A',
  width: 'calc(100%)',
  '&::placeholder': {
    color: '#B0B0B0',
  },
});
