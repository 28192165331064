import React, { useState, useEffect } from 'react';

import { Modal } from './styles';
import { Caption2, Headline, SubHeadline } from 'styles/typography';
import Row from 'components/atoms/Row';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import { IOperTimeInfo } from 'models/Share/shareProductListModel';
import { handleChatClick } from 'utils/common';

interface BottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  data: IOperTimeInfo | null;
}

const OperatingTimeModal: React.FC<BottomModalProps> = ({ isVisible, onClose, data }) => {
  if (!isVisible) return null;
  console.log(data);
  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 9999,
        }}
        onClick={onClose}
      />
      <Modal isVisible={isVisible}>
        <Headline weight={'medium'} color={'black1'}>
          주차장 운영시간
        </Headline>

        {data === null ? (
          <>
            <SubHeadline
              weight={'regular'}
              color={'gray07'}
              style={{
                marginTop: 16,
                wordBreak: 'keep-all',
              }}
            >
              정보가 없어요. 정보 수정을 원하시면 문의해 주세요.
            </SubHeadline>

            <Row style={{ gap: 10 }}>
              <Button
                backgroundColor={colors.gray01}
                radius={4}
                style={{
                  height: 46,
                  width: 'calc(100%)',
                  marginBottom: 20,
                  marginTop: 28,
                }}
                onClick={onClose}
              >
                <Caption2 weight={'semibold'} color={'gray06'}>
                  취소
                </Caption2>
              </Button>
              <Button
                backgroundColor={colors.primary}
                radius={4}
                style={{
                  height: 46,
                  width: 'calc(100%)',
                  marginBottom: 20,
                  marginTop: 28,
                }}
                onClick={() => {
                  onClose();
                  handleChatClick();
                }}
              >
                <Caption2 weight={'semibold'} color={'white'}>
                  문의하러 가기
                </Caption2>
              </Button>
            </Row>
          </>
        ) : (
          <>
            {data.weeklyOperTime ? (
              <Row justifyContent={'space-between'} style={{ marginTop: 16 }}>
                <SubHeadline weight={'regular'} color={'gray07'}>
                  평일
                </SubHeadline>
                <SubHeadline weight={'semibold'} color={'gray09'}>
                  {data.weeklyOperTime}
                </SubHeadline>
              </Row>
            ) : null}
            {data.satOperTime ? (
              <Row justifyContent={'space-between'} style={{ marginTop: 16 }}>
                <SubHeadline weight={'regular'} color={'gray07'}>
                  토요일
                </SubHeadline>
                <SubHeadline weight={'semibold'} color={'gray09'}>
                  {data.satOperTime}
                </SubHeadline>
              </Row>
            ) : null}
            {data.sunOperTime ? (
              <Row justifyContent={'space-between'} style={{ marginTop: 16 }}>
                <SubHeadline weight={'regular'} color={'gray07'}>
                  일요일
                </SubHeadline>
                <SubHeadline weight={'semibold'} color={'gray09'}>
                  {data.sunOperTime}
                </SubHeadline>
              </Row>
            ) : null}
            <Button
              backgroundColor={colors.primary}
              radius={4}
              style={{
                height: 46,
                width: 'calc(100%)',
                marginBottom: 20,
                marginTop: 28,
              }}
              onClick={onClose}
            >
              <SubHeadline weight={'bold'} color={'white'}>
                확인
              </SubHeadline>
            </Button>
          </>
        )}
      </Modal>
    </>
  );
};

export default OperatingTimeModal;
