import React, { useState } from 'react';
import { DropdownContainer } from 'components/atoms/Dropdown/styles';
import { DropdownHeader, DropdownItem, DropdownList } from 'components/atoms/Dropdown/styles';
import { Caption2 } from 'styles/typography';
import Row from 'components/atoms/Row';
// @ts-ignore
import { IcArrowDown16SVG, IcArrowUp16SVG } from 'assets/ic';
import CancelResaonStore from 'stores/store/CancelReason/CancelResaonStore';

interface Props {
  reasons: string[];
}

const Dropdown: React.FC<Props> = ({ reasons }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState(0);

  const handleSelect = (reason: number) => {
    setSelectedReason(reason + 1);
    setIsOpen(false);
    CancelResaonStore.setReason(reason + 1);
  };

  return (
    <DropdownContainer>
      <DropdownHeader onClick={() => setIsOpen(!isOpen)}>
        <Row justifyContent={'space-between'} style={{ width: '100%' }}>
          <Caption2 weight={'medium'} color={selectedReason ? 'gray09' : 'gray05'}>
            {selectedReason ? reasons[selectedReason - 1] : '거절 사유를 선택해 주세요'}
          </Caption2>
          {!isOpen ? <IcArrowDown16SVG /> : <IcArrowUp16SVG />}
        </Row>
      </DropdownHeader>
      {isOpen && (
        <DropdownList>
          {reasons.map((reason, index) => (
            <DropdownItem key={index} onClick={() => handleSelect(index)}>
              <Caption2 weight={'medium'} color={'gray09'}>
                {reason}
              </Caption2>
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default Dropdown;
