import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import Title from 'components/atoms/Title/PageTitle';
import { MainContainerNoMargin } from 'components/styles';
import { Container } from './styles';
import Row from 'components/atoms/Row';
import Logo from 'assets/images/img_zoomansa_logo.png';
import Divider from 'components/atoms/Divider';
import { colors } from 'styles/colors';
import ParkingName from 'containers/geojuParking/ParkingName';
import ParkingInfo from 'containers/geojuParking/ParkingInfo';
import ShareStopModal from 'components/atoms/Modal/ShareStopModal';
import ParkingUsingInfo from 'containers/geojuParking/ParkingUsingInfo';
import GeojuParkingCaution from 'containers/geojuParking/GeojuParkingCaution';
import Button from 'components/atoms/Button/Button';
import { Caption2 } from 'styles/typography';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import Footer from 'components/atoms/Footer';
import CouponRegister from 'containers/geojuParking/CouponRegister';
import AutomaticInfo from 'containers/geojuParking/AutomaticInfo';
import GeojuUseInfo from 'containers/geojuParking/GeojuUseInfo';
import GeojuPaymentInfo from 'containers/geojuParking/GeojuPaymentInfo';
import PaymentMethod from 'containers/geojuParking/PaymentMethod';
import CustomListModal from 'components/atoms/Modal/CustomListModal';
import { shareStore } from 'stores/store/Share/shareStore';
import icSuccessCheckGreen from 'assets/ic/ic_success_check_green.svg';
import { METHODS } from 'utils/bankUtil';
import AutomaticCaution from 'containers/geojuParking/AutomaticCaution';

const GeojuParkingPaymentPage = observer(() => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const [carNumber, setCarNumber] = useState('');
  const [isMethodModal, setIsMethodModal] = useState(false);
  const [selected, setSelected] = useState(0);

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  useEffect(() => {
    // 데이터를 가져오는 함수 정의
    const fetchData = async () => {
      // try {
      //   await shareStore.fetchGetProductDetailOperInfo(productUID);
      // } catch (error) {
      //   console.error('Error fetching data:', error);
      // }
    };

    fetchData(); // 데이터 가져오기 호출
  }, []);

  const onCloseMethodModal = () => {
    setIsMethodModal(false);
  };

  const handleSelected = async (index: number) => {
    setSelected(index);
  };

  // if (isLoading) {
  //   return (
  //     <div
  //       style={{
  //         display: 'flex',
  //         alignItems: 'center',
  //         justifyContent: 'center',
  //         height: '50vh',
  //       }}
  //     >
  //       <Spinner />
  //     </div>
  //   );
  // }

  return (
    <MainContainerNoMargin>
      <Container>
        <Row style={{ margin: '24px 0px' }} alignItems={'center'} justifyContent={'center'}>
          <img alt={'logo'} width={79} height={19} src={Logo} />
        </Row>
      </Container>
      <ParkingName />
      <Divider style={{ backgroundColor: colors.primaryBackground, height: 6 }} />
      <ParkingInfo type={'payment'} />
      <Divider style={{ backgroundColor: colors.primaryBackground, height: 6 }} />
      <GeojuUseInfo />
      <Divider style={{ backgroundColor: colors.primaryBackground, height: 6 }} />
      <GeojuPaymentInfo />
      <Divider style={{ backgroundColor: colors.primaryBackground, height: 6 }} />
      <PaymentMethod selected={selected} setIsMethodModal={setIsMethodModal} />

      <Divider style={{ backgroundColor: colors.primaryBackground, height: 6 }} />
      <AutomaticCaution />
      <Footer />

      <CustomListModal
        isVisible={isMethodModal}
        onClose={() => {
          onCloseMethodModal();
        }}
        onSelectItem={handleSelected}
        data={METHODS}
        title={'결제 수단을 선택해주세요'}
      />
    </MainContainerNoMargin>
  );
});

export default GeojuParkingPaymentPage;
