import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const ToggleWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
});

export const ToggleSwitch = styled('div', {
  width: '44px',
  height: '26px',
  borderRadius: '13px',
  position: 'relative',
  transition: 'background-color 0.3s ease',
  variants: {
    isOn: {
      true: {
        backgroundColor: colors.primary, // 활성화 색상
      },
      false: {
        backgroundColor: '#cccccc', // 비활성화 색상
      },
    },
  },
});

export const ToggleCircle = styled('div', {
  width: '18px',
  height: '18px',
  backgroundColor: 'white',
  borderRadius: '50%',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  transition: 'left 0.3s ease',
  variants: {
    isOn: {
      true: {
        left: '22px', // 활성화 시 위치
      },
      false: {
        left: '4px', // 비활성화 시 위치
      },
    },
  },
});
