import {useNavigate} from 'react-router-dom';
import icDotDarkGray from 'assets/ic/ic_dot_dark_gray.svg';
import {Body, Caption1, Caption2, Caption3, SmallText} from 'styles/typography';
import {
  DivideContainer,
  DivideLine,
  NotPastParkingContainer,
  PastContainer,
  PastContentsContainer,
  PastStatusContainer,
  SixMonthSpan,
  SpinnerContainer,
  ThreeMonthSpan,
  TicketContentsContainer,
  TicketContentsDetailContainer,
  TicketTypeContainer,
  ToggleButtonContainer,
  ToggleContainer,
  ToggleSpan,
  YearContainer,
  YearMonthContentContainer,
} from 'pages/MyParking/styles';
import React, {useEffect, useRef, useState} from 'react';
import {myParkingPastHistoryStore} from "stores/store/MyParking/myParkingPastListStore";
import {observer} from "mobx-react";
import {Spinner} from "components/atoms/LoadingSpinner/LoadingSpinnerImage/styles";
import Lottie from "react-lottie";
import lottieEmpty from "assets/lottie/lottieEmpty80.json";
import icArrowBottomGray from "assets/ic/ic_arrow_bottom_gray.svg";
import icSuccessCheckGreen from "assets/ic/ic_success_check_green.svg";
import {useToast} from "components/atoms/Toast/ToastProvider";
import YearSelectBottomModal from "components/atoms/Modal/YearSelectBottomModal";
import {userInfoStore} from "stores/store/UserInfo/userInfoStore";
import {YearMonthContainer} from "pages/Point/PointHistoryBottomList/styles";
import {colors} from "styles/colors";

const MyParkingPastList = observer(() => {
  const navigate = useNavigate()
  const {showToast} = useToast()
  const currentYear = new Date().getFullYear()
  const [isYearSelectBottomModal, setIsYearSelectBottomModal] = useState(false)
  const [selectedTicketType, setSelectedTicketType] = useState<string>('전체')
  const { memberUID, memberData } = userInfoStore;

  const TICKET_TYPE_ALL = '전체'
  const TICKET_TYPE_SEASON = '정기권'
  const TICKET_TYPE_TIME = '시간권'
  const TICKET_PART_TIME = '시간제'

  const PERIOD_3_MONTH = '3개월';
  const PERIOD_6_MONTH = '6개월';

  // const year = ['2024', '2023', '2022', '2021', '2020']
  const [isYearSelected, setIsYearSelected] = useState<boolean>(false)

  const createYearList = (baseYear: string): string[] => {
    const currentYear = new Date().getFullYear()
    const yearList = [];

    for (let year = currentYear; year >= Number(baseYear); year--) {
      yearList.push(year + '년')
    }
    return yearList
  }

  const year = createYearList(memberData?.regDate.split('-')[0] ?? '2024')

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());

  const [selectedPeriod, setSelectedPeriod] = useState<'threeMonth' | 'sixMonth' | string>('threeMonth')

  const bottomRef = useRef<HTMLDivElement>(null)

  const {
    // 전체 조회
    isAllTicketLoading, allCurrentPage, allItemsPerPage, allTotalCount, allParkingPastHistoryList,

    // 정기권
    isSeasonTicketLoading, seasonTicketCurrentPage, seasonTicketItemsPerPage, seasonTicketTotalCount,
    seasonTicketParkingPastHistoryList,

    // 시간권, 시간제
    isTimeTicketLoading, timeTicketCurrentPage, timeTicketItemsPerPage, timeTicketTotalCount,
    timeTicketParkingPastHistoryList,
  } = myParkingPastHistoryStore

  // 전체
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(async (entry) => {
          if (entry.isIntersecting && !isAllTicketLoading) {
            if (allCurrentPage * allItemsPerPage < allTotalCount) {
              try {
                if (selectedYear === String(currentYear)) {
                  if (selectedPeriod === "threeMonth") {
                    await myParkingPastHistoryStore.fetchGetMyParkingAllPastHistory(
                      memberUID,
                      PERIOD_3_MONTH,
                      allCurrentPage + 1
                    )
                    return;
                  }

                  if (selectedPeriod === "sixMonth") {
                    await myParkingPastHistoryStore.fetchGetMyParkingAllPastHistory(
                      memberUID,
                      PERIOD_6_MONTH,
                      allCurrentPage + 1
                    )
                    return
                  }
                }

                if (selectedYear !== String(currentYear)) {
                  await myParkingPastHistoryStore.fetchGetMyParkingAllPastHistory(
                    memberUID,
                    selectedYear,
                    allCurrentPage + 1
                  )
                  return
                }

                await myParkingPastHistoryStore.fetchGetMyParkingAllPastHistory(
                  memberUID,
                  String(currentYear),
                  allCurrentPage + 1
                );
              } catch (error) {
                console.error("Error fetching parking history:", error);
              }
            }
          }
        });
      },
      { threshold: 1.0 }
    )

    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }

    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current);
      }
    }
  }, [
    allCurrentPage,
    allItemsPerPage,
    allTotalCount,
    currentYear,
    isAllTicketLoading,
    selectedPeriod,
    selectedYear,
  ])

  // 정기권
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(async (entry) => {
          if (entry.isIntersecting && !isSeasonTicketLoading) {
            if (seasonTicketCurrentPage * seasonTicketItemsPerPage < seasonTicketTotalCount) {
              try {
                if (selectedYear === String(currentYear)) {
                  if (selectedPeriod === "threeMonth") {
                    await myParkingPastHistoryStore.fetchGetMyParkingSeasonTicketPastHistory(
                      memberUID,
                      PERIOD_3_MONTH,
                      seasonTicketCurrentPage + 1
                    )
                    return
                  }

                  if (selectedPeriod === "sixMonth") {
                    await myParkingPastHistoryStore.fetchGetMyParkingSeasonTicketPastHistory(
                      memberUID,
                      PERIOD_6_MONTH,
                      seasonTicketCurrentPage + 1
                    )
                    return
                  }
                }

                if (selectedYear !== String(currentYear)) {
                  await myParkingPastHistoryStore.fetchGetMyParkingSeasonTicketPastHistory(
                    memberUID,
                    selectedYear,
                    seasonTicketCurrentPage + 1
                  )
                  return
                }

                await myParkingPastHistoryStore.fetchGetMyParkingSeasonTicketPastHistory(
                  memberUID,
                  String(currentYear),
                  seasonTicketCurrentPage + 1
                );
              } catch (error) {
                console.error("Error fetching season ticket past history:", error)
              }
            }
          }
        });
      },
      { threshold: 1.0 }
    )

    if (bottomRef.current) {
      observer.observe(bottomRef.current)
    }

    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current)
      }
    }
  }, [
    isSeasonTicketLoading,
    seasonTicketCurrentPage,
    seasonTicketTotalCount,
    seasonTicketItemsPerPage,
    selectedTicketType,
    selectedYear,
    selectedPeriod,
    currentYear,
  ])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(async (entry) => {
          if (entry.isIntersecting && !isTimeTicketLoading) {
            if (timeTicketCurrentPage * timeTicketItemsPerPage < timeTicketTotalCount) {
              try {
                if (selectedYear === String(currentYear)) {
                  if (selectedPeriod === "threeMonth") {
                    await myParkingPastHistoryStore.fetchGetMyParkingTimeTicketPastHistory(
                      memberUID,
                      PERIOD_3_MONTH,
                      timeTicketCurrentPage + 1
                    );
                    return;
                  }

                  if (selectedPeriod === "sixMonth") {
                    await myParkingPastHistoryStore.fetchGetMyParkingTimeTicketPastHistory(
                      memberUID,
                      PERIOD_6_MONTH,
                      timeTicketCurrentPage + 1
                    );
                    return;
                  }
                }

                if (selectedYear !== String(currentYear)) {
                  await myParkingPastHistoryStore.fetchGetMyParkingTimeTicketPastHistory(
                    memberUID,
                    selectedYear,
                    timeTicketCurrentPage + 1
                  );
                  return;
                }

                await myParkingPastHistoryStore.fetchGetMyParkingTimeTicketPastHistory(
                  memberUID,
                  String(currentYear),
                  timeTicketCurrentPage + 1
                );
              } catch (error) {
                console.error("Error fetching time ticket past history:", error)
              }
            }
          }
        });
      },
      { threshold: 1.0 }
    )

    if (bottomRef.current) {
      observer.observe(bottomRef.current)
    }

    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current)
      }
    }
  }, [
    isTimeTicketLoading,
    timeTicketCurrentPage,
    timeTicketTotalCount,
    timeTicketItemsPerPage,
    selectedTicketType,
    selectedYear,
    selectedPeriod,
    currentYear,
  ])

  const handleSelectYear = async (index: number) => {
    const selectedYearFromList = year[index]
    setSelectedYear(selectedYearFromList)
    setSelectedPeriod(selectedYearFromList)
    setIsYearSelectBottomModal(false)
    setSelectedTicketType(TICKET_TYPE_ALL)

    setIsYearSelected(true)

    try {
      const response = await myParkingPastHistoryStore.fetchGetMyParkingAllPastHistory(memberUID, selectedYearFromList, 1)
      console.log(`${selectedYearFromList} 데이터:`, response)
    } catch (error) {
      console.error(`${selectedYearFromList} 데이터 불러오기 에러:`, error)
    }
  }

  const handlePeriodClick = async (period: 'threeMonth' | 'sixMonth') => {
    setSelectedPeriod(period);

    const periodStr = period === 'threeMonth' ? PERIOD_3_MONTH : PERIOD_6_MONTH;

    if (selectedYear !== String(currentYear)) {
      try {

        const response = await fetchParkingHistory(periodStr, selectedTicketType)
        console.log('전체 데이터:', response)
        showToast('현재 년도로 적용되었어요.', icSuccessCheckGreen)
        setSelectedYear(String(currentYear))
      } catch (error) {
        console.error('전체 데이터 불러오기 에러:', error)
      }
      return
    }

    if (selectedYear === String(currentYear)) {
      await fetchParkingHistory(periodStr, selectedTicketType)
      return
    }
  }

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: lottieEmpty,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const fetchAllTickets = async () => {
    try {
      const response = await myParkingPastHistoryStore.fetchGetMyParkingAllPastHistory(memberUID, PERIOD_3_MONTH, 1)
      console.log('전체 데이터:', response);
    } catch (error) {
      console.error('전체 데이터 불러오기 에러:', error);
    }
  }

  useEffect(() => {
    if (selectedTicketType === '전체') {
      fetchAllTickets().catch((error) => {
        console.error('초기 데이터 불러오기 에러:', error)
      })
    }
  }, [selectedTicketType])

  const mapTicketTypeToVariant = (ticketType: string) => {
    switch (ticketType) {
      case '정기권':
        return 'seasonTicket';
      case '시간권':
        return 'default';
      case '시간제':
        return 'default';
      default:
        return 'default';
    }
  }

  const fetchParkingHistory = async (period: "3개월" | "6개월", ticketType: string) => {
    let response;
    try {
      if (ticketType === TICKET_TYPE_ALL) {
        response = await myParkingPastHistoryStore.fetchGetMyParkingAllPastHistory(memberUID, period, 1);
      } else if (ticketType === TICKET_TYPE_SEASON) {
        response = await myParkingPastHistoryStore.fetchGetMyParkingSeasonTicketPastHistory(memberUID, period, 1);
      } else if (ticketType === TICKET_TYPE_TIME) {
        response = await myParkingPastHistoryStore.fetchGetMyParkingTimeTicketPastHistory(memberUID, period, 1);
      }
      console.log('전체 데이터:', response);
    } catch (error) {
      console.error('전체 데이터 불러오기 에러:', error);
    }
  }

  const toggleButtons = [
    {
      key: TICKET_TYPE_ALL,
      label: TICKET_TYPE_ALL,
      onClick: async () => {
        try {
          setSelectedTicketType(TICKET_TYPE_ALL);

          if (selectedYear === String(currentYear)) {
            if (selectedPeriod === "threeMonth") {
              await myParkingPastHistoryStore.fetchGetMyParkingAllPastHistory(
                memberUID,
                PERIOD_3_MONTH,
                1
              );
              return;
            }

            if (selectedPeriod === "sixMonth") {
              await myParkingPastHistoryStore.fetchGetMyParkingAllPastHistory(
                memberUID,
                PERIOD_6_MONTH,
                1
              );
              return;
            }
          }

          if (selectedYear !== String(currentYear)) {
            await myParkingPastHistoryStore.fetchGetMyParkingAllPastHistory(
              memberUID,
              selectedYear,
              1
            );
            return;
          }

          await myParkingPastHistoryStore.fetchGetMyParkingAllPastHistory(
            memberUID,
            String(currentYear),
            1
          );
        } catch (error) {
          console.error("Error fetching all parking history:", error);
        }
      },
    },
    {
      key: TICKET_TYPE_SEASON,
      label: TICKET_TYPE_SEASON,
      onClick: async () => {
        try {
          setSelectedTicketType(TICKET_TYPE_SEASON);

          if (selectedYear === String(currentYear)) {
            if (selectedPeriod === "threeMonth") {
              await myParkingPastHistoryStore.fetchGetMyParkingSeasonTicketPastHistory(
                memberUID,
                PERIOD_3_MONTH,
                1
              )
              return
            }

            if (selectedPeriod === "sixMonth") {
              await myParkingPastHistoryStore.fetchGetMyParkingSeasonTicketPastHistory(
                memberUID,
                PERIOD_6_MONTH,
                1
              )
              return
            }
          }

          if (selectedYear !== String(currentYear)) {
            await myParkingPastHistoryStore.fetchGetMyParkingSeasonTicketPastHistory(
              memberUID,
              selectedYear,
              1
            )
            return
          }

          await myParkingPastHistoryStore.fetchGetMyParkingSeasonTicketPastHistory(
            memberUID,
            String(currentYear),
            1
          )
        } catch (error) {
          console.error("Error fetching season ticket history:", error);
        }
      },
    },
    {
      key: TICKET_TYPE_TIME,
      label: TICKET_TYPE_TIME,
      onClick: async () => {
        try {
          setSelectedTicketType(TICKET_TYPE_TIME);

          if (selectedYear === String(currentYear)) {
            if (selectedPeriod === "threeMonth") {
              await myParkingPastHistoryStore.fetchGetMyParkingTimeTicketPastHistory(
                memberUID,
                PERIOD_3_MONTH,
                1
              )
              return
            }

            if (selectedPeriod === "sixMonth") {
              await myParkingPastHistoryStore.fetchGetMyParkingTimeTicketPastHistory(
                memberUID,
                PERIOD_6_MONTH,
                1
              )
              return
            }
          }

          if (selectedYear !== String(currentYear)) {
            await myParkingPastHistoryStore.fetchGetMyParkingTimeTicketPastHistory(
              memberUID,
              selectedYear,
              1
            )
            return
          }

          await myParkingPastHistoryStore.fetchGetMyParkingTimeTicketPastHistory(
            memberUID,
            String(currentYear),
            1
          )
        } catch (error) {
          console.error("Error fetching time ticket history:", error)
        }
      },
    },
  ]

  const filteredList =
    selectedTicketType === TICKET_TYPE_ALL
      ? allParkingPastHistoryList
      : selectedTicketType === TICKET_TYPE_SEASON
        ? seasonTicketParkingPastHistoryList
        : selectedTicketType === TICKET_TYPE_TIME
          ? timeTicketParkingPastHistoryList
          : allParkingPastHistoryList.filter((item) => item.passType === selectedTicketType)

  const totalCount =
    selectedTicketType === TICKET_TYPE_ALL ? allTotalCount
      : selectedTicketType === TICKET_TYPE_SEASON
        ? seasonTicketTotalCount
        : selectedTicketType === TICKET_TYPE_TIME
          ? timeTicketTotalCount
          : 0;

  useEffect(() => {
    console.log("Updated totalCount:", totalCount);
  }, [totalCount, allTotalCount, selectedPeriod, selectedTicketType])

  return (
    <div>
      <ToggleButtonContainer>
        {toggleButtons.map((button) => (
          <ToggleContainer
            key={button.key}
            isClicked={selectedTicketType === button.key}
            onClick={button.onClick}
          >
            <ToggleSpan isClicked={selectedTicketType === button.key}>{button.label}</ToggleSpan>
          </ToggleContainer>
        ))}
      </ToggleButtonContainer>

      <YearMonthContainer>
        <div style={{
          display: 'flex',
        }}>
          <SmallText color={'gray06'} weight={'regular'}>총</SmallText>
          <SmallText color={'gray08'} weight={'medium'}>&nbsp;{totalCount}&nbsp;</SmallText>
          <SmallText color={'gray06'} weight={'regular'}>건</SmallText>
        </div>
        <YearMonthContentContainer>
          <ThreeMonthSpan
            onClick={() => handlePeriodClick('threeMonth')}
            isThreeMonthHistory={selectedPeriod === 'threeMonth'}
            isDisabled={selectedYear !== String(currentYear)}
          >
            {PERIOD_3_MONTH}&nbsp;
          </ThreeMonthSpan>
          <SmallText color={'gray06'} weight={'regular'}>|</SmallText>
          <SixMonthSpan
            onClick={() => handlePeriodClick('sixMonth')}
            isSixMonthHistory={selectedPeriod === 'sixMonth'}
            isDisabled={selectedYear !== String(currentYear)}
          >
            &nbsp;{PERIOD_6_MONTH}&nbsp;
          </SixMonthSpan>
          <SmallText color={'gray06'} weight={'regular'}>|</SmallText>
          <YearContainer onClick={() => setIsYearSelectBottomModal(true)}>
             <SmallText
               style={{
                 color: selectedPeriod === 'threeMonth' || selectedPeriod === 'sixMonth' ? colors.gray05 : colors.gray08,
               }}
             >&nbsp;{selectedYear}&nbsp;
             </SmallText>
            <img src={icArrowBottomGray} alt="보라색 화살표 아래 아이콘"/>
          </YearContainer>
        </YearMonthContentContainer>
      </YearMonthContainer>

      {isAllTicketLoading || isSeasonTicketLoading || isTimeTicketLoading ? (
          <SpinnerContainer>
            <Spinner/>
          </SpinnerContainer>
        ) : filteredList.length === 0 ? (
          <NotPastParkingContainer>
            <Lottie options={defaultOptions} height={80} width={80}/>
            <Caption1 weight={'medium'} color={'gray09'}>
              주차권 이용 내역이 없어요
            </Caption1>
          </NotPastParkingContainer>
        ) : (
          <div>
            {filteredList.map((item, index) => (
              <PastContainer
                ref={bottomRef}
                key={index}
                css={{
                  marginTop: index === 0 ? '24px' : '0px', // 첫 번째 아이템에만 marginTop 적용
                }}
                onClick={() => {
                  if (item.passType === TICKET_TYPE_SEASON) {
                    navigate('/myParkingSeasonTicketMain');
                    return
                  }

                  if (item.passType === TICKET_TYPE_TIME) {
                    navigate('/terraceTowerDetail');
                    return
                  }

                  if (item.passType === TICKET_PART_TIME) {
                    navigate('/myParkingPartTimeMain');
                    return
                  }
                }}
              >
                <DivideContainer>
                  <img src={icDotDarkGray} alt="회색 점 아이콘" width={8} height={8}/>
                  <DivideLine/>
                </DivideContainer>

                <PastContentsContainer>
                  <PastStatusContainer>
                    {item.status === '이용취소' || item.status === '결제취소' ? (
                      <Caption2 weight={'semibold'} color={'red'}>
                        {item.status}
                      </Caption2>
                    ) : (
                      <Caption2 weight={'semibold'} color={'gray06'}>
                        {item.status}
                      </Caption2>
                    )}
                    <TicketTypeContainer ticketType={mapTicketTypeToVariant(item.passType)}>
                      {item.passType === TICKET_TYPE_SEASON ? (
                        <Caption3 weight="bold" color="primary">
                          {item.passType}
                        </Caption3>
                      ) : (
                        <Caption3 weight="bold" color="blue">
                          {item.passType}
                        </Caption3>
                      )}
                    </TicketTypeContainer>
                  </PastStatusContainer>

                  <Body weight={'medium'} color={'black1'}>
                    {item.passType === TICKET_PART_TIME && 'regionRealCode' in item ? item.regionRealCode : item.productName}
                  </Body>

                  <TicketContentsContainer>
                    <TicketContentsDetailContainer>
                      <Caption2
                        style={{width: '28px',}} weight={'regular'} color={'gray06'}>
                        주소
                      </Caption2>
                      <Caption2 style={{marginLeft: '8px'}} weight={'medium'} color={'gray08'}>
                        {item.passType === TICKET_PART_TIME ? item.lotName : item.lotAddress}
                      </Caption2>
                    </TicketContentsDetailContainer>

                    {/* 차량 섹션 */}
                    <TicketContentsDetailContainer style={{marginTop: '8px'}}>
                      <Caption2
                        style={{
                          width: '28px',
                        }}
                        weight={'regular'}
                        color={'gray06'}
                      >
                        차량
                      </Caption2>
                      <Caption2 style={{marginLeft: '8px'}} weight={'medium'} color={'gray08'}>
                        {item.carNumber}
                      </Caption2>
                    </TicketContentsDetailContainer>

                    <TicketContentsDetailContainer style={{marginTop: '8px'}}>
                      <Caption2
                        style={{
                          width: '28px',
                        }}
                        weight={'regular'}
                        color={'gray06'}
                      >
                        이용
                      </Caption2>
                      <Caption2 style={{marginLeft: '8px'}} weight={'medium'} color={'gray08'}>
                        {item.usingTime}
                      </Caption2>
                    </TicketContentsDetailContainer>
                    <TicketContentsDetailContainer style={{marginTop: '8px'}}>
                      <Caption2
                        style={{
                          width: '28px',
                        }}
                        weight={'regular'}
                        color={'gray06'}
                      >
                        금액
                      </Caption2>
                      <Caption2 style={{marginLeft: '8px'}} weight={'medium'} color={'gray08'}>
                        {item.amount}
                      </Caption2>
                    </TicketContentsDetailContainer>
                  </TicketContentsContainer>
                </PastContentsContainer>
              </PastContainer>
            ))}
          </div>
        )
      }

      <YearSelectBottomModal
        isVisible={isYearSelectBottomModal}
        onClose={() => {
          setIsYearSelectBottomModal(false)
        }}
        onSelectYear={handleSelectYear}
        year={year}
      />
    </div>
  );
})

export default MyParkingPastList;
