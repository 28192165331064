import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Container, WrapInfo, WrapItem } from './styles';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import Row from 'components/atoms/Row';
import {
  Body,
  Caption1,
  Caption2,
  Caption3,
  Caption4,
  Headline,
  SubHeadline,
} from 'styles/typography';
import Divider from 'components/atoms/Divider';
import { pxToRem } from 'utils/common';
import { IcArrowBottomBlackSVG, IcArrowDown16SVG } from 'assets/ic';
import { ProductDetailData, UseHistory } from 'models/Share/shareProductDetailModel';
import { formatPriceWithCommas } from 'utils/number';
import { shareStore } from 'stores/store/Share/shareStore';
import { formatDateRange } from 'utils/dateUtils';

interface Props {
  onClick: () => void;
  selectYear: string;
  productUID: number;
  hasMore: boolean;
  setHasMore: React.Dispatch<React.SetStateAction<boolean>>;
}

const MinganParkingUsingHistory: React.FC<Props> = observer(
  ({ hasMore, onClick, selectYear, productUID, setHasMore }) => {
    const navigate = useNavigate();
    const observerRef = useRef<HTMLDivElement>(null);

    const [currentTab, setCurrentTab] = useState(0);
    const [pageNum, setPageNum] = useState(1);

    const [isLoading, setIsLoading] = useState(false);

    const { endProductSaleHistory } = shareStore;

    const tabs = ['3개월', '6개월', `${selectYear}`];

    useEffect(() => {
      fetchData();
    }, [currentTab, selectYear]);

    const fetchData = async () => {
      if (isLoading || !hasMore) return;
      setIsLoading(true);
      let response;

      if (currentTab === 2) {
        response = await shareStore.fetchGetEndProductSaleHistory(
          productUID,
          selectYear.slice(0, -1),
          'year',
          pageNum,
        );
      } else {
        const filter = currentTab === 0 ? '3' : '6';
        response = await shareStore.fetchGetEndProductSaleHistory(
          productUID,
          filter,
          'month',
          pageNum,
        );
      }

      if (response) {
        const currentCount = shareStore.endProductSaleHistory.length; // shareStore에서 직접 값 가져오기
        setHasMore(currentCount < shareStore.endProductSaleHistoryTotalCount);

        if (currentCount < shareStore.endProductSaleHistoryTotalCount) {
          setPageNum(pageNum + 1);
        }
      }
      setIsLoading(false);
    };

    useEffect(() => {
      let debounceTimer: NodeJS.Timeout | null = null;

      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(async entry => {
            if (entry.isIntersecting && !debounceTimer) {
              debounceTimer = setTimeout(() => {
                fetchData();
                debounceTimer = null;
              }, 500);
            }
          });
        },
        { threshold: 1.0 },
      );

      if (observerRef.current) {
        observer.observe(observerRef.current);
      }

      return () => {
        if (observerRef.current) {
          observer.unobserve(observerRef.current);
        }
        if (debounceTimer) clearTimeout(debounceTimer);
      };
    }, [observerRef.current, isLoading]);

    return (
      <Container>
        <Row justifyContent={'space-between'}>
          <Body weight={'semibold'} color={'black1'}>
            이용기록
          </Body>

          <Row>
            {tabs.map((tab, index) => {
              return (
                <Row key={index}>
                  <Caption4
                    onClick={() => {
                      setCurrentTab(index);
                      setPageNum(1);
                      setHasMore(true);
                      if (index === 2) {
                        onClick();
                      }
                    }}
                    weight={currentTab === index ? 'semibold' : 'regular'}
                    color={currentTab === index ? 'gray08' : 'gray05'}
                    style={{ marginLeft: 4 }}
                  >
                    {tab}
                  </Caption4>
                  {index !== tabs.length - 1 ? (
                    <Caption4 weight={'semibold'} color={'gray03'} style={{ marginLeft: 4 }}>
                      |
                    </Caption4>
                  ) : (
                    <IcArrowDown16SVG />
                  )}
                </Row>
              );
            })}
          </Row>
        </Row>
        {endProductSaleHistory?.length !== 0 ? (
          <div>
            {endProductSaleHistory?.map((el, index) => {
              const isRefund = el.settlement === el.refundPrice;
              return (
                <WrapItem key={index}>
                  {index !== 0 ? <Divider style={{ marginBottom: 20 }} /> : null}

                  <Row justifyContent={'space-between'} alignItems={'flex-start'}>
                    <SubHeadline
                      weight={'medium'}
                      color={'black3'}
                      style={{
                        wordBreak: 'break-all', // 긴 단어가 있을 경우 강제로 줄바꿈
                        overflow: 'hidden',
                        WebkitLineClamp: 2,
                        textOverflow: 'ellipsis',
                        WebkitBoxOrient: 'vertical',
                        display: '-webkit-box',
                        width: 'calc(100% - 64px)',
                      }}
                    >
                      {el.carNumber} · {el.carType}
                    </SubHeadline>
                    <div style={{ marginLeft: 12, width: 52 }}>
                      <Caption1 weight={'medium'} color={isRefund ? 'red' : 'blue'}>
                        {isRefund ? '이용취소' : '이용완료'}
                      </Caption1>
                    </div>
                  </Row>
                  <WrapInfo>
                    <Row>
                      <Caption3
                        weight={'regular'}
                        color={'gray06'}
                        style={{ width: pxToRem(80), marginRight: 8 }}
                      >
                        이용 기간
                      </Caption3>
                      <Caption3 weight={'medium'} color={isRefund ? 'gray05' : 'gray07'}>
                        {formatDateRange(el.useDate)}
                      </Caption3>
                    </Row>
                    {el.refundPrice && !isRefund ? (
                      <Row style={{ marginTop: 8 }}>
                        <Caption3
                          weight={'regular'}
                          color={'gray06'}
                          style={{ width: pxToRem(80), marginRight: 8 }}
                        >
                          부분 환불 금액
                        </Caption3>
                        <Caption3 weight={'medium'} color={'gray07'}>
                          {formatPriceWithCommas(el.refundPrice)}원
                        </Caption3>
                      </Row>
                    ) : null}

                    {el.refundDates && !isRefund ? (
                      <Row style={{ marginTop: 8 }}>
                        <Caption3
                          weight={'regular'}
                          color={'gray06'}
                          style={{ width: pxToRem(80), marginRight: 8 }}
                        >
                          환불 일자
                        </Caption3>
                        <Caption3 weight={'medium'} color={'gray07'}>
                          {el.refundDates}
                        </Caption3>
                      </Row>
                    ) : null}

                    <Row style={{ marginTop: 8 }}>
                      <Caption3
                        weight={'regular'}
                        color={'gray06'}
                        style={{ width: pxToRem(80), marginRight: 8 }}
                      >
                        정산 일자
                      </Caption3>
                      <Caption3 weight={'medium'} color={isRefund ? 'gray05' : 'gray07'}>
                        {el.settlementDate}
                      </Caption3>
                    </Row>
                    <Row style={{ marginTop: 8 }}>
                      <Caption3
                        weight={'regular'}
                        color={'gray06'}
                        style={{ width: pxToRem(80), marginRight: 8 }}
                      >
                        정산 금액
                      </Caption3>
                      <Caption3 weight={'medium'} color={isRefund ? 'gray05' : 'gray07'}>
                        {formatPriceWithCommas(el.settlement)}원
                      </Caption3>
                    </Row>
                  </WrapInfo>
                </WrapItem>
              );
            })}
            <div ref={observerRef}></div>
          </div>
        ) : (
          <Caption2
            weight={'medium'}
            color={'gray08'}
            style={{ marginTop: 80, marginBottom: 100, textAlign: 'center' }}
          >
            이용한 운전자가 없어요.
          </Caption2>
        )}
      </Container>
    );
  },
);

export default MinganParkingUsingHistory;
