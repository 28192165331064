import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, WrapItem } from './styles';
import { Body, Caption1, Caption2, SubHeadline } from 'styles/typography';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import Row from 'components/atoms/Row';
import { IMonthlyProductInfo } from 'models/Share/shareProductListModel';
import { formatPriceWithCommas } from 'utils/number';

interface Props {
  isSale: boolean;
  type: string;
  data: IMonthlyProductInfo[] | null;
  slotUID: number;
}

const MinganMonthParkInfo = ({ isSale, data, type, slotUID }: Props) => {
  const navigate = useNavigate();

  const totalCompleteDriversCnt = data?.reduce((accumulator, currentItem) => {
    return accumulator + currentItem.completeDriversCnt;
  }, 0);

  return (
    <Container>
      <Row style={{ marginBottom: 8 }}>
        <Body weight={'semibold'} color={'black1'} style={{ marginRight: 8 }}>
          {type}
        </Body>
        {isSale && type === '월정기권' && totalCompleteDriversCnt !== 0 ? (
          <Caption1 weight={'medium'} color={'primary'}>
            {totalCompleteDriversCnt}대 이용중
          </Caption1>
        ) : null}
      </Row>
      {data?.map((el, index) => {
        return type === '월정기권' ? (
          <WrapItem
            key={index}
            onClick={() =>
              navigate('/minganProductDetail', {
                state: {
                  productUID: el.productUID,
                  type,
                  slotUID,
                },
              })
            }
          >
            <Row justifyContent={'space-between'}>
              <SubHeadline weight={'medium'} color={'black1'} style={{ marginRight: 8 }}>
                {el.productName}
              </SubHeadline>
              {!isSale ? (
                <Row style={{ whiteSpace: 'pre-wrap' }}>
                  <Caption2 weight={'medium'} color={'gray06'}>
                    수량 {el.saleProductTotalCnt}
                  </Caption2>
                </Row>
              ) : el.productStatus === 'STOP' ? (
                <Caption2 weight={'medium'} color={'red'}>
                  판매중지
                </Caption2>
              ) : (
                <Row style={{ whiteSpace: 'pre-wrap' }}>
                  <Caption2 weight={'medium'} color={'gray06'}>
                    이용중
                  </Caption2>
                  <Caption2 weight={'medium'} color={'primary'}>
                    {' '}
                    {el.completeDriversCnt}{' '}
                  </Caption2>
                  <Caption2 weight={'medium'} color={'gray06'}>
                    / 판매중 {el.saleProductCnt}
                  </Caption2>
                </Row>
              )}
            </Row>
            <Row style={{ marginTop: 6 }} justifyContent={'space-between'}>
              <SubHeadline weight={'medium'} color={'black1'} style={{ marginRight: 8 }}>
                {formatPriceWithCommas(el.salePrice)}원
              </SubHeadline>
              {el.waitingDriversCnt && isSale ? (
                <Button
                  horizontal={10}
                  radius={4}
                  backgroundColor={colors.gray01}
                  style={{ height: 28 }}
                >
                  <Row style={{ whiteSpace: 'pre-wrap' }}>
                    <Caption2 weight={'medium'} color={'gray09'}>
                      승인 요청
                    </Caption2>
                    <Caption2 weight={'medium'} color={'primary'}>
                      {' '}
                      {el.waitingDriversCnt}
                    </Caption2>
                  </Row>
                </Button>
              ) : null}
            </Row>
          </WrapItem>
        ) : (
          <WrapItem
            key={index}
            onClick={() =>
              navigate('/minganProductDetail', {
                state: {
                  productUID: el.productUID,
                  type,
                  slotUID,
                },
              })
            }
          >
            <Row justifyContent={'space-between'}>
              <SubHeadline weight={'medium'} color={'black1'} style={{ marginRight: 8 }}>
                {el.productName}
              </SubHeadline>
              {el.productStatus === 'STOP' ? (
                <Caption2 weight={'medium'} color={'red'}>
                  판매중지
                </Caption2>
              ) : (
                <Caption2 weight={'medium'} color={'gray06'}>
                  판매중
                </Caption2>
              )}
            </Row>
            <Row style={{ marginTop: 6 }} justifyContent={'space-between'}>
              <SubHeadline weight={'medium'} color={'black1'} style={{ marginRight: 8 }}>
                {el.salePrice}
              </SubHeadline>
            </Row>
          </WrapItem>
        );
      })}
    </Container>
  );
};

export default MinganMonthParkInfo;
