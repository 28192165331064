import { makeAutoObservable } from 'mobx';

class CancelReasonStore {
  selectedReason: number | null = null;
  reasonValue: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setReason(reason: number | null) {
    this.selectedReason = reason;
  }

  setReasonValue(reason: string) {
    this.reasonValue = reason;
  }
}

export default new CancelReasonStore();
