import { styled } from '@stitches/react';
import { colors } from 'styles/colors';
import { pxToRem } from 'utils/common';

export const Container = styled('div', {
  backgroundColor: '#fff',
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  alignItems: 'center',
});

export const BottomContainer = styled('div', {
  flex: '1',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
  bottom: 0,
  width: 'calc(100% - 40px)'
});

export const TipWrap = styled('div', {
  flex: '1',
  flexDirection: 'column',
  borderRadius: '8px',
  padding: '16px',
  backgroundColor: colors.primaryBackground,
});

export const LottieWrap = styled('div', {
  marginTop: pxToRem(80)
});