import React, { useState } from 'react';
import { MainContainer } from 'components/styles';
import { StyledCheckbox, CheckBoxContainer, CheckBoxLabel, BottomContainer } from "./styles";
import Title from '../../components/atoms/Title/PageTitle';
import { useNavigate } from "react-router-dom";
import icArrowRightGraySmall from "../../assets/ic/ic_arrow_right_gray_small.svg";
import { Caption2, Caption3, Headline, SubHeadline } from 'styles/typography';
import Divider from 'components/atoms/Divider';
import { colors } from 'styles/colors';
import PopupModal from 'components/atoms/Modal/PopupModal';
import Row from 'components/atoms/Row';


const AgreeTerms: React.FC = () => {
  const navigate = useNavigate();
  const [isCheckedAll, setIsCheckedAll] = React.useState(false);
  const [checkboxes, setCheckboxes] = React.useState({
    serviceTerms: false,
    privacyPolicy: false,
    locationBased: false,
    marketing: false
  });
  const [isPopup, setIsPopup] = useState(false)

  const handleAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setIsCheckedAll(isChecked);
    setCheckboxes({
      serviceTerms: isChecked,
      privacyPolicy: isChecked,
      locationBased: isChecked,
      marketing: isChecked
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, key: keyof typeof checkboxes) => {
    const { checked } = event.target;
    setCheckboxes(prev => {
      const updatedCheckboxes = {
        ...prev,
        [key]: checked
      };

      const allChecked = Object.values(updatedCheckboxes).every(value => value);
      setIsCheckedAll(allChecked);
      return updatedCheckboxes;
    });
  };

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const navigateToDetails = (path: string) => {
    navigate(path);
  };

  const isButtonEnabled =
      isCheckedAll ||
      [checkboxes.serviceTerms, checkboxes.privacyPolicy, checkboxes.locationBased].filter(Boolean).length >= 3;

  // Button style
  const buttonStyle = {
    padding: '0px',
    border: '0px',
    width: '100%',
    marginRight: '16px',
    height: '46px',
    color: isButtonEnabled ? '#FFF' : '#8B63BB',
    backgroundColor: isButtonEnabled ? '#8B63BB' : '#F2F2F2',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: 'normal',
    borderRadius: '5px',
    cursor: isButtonEnabled ? 'pointer' : 'not-allowed',
  };

  // Button click handler
  const handleButtonClick = () => {
    if(!checkboxes.marketing) {
      setIsPopup(true)
    } else if (isButtonEnabled) {
      navigate('/signUpCert')
    }
  };

  return (
    <MainContainer>
      <Title
        title="약관 동의"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      <CheckBoxContainer>
        <CheckBoxLabel style={{ marginBottom: 0 }}>
          <StyledCheckbox
            type="checkbox"
            checked={isCheckedAll}
            onChange={handleAllChange}
          />

          <SubHeadline weight={'regular'} color={'black1'}>전체동의</SubHeadline>
        </CheckBoxLabel>
         <Divider  style={{ backgroundColor: colors.gray01, marginTop: 15, marginBottom: -5 }}/>
          <CheckBoxLabel style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledCheckbox
                type="checkbox"
                checked={checkboxes.serviceTerms}
                onChange={(e) => handleChange(e, 'serviceTerms')}
              />
              <SubHeadline weight={'regular'} color={'red'} style={{ marginRight: 4 }}>(필수)</SubHeadline>
              <SubHeadline weight={'regular'} color={'black1'}>주만사 서비스 이용약관</SubHeadline>
            </div>
            <div
              style={{ flexShrink: 0 }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent the click event from propagating to the checkbox
                console.log('주만사 서비스 이용약관 상세');
              }}
            >
            <img src={icArrowRightGraySmall} alt="arrow.png" />
            </div>
          </CheckBoxLabel>

          <CheckBoxLabel style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledCheckbox
                type="checkbox"
                checked={checkboxes.privacyPolicy}
                onChange={(e) => handleChange(e, 'privacyPolicy')}
              />
              <SubHeadline weight={'regular'} color={'red'} style={{ marginRight: 4 }}>(필수)</SubHeadline>
              <SubHeadline weight={'regular'} color={'black1'}>개인정보 수집 및 이용동의</SubHeadline>
            </div>
            <div
              style={{ flexShrink: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                console.log('개인정보 수집 및 이용동의 상세');
              }}
            >
            <img src={icArrowRightGraySmall} alt="arrow.png" />
            </div>
          </CheckBoxLabel>

          <CheckBoxLabel style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledCheckbox
                type="checkbox"
                checked={checkboxes.locationBased}
                onChange={(e) => handleChange(e, 'locationBased')}
              />
              <SubHeadline weight={'regular'} color={'red'} style={{ marginRight: 4 }}>(필수)</SubHeadline>
              <SubHeadline weight={'regular'} color={'black1'}>위치기반 서비스</SubHeadline>
            </div>
            <div
              style={{ flexShrink: 0 }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent the click event from propagating to the checkbox
                console.log('위치기반 서비스 상세');
              }}
            >
            <img src={icArrowRightGraySmall} alt="arrow.png" />
            </div>
          </CheckBoxLabel>

          <CheckBoxLabel style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledCheckbox
                  type="checkbox"
                  checked={checkboxes.marketing}
                  onChange={(e) => handleChange(e, 'marketing')}
                />
                <SubHeadline weight={'regular'} color={'gray06'} style={{ marginRight: 4 }}>(선택)</SubHeadline>
                <SubHeadline weight={'regular'} color={'gray06'}>마케팅 수신동의</SubHeadline>
              </div>
              <div
                style={{ flexShrink: 0 }}
                onClick={(e) => {
                  e.preventDefault();
                  console.log('마케팅 수신동의 상세');
                }}
              >
                <img src={icArrowRightGraySmall} alt="arrow.png" />
              </div>
          </CheckBoxLabel>
           <Caption2 weight={'regular'} color={'gray06'} style={{ marginLeft: 30}}> 주차장 및 다양한 이벤트 정보를 보내 드립니다.</Caption2>
      </CheckBoxContainer>
      <BottomContainer >
        <button
          style={buttonStyle}
          onClick={handleButtonClick}
          disabled={!isButtonEnabled}
        >
          다음
        </button>
      </BottomContainer>

      <PopupModal
        isVisible={isPopup}
        onClose={() => {
          setIsPopup(false)
        }}
        title1={`마케팅 수신동의를 하시면\n다양한 정보와 혜택을 드려요!`}
        leftClick={() => {
          setCheckboxes({...checkboxes, marketing: true})
          navigate('/signUpCert')
          setIsPopup(false)
        }}
        leftText={
        '확인'
        }
        description={
          <div style={{
            color: '#868686',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <div>
              <Row>
                <Headline weight={'regular'} color={'primary'} style={{ marginRight: 8 }}>
                  ·
                </Headline>
                <Caption2 weight={'regular'} color={'black3'}>
                  신규 확보 주차장 정보
                </Caption2>
              </Row>
              <Row style={{ marginRight: 6 }}>
                <Headline weight={'regular'} color={'primary'} style={{ marginRight: 8 }}>
                  ·
                </Headline>
                <Caption2 weight={'regular'} color={'black3'}>
                  관심 주자장 정보
                </Caption2>
              </Row>
              <Row style={{ marginRight: 6 }}>
                <Headline weight={'regular'} color={'primary'} style={{ marginRight: 8 }}>
                  ·
                </Headline>
                <Caption2 weight={'regular'} color={'black3'}>
                  할인 및 이벤트 정보 등
                </Caption2>
              </Row>
            </div>
            <Caption3 weight={'regular'} color={'gray06'} style={{ marginTop: 15 }}>
              메뉴 &gt; 설정에서 언제든지 변경 가능합니다.
            </Caption3>
            <div style={{
              marginTop: '16px',
              textDecoration: 'underline',
            }} onClick={() => {
                navigate('/signUpCert')
            }}>
              나중에 할래요
            </div>
          </div>
        }
      />
    </MainContainer>
  );
};

export default AgreeTerms;
