import { styled } from '@stitches/react';


export const Container = styled('div', {
  marginTop: 20
})


export const CarInfoRepresentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '22px',
  padding: '0px 24px 25px 24px',
  widht: '100%',

})

export const CarInfoRepresentSpan = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '22px',
  color: '#333',
})


export const CarNumberBackGroundContainer = styled('div', {
  display: 'flex',
  marginTop: '30px',
  color: '#6B6B6B',
  borderRadius: '10px',
  background: '#EBEBFB',
  lineHeight: '22px',
  height: '70px',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '7px',
  width: 'calc(100% - 100px)',
})

export const CarNumberOutlineContainer = styled('div', {
  borderRadius: '6px',
  border: '2px solid #000',
  background: '#fff',
  width: '100%',
  height: '56px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  boxSizing: 'border-box',
})

export const LeftDotImg = styled('img', {
  position: 'absolute',
  left: '10px',
  top: '50%',
  transform: 'translateY(-50%)',
})

export const CarNumberText = styled('span', {
  position: 'relative',
  zIndex: 1,
  color: '#333',
  fontFamily: 'Pretendard',
  fontSize: '35px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '20px'
})

export const RightDotImg = styled('img', {
  position: 'absolute',
  right: '10px',
  top: '50%',
  transform: 'translateY(-50%)',
})




export const ToggleButtonContainer = styled('div', {
  width: '40px',
  height: '20px',
  borderRadius: '20px',
  backgroundColor: '#ccc',
  position: 'relative',
  transition: 'background-color 0.3s',
  cursor: 'pointer',

  '&:before': {
    content: '',
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    backgroundColor: 'white',
    position: 'absolute',
    top: '1px',
    left: '1px',
    transition: 'left 0.3s',
  },

  variants: {
    isToggled: {
      true: {
        backgroundColor: '#8B63BB',

        '&:before': {
          left: '20px',
        },
      },
      false: {
        backgroundColor: '#ccc',

        '&:before': {
          left: '1px',
        },
      },
    },
  },
});

export const ViewDivideContainer = styled('div', {
  width: '100%',
  height: '8px',
  backgroundColor: '#CCC',
  marginTop: '26px',
})

export const ParkingReductionContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
  color: '#6B6B6B',
  width: '100%',
})

export const ParkingReductionStatus = styled('span', {
  color: '#333',
  fontFamily: 'Inter',
  fontSize: '17px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px'
})

export const ModifyDeleteContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
})

export const LengthDividerSpan = styled('span', {
  margin: '0 10px'
})

export const CarInfoRegistrationSpan = styled('span', {
  color: '#6B6B6B',
  fontStyle: 'normal',
  fontSize: '15px',
  fontWeight: 400,
  lineHeight: '22px'
})

export const CarInfoRegistrationDateSpan = styled('span', {
  color: '#333',
  fontFamily: 'Inter',
  fontSize: '17px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px'
})