import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  backgroundColor: colors.white,
  padding: '28px 20px',
});

export const WrapItem = styled('div', {
  padding: '16px 0px 16px 0px'
});

