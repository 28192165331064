import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import Title from 'components/atoms/Title/PageTitle';
import { MainContainerNoMargin } from 'components/styles';
import { Container } from './styles';
import { SubHeadline, Title3 } from 'styles/typography';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import { IcClose48SVG } from 'assets/ic';
import DesiredAreaRegisterList from 'containers/monthlyParking/DesiredAreaRegisterList';

const DesiredAreaManagementPage = observer(() => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { state } = useLocation();
  const { nextPage } = state;

  const handleLeftOnClick = () => {
    navigate(nextPage);
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  useEffect(() => {
    // 데이터를 가져오는 함수 정의
    const fetchData = async () => {
      // try {
      //   await shareStore.fetchGetProductDetailOperInfo(productUID);
      // } catch (error) {
      //   console.error('Error fetching data:', error);
      // }
    };

    fetchData(); // 데이터 가져오기 호출
  }, []);

  // if (isLoading) {
  //   return (
  //     <div
  //       style={{
  //         display: 'flex',
  //         alignItems: 'center',
  //         justifyContent: 'center',
  //         height: '50vh',
  //       }}
  //     >
  //       <Spinner />
  //     </div>
  //   );
  // }

  return (
    <MainContainerNoMargin>
      <Title
        title="희망 지역 관리"
        leftArrowButtonVisible={false}
        rightTextButtonVisible={true}
        rightTitle={
          <>
            <IcClose48SVG />
          </>
        }
        rightOnClick={() => {
          handleLeftOnClick();
        }}
      />

      <Container>
        <Title3 weight={'bold'} color={'black3'}>
          월 주차 희망지역
        </Title3>
        <SubHeadline weight={'regular'} color={'gray09'} style={{ marginTop: 16 }}>
          지역을 등록하면, 희망 지역 인근에 있는 주차장을 알려드려요.
        </SubHeadline>

        <DesiredAreaRegisterList />
      </Container>
    </MainContainerNoMargin>
  );
});

export default DesiredAreaManagementPage;
