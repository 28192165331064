import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Container, WrapInput, Input } from './styles';
import { Caption1, Caption2, Caption3, Title2 } from 'styles/typography';
import Row from 'components/atoms/Row';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import { useToast } from 'components/atoms/Toast/ToastProvider';

interface IFormInputs {
  coupon: string;
}

const CouponRegister = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputs>({
    defaultValues: {
      coupon: '',
    },
  });

  const coupon = watch('coupon', '');

  return (
    <Container>
      <Caption1 weight={'bold'} color={'gray07'}>
        쿠폰 등록
      </Caption1>

      <WrapInput>
        <Row justifyContent={'space-between'}>
          <Input
            type="text"
            placeholder={'‘-’를 제외한 숫자만 입력해 주세요.'}
            id="coupon"
            {...register('coupon', { required: '필수 입력 항목입니다.' })}
          />
          <Button
            style={{
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              width: 52,
              height: 30,
            }}
            backgroundColor={colors.primary}
            onClick={() => {
              // showToast('쿠폰 적용이 완료 되었습니다.');
              showToast('사용할 수 없는 쿠폰입니다.');
            }}
          >
            <Caption3 weight={'regular'} color={'white'}>
              등록
            </Caption3>
          </Button>
        </Row>
      </WrapInput>
    </Container>
  );
};

export default CouponRegister;
