import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, WrapInfo } from './styles';
import Row from 'components/atoms/Row';
import {
  Body,
  Caption1,
  Caption2,
  Caption3,
  Caption4,
  Headline,
  SubHeadline,
} from 'styles/typography';
import { IShareResidentSlotDetailInfoData } from 'models/Share/shareResidentSlotDetailInfo';

interface Props {
  name: string;
}

const ShareParkingAddress: React.FC<Props> = ({ name }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <WrapInfo>
        <Row justifyContent={'space-between'}>
          <Caption2 weight={'medium'} color={'gray09'}>
            주소
          </Caption2>
          <SubHeadline weight={'semibold'} color={'black3'}>
            {name}
          </SubHeadline>
        </Row>
      </WrapInfo>
    </Container>
  );
};

export default ShareParkingAddress;
