import React from 'react';
import {
  ImgSnsLogin,
  ImgHalf,
  ImgHalf2,
  MemberLoginContainer,
  SpanLink,
  LogoContainer,
  ContentsContainer
} from './styles';
import imgZoomansaSmall from '../../assets/images/img_zoomansa_small.svg';
import imgkakaoLoginBtn from '../../assets/images/img_kakao_login_btn.png';
import imgNaverLoginBtn from '../../assets/images/img_naver_login_btn.png';
import imgAppleLoginBtn from '../../assets/images/img_apple_login_btn.png';
import imgRentParking from '../../assets/images/img_reent_parking.png'
import imgFastSignUp from '../../assets/images/img_fast_sign_up.png';
import { isIOS } from 'react-device-detect';
import { MainContainer, MainContainerNoMargin } from '../../components/styles';
import { useNavigate } from 'react-router-dom';
import { Caption2, Caption4 } from 'styles/typography';
import { colors } from 'styles/colors';
import Row from 'components/atoms/Row';

const Login = () => {

  const navigate = useNavigate();

  const goMain = () => {

  }

  const handleLoginClick = () => {
    navigate('/zmsLogin')
  };

  const handleSignupClick = () => {
    navigate('/agreeTerms')
  };

  const handleNotMemberClick = () => {
    navigate('/home')
  }

  return (
      <MainContainerNoMargin style={{ height: isIOS ? 'calc(100vh - 30px)' : '100vh'}}>

        <ContentsContainer style={{ textAlign: 'center'}}>
          <LogoContainer>
            <img src={imgZoomansaSmall} alt="imgZoomansaSmall" />
          </LogoContainer>
          <ImgHalf2 src={imgRentParking} alt="imgRentParking" />
        </ContentsContainer>
        <ContentsContainer style={{ justifyContent: 'space-between'}}>
          <div>
            <ImgHalf src={imgFastSignUp} alt="imgFastSignUp" />
            <ImgSnsLogin
              src={imgkakaoLoginBtn}
              alt="KakaoLoginBtn"
              onClick={() => {
                console.log('카카오 로그인 클릭');
              }}
            />
            <ImgSnsLogin
              src={imgNaverLoginBtn}
              alt="imgNaverLoginBtn"
              onClick={() => {
                console.log('네이버 로그인 클릭');
              }}
            />
            {isIOS &&
              <ImgSnsLogin
                src={imgAppleLoginBtn}
                alt="imgAppleLoginBtn"
                onClick={() => {
                  console.log('애플 로그인 클릭');
                }}
              />
            }
            <MemberLoginContainer>
              <Row>
                <SpanLink onClick={handleLoginClick}>
                  <Caption2 weight={'regular'} color={'gray07'}>
                    아이디 로그인
                  </Caption2>
                </SpanLink>
                <Caption2 weight={'regular'} color={'gray03'}>
                  {' '}|{' '}
                </Caption2>
                <SpanLink onClick={handleSignupClick}>
                  <Caption2 weight={'regular'} color={'gray07'}>
                    회원가입
                  </Caption2>
                </SpanLink>
              </Row>
            </MemberLoginContainer>
          </div>
          <Caption4 weight={'regular'} color={'gray06'} style={{
            textDecoration: 'underline',
            marginBottom: 40
          }}>
            비회원으로 이용하기
          </Caption4>
        </ContentsContainer>
      </MainContainerNoMargin>
  );
};

export default Login;
