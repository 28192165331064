import React, { useState, useEffect } from 'react';

import { Modal } from './styles';
import Alexander from 'assets/images/alexander.png';
import ParkImg from 'assets/images/img_park.png';
import { Caption1, Caption2, Headline, SubHeadline } from 'styles/typography';
import Row from 'components/atoms/Row';
import { IcClose48SVG, IcTooltipAlertSVG, IcTooltipHelpSVG } from 'assets/ic';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';

interface BottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  title1: string;
  description: string | React.ReactNode;
  leftClick: () => void;
  leftText: string;
  rightClick?: () => void;
  rightText?: string;
  title2?: string;
}

const CustomBottomModal: React.FC<BottomModalProps> = ({
  isVisible,
  onClose,
  title1,
  description,
  leftText,
  leftClick,
  rightClick,
  rightText,
  title2,
}) => {
  if (!isVisible) return null;

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 9999,
        }}
        onClick={onClose}
      />
      <Modal isVisible={isVisible}>
        <Row style={{ whiteSpace: 'pre-wrap' }}>
          <Headline weight={'medium'} color={'black1'}>
            {title1}
          </Headline>
        </Row>

        <Caption1
          weight={'regular'}
          color={'gray08'}
          style={{ marginTop: 16, wordBreak: 'keep-all' }}
        >
          {description}
        </Caption1>
        {title2 ? (
          <Caption1
            weight={'regular'}
            color={'primary'}
            style={{ marginTop: 10, wordBreak: 'keep-all' }}
          >
            {title2}
          </Caption1>
        ) : null}

        {rightText ? (
          <Row style={{ gap: '10px' }}>
            <Button
              backgroundColor={colors.gray01}
              radius={4}
              style={{
                height: 46,
                width: 'calc(100%)',
                marginTop: 28,
              }}
              onClick={() => {
                onClose();
                leftClick && leftClick();
              }}
            >
              <Caption2 weight={'semibold'} color={'gray06'}>
                {leftText}
              </Caption2>
            </Button>
            <Button
              backgroundColor={colors.primary}
              radius={4}
              style={{
                height: 46,
                width: 'calc(100%)',
                marginTop: 28,
              }}
              onClick={() => {
                onClose();
                rightClick && rightClick();
              }}
            >
              <Caption2 weight={'semibold'} color={'white'}>
                {rightText}
              </Caption2>
            </Button>
          </Row>
        ) : (
          <Button
            backgroundColor={colors.primary}
            radius={4}
            style={{
              height: 46,
              width: 'calc(100%)',
              marginTop: 28,
            }}
            onClick={() => {
              onClose();
              leftClick && leftClick();
            }}
          >
            <Caption2 weight={'semibold'} color={'white'}>
              {leftText}
            </Caption2>
          </Button>
        )}
      </Modal>
    </>
  );
};

export default CustomBottomModal;
