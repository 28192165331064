import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Container } from './styles';
import { Caption2, Title2 } from 'styles/typography';

const ParkingName = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Title2 weight={'bold'} color={'primary'}>
        06-23 주차장
      </Title2>

      <Caption2 weight={'regular'} color={'gray06'}>
        서울 성동구
      </Caption2>
    </Container>
  );
};

export default ParkingName;
