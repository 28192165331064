import { styled } from '@stitches/react'

export const TopContainer = styled('div', {
  marginTop: '20px',
  marginBottom: '40px'
})

export const DetailContainer  = styled('div', {
  marginTop: '16px',
  marginLeft: '10px',
  marginBottom: '16px'
})

export const MiddleContainer = styled('div', {
  marginTop: '10px'
})

export const MiddleContainer2 = styled('div', {
  marginTop: '16px'
})

