import { styled } from "@stitches/react";
import { isIOS } from 'react-device-detect';

export const MainContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: '0 20px',
  touchAction: 'pan-x pan-y pinch-zoom',
  marginTop: isIOS ? '30px' : '0px',
  flex: 1,
});

export const MainContainerNoMargin = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  touchAction: 'pan-x pan-y pinch-zoom',
  marginTop: isIOS ? '30px' : '0px',
  flex: 1,
});
