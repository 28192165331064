import React from 'react';
import {CheckModalButton, CheckModalContainer, CheckModalTitleSpan} from './styles';
import { SubHeadline } from 'styles/typography';

interface CheckModalProps {
  isVisible: boolean
  onClose: () => void
  title: string
  content: React.ReactNode
  buttonText: string
}

const CheckModal: React.FC<CheckModalProps> = ({ isVisible, onClose, title, content, buttonText }) => {
  if (!isVisible) return null

  return (
      <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999,
          }}
          onClick={onClose}
      >
        <CheckModalContainer onClick={(e) => e.stopPropagation()}>
            <div style={{padding: '30px 0px 14px 0px'}}>
              <SubHeadline weight={'bold'} color={'primaryDark'}>
                {title}
              </SubHeadline>
            </div>
            <div style={{
              paddingBottom: content ? '22px' : '0px'
            }}>{content}</div>
          <CheckModalButton onClick={onClose}>
            {buttonText}
          </CheckModalButton>
        </CheckModalContainer>
      </div>
  );
};

export default CheckModal;