import React, {  CSSProperties } from 'react';

import { colors } from 'styles/colors';

interface Props {
  style?: CSSProperties;
}


const Divider: React.FC<Props> = (props) => {
  const { style } = props;
  return (
    <div
      style={{
        height: '1px',
        backgroundColor: colors.gray03,
        ...style, // 전달된 스타일 덮어쓰기
      }}
    />
  );
};

export default Divider;