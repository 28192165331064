import { MainContainer } from '../../../../components/styles';
import {
  AllDeleteContainer,
  BottomButtonKeyboardContainer,
  BottomGridContainer,
  ButtonEnableDisable,
  CautionContainer,
  InputBoxContainer,
  InputButton,
  InputGridContainer,
  OneDeleteContainer,
  OneDeleteImg,
  PasswordInput,
  PasswordTitleInputContainer,
} from '../styles';
import Title from '../../../../components/atoms/Title/PageTitle';
import icArrowDelete from '../../../../assets/ic/ic_arrow_delete.svg';
import WithdrawBottomModal from '../../../../components/atoms/Modal/WithdrawBottomModal';
import PurpleOneButtonCheckModal from '../../../../components/atoms/Modal/PurpleOneButtonCheckModal';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { encTextWord, getRandomString } from '../../../../utils/cryptoJS';
import { IPointPasswordGenerate } from '../../../../models/Point/pointPasswordGenerateModel';
import BaseError from '../../../../models/baseError';
import { LINK_UID } from '../../../../constants/userInfoConstants';
import { pointPasswordGenerateStore } from '../../../../stores/store/Point/pointPasswordGenerateStore';
import { observer } from 'mobx-react';

const PointWithdrawalPasswordSettingRe = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const password = location.state?.password;
  const navigateType = location.state?.navigateType ?? '/pointWithdrawalMoney';

  const [isWithdrawBottomModalVisible, setIsWithdrawBottomModalVisible] = React.useState(false);
  const [inputValues, setInputValues] = React.useState<string[]>(Array(6).fill(''));
  const [isPasswordSettingSuccessModal, setIsPasswordSettingSuccessModal] = useState(false);

  const [isPasswordMismatch, setIsPasswordMismatch] = useState(false);
  const [hasStartedEntering, setHasStartedEntering] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);

  const linkUID = parseInt(sessionStorage.getItem(LINK_UID) || '0', 10);

  const handleNumberClick = (index: number, number: string) => {
    setHasStartedEntering(true);
    setInputValues(prevValues => {
      const newValues = [...prevValues];
      newValues[index] = number;
      return newValues;
    });
  };

  const handleClearAll = () => {
    setInputValues(Array(6).fill(''));
    setIsPasswordMismatch(false);
    setHasStartedEntering(false);
  };

  const handleBackspace = () => {
    setInputValues(prevValues => {
      const lastNonEmptyIndex = prevValues
        .map((value, index) => (value !== '' ? index : -1))
        .filter(index => index !== -1)
        .pop();

      if (lastNonEmptyIndex !== undefined) {
        const newValues = [...prevValues];
        newValues[lastNonEmptyIndex] = '';
        return newValues;
      }
      return prevValues;
    });
  };

  const getDisplayValue = (value: string, index: number) => {
    const filledCount = inputValues.filter(v => v !== '').length;
    return index < filledCount - 1 ? '*' : value;
  };

  const [iv, setIv] = useState('');
  const [salt, setSalt] = useState('');
  const [accountRegiPW, setAccountRegiPW] = useState('');

  useEffect(() => {
    const { iv: newIv, salt: newSalt } = getRandomString();
    setIv(newIv);
    setSalt(newSalt);
  }, []);

  useEffect(() => {
    if (iv && salt) {
      const concatenatedInputValues = inputValues.join('');
      const encryptedPassword = encTextWord(concatenatedInputValues, iv, salt);
      setAccountRegiPW(encryptedPassword);
      console.log('새롭게 설정된 accountRegiPW:', encryptedPassword);
    }
  }, [iv, salt, inputValues]);

  useEffect(() => {
    // 비밀번호 입력이 6글자인지 확인
    const isAllFieldsFilled = inputValues.length === 6 && inputValues.every(value => value !== '');

    // 6글자가 입력되면 버튼을 활성화
    setButtonEnabled(isAllFieldsFilled);
  }, [inputValues]);

  const handlePasswordGenerate = async () => {
    const isPwUpdateUser = sessionStorage.getItem('isPwUpdateUser') === 'true';
    const inputPassword = inputValues.join('');

    // 확인 버튼 클릭 후 비밀번호 일치 여부 확인
    if (inputPassword !== password) {
      setIsPasswordMismatch(true); // 비밀번호가 일치하지 않으면 오류 표시
      setInputValues(Array(6).fill('')); // 비밀번호가 일치하지 않으면 입력 필드 초기화
      setHasStartedEntering(false); // 입력 시작 상태 초기화
      return;
    } else {
      setIsPasswordMismatch(false); // 비밀번호가 일치하면 오류 표시 해제
    }

    if (!accountRegiPW) {
      console.error('accountRegiPW가 비어 있습니다.');
      return;
    }

    const requestBody: IPointPasswordGenerate = {
      memberUID: linkUID,
      wdPassword: accountRegiPW,
    };

    try {
      if (isPwUpdateUser) {
        console.log('Calling postPointPasswordUpdate');
        await pointPasswordGenerateStore.updatePassword(requestBody, iv, salt);
      } else {
        console.log('Calling postPointPasswordGenerate');
        await pointPasswordGenerateStore.generatePassword(requestBody, iv, salt);
      }

      // 비밀번호가 성공적으로 설정되었을 때 성공 모달 표시
      setIsPasswordSettingSuccessModal(true);
    } catch (error) {
      if (error instanceof BaseError) {
        console.error('BaseError 발생:', error.message);
      } else {
        console.error('알 수 없는 에러 발생:', error);
      }
    }
  };

  return (
    <>
      <MainContainer>
        <Title
          title="출금"
          leftArrowButtonVisible={true}
          leftOnClick={() => {
            navigate(-1);
          }}
          rightTextButtonVisible={false}
        />

        <PasswordTitleInputContainer>
          <span>비밀번호를 한번 더 입력해주세요</span>
        </PasswordTitleInputContainer>
        <InputBoxContainer>
          {inputValues.map((value, index) => (
            <PasswordInput key={index} value={getDisplayValue(value, index)} readOnly />
          ))}
        </InputBoxContainer>

        {isPasswordMismatch && (
          <CautionContainer>
            <span>비밀번호가 일치하지 않습니다.</span>
          </CautionContainer>
        )}

        <WithdrawBottomModal
          isVisible={isWithdrawBottomModalVisible}
          onClose={() => setIsWithdrawBottomModalVisible(false)}
        />

        <PurpleOneButtonCheckModal
          isVisible={isPasswordSettingSuccessModal}
          onClose={() => {
            setIsPasswordSettingSuccessModal(false);
            navigate(navigateType);
          }}
          title={'비밀번호를 설정이 완료되었습니다.'}
          content={''}
          buttonText={'확인'}
        />
      </MainContainer>

      <BottomButtonKeyboardContainer>
        <ButtonEnableDisable onClick={handlePasswordGenerate} disabled={!buttonEnabled}>
          확인
        </ButtonEnableDisable>
        <BottomGridContainer>
          <InputGridContainer>
            {[1, 2, 3].map((number: number) => (
              <InputButton
                key={number}
                onClick={() => {
                  const emptyIndex = inputValues.indexOf('');
                  if (emptyIndex !== -1) {
                    handleNumberClick(emptyIndex, number.toString());
                  }
                }}
              >
                {number}
              </InputButton>
            ))}
          </InputGridContainer>
          <InputGridContainer>
            {[4, 5, 6].map((number: number) => (
              <InputButton
                key={number}
                onClick={() => {
                  const emptyIndex = inputValues.indexOf('');
                  if (emptyIndex !== -1) {
                    handleNumberClick(emptyIndex, number.toString());
                  }
                }}
              >
                {number}
              </InputButton>
            ))}
          </InputGridContainer>
          <InputGridContainer>
            {[7, 8, 9].map((number: number) => (
              <InputButton
                key={number}
                onClick={() => {
                  const emptyIndex = inputValues.indexOf('');
                  if (emptyIndex !== -1) {
                    handleNumberClick(emptyIndex, number.toString());
                  }
                }}
              >
                {number}
              </InputButton>
            ))}
          </InputGridContainer>
          <InputGridContainer>
            <AllDeleteContainer onClick={handleClearAll}>전체삭제</AllDeleteContainer>
            <InputButton
              onClick={() => {
                const emptyIndex = inputValues.indexOf('');
                if (emptyIndex !== -1) {
                  handleNumberClick(emptyIndex, '0');
                }
              }}
            >
              0
            </InputButton>
            <OneDeleteContainer>
              <OneDeleteImg
                onClick={handleBackspace}
                src={icArrowDelete}
                alt={'입력 필드 지우는 아이콘'}
              />
            </OneDeleteContainer>
          </InputGridContainer>
        </BottomGridContainer>
      </BottomButtonKeyboardContainer>
    </>
  );
});

export default PointWithdrawalPasswordSettingRe;
