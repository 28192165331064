import React, { useState, useEffect, useRef } from 'react';

import {
  Modal,
  WrapItem
} from './styles';
import { Body, Caption2, Headline, SubHeadline } from 'styles/typography';
import Row from 'components/atoms/Row';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import Divider from 'components/atoms/Divider';
import { IcArrowDown16SVG, IcArrowUp16SVG } from 'assets/ic';
import TimePicker from 'components/atoms/TimePicker';
import { useToast } from 'components/atoms/Toast/ToastProvider';

interface BottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSuccess: (start: string, end: string) => void;
  selected: number;
  data: {
    day : string;
    startTime: string;
    endTime: string;
    isActive: string;
  }[]
}


const GangseoShareTimeModal: React.FC<BottomModalProps> = ({ isVisible, onClose, onSuccess, selected, data }) => {
  const endPickerRef = useRef<HTMLDivElement>(null);
  const startPickerRef = useRef<HTMLDivElement>(null);
  const { showToast } = useToast();

  const [isStartPickerVisible, setIsStartPickerVisible] = useState(false);
  const [isEndPickerVisible, setIsEndPickerVisible] = useState(false);
  const [startHour, setStartHour] = useState('09')
  const [startMinute, setStartMinute] = useState('00')
  const [endHour, setEndHour] = useState('18')
  const [endMinute, setEndMinute] = useState('00')

  const minutes = ["00", "30", "", ""]
  const startHours = ["09", "10", "11", "12", "13", "14", "15", "", ""]
  const endHours = ["09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "", ""]
  const itemHeight = 48; // TimeItem 높이


  useEffect(() => {
    if (isStartPickerVisible && startPickerRef.current) {
      // 현재 선택된 시간을 기준으로 스크롤 위치 설정
      const hourIndex = startHours.indexOf(startHour);
      const targetScrollPosition = hourIndex * itemHeight;
      startPickerRef.current.scrollTop = targetScrollPosition;
    }
  }, [isStartPickerVisible, startHour]);

  useEffect(() => {
    if (isEndPickerVisible && endPickerRef.current) {
      const hourIndex = endHours.indexOf(endHour); // 현재 선택된 시간의 인덱스

      // 선택된 시간의 인덱스가 없는 경우 초기 스크롤 위치를 18로 설정
      const targetIndex = hourIndex !== -1 ? hourIndex : endHours.indexOf("18");
      const targetScrollPosition = targetIndex * itemHeight;

      endPickerRef.current.scrollTop = targetScrollPosition;
    }
  }, [isEndPickerVisible, endHour]);



  const onStartChange = (hour: string, minute: string) => {
    setStartHour(hour)
    setStartMinute(minute)
  }


  const onEndChange = (hour: string, minute: string) => {
    const startTime = parseInt(startHour) * 60 + parseInt(startMinute);
    const endTime = parseInt(hour) * 60 + parseInt(minute);

    if (startTime >= endTime) {
      // 시작 시간보다 +1 시간 설정
      const newEndTime = startTime + 60;
      const newEndHour = Math.floor(newEndTime / 60).toString().padStart(2, "0");
      const newEndMinute = (newEndTime % 60).toString().padStart(2, "0");
      // 스크롤 위치를 업데이트
      const endIndex = endHours.indexOf(newEndHour);
      const targetScrollPosition = endIndex * itemHeight;

      setTimeout(() => {
        endPickerRef.current?.scrollTo({
          top: targetScrollPosition,
          behavior: "smooth",
        });

      }, 500);
      setEndHour(newEndHour);
      setEndMinute(newEndMinute);

    } else  {
      setEndHour(hour);
      setEndMinute(minute);
    }
  };



  const init = () => {

    const [startHour, startMinute] = data[selected].startTime.split(':');
    const [endHour, endMinute] = data[selected].endTime.split(':');

    setStartHour(startHour)
    setStartMinute(startMinute)
    setEndHour(endHour);
    setEndMinute(endMinute);
    setIsEndPickerVisible(false);
    setIsStartPickerVisible(false);
  }

  useEffect(() => {
    init()
  }, [isVisible])



  if (!isVisible) return null;


  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 9999,
        }}
        onClick={onClose}
      />
      <Modal isVisible={isVisible}>
        <Headline weight={'medium'} color={'black1'}>{`${data[selected].day}요일 공유 시간`}</Headline>
        <WrapItem
          onClick={() =>
            setIsStartPickerVisible((prev) => !prev) // 클릭 시 타임피커 토글
          }
        >
            <Row  justifyContent={'space-between'} style={{ marginTop: 16 }}>
              <SubHeadline weight={'medium'} color={'gray07'}>시작 시간</SubHeadline>
              <Row>
                <Body weight={'medium'}  color={'black1'} style={{ marginRight: 16 }}>{`${startHour}:${startMinute}`}</Body>
                {isStartPickerVisible ? (<IcArrowUp16SVG />) : (<IcArrowDown16SVG />)}
              </Row>
            </Row>
          {isStartPickerVisible && (
            <TimePicker
              onChange={onStartChange} // 시간 선택 시 업데이트
              minutes={minutes}
              hours={startHours}
              ref={startPickerRef}
            />
          )}
        </WrapItem>

        <Divider style={{ color: colors.gray02 , marginTop: 16 }} />

        <WrapItem
          onClick={() =>
            setIsEndPickerVisible((prev) => !prev) // 클릭 시 타임피커 토글
          }
        >
          <Row justifyContent={'space-between'} >
            <SubHeadline weight={'medium'} color={'gray07'}>종료 시간</SubHeadline>
            <Row>
              <Body weight={'medium'} color={'black1'} style={{ marginRight: 16 }} >{`${endHour}:${endMinute}`}</Body>
              {isEndPickerVisible ? (<IcArrowUp16SVG/>) : (<IcArrowDown16SVG />)}
            </Row>
          </Row>
          {isEndPickerVisible && (
            <TimePicker
              onChange={onEndChange} // 시간 선택 시 업데이트
              minutes={minutes}
              hours={endHours}
              ref={endPickerRef}
            />
          )}
        </WrapItem>
        <Row style={{ gap: 10, position: 'absolute', bottom: 0, width: 'calc(100% - 40px)' }}>
          <Button
            backgroundColor={colors.gray01}
            radius={4}
            style={{
              height: 46,
              width: 'calc(100%)',
              marginBottom: 20,
              marginTop: 28
            }}
            onClick={onClose}
          >
            <SubHeadline weight={'bold'} color={'gray06'}>취소</SubHeadline>
          </Button>
          <Button
            backgroundColor={colors.primary}
            radius={4}
            style={{
              height: 46,
              width: 'calc(100%)',
              marginBottom: 20,
              marginTop: 28
            }}
            onClick={() => {
              if(Number(startHour) + 1 === Number(endHour) && startMinute !== endMinute) {
                showToast(`최소 1시간부터 가능해요.`,)
                return;
              }
              onClose()
              onSuccess(`${startHour}:${startMinute}` , `${endHour}:${endMinute}`);
            }}
          >
            <SubHeadline weight={'bold'} color={'white'}>확인</SubHeadline>
          </Button>
        </Row>
      </Modal>
    </>
  );
};

export default GangseoShareTimeModal;
