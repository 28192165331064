import React, { useState } from "react";
import { MainContainer, MainContainerNoMargin } from '../../../components/styles';
import { useNavigate } from "react-router-dom";
import Title from "../../../components/atoms/Title/PageTitle";
import { } from "../styles";
import icDotBlack from "../../../assets/ic/ic_dot_black.svg";
import {
  CarInfoRegistrationDateSpan,
  CarInfoRegistrationSpan,
  CarInfoRepresentContainer,
  CarInfoRepresentSpan, Container, LengthDividerSpan, ModifyDeleteContainer,
  ParkingReductionContainer, ParkingReductionStatus,
  ToggleButtonContainer,
  ViewDivideContainer,
  CarNumberBackGroundContainer,
  CarNumberOutlineContainer,
  CarNumberText,
  LeftDotImg,
  RightDotImg
} from './styles';
import TwoButtonModal from "../../../components/atoms/Modal/TwoButtonModal";
import CheckModal from "../../../components/atoms/Modal/CheckModal";
import Row from 'components/atoms/Row';
import Divider from 'components/atoms/Divider';
import { colors } from 'styles/colors';

const CarInfoEdit = () => {
  const navigate = useNavigate();
  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const [isToggled, setIsToggled] = useState(false);
  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const [isReductionDeleteModal, setIsReductionDeleteModal] = useState(false);
  const reductionDeleteModal = () => {
    setIsReductionDeleteModal(true);
  };

  const [isCarInfoDeleteModal, setIsCarInfoDeleteModal] = useState(false);
  const carInfoDeleteModal = () => {
    setIsCarInfoDeleteModal(true);
  };

  const [isCheckModalVisible, setIsCheckModalVisible] = useState(false);
  const showCheckModal = () => {
    setIsCheckModalVisible(true);
  };

  const hideCheckModal = () => {
    setIsCheckModalVisible(false);
    navigate('/carRegistration')
  };

  return (
      <MainContainerNoMargin>
        <Title
            title="차량 정보 수정"
            leftArrowButtonVisible={true}
            leftOnClick={handleLeftOnClick}
            rightTextButtonVisible={true}
            rightOnClick={() => {
              carInfoDeleteModal();
            }}
            rightTitle={'삭제'}
        />

    <Container>

        <CarInfoRepresentContainer>
          <Row justifyContent={'space-between'} style={{width: '100%'}}>
            <CarInfoRepresentSpan>
              대표 차량으로 설정하기
            </CarInfoRepresentSpan>
            <ToggleButtonContainer isToggled={isToggled} onClick={handleToggle} />
          </Row>
          <CarNumberBackGroundContainer>
            <CarNumberOutlineContainer>
              <LeftDotImg
                src={icDotBlack}
                alt={'왼쪽 점 아이콘'}
              />
              <CarNumberText>
                345구4567
              </CarNumberText>
              <RightDotImg
                src={icDotBlack}
                alt={'점 아이콘2'}
              />
            </CarNumberOutlineContainer>
          </CarNumberBackGroundContainer>
        </CarInfoRepresentContainer>


        <Divider style={{ backgroundColor: colors.primaryBackground, height: 8 }}/>
        <div style={{ margin: '30px 25px 25px' }}>
        <span
            style={{
              color: '#6B6B6B',
              fontFamily: 'Pretendard',
              fontSize: '15px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '22px'
            }}
        >
          주차요금 감면 대상
        </span>
          <br />
          <ParkingReductionContainer>
            <ParkingReductionStatus>감면 신청 진행 중</ParkingReductionStatus>
            <ModifyDeleteContainer>
            <span onClick={() => {
              reductionDeleteModal();
            }}>삭제</span>
              <LengthDividerSpan>|</LengthDividerSpan>
              <span onClick={() => {
                navigate('/reductionCategory');
              }}>수정</span>
            </ModifyDeleteContainer>
          </ParkingReductionContainer>
        </div>
        <div style={{
          margin: '40px 25px'
        }}>
          <CarInfoRegistrationSpan>차량 정보 등록일</CarInfoRegistrationSpan>
          <br />
          <CarInfoRegistrationDateSpan>2024년 08월 26일</CarInfoRegistrationDateSpan>
        </div>

        {isReductionDeleteModal &&
            <TwoButtonModal
                isVisible={isReductionDeleteModal}
                onClose={() => setIsReductionDeleteModal(false)}
                title={'감면 정보를 삭제하시겠습니까?'}
                content={''}
                leftButtonText={'취소'}
                rightButtonText={'삭제'}
                rightClick={() => {
                  navigate('/carRegistration');
                }}
            />
        }

        {isCarInfoDeleteModal &&
            <TwoButtonModal
                isVisible={isCarInfoDeleteModal}
                onClose={() => setIsCarInfoDeleteModal(false)}
                title={'차량 정보를 삭제하시겠습니까?'}
                content={''}
                leftButtonText={'취소'}
                rightButtonText={'삭제'}
                rightClick={() => {
                  showCheckModal(); // 체크 모달 띄우기
                }}
            />
        }

        { isCheckModalVisible &&
            <CheckModal
                isVisible={isCheckModalVisible}
                onClose={hideCheckModal}
                title={'차량 정보 삭제가 완료되었습니다.'}
                content={''}
                buttonText={'확인'}
            />
        }
       </Container>

    </MainContainerNoMargin>

);
}

export default CarInfoEdit;
