import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  padding: '20px 25px',
});

export const WrapMap = styled('div', {
  width: 'calc(100%)',
  height: window.innerWidth * 0.4166,
  marginTop: 20,
});
