import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  backgroundColor: '#fff',
  padding: '28px 20px 88px 20px',
});

export const WrapItem = styled('div', {

  paddingLeft: '16px',
  borderRadius: '8px',
  marginBottom: '10px',
  variants: {
    isActive: {
      true: {
        backgroundColor: colors.primaryBackground,
        border: `1px solid transparent`
      },
      false: {
        backgroundColor: colors.white,
        border: `1px solid ${colors.gray02}`
      }
    }
  }
});