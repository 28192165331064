import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const PointHistoryContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  touchAction: 'pan-x pan-y pinch-zoom',
});

export const HoldAmountPointContainer = styled('div', {
  background: 'linear-gradient(to right, #BB8AF4, #7749AE)',
  paddingTop: '92px',

  '@media (max-width: 600px)': {
    // 작은 화면 (600px 이하)
    paddingTop: '100px', // 원하는 패딩 값으로 조정
  },
});

export const AmountPointContainer = styled('div', {
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  marginLeft: '20px',
});

export const AmountPointContentsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});

export const HaveMoneySpan = styled('span', {
  color: '#FFF',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
});

export const QuestionIcon = styled('img', {
  marginLeft: '4px',
});

export const PointContainer = styled('div', {
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  marginLeft: '20px',
  marginTop: '26px',
});

export const PointContentContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
});

export const HaveMoneyPointNumberSpan = styled('span', {
  color: '#FFF',
  fontFamily: 'Pretendard',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '800',
  lineHeight: 'normal',
  marginTop: '5px',
});

export const HavePointSpan = styled('span', {
  color: '#FFF',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
});

export const ShoppingBackIcon = styled('img', {
  marginLeft: '16px',
  marginRight: '8px',
  width: '20px',
  height: '20px',
});

export const WithdrawPointAmountContainer = styled('div', {
  color: '#fff',
  justifyContent: 'space-between',
  display: 'flex',
  marginLeft: '20px',
});

export const WithdrawTextContainer = styled('div', {
  backgroundColor: '#60388F',
  color: '#fff',
  padding: '15px 28px',
  borderRadius: '8px',
  marginRight: '20px',
  textAlign: 'center',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
});

export const RedeemPointWithdrawalContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'center',
  alignItems: 'center',
  color: '#EBEBFB',
  fontFamily: 'Pretendard',
  fontSize: '17px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  justifyContent: 'center',
  boxSizing: 'border-box',
  marginTop: '28px',
  width: '100%',
  paddingBottom: '28px',
});

export const RedeemPointContainer = styled('div', {
  padding: '15px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  backgroundColor: '#60388F',
  height: '48px',
  borderRadius: '8px',
  marginRight: '10px',
  boxSizing: 'border-box',
  whiteSpace: 'nowrap',
  flexGrow: 7,
  marginLeft: '20px',
});

export const RedeemPointContentContainer = styled('div', {
  alignItems: 'center',
  textAlign: 'center',
  flexDirection: 'row',
  display: 'flex',
  marginRight: '20px',
});

export const RedeemPointSpan = styled('span', {
  display: 'flex',
  alignItems: 'center',
});

export const WithdrawalContainer = styled('div', {
  padding: '15px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  backgroundColor: '#60388F',
  height: '48px',
  borderRadius: '8px',
  boxSizing: 'border-box',
  whiteSpace: 'nowrap',
  flexGrow: 3,
  marginRight: '20px',

  variants: {
    disabled: {
      true: {
        backgroundColor: '#ccc',
        cursor: 'not-allowed',
        opacity: 0.6,
      },
      false: {
        backgroundColor: '#60388F',
      },
    },
  },
});

export const ViewDivideContainer = styled('div', {
  height: '1px',
  backgroundColor: '#B799DB',
  margin: '24px 20px 0px 20px',
});

export const BenefitContainer = styled('div', {
  backgroundColor: '#60388F',
  padding: '15px 53px',
  margin: '25px',
  borderRadius: '5px',
  height: '50px',
  display: 'flex',
  alignItems: 'center',
});

export const AccumulationExpiresContainer = styled('div', {
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'row',
  color: '#EBEBFB',
  alignItems: 'center',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
});

export const TodayBenefitContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '8px',
});

export const EarnExpectedContainer = styled('div', {
  backgroundColor: '#6C429F',
  padding: '18px 20px',
});

export const EarnExpectedMoneyContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

export const EarnDisappearExpectedMoneySpan = styled('div', {
  marginRight: '8px',
});

export const DisappearExpectedMoneyContentContainer = styled('div', {
  marginTop: '12px',
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
});

export const DisappearExpectedMoneyContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

export const TodayBenefitText = styled('span', {
  color: '#FFF',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
});

export const NotAccountContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: '#F2EEF8',
  justifyContent: 'space-between',
  padding: '16px',
  margin: '28px 20px 0px 20px',
  borderRadius: '8px',
  alignItems: 'center',
});

export const NotAccountSpan = styled('span', {
  color: '#555',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '15px',
  lineHeight: 'normal',
});

export const UseAccountContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#FFF',
  border: '2px solid #EBEBFB',
  padding: '10px 16px',
  margin: '28px 20px 0px 20px',
  borderRadius: '8px',
});

export const ModifyAccountContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  color: '#8B63BB',
});

export const ModifyContentsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  color: '#868686',
  alignItems: 'center',
});

export const AccountInfoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '11px',
});

export const AccountInfoContentsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const BankIcon = styled('img', {
  width: '28px',
  height: '28px',
  marginRight: '8px',
});

export const BankAccountInfoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  color: '#666',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: 'normal',
});

export const BankAccountInfoContentsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const AccountNumberSpan = styled('span', {
  color: '#B0B0B0',
});

export const RegiAccountContainer = styled('div', {
  textAlign: 'center',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
});

export const RegiAccountSpan = styled('span', {
  color: '#8B63BB',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  marginRight: '4px',
});

export const NotificationContentContainer = styled('div', {
  color: '#6B6B6B',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  marginTop: '8px',
});

export const PointListContainer = styled('div', {
  marginTop: '28px',
  textAlign: 'center',
});

export const SpinnerContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '40px',
});

export const WithdrawalLimitModalContentContainer = styled('div', {
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  marginTop: '4px',
});

export const ListText = styled('div', {
  fontSize: 15,
  fontWeight: 400,
  lineHeight: 'normal',

  color: '#6B6B6B',
  '& span': {
    color: '#8B63BB',
  },
});

export const WrapDescription = styled('div', {
  padding: 10,
  backgroundColor: colors.primaryBackground,
  marginTop: '28px',
});
