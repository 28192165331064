import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MainContainer } from 'components/styles';
import Title from '../../../components/atoms/Title/PageTitle';
import { SubHeadline, TermsDetail } from 'styles/typography';
import { DetailContainer, TopContainer } from 'pages/Policy/styles';

const PolicyMarketingPage = () => {
  const navigate = useNavigate();

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const marketingChapter1 =
    '주만사 서비스를 운용함에 있어 각종 정보를 모바일 APP Push, SMS(문자, 카카오 알림톡), 이메일 등의 방법으로 회원에게 제공할 수 있으며, 결제 안내 등 의무적으로 안내되어야 하는 정보성 내용 및 일부 혜택성 정보는 수신동의 여부와 무관하게 제공합니다.';

  const marketingChapter1Detail = [
    '- 신규 기능 개발 및 맞춤 서비스 제공',
    '- 뉴스레터 발송, 새로운 기능(제품)의 안내',
    '- 할인 및 쿠폰 등 이벤트 등 광고성 정보 제공',
  ];

  const marketingChapter2 =
    '개인정보보호법 제22조 제4항에 의해 선택정보 사항에 대해서는 기재하지 않으셔도 서비스를 이용하실 수 있습니다.';

  const marketingChapter3 = '고객센터(1666-6248)를 통해 수신동의를 변경(동의/철회) 할 수 있습니다.';

  return (
    <MainContainer>
      <Title
        title="마케팅 정보 수신동의"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
        titleColor={'darkPurple'}
        showSpacer={true}
      />
      <TopContainer>
        <SubHeadline weight={'semibold'}>제 1조 (마케팅 정보 수신동의)</SubHeadline>
        <TermsDetail
          color={'gray09'}
          weight={'regular'}
          style={{
            marginTop: '10px',
          }}
        >
          {marketingChapter1}
        </TermsDetail>
        <div style={{
          marginTop: '16px',
          marginLeft: '10px'
        }}>
          {marketingChapter1Detail.map((chapter1Detail, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter1Detail}
            </TermsDetail>
          ))}
        </div>

        <SubHeadline style={{
          marginTop: '40px'
        }} weight={'semibold'}>제 2조 (미동의 시 불이익 사항)</SubHeadline>
        <TermsDetail color={'gray09'} weight={'regular'} style={{ marginTop: '10px' }}>
          {marketingChapter2}
        </TermsDetail>

        <SubHeadline style={{
          marginTop: '40px'
        }} weight={'semibold'}>제 3조 (서비스 정보 수신 동의 변경)</SubHeadline>
        <TermsDetail color={'gray09'} weight={'regular'} style={{ marginTop: '10px' }}>
          {marketingChapter3}
        </TermsDetail>
      </TopContainer>
    </MainContainer>
  )
}

export default PolicyMarketingPage;
