import React from 'react';

import { Container } from 'containers/point/AutomaticTransfer/styles';
import { Caption1, Caption3 } from 'styles/typography';
import CustomToggle from 'components/atoms/CustomToggle';

interface Props {
  isToggle: boolean;
  onToggle: (state: boolean) => void;
}

const AutomaticTransfer: React.FC<Props> = ({ isToggle, onToggle }) => {
  return (
    <Container>
      <div>
        <Caption1 weight={'regular'} color={'gray09'}>
          자동이체
        </Caption1>
        <Caption3 weight={'regular'} color={'gray05'} style={{ marginTop: 4 }}>
          매월 5일에 주머니를 자동으로 이체해드려요.
        </Caption3>
      </div>

      <CustomToggle
        toggleStyle={{ width: 40, height: 20 }}
        circleStyle={{ width: 16, height: 16 }}
        isOn={isToggle}
        onToggle={onToggle}
      />
    </Container>
  );
};

export default AutomaticTransfer;
