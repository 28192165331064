import { MainContainer } from '../../../components/styles';
import Title from '../../../components/atoms/Title/PageTitle';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Container,
  PhoneNumberBorderContainer,
  UserInfoInput,
  TimerContainer,
  VerificationCodeInput,
  VerificationCodeInputContainer,
} from './styles';
import { Body, Caption2, SubHeadline } from 'styles/typography';
import Row from 'components/atoms/Row';
import { idRegex, passwordRegex } from 'utils/common';

interface IFormInputs {
  phone: string;
  certification: string;
  password: string;
  passwordVerify: string;
  id: string;
}

const SignUpCert: React.FC = () => {
  const navigate = useNavigate();
  const {
    formState: { errors },
    register,
    setValue,
    handleSubmit,
    watch,
  } = useForm<IFormInputs>();

  const [isCertification, setIsCertification] = useState(false);
  const [time, setTime] = useState(180);
  const [phoneError, setPhoneError] = useState('');
  const [isVerifyComplete, setIsVerifyComplete] = useState(false);
  const [idMessage, setIdMessage] = useState<string | null>(null);

  const phone = watch('phone', '');
  const certification = watch('certification', '');
  const password = watch('password', '');
  const passwordVerify = watch('passwordVerify', '');
  const id = watch('id', '');

  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    if (!isCertification) return;

    // 1초마다 타이머 업데이트
    const timerInterval = setInterval(() => {
      setTime(prevTime => {
        if (prevTime <= 0) {
          clearInterval(timerInterval);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    // 컴포넌트가 언마운트되면 타이머를 정리합니다.
    return () => clearInterval(timerInterval);
  }, [isCertification]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const onSubmit: SubmitHandler<IFormInputs> = data => {
    if (!isVerifyComplete) {
      if (!passwordRegex.test(data.password)) {
        setMessage('8~16자 영문 대 소문자, 숫자, 특수문자를 사용하세요.');
      } else {
        navigate('/signUpRegionSetting', {
          state: {
            nextPage: '/home',
          },
        });
      }
    } else {
      if (phone.length !== 11) {
        setPhoneError('올바른 휴대폰 번호를 입력해주세요');
        return;
      } else if (!isCertification) {
        setPhoneError('');
        setIsCertification(true);
        return;
      } else {
        // 인증 맞는 경우
        console.log(true);
      }
    }
  };

  const validateId = (value: string) => {
    // 중복 검사 (모의, 실제 API 연동 필요)

    if (!value) {
      setIdMessage('아이디는 필수 입력 항목입니다.');
    } else if (value === 'test123') {
      setIdMessage('이미 사용 중인 아이디입니다.');
    } else if (!idRegex.test(value)) {
      setIdMessage('5~20자의 영문 소문자, 숫자, 특수기호(_)(-)만 사용 가능합니다.');
    } else {
      setIdMessage('사용 가능한 아이디입니다.');
    }
  };

  // ID 변경 시마다 유효성 검사 수행
  const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue('id', value); // React Hook Form 상태 업데이트
    validateId(value);
  };

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const buttonStyle = {
    padding: '0px',
    width: 'calc(100%)',
    height: '46px',
    backgroundColor: certification.length === 6 ? '#8B63BB' : '#fff',
    border: '1px solid #8B63BB',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: 'normal',
    borderRadius: '4px',
    cursor: phone ? 'pointer' : 'not-allowed',
    marginTop: 28,
  };

  const idPwDisabled = idMessage !== '사용 가능한 아이디입니다.' || !password || !passwordVerify;

  const buttonStyle2 = {
    padding: '0px',
    width: 'calc(100%)',
    height: '46px',
    backgroundColor: idPwDisabled ? '#f2f2f2' : '#8B63BB',
    border: 'none',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: 'normal',
    borderRadius: '4px',
    cursor: phone ? 'pointer' : 'not-allowed',
    marginTop: 28,
  };

  return (
    <MainContainer>
      <Title
        title="휴대폰 번호 인증"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />

      {!isVerifyComplete ? (
        <Container>
          <div
            style={{
              marginTop: '20px',
            }}
          >
            <div>
              <PhoneNumberBorderContainer>
                <UserInfoInput
                  type="text"
                  id="id"
                  {...register('id')}
                  onChange={handleIdChange}
                  placeholder="아이디"
                />
              </PhoneNumberBorderContainer>

              <Caption2
                weight={'bold'}
                color={idMessage === '사용 가능한 아이디입니다.' ? 'blue' : 'red'}
                style={{ marginTop: '7px' }}
              >
                {idMessage}
              </Caption2>
            </div>
            <div>
              <PhoneNumberBorderContainer>
                <UserInfoInput
                  type="password"
                  id="password"
                  {...register('password')}
                  placeholder="비밀번호"
                />
              </PhoneNumberBorderContainer>
              <Caption2 color="red" style={{ marginTop: '7px' }}>
                {errors.password && errors.password.message}
              </Caption2>
            </div>
            <div>
              <PhoneNumberBorderContainer>
                <UserInfoInput
                  type="password"
                  placeholder="비밀번호 확인"
                  id="passwordVerify"
                  {...register('passwordVerify')}
                />
              </PhoneNumberBorderContainer>
              {passwordVerify !== password || message ? (
                <Caption2 weight={'bold'} color={'red'} style={{ marginTop: 7 }}>
                  {passwordVerify !== password ? '비밀번호가 일치하지 않습니다' : message}
                </Caption2>
              ) : null}
            </div>
          </div>
          <button
            style={{
              ...buttonStyle2,
            }}
            onClick={handleSubmit(onSubmit)}
            disabled={idPwDisabled}
          >
            <SubHeadline weight={'regular'} color={idPwDisabled ? 'primary' : 'white'}>
              가입하기
            </SubHeadline>
          </button>
        </Container>
      ) : (
        <Container>
          <div
            style={{
              marginTop: '40px',
            }}
          >
            <div style={{ marginTop: '37px' }}>
              <PhoneNumberBorderContainer>
                <UserInfoInput
                  type="number"
                  id="phone"
                  {...register('phone', {
                    required: '필수 입력 항목입니다.',
                  })}
                  placeholder="휴대폰 번호"
                />
              </PhoneNumberBorderContainer>
              {phoneError ? (
                <Caption2 weight={'bold'} color={'red'} style={{ marginTop: 7 }}>
                  {phoneError}
                </Caption2>
              ) : null}
            </div>
            {isCertification ? (
              <div>
                <VerificationCodeInputContainer>
                  <VerificationCodeInput
                    type="number"
                    placeholder="인증번호 입력"
                    id="certification"
                    {...register('certification', { required: '필수 입력 항목입니다.' })}
                  />
                  <TimerContainer>{formatTime(time)}</TimerContainer>
                </VerificationCodeInputContainer>
                {certification.length !== 6 ? (
                  <Caption2 weight={'bold'} color={'red'} style={{ marginTop: 7 }}>
                    인증 번호를 입력해 주세요
                  </Caption2>
                ) : null}
              </div>
            ) : null}
          </div>
          <button
            style={{
              ...buttonStyle,
            }}
            onClick={handleSubmit(onSubmit)}
            disabled={!phone}
          >
            <SubHeadline
              weight={'regular'}
              color={certification.length === 6 ? 'white' : 'primary'}
            >
              {!isCertification
                ? '인증번호 요청'
                : certification.length === 6
                  ? '확인'
                  : '인증번호 다시 받기'}
            </SubHeadline>
          </button>
        </Container>
      )}

      {/*<CheckModal*/}
      {/*  isVisible={isVerificationCodeTransmissionModal}*/}
      {/*  onClose={() => {*/}
      {/*    setIsVerificationCodeTransmissionModal(false);*/}
      {/*  }}*/}
      {/*  title={'인증번호가 발송되었습니다.'}*/}
      {/*  content={''}*/}
      {/*  buttonText={'확인'}*/}
      {/*/>*/}
    </MainContainer>
  );
};

export default SignUpCert;
