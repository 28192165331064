import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

// Dialog - 다이얼로그
export const Dialog = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: '100%', // 다이얼로그의 높이 조절
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  boxSizing: 'border-box',
  zIndex: 10000,
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' },
      false: { transform: 'translateY(100%)' },
    },
  },
  overflowY: 'auto',
});

// CloseButton - 다이얼로그 닫기 버튼
export const CloseButton = styled('button', {
  background: 'none',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  alignSelf: 'flex-end',
});

// Content - 다이얼로그의 내용 영역
export const Content = styled('div', {
  flex: 1,
  overflowY: 'auto',
});

export const SearchInput = styled('input', {
  border: 'none',
  background: 'transparent',
  fontSize: '15px',
  fontWeight: 400,
  color: '#333',
  width: '100%',
  outline: 'none',

  '&::placeholder': {
    color: '#B0B0B0',
    fontSize: '15px',
    fontWeight: 400,
  },
});

export const WrapSearch = styled('div', {
  width: 'calc(100% - 80px)',
  display: 'flex',
  backgroundColor: colors.primaryBackground,
  height: 45,
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px 20px 0px 14px',
});

export const WrapResult = styled('div', {
  // padding: '20px',
});

export const WrapItem = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  // marginBottom: 16
  padding: '16px 20px',
});

export const Text = styled('p', {
  fontSize: '11px',
  fontWeight: 400,
  lineHeight: 'normal',
  color: colors.gray06,
});

export const WrapSeeMore = styled('div', {
  display: 'flex',
  marginTop: 32,
  flexDirection: 'column',
  // marginBottom: 20,
  textDecoration: 'underline',
  textAlign: 'center',
});
