import React from 'react';
import { Dialog } from './styles'; // 경로는 실제 파일 위치에 맞게 조정하세요
import Title from '../../Title/PageTitle';

// Props 타입 정의
interface BottomModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const ParkingSearchModal: React.FC<BottomModalProps> = ({ isVisible, onClose }) => {
  return (
    <>
      <Dialog isVisible={isVisible}>
        <Title
          title="주차장 검색"
          leftArrowButtonVisible={true}
          leftOnClick={onClose}
          rightTextButtonVisible={false}
        />
      </Dialog>
    </>
  );
};

export default ParkingSearchModal;
