import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Container, WrapInfo, WrapItem } from './styles';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import Row from 'components/atoms/Row';
import {
  Body,
  Caption1,
  Caption2,
  Caption3,
  Caption4,
  Headline,
  SubHeadline,
} from 'styles/typography';
import Divider from 'components/atoms/Divider';
import { pxToRem } from 'utils/common';
import { IcArrowBottomBlackSVG, IcArrowDown16SVG } from 'assets/ic';
import { UseHistory } from 'models/Share/shareProductDetailModel';
import { IShareResidentUseCompleteSlotListData } from 'models/Share/shareResidentUseCompleteSlotListModel';
import { shareResidentStore } from 'stores/store/Share/shareResidentStore';
import {
  IShareResidentSlotDetailInfoData,
  UseCompleteDriver,
} from 'models/Share/shareResidentSlotDetailInfo';
import { shareStore } from 'stores/store/Share/shareStore';
import { formatCommaDateTimeColons } from 'utils/dateUtils';

interface Props {
  onClick: () => void;
  selectYear: string;
  slotCode: number;
  regionCode: number;
  hasMore: boolean;
  setHasMore: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResidentParkingUsingHistory: React.FC<Props> = observer(
  ({ onClick, selectYear, hasMore, slotCode, regionCode, setHasMore }) => {
    const navigate = useNavigate();
    const observerRef = useRef<HTMLDivElement>(null);

    const [currentTab, setCurrentTab] = useState(0);
    const [pageNum, setPageNum] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const { residentUseCompleteSlotList } = shareResidentStore;

    const tabs = ['3개월', '6개월', `${selectYear}`];

    useEffect(() => {
      fetchData();
    }, [currentTab, selectYear]);

    const fetchData = async () => {
      if (isLoading || !hasMore) return;
      setIsLoading(true);

      let response;

      if (currentTab === 2) {
        await shareResidentStore.fetchResidentUseCompleteSlotList(
          slotCode,
          regionCode,
          selectYear.slice(0, -1),
          'year',
          pageNum,
        );
      } else {
        const filter = currentTab === 0 ? '3' : '6';
        await shareResidentStore.fetchResidentUseCompleteSlotList(
          slotCode,
          regionCode,
          filter,
          'month',
          pageNum,
        );
      }

      const currentCount = shareResidentStore.residentUseCompleteSlotList.length;
      setHasMore(currentCount < shareResidentStore.residentUseCompleteSlotListTotalCount);
      if (currentCount < shareResidentStore.residentUseCompleteSlotListTotalCount) {
        setPageNum(pageNum + 1);
      }
      setIsLoading(false);
    };

    useEffect(() => {
      let debounceTimer: NodeJS.Timeout | null = null;
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(async entry => {
            if (entry.isIntersecting && !debounceTimer) {
              debounceTimer = setTimeout(() => {
                fetchData();
                debounceTimer = null;
              }, 500);
            }
          });
        },
        { threshold: 1.0 },
      );

      if (observerRef.current) {
        observer.observe(observerRef.current);
      }

      return () => {
        if (observerRef.current) {
          observer.unobserve(observerRef.current);
        }
        if (debounceTimer) clearTimeout(debounceTimer);
      };
    }, [observerRef.current, isLoading]);

    return (
      <Container>
        <Row justifyContent={'space-between'} style={{ paddingBottom: 10 }}>
          <Body weight={'semibold'} color={'black1'}>
            이용기록
          </Body>
          <Row>
            {tabs.map((tab, index) => {
              return (
                <Row key={index}>
                  <Caption4
                    onClick={() => {
                      setCurrentTab(index);
                      setPageNum(1);
                      setHasMore(true);

                      if (index === 2) {
                        onClick();
                      }
                    }}
                    weight={currentTab === index ? 'semibold' : 'regular'}
                    color={currentTab === index ? 'gray08' : 'gray05'}
                    style={{ marginLeft: 4 }}
                  >
                    {tab}
                  </Caption4>
                  {index !== tabs.length - 1 ? (
                    <Caption4 weight={'semibold'} color={'gray03'} style={{ marginLeft: 4 }}>
                      |
                    </Caption4>
                  ) : (
                    <IcArrowDown16SVG />
                  )}
                </Row>
              );
            })}
          </Row>
        </Row>
        {residentUseCompleteSlotList?.length !== 0 ? (
          <>
            {residentUseCompleteSlotList?.map((el, index) => (
              <WrapItem key={index}>
                {index !== 0 ? <Divider style={{ marginBottom: 20 }} /> : null}
                <SubHeadline weight={'medium'} color={'black3'}>
                  *** {el.carNumber.slice(-4)}
                </SubHeadline>
                <WrapInfo>
                  <Row>
                    <Caption3 weight={'regular'} color={'gray06'} style={{ marginRight: 8 }}>
                      이용 기간
                    </Caption3>
                    <Caption3 weight={'medium'} color={'gray07'}>
                      {formatCommaDateTimeColons(el.parkingStartTime)} ~{' '}
                      {formatCommaDateTimeColons(el.parkingEndTime)}
                    </Caption3>
                  </Row>
                </WrapInfo>
              </WrapItem>
            ))}
            <div ref={observerRef}></div>
          </>
        ) : (
          <Caption2
            weight={'medium'}
            color={'gray08'}
            style={{ marginTop: 80, textAlign: 'center' }}
          >
            이용한 운전자가 없어요.
          </Caption2>
        )}
      </Container>
    );
  },
);

export default ResidentParkingUsingHistory;
