import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainContainer } from 'components/styles';
import Title from 'components/atoms/Title/PageTitle';
import CarInfoList from './CarInfoList';
import { Container, BottomContainer } from './styles';
import Row from 'components/atoms/Row';
import { Caption2, Caption3, SubHeadline, Title3 } from 'styles/typography';
import { CheckBoxLabel, StyledCheckbox } from 'pages/AgreeTerms/styles';
import icArrowRightGraySmall from 'assets/ic/ic_arrow_right_gray_small.svg';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import VehicleRegistrationBottomModal from 'components/atoms/Modal/VehicleRegistrationBottomModal';
import VehicleRegistration from 'containers/car/VehicleRegistration';

interface Car {
  carNumber: string;
  status: string;
  isToggled: boolean;
}

const CarInfo = () => {
  const navigate = useNavigate();
  const scrollContainerRef = useRef<HTMLDivElement>(null); // 스크롤 컨테이너 참조
  const [carList, setCarList] = useState<Car[]>([
    { carNumber: '123가4567', status: '정상', isToggled: false },
    { carNumber: '234나5678', status: '감면신청 진행 중', isToggled: false },
    { carNumber: '345다6789', status: '정상', isToggled: false },
    { carNumber: '449라6789', status: '정상', isToggled: false },
    { carNumber: '549라6789', status: '감면신청 진행 중', isToggled: false },
    { carNumber: '649라6789', status: '감면신청 진행 중', isToggled: false },
  ]);
  const [carNumber, setCarNumber] = useState('');
  const [checkboxes, setCheckboxes] = React.useState({
    serviceTerms: false,
    privacyPolicy: true,
    locationBased: false,
    marketing: false,
  });
  const [isCarNumberCheckBottomModal, setIsCarNumberCheckBottomModal] = useState(false);

  // 토글 핸들러
  const handleToggle = (index: number) => {
    // 모든 차량의 isToggled를 false로 설정
    const resetToggledList = carList.map(car => ({ ...car, isToggled: false }));

    // 선택된 차량만 isToggled를 true로 설정
    const updatedCarList = resetToggledList.map((car, i) => {
      if (i === index) {
        return { ...car, isToggled: !car.isToggled };
      }
      return car;
    });

    // 선택된 차량을 리스트의 상단으로 이동
    const toggledCar = updatedCarList.find(car => car.isToggled);
    const remainingCars = updatedCarList.filter(car => !car.isToggled);

    const sortedCarList = toggledCar ? [toggledCar, ...remainingCars] : remainingCars;

    setCarList(sortedCarList);

    // 스크롤 위치를 상단으로 조정
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: keyof typeof checkboxes,
  ) => {
    const { checked } = event.target;
    setCheckboxes(prev => {
      const updatedCheckboxes = {
        ...prev,
        [key]: checked,
      };

      return updatedCheckboxes;
    });
  };

  const carCount = carList.length;
  const isDisabled = !checkboxes.privacyPolicy || carNumber === '';

  return (
    <MainContainer>
      <Title
        title="차량 관리"
        leftArrowButtonVisible={true}
        leftOnClick={() => navigate(-1)}
        rightTextButtonVisible={carCount ? true : false}
        // rightOnClick={() => {}}
        rightTitle={`${carCount}/20`}
      />
      <Container>
        {carList.length ? (
          <div
            ref={scrollContainerRef}
            style={{ flex: 1, overflowY: 'auto', paddingBottom: '40px' }}
          >
            {carList.map((car, index) => (
              <CarInfoList
                key={index}
                carNumber={car.carNumber}
                status={car.status}
                isLast={index === carList.length - 1}
                isToggled={car.isToggled}
                onToggle={() => handleToggle(index)}
              />
            ))}
            <div style={{ height: '60px' }}></div>
            <BottomContainer>
              <Button
                radius={4}
                backgroundColor={colors.primary}
                style={{
                  width: '100%',
                  height: '46px ',
                  marginBottom: 40,
                  marginTop: 16,
                  border: 'none',
                }}
                onClick={() => {
                  navigate('/carRegistration');
                }}
              >
                <SubHeadline weight={'semibold'} color={'white'}>
                  차량 등록
                </SubHeadline>
              </Button>
            </BottomContainer>
          </div>
        ) : (
          <>
            <Row>
              <Title3 weight={'bold'} color={'primary'}>
                차량번호
              </Title3>
              <Title3 weight={'bold'} color={'black3'}>
                를 입력해 주세요
              </Title3>
            </Row>
            <VehicleRegistration setCarNumber={setCarNumber} />
            <BottomContainer>
              <CheckBoxLabel
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <StyledCheckbox
                    type="checkbox"
                    checked={checkboxes.privacyPolicy}
                    onChange={e => handleChange(e, 'privacyPolicy')}
                  />
                  <SubHeadline weight={'regular'} color={'red'} style={{ marginRight: 4 }}>
                    (필수)
                  </SubHeadline>
                  <SubHeadline weight={'regular'} color={'black1'}>
                    개인정보 수집 및 이용동의
                  </SubHeadline>
                </div>
                <div
                  style={{ flexShrink: 0 }}
                  onClick={e => {
                    e.stopPropagation();
                    console.log('개인정보 수집 및 이용동의 상세');
                    navigate('/policyPersonal');
                  }}
                >
                  <img src={icArrowRightGraySmall} alt="arrow.png" />
                </div>
              </CheckBoxLabel>
              <Button
                radius={4}
                backgroundColor={isDisabled ? colors.gray01 : colors.primary}
                style={{
                  width: '100%',
                  height: '46px ',
                  marginBottom: 40,
                  marginTop: 16,
                  border: 'none',
                }}
                onClick={() => {
                  setIsCarNumberCheckBottomModal(true);
                }}
                disabled={isDisabled}
              >
                <SubHeadline weight={'semibold'} color={isDisabled ? 'primary' : 'white'}>
                  다음
                </SubHeadline>
              </Button>
            </BottomContainer>
          </>
        )}
      </Container>

      <VehicleRegistrationBottomModal
        isVisible={isCarNumberCheckBottomModal}
        onClose={() => setIsCarNumberCheckBottomModal(false)}
        carNumber={carNumber}
        onClick={() => {
          navigate('/reductionCategory');
        }}
      />
    </MainContainer>
  );
};

export default CarInfo;
