import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  padding: '30px 25px',
});

export const WrapInput = styled('div', {
  marginTop: 13,
  border: '1px solid #E6E6E6',
  borderRadius: 5,
  padding: '4px 10px',
  position: 'relative',
});
