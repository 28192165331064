import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';

import RegionSelectBottomModal from 'components/atoms/Modal/RegionSelectBottomModal';
import CheckModal from 'components/atoms/Modal/CheckModal';
import Title from 'components/atoms/Title/PageTitle';
import icArrowRightGraySmall from 'assets/ic/ic_arrow_right_gray_small.svg';
import {
  GeojuInput,
  PhoneNumber,
  PhoneNumberHeader,
  CheckBoxLabel,
  StyledCheckbox,
  WrapPhoneInfo,
  WrapInput,
  BottomContainer,
  Container,
  WrapGeojuInput,
} from './styles';
import { MainContainerNoMargin } from 'components/styles';
import { Caption1, Caption2, Caption3, SubHeadline } from 'styles/typography';
import Row from 'components/atoms/Row';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import { IcCheckSVG } from 'assets/ic';
import { shareStore } from 'stores/store/Share/shareStore';
import { observer } from 'mobx-react';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import { setDial } from 'utils/deviceUtils';
import { JSP_TEST_URL } from 'constants/baseConstants';

interface IFormInputs {
  vehicleNumber: string;
  compartmentNumber: string;
  region: string;
  areaUID: number;
}

const ShareRequestGeoju = observer(() => {
  const navigate = useNavigate();
  const { register, handleSubmit, watch, setValue, reset } = useForm<IFormInputs>();

  const [isRegionSelectBottomModalVisible, setIsRegionSelectBottomModalVisible] =
    React.useState(false);
  const [isApplyCheckModal, setIsApplyCheckModal] = React.useState(false);

  const vehicleNumber = watch('vehicleNumber', '');
  const compartmentNumber = watch('compartmentNumber', '');
  const region = watch('region', '');
  const areaUID = watch('areaUID', 0);

  const [isPrivacyPolicy, setIsPrivacyPolicy] = useState(false);

  const { areaList, isLoading } = shareStore;
  const { phoneNumber } = userInfoStore;

  const isButtonDisabled = !(vehicleNumber && compartmentNumber && region && isPrivacyPolicy);

  useEffect(() => {
    // 데이터를 가져오는 함수 정의
    const fetchData = async () => {
      try {
        await shareStore.fetchGetAreaList();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // 데이터 가져오기 호출
  }, []);

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  useEffect(() => {
    const subscription = watch(values => {
      console.log(values);
      localStorage.setItem('shareRequestData', JSON.stringify(values));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  // 2. 초기 데이터 복원
  useEffect(() => {
    const savedData = localStorage.getItem('shareRequestData');
    console.log(savedData);
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      reset(parsedData); // 전체 폼 데이터 초기화
    }
  }, [reset]);

  const onSubmit: SubmitHandler<IFormInputs> = async data => {
    setIsApplyCheckModal(true);
    await shareStore.fetchShareResidentSlot(phoneNumber, vehicleNumber, compartmentNumber, areaUID);
    localStorage.removeItem('shareRequestData');
  };

  const handleLeftOnClick = () => {
    navigate(-1);
    localStorage.removeItem('shareRequestData');
  };

  const handleSelectRegion = (selectedRegion: string, index: number) => {
    setValue('region', selectedRegion);
    setValue('areaUID', index);
  };

  const handleChange = () => {
    setIsPrivacyPolicy((prev: boolean) => !prev);
  };

  const formattedNumber = phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');

  return (
    <MainContainerNoMargin style={{ height: '100vh' }}>
      <Title
        title="거주자 우선 주차장 신청"
        leftArrowButtonVisible
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      <Container>
        <WrapPhoneInfo>
          <PhoneNumberHeader>
            <Caption1 weight={'regular'} color={'gray06'}>
              휴대 전화번호
            </Caption1>
            <PhoneNumber>{formattedNumber}</PhoneNumber>
          </PhoneNumberHeader>
          <Caption3
            weight={'regular'}
            color={'gray06'}
            style={{ textAlign: 'right', marginTop: 6 }}
          >
            위 연락처로 신청 관련 안내가 진행됩니다.
          </Caption3>
        </WrapPhoneInfo>
        <form onSubmit={handleSubmit(onSubmit)}>
          <WrapInput>
            <Caption2 weight={'regular'} color={'black1'} style={{ marginTop: '20px' }}>
              차량번호
            </Caption2>
            <WrapGeojuInput>
              <GeojuInput
                type="text"
                id="vehicleNumber"
                placeholder="배정 받으신 차량번호를 입력"
                {...register('vehicleNumber', { required: '필수 입력 항목입니다.' })}
              />
            </WrapGeojuInput>
            <Caption2 weight={'regular'} color={'black1'} style={{ marginTop: '20px' }}>
              구획번호
            </Caption2>
            <WrapGeojuInput>
              <GeojuInput
                type="text"
                id="compartmentNumber"
                placeholder="‘-’를 포함 한 배정 받으신 전체 구획번호를 입력"
                {...register('compartmentNumber', { required: '필수 입력 항목입니다.' })}
              />
            </WrapGeojuInput>
            <Caption2 weight={'regular'} color={'black1'} style={{ marginTop: '20px' }}>
              지역 (자치구)
            </Caption2>
            <WrapGeojuInput>
              <div
                style={{ marginTop: '4px', width: '100%' }}
                onClick={() => setIsRegionSelectBottomModalVisible(true)}
              >
                <Caption3 weight={'regular'} color={region ? 'black1' : 'gray06'}>
                  {region ? region : '지역 선택'}
                </Caption3>
              </div>
            </WrapGeojuInput>
          </WrapInput>
          <BottomContainer>
            <CheckBoxLabel>
              <Row>
                <StyledCheckbox checked={isPrivacyPolicy} onClick={handleChange}>
                  {isPrivacyPolicy ? <IcCheckSVG stroke={'#fff'} /> : null}
                </StyledCheckbox>
                <Caption2 weight={'regular'} color={'red'} style={{ marginRight: 4 }}>
                  (필수)
                </Caption2>
                <Caption2 weight={'regular'} color={'gray06'}>
                  개인정보 제공 및 위탁안내
                </Caption2>
              </Row>
              <div
                style={{ flexShrink: 0 }}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <img
                  src={icArrowRightGraySmall}
                  alt="arrow.png"
                  onClick={() => {
                    navigate('/policyPersonal');
                  }}
                />
              </div>
            </CheckBoxLabel>
            <button
              style={{
                width: '100%',
                height: 46,
                border: 'none',
                borderRadius: 4,
                backgroundColor: isButtonDisabled ? colors.gray01 : colors.primary,
              }}
              type="submit"
              disabled={isButtonDisabled}
            >
              <SubHeadline weight={'bold'} color={isButtonDisabled ? 'primary' : 'white'}>
                약관 동의 및 공유 신청
              </SubHeadline>
            </button>
          </BottomContainer>
        </form>
      </Container>

      <RegionSelectBottomModal
        isVisible={isRegionSelectBottomModalVisible}
        onClose={() => setIsRegionSelectBottomModalVisible(false)}
        onSelectRegion={handleSelectRegion}
        regions={areaList ?? []}
      />

      <CheckModal
        isVisible={isApplyCheckModal}
        onClose={() => {
          setIsApplyCheckModal(false);
          // navigate('/shareHistory');
          window.location.href = JSP_TEST_URL;
        }}
        title={'공유 신청이 완료되었습니다.'}
        content={
          <div>
            <Caption3 weight={'regular'} color={'gray06'}>
              영업시간(평일 09시 ~ 18시)내 <br />
              상담드리겠습니다
            </Caption3>
            <Caption3
              weight={'regular'}
              color={'primary'}
              style={{
                marginTop: '22px',
              }}
            >
              빠른 상담
            </Caption3>
            <button
              style={{
                width: '224px',
                height: '44px',
                marginTop: '6px',
                color: '#8B63BB',
                textAlign: 'center',
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontStyle: 'normal',
                lineHeight: 'normal',
                background: '#fff',
                borderRadius: '5px',
                borderColor: '#8B63BB',
                borderWidth: '1px',
                borderStyle: 'solid',
              }}
              onClick={() => setDial('1666-6248')}
            >
              <Caption1 weight={'regular'} color={'primary'} style={{}}>
                고객센터 1666-6248
              </Caption1>
            </button>
          </div>
        }
        buttonText={'확인'}
      />
    </MainContainerNoMargin>
  );
});

export default ShareRequestGeoju;
