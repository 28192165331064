import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import Title from 'components/atoms/Title/PageTitle';
import { MainContainerNoMargin } from 'components/styles';
import { Container } from './styles';
import Row from 'components/atoms/Row';
import Logo from 'assets/images/img_zoomansa_logo.png';
import Divider from 'components/atoms/Divider';
import { colors } from 'styles/colors';
import ParkingName from 'containers/geojuParking/ParkingName';
import ParkingInfo from 'containers/geojuParking/ParkingInfo';
import ShareStopModal from 'components/atoms/Modal/ShareStopModal';
import ParkingUsingInfo from 'containers/geojuParking/ParkingUsingInfo';
import GeojuParkingCaution from 'containers/geojuParking/GeojuParkingCaution';
import Button from 'components/atoms/Button/Button';
import { Caption2 } from 'styles/typography';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import Footer from 'components/atoms/Footer';
import CarSelectModal from 'components/atoms/Modal/CarSelectModal';

const GeojuParkingUsingPage = observer(() => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const [isTimeStop, setIsTimeStop] = useState(false);
  const [hour, setHour] = useState('');
  const [minute, setMinute] = useState('');
  const [carNumber, setCarNumber] = useState('');
  const [currentTime, setCurrentTime] = useState<string>('');
  const [isCarModal, setIsCarModal] = useState(false);

  const carNumbers = [
    {
      carNumber: '12rk1234',
      isMain: true,
    },
    {
      carNumber: '82마2452',
      isMain: false,
    },
  ];

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const updateTime = () => {
    const now = new Date();

    // 한국 표준시 (KST, UTC+9)로 변환
    const options: Intl.DateTimeFormatOptions = {
      timeZone: 'Asia/Seoul', // 한국 표준시(KST)
    };

    // 년, 월, 일, 시간, 분, 초를 각각 추출
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getHours()).padStart(2, '0');
    const minute = String(now.getMinutes()).padStart(2, '0');
    const second = String(now.getSeconds()).padStart(2, '0');

    // "YYYY-MM-DD HH:MM:SS" 형식으로 포맷
    const formattedTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;

    setCurrentTime(formattedTime);
  };

  useEffect(() => {
    // 1초마다 시간을 업데이트
    const intervalId = setInterval(updateTime, 1000);

    // 컴포넌트가 언마운트될 때 interval을 클리어
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  useEffect(() => {
    // 데이터를 가져오는 함수 정의
    const fetchData = async () => {
      // try {
      //   await shareStore.fetchGetProductDetailOperInfo(productUID);
      // } catch (error) {
      //   console.error('Error fetching data:', error);
      // }
    };

    fetchData(); // 데이터 가져오기 호출
  }, []);

  const onCloseTimeStopModal = () => {
    setIsTimeStop(false);
  };

  const onCloseCarModal = () => {
    setIsCarModal(false);
  };
  const onOpenCarModal = () => {
    setIsCarModal(true);
  };

  const onChange = (hour: string, minute: string) => {
    setHour(hour);
    setMinute(minute);
  };
  // if (isLoading) {
  //   return (
  //     <div
  //       style={{
  //         display: 'flex',
  //         alignItems: 'center',
  //         justifyContent: 'center',
  //         height: '50vh',
  //       }}
  //     >
  //       <Spinner />
  //     </div>
  //   );
  // }

  const disabled = hour === '' || carNumber === '';

  return (
    <MainContainerNoMargin>
      <Container>
        <Row style={{ margin: '24px 0px' }} alignItems={'center'} justifyContent={'center'}>
          <img alt={'logo'} width={79} height={19} src={Logo} />
        </Row>
      </Container>
      <ParkingName />
      <Divider style={{ backgroundColor: colors.primaryBackground, height: 6 }} />
      <ParkingInfo />
      <Divider style={{ backgroundColor: colors.primaryBackground, height: 6 }} />
      <ParkingUsingInfo
        hour={hour}
        minute={minute}
        setIsTimeStop={setIsTimeStop}
        carNumber={carNumber}
        currentTime={currentTime}
        onOpenCarModal={onOpenCarModal}
      />

      <GeojuParkingCaution />

      <Button
        backgroundColor={disabled ? colors.gray05 : colors.primary}
        radius={4}
        style={{
          height: 46,
          width: 'calc(100% - 50px)',
          marginTop: 28,
          marginLeft: 25,
          marginBottom: 20,
        }}
        onClick={() => {
          if (hour === '') {
            showToast('예상 출차 시간을 선택해주세요.');
          } else if (carNumber === '') {
            showToast('차량 번호를 등록해주세요');
          } else {
            showToast('주차가 시작되었어요!');
          }
        }}
      >
        <Caption2 weight={'semibold'} color={'white'}>
          주차 시작
        </Caption2>
      </Button>
      <Footer />

      <ShareStopModal
        isVisible={isTimeStop}
        onClose={onCloseTimeStopModal}
        rightClick={onChange}
        shareEndTime={'18:00'}
        title={'예상 출차 시간'}
      />

      <CarSelectModal isVisible={isCarModal} onClose={onCloseCarModal} data={carNumbers} />
    </MainContainerNoMargin>
  );
});

export default GeojuParkingUsingPage;
