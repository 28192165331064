import { useState } from 'react';

interface Error {
  title: string;
  description: string;
}

export const useImageUploader = () => {
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [error, setError] = useState<Error | null>(null);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files);
      const newImageUrls: string[] = [];
      let formatError = false; // 파일 형식 오류 여부를 추적

      const totalFiles = selectedImages.length + newFiles.length;

      if (totalFiles > 5) {
        setError({
          title: '최대 업로드 수 제한을 초과하였습니다',
          description: '업로드 파일은 5개를 초과할 수 없습니다',
        });
        // 5개 이상일 경우, 최대 5개까지만 선택되도록 처리
        newFiles.length = 5 - selectedImages.length; // 5개까지만 자른다
      }

      newFiles.forEach(file => {
        if (!/\.(jpe?g|a?png|gif|webp|bmp|pdf)$/i.test(file.name)) {
          formatError = true;
          return;
        }

        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = e => {
          if (e.target?.result) {
            newImageUrls.push(e.target.result as string);
            if (newImageUrls.length === newFiles.length) {
              setSelectedImages(prev => [...prev, ...newImageUrls]);
              setImageFiles(prev => [...prev, ...newFiles]);
            }
          }
        };
      });

      if (formatError) {
        setError({
          title:
            '이미지 파일(jpg, jpeg, png, gif, webp, bmp) 또는 PDF 파일만 업로드할 수 있습니다.',
          description: '',
        });
      }

      // **중요**: 동일한 파일을 다시 선택할 수 있도록 `input` 초기화
      event.target.value = '';
    }
  };

  const removeImage = (index: number) => {
    setSelectedImages(prev => prev.filter((_, i) => i !== index));
    setImageFiles(prev => prev.filter((_, i) => i !== index));
  };

  return { selectedImages, imageFiles, handleImageChange, removeImage, error };
};
