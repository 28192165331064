import { styled } from '@stitches/react';
import { colors } from 'styles/colors';
import { pxToRem } from 'utils/common';

export const Container = styled('div', {
  backgroundColor: '#fff',
  position: 'relative'
});

export const BottomContainer = styled('div', {
  backgroundColor: '#fff',
  position: 'fixed',
  bottom: 0,
  width: 'calc(100%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

});
