import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { isIOS } from 'react-device-detect';

import { Dialog, SearchInput, Text, WrapItem, WrapResult, WrapSearch, WrapSeeMore } from './styles';
import icArrowRightGrayMedium from 'assets/ic/ic_arrow_right_gray_medium.svg';
import Row from 'components/atoms/Row';
import { SearchIcon } from 'pages/Share/ShareRequestMingan/styles';
import { Caption3, Caption4, SubHeadline } from 'styles/typography';
import icSearch from 'assets/ic/ic_setting.svg';
import icArrowLeftPurple from 'assets/ic/ic_arrow_left_purple.svg';
import Divider from 'components/atoms/Divider';

const iosStyle: CSSProperties = isIOS
  ? {
      height: 'calc(100px + env(safe-area-inset-top))',
      paddingTop: '30px',
    }
  : {};

// Props 타입 정의
interface BottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSelectAddress: (address: string) => void; // 주소를 선택했을 때 호출되는 함수
}

const AddressSearchModal: React.FC<BottomModalProps> = ({
  isVisible,
  onClose,
  onSelectAddress,
}) => {
  const [value, setValue] = useState<string>(''); // 추가된 상태
  const [places, setPlaces] = useState<any[]>([]);
  const [visiblePlacesCount, setVisiblePlacesCount] = useState<number>(5);
  const [address, setAddress] = useState<any[]>([]);
  const [visibleAddressCount, setVisibleAddressCount] = useState<number>(5);

  useEffect(() => {
    if (value !== '') {
      let ps = new window.kakao.maps.services.Places();
      const geocoder = new window.kakao.maps.services.Geocoder();

      geocoder.addressSearch(value, searchResultAddr);
      ps.keywordSearch(value, placesSearchCB);

      // @ts-ignore
      function placesSearchCB(data: any, status: any) {
        if (status === window.kakao.maps.services.Status.OK) {
          setPlaces(data);
        }
      }

      // @ts-ignore
      function searchResultAddr(result: any, status: any) {
        if (status === window.kakao.maps.services.Status.OK) {
          setAddress(result);
        } else {
          console.error('주소 검색 실패:', status);
        }
      }
    }
  }, [value]);

  const handleShowMoreAddress = () => {
    setVisibleAddressCount(prevCount => prevCount + 5); // 5개씩 추가로 보여주기
  };

  const handleShowMorePlaces = () => {
    setVisiblePlacesCount(prevCount => prevCount + 5); // 5개씩 추가로 보여주기
  };

  return (
    <Dialog isVisible={isVisible}>
      <Row
        style={{
          ...iosStyle,
        }}
      >
        <button
          type="button"
          style={{
            margin: 'auto 0px auto 0px',
            width: '30px',
            height: '30px',
            cursor: 'none',
            background: 'none',
            border: 'none',
            padding: 0,
          }}
          onClick={onClose}
          data-id="back"
        >
          <img width={'100%'} src={icArrowLeftPurple} alt="타이틀 뒤로가기 버튼" />
        </button>
        <WrapSearch>
          {/* 주소 입력창 */}
          <SearchInput
            value={value}
            onChange={e => setValue(e.target.value)}
            placeholder={'주소를 검색하세요'}
          />
          <SearchIcon src={icSearch} alt="검색아이콘" />
        </WrapSearch>
      </Row>
      {address.length ? (
        <WrapResult>
          <Caption3 weight={'regular'} color={'gray06'}>
            주소 결과
          </Caption3>
          <Divider style={{ marginTop: 4, marginBottom: 18 }} />
          {address.slice(0, visibleAddressCount).map((el, index) => {
            return (
              <WrapItem
                key={index}
                onClick={() => {
                  onClose();
                  onSelectAddress(el.address_name);
                }}
              >
                <Row justifyContent={'space-between'}>
                  <Caption3 weight={'bold'} color={'black1'}>
                    {el.address_name}
                  </Caption3>
                  <img
                    src={icArrowRightGrayMedium}
                    alt={'애로우 아이콘'}
                    style={{ width: 10, height: 14 }}
                  />
                </Row>
              </WrapItem>
            );
          })}
          {visibleAddressCount < address.length ? (
            <WrapSeeMore onClick={handleShowMoreAddress}>
              <Caption4 weight={'regular'} color={'gray06'}>
                주소 결과 더보기
              </Caption4>
            </WrapSeeMore>
          ) : null}
        </WrapResult>
      ) : null}

      {places.length ? (
        <WrapResult>
          <Caption3 weight={'regular'} color={'gray06'}>
            장소 결과
          </Caption3>
          <Divider style={{ marginTop: 4, marginBottom: 18 }} />
          {places.slice(0, visiblePlacesCount).map((el, index) => {
            return (
              <WrapItem
                key={index}
                onClick={() => {
                  onClose();
                  onSelectAddress(el.address_name);
                }}
              >
                <Caption3 weight={'bold'} color={'black1'}>
                  {el.place_name}
                </Caption3>
                <Row justifyContent={'space-between'}>
                  <Row>
                    <Text>{el.address_name}</Text>
                  </Row>
                  <img
                    src={icArrowRightGrayMedium}
                    alt={'애로우 아이콘'}
                    style={{ width: 10, height: 14 }}
                  />
                </Row>
              </WrapItem>
            );
          })}
          {visiblePlacesCount < places.length ? (
            <WrapSeeMore onClick={handleShowMorePlaces}>
              <Caption4 weight={'regular'} color={'gray06'}>
                주소 결과 더보기
              </Caption4>
            </WrapSeeMore>
          ) : null}
        </WrapResult>
      ) : null}
    </Dialog>
  );
};

export default AddressSearchModal;
