import { styled } from '@stitches/react';
import {colors} from "styles/colors";

export const TitleContainer = styled('div', {
  zIndex: 2,
  width: '100%',
  maxWidth: '480px',
  height: '66px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  padding: '0 16px',
  boxSizing: 'border-box',
  variants: {
    background: {
      default: {
        background: '#FFFFFF',
      },
      purpleGradient: {
        background: 'linear-gradient(to right, #BB8AF4, #7749AE)',
      },
      gray: {
        background: '#F0F0F0',
      },
      lightGray: {
        background: '#F3F4F6',
      }
    },
  },

  titleColor: {
    default: {
      color: '#514263',
    },
    white: {
      color: '#FFF',
    },
  },

  imgBackColor : {
    default: {
      color: '#514263',
    },
    white: {
      color: '#FFF',
    },
  },

  defaultVariants: {
    background: 'default',
  },
})
