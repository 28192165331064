import { styled } from '@stitches/react';


export const Container = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  bottom: 20,
  backgroundColor: '#1A1A1A80',
  height: 28,
  width: 60,
  left: '50%',
  transform: 'translateX(-50%)',
  borderRadius: 14
});
