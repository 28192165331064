import { styled } from '@stitches/react';
import {colors} from "styles/colors";

export const TicketContainer = styled('div', {
    backgroundColor: colors.primaryBackground,
    padding: '10px 16px',
    borderRadius: 8,
    marginTop: 10
})

export const ViewDivide = styled('div', {
    borderBottom: '1px solid #E6E6E6',
    marginTop: '20px'
})