import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Container } from './styles';
import { Caption1, Caption4 } from 'styles/typography';
import Row from 'components/atoms/Row';
import { colors } from 'styles/colors';
import Divider from 'components/atoms/Divider';

const GeojuPaymentSuccessInfo = () => {
  const navigate = useNavigate();

  const contents = [
    {
      title: '이용 시간',
      desc: '59분',
    },
    {
      title: '주차 요금',
      desc: '1,200원',
    },
    {
      title: '장애인 감면(50%)',
      desc: '-600원',
    },
    {
      title: '결제 금액',
      desc: '600원',
    },
    {
      title: '결제 수단',
      desc: '하나카드 OK: 47220714',
    },
    {
      title: '결제 일시',
      desc: '2023-09-15 12:31',
    },
  ];

  return (
    <Container>
      <Caption1 weight={'bold'} color={'gray07'} style={{ marginBottom: 10 }}>
        이용 정보
      </Caption1>

      {contents.map((el, index) => {
        return (
          <Row key={index} justifyContent={'space-between'} style={{ marginTop: 10 }}>
            <Caption4 weight={'regular'} color={'gray06'}>
              {el.title}
            </Caption4>

            <Row>
              <Caption4 weight={'regular'} color={'black1'}>
                {el.desc}
              </Caption4>
            </Row>
          </Row>
        );
      })}
      <Divider style={{ backgroundColor: colors.gray03, marginTop: 20 }} />
    </Container>
  );
};

export default GeojuPaymentSuccessInfo;
