import { styled } from '@stitches/react';

export const Container = styled('div', {
  background: 'linear-gradient(171deg, #F5F8FF 5.02%, #EAD9FE 63.08%, #FCFAFF 109.3%)',
  position: 'relative',
  height: '100vh',
  width: '100%',
  overflow: 'hidden',
});

export const WrapTitle = styled('div', {
  marginTop: 35,
  paddingLeft: 20,
});

export const WrapDesc = styled('div', {
  marginTop: 28,
  paddingLeft: 20,
});

export const WrapBottom = styled('div', {
  position: 'absolute',
  bottom: 40,
  width: '100%',
});
