// src/components/styles.ts
import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const TabMenu = styled('div', {
  display: 'flex',
  justifyContent: 'center',
});

export const Submenu = styled('button', {
  padding: '15px 0px',
  width: '100%',
  backgroundColor: 'transparent',
  border: 'none',
  borderBottom: '2px solid transparent',
  transition: 'border-color 0.3s, color 0.3s',
  position: 'sticky',
  top: 0,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  // '&:after': {
  //   content: '""',
  //   position: 'absolute',
  //   left: 0,
  //   right: 0,
  //   marginTop: '29px',
  //   height: '1px',
  //   backgroundColor: '#E4E4E4',
  //   borderBottom: '1px solid transparent',
  //   zIndex: -1,
  // },
  variants: {
    focused: {
      true: {
        borderBottom: `2px solid ${colors.primaryDark}`,
        fontFamily: 'Pretendard',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
      },
      false: {
        borderBottom: `1px solid ${colors.gray04}`,
        fontFamily: 'Pretendard',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
      },
    },
  },
  defaultVariants: {
    focused: 'false',
  },
});

export const Desc = styled('div', {
  border: '1px solid $border',
  borderRadius: '$medium',
  paddingTop: '20px',
});
