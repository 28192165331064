import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MainContainer, MainContainerNoMargin } from 'components/styles';
import Title from 'components/atoms/Title/PageTitle';

import CheckModal from 'components/atoms/Modal/CheckModal';
import { BottomButtonContainer, ButtonEnableDisable, WrapContent } from './styles';
import { Caption1, Caption2, Headline, LargeTitle3, SubHeadline } from 'styles/typography';
import Dropdown from 'components/atoms/Dropdown';
import { observer } from 'mobx-react';
import CancelResaonStore from 'stores/store/CancelReason/CancelResaonStore';
import { showWebBrowser, webViewClose } from 'utils/deviceUtils';
import { handleChatClick } from 'utils/common';
import {
  StyledTextArea,
  TextAreaContainer,
} from 'components/atoms/Modal/MinganParkDisagreeModal/styles';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import Row from 'components/atoms/Row';
import {
  BottomContainer,
  CheckButton,
  PaymentCheckContainer,
  PaymentTopContainer,
  TicketButtonContainer,
} from 'pages/ExternalPayment/styles';
import icCheckGradient from 'assets/ic/ic_check_gradient.svg';
import { JSP_LOGIN_URL, JSP_URL } from 'constants/baseConstants';

const DeleteAccountReason: React.FC = observer(() => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const [value, setValue] = useState('');
  const [isAccountDelete, setIsAccountDelete] = useState(false);

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  // 탈퇴 사유 항목 목록을 정의합니다.
  const reasons = {
    reason: [
      '원하는 주차장이 없어요',
      '사용 빈도가 낮아요',
      '개인정보 유출이 걱정돼요',
      '다른 서비스를 이용해요',
      '앱 사용이 불편해요',
      '기타',
    ],
    helpText: [
      '희망하는 주차장이나 지역을 알려주시면 새로운 주차장이 생겼을 때 알려드리고 있어요. 아래 링크를 통해 주차장 요청이 가능해요.',
      '우리나라 공유 주차장 정보를 모두 담기 위해 열심히 노력하고 있어요. 성장해 나가는 주만사를 지켜봐 주세요.',
      '주만사는 개인정보를 최우선으로 생각하며 안전하게 지키고 있어요. 유출 걱정마세요.',
      '더 나은 경험을 제공하는 주만사가 되도록 노력하고 있으니 응원 부탁드려요.',
      '어떤 부분이 불편했는지 알려주시면 개선하는데 큰 도움이 되어요.',
    ],
    button: ['주차장 요청하기', null, '개인정보 처리방침 보기', null, '의견 남기기'],
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value;

    // 조건 1: 아무것도 입력 안 된 상태에서 띄어쓰기 입력 방지
    if (inputValue.trimStart() === '' && inputValue.startsWith(' ')) {
      return;
    }

    // 조건 2: 띄어쓰기 연속 2번 이상 입력 방지
    if (/\s{2}/.test(inputValue)) {
      return;
    }

    // 조건 3: 최대 50자 입력 가능
    if (inputValue.length > 50) {
      // 토스트 메세지 호출 (예: alert 사용)
      showToast('최대 50자까지 입력 가능합니다.');
      return;
    }
    setValue(inputValue);
  };

  const isDisabled =
    CancelResaonStore.selectedReason === null || // selectedReason이 선택되지 않은 경우
    (CancelResaonStore.selectedReason === 6 && (value.length < 10 || value.length > 50));

  return (
    <MainContainerNoMargin>
      <Title
        title="회원 탈퇴"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      {!isAccountDelete ? (
        <>
          <WrapContent>
            <Headline weight={'semibold'} color={'black1'}>
              떠나시는 이유를 알려주세요.
            </Headline>
            <SubHeadline
              weight={'regular'}
              color={'gray09'}
              style={{
                marginTop: 20,
              }}
            >
              소중한 의견을 통해 다음에 볼 때는 더 발전한 모습으로 맞이할 수 있도록 할게요. 그동안
              감사했어요.
            </SubHeadline>
          </WrapContent>
          <WrapContent
            style={{
              paddingTop: 12,
            }}
          >
            <Dropdown reasons={reasons.reason} />
            {CancelResaonStore.selectedReason ? (
              <>
                <Caption2
                  weight={'regular'}
                  color={'primary'}
                  style={{ marginTop: 20, wordBreak: 'keep-all' }}
                >
                  {reasons.helpText[CancelResaonStore.selectedReason - 1]}
                </Caption2>
                {reasons.button[CancelResaonStore.selectedReason - 1] ? (
                  <div
                    onClick={() => {
                      if (CancelResaonStore.selectedReason === 1) {
                        showWebBrowser(
                          'http://www.zoomansa.com/GuestLanding?type=%EC%A3%BC%EB%A7%8C%EC%82%AC%20%EA%B3%B5%EC%8B%9D',
                        );
                      } else if (CancelResaonStore.selectedReason === 3) {
                        navigate('/policyPersonal');
                      } else if (CancelResaonStore.selectedReason === 5) {
                        handleChatClick();
                      }
                      CancelResaonStore.setReason(null);
                    }}
                  >
                    <Caption2
                      weight={'regular'}
                      color={'gray09'}
                      style={{ marginTop: 16, textDecoration: 'underline' }}
                    >
                      {reasons.button[CancelResaonStore.selectedReason - 1]}
                    </Caption2>
                  </div>
                ) : null}
              </>
            ) : null}
            {CancelResaonStore.selectedReason === 6 ? (
              <TextAreaContainer>
                <StyledTextArea
                  placeholder={'10자 이상 입력해 주세요.'}
                  value={value}
                  onChange={handleChange}
                  rows={3}
                />
              </TextAreaContainer>
            ) : null}
          </WrapContent>
          <BottomButtonContainer>
            <ButtonEnableDisable onClick={() => setIsAccountDelete(true)} disabled={isDisabled}>
              주만사 탈퇴하고 계정 삭제하기
            </ButtonEnableDisable>
            <Row style={{ whiteSpace: 'pre-wrap' }}>
              <Caption2 weight={'regular'} color={'gray09'}>
                탈퇴하면
              </Caption2>
              <Caption2 weight={'regular'} color={'red'}>
                {' '}
                3개월{' '}
              </Caption2>
              <Caption2 weight={'regular'} color={'gray09'}>
                뒤에 재가입 가능해요
              </Caption2>
            </Row>
          </BottomButtonContainer>
        </>
      ) : (
        <div>
          <PaymentTopContainer>
            <img src={icCheckGradient} alt={'체크 그라디언트 아이콘'} />
            <LargeTitle3 weight={'bold'} color="primary">
              다음에 또 만나요
            </LargeTitle3>
            <PaymentCheckContainer>
              <SubHeadline css={{ color: '#6B6B6B' }}>
                그동안 주만사를 이용해 주셔서 감사해요.
                <br />
                주만사는 3개월 이후에 가입 가능해요
              </SubHeadline>
            </PaymentCheckContainer>
          </PaymentTopContainer>
          <BottomContainer>
            <CheckButton
              onClick={() => {
                // webViewClose();
                window.location.href = JSP_LOGIN_URL;
              }}
            >
              <SubHeadline weight={'bold'} color={'white'}>
                종료하기
              </SubHeadline>
            </CheckButton>
          </BottomContainer>
        </div>
      )}
    </MainContainerNoMargin>
  );
});

export default DeleteAccountReason;
