import { styled } from '@stitches/react';
import { colors } from 'styles/colors';
import { pxToRem } from 'utils/common';

export const Container = styled('div', {
  padding: '16px',
  backgroundColor: colors.white,
  borderRadius: '10px',
  width: 'calc(100% - 40px)',
  marginBottom: '20px',
});

export const ExitContainer = styled('div', {
  padding: '10px 16px',
  borderRadius: '8px',
  backgroundColor: '#F8F8FA',
  margin: '12px 0px',
  position: 'relative',
});
