export const colors = {
  black3: '#333333',
  black1: '#1A1A1A',
  white: '#FFFFFF',
  red: '#ED1659',
  sky: '#20AEE5',
  primaryDark: '#514263',
  primarySub: '#B799DB',
  primaryBackground: '#F8F8FA',
  primarySubSub: '#EBEBFB',
  primary: '#8B63BB',
  gray01: '#F2F2F2',
  gray02: '#E4E4E4',
  gray03: '#E6E6E6',
  gray04: '#CCCCCC',
  gray05: '#B0B0B0',
  gray06: '#868686',
  gray07: '#666666',
  gray08: '#6B6B6B',
  gray09: '#555555',
  gray10: '#F3F4F6',
  blue: '#1D75CA',
  darkPurple: '#514263'
}