import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, WrapCarNumber, WrapDropdown } from './styles';
import { Caption1, Caption3 } from 'styles/typography';
import Row from 'components/atoms/Row';
import { IcArrowBottomBlackSVG } from 'assets/ic';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';

interface Props {
  hour: string;
  minute: string;
  setIsTimeStop: React.Dispatch<React.SetStateAction<boolean>>;
  carNumber: string;
  currentTime: string;
  onOpenCarModal?: () => void;
}

const ParkingUsingInfo: React.FC<Props> = ({
  hour,
  minute,
  setIsTimeStop,
  carNumber,
  currentTime,
  onOpenCarModal,
}) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Caption1 weight={'bold'} color={'gray07'}>
        주차장 이용
      </Caption1>

      <Row justifyContent={'space-between'} style={{ marginTop: 20 }}>
        <Caption1 weight={'bold'} color={'gray05'}>
          주차 시작 시간
        </Caption1>

        <Caption1 weight={'regular'} color={'black3'}>
          {currentTime}
        </Caption1>
      </Row>

      <Row justifyContent={'space-between'} style={{ marginTop: 10 }}>
        <Caption1 weight={'bold'} color={'gray05'}>
          예상 출차 시간
        </Caption1>

        <WrapDropdown
          onClick={() => {
            setIsTimeStop(true);
          }}
        >
          <Row justifyContent={'space-between'}>
            <Caption1 weight={'regular'} color={hour ? 'black3' : 'gray05'}>
              {hour === '' ? '00:00' : `${hour} : ${minute}`}
            </Caption1>
            <IcArrowBottomBlackSVG width={8} height={5} />
          </Row>
        </WrapDropdown>
      </Row>

      <Row justifyContent={'space-between'} style={{ marginTop: 10 }}>
        <Caption1 weight={'bold'} color={'gray05'}>
          차량번호
        </Caption1>

        <WrapCarNumber>
          <Row justifyContent={'space-between'}>
            <Caption1 weight={'regular'} color={carNumber ? 'black3' : 'gray05'}>
              {carNumber === '' ? '12가1234' : carNumber}
            </Caption1>
            {onOpenCarModal ? (
              <Button
                style={{
                  borderTopRightRadius: 5,
                  borderBottomRightRadius: 5,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  height: 28,
                  border: 'none',
                }}
                backgroundColor={colors.primary}
                onClick={onOpenCarModal}
              >
                <Caption3 weight={'regular'} color={'white'}>
                  {carNumber === '' ? '등록' : `변경`}
                </Caption3>
              </Button>
            ) : null}
          </Row>
        </WrapCarNumber>
      </Row>

      <Row justifyContent={'space-between'} style={{ marginTop: 10 }}>
        <Caption1 weight={'bold'} color={'gray05'}>
          요금 감면 적용
        </Caption1>

        <Caption1 weight={'regular'} color={'black3'}>
          장애인(80%)
        </Caption1>
      </Row>
    </Container>
  );
};

export default ParkingUsingInfo;
