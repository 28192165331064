import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  padding: '40px 20px',
  backgroundColor: colors.primaryBackground,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  // lineHeight: 12,
});

export const Text = styled('p', {
  color: colors.gray06,
  fontSize: 8,
  fontWeight: 400,
  whiteSpace: 'pre-wrap',
});
