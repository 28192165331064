import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, WrapInput } from './styles';
import { Caption1, Caption3 } from 'styles/typography';
import Row from 'components/atoms/Row';
import PopupModal from 'components/atoms/Modal/PopupModal';

interface IFormInputs {
  coupon: string;
}

const AutomaticInfo = () => {
  const navigate = useNavigate();

  const [isPopup, setIsPopup] = useState(false);
  const [popupText, setPopupText] = useState({
    title: '',
  });

  const closePopup = () => {
    setIsPopup(false);
  };

  return (
    <Container>
      <Caption1 weight={'bold'} color={'gray07'}>
        자동결제
      </Caption1>

      <Row justifyContent={'space-between'} style={{ marginTop: 10 }}>
        <Caption3 weight={'bold'} color={'gray06'}>
          국민카드로 자동결제를 이용 중입니다.
        </Caption3>

        <Row>
          <div
            onClick={() => {
              setIsPopup(true);
              setPopupText({
                title: '등록 된 카드를 삭제하시겠습니까?',
              });
            }}
          >
            <Caption3 weight={'regular'} color={'gray06'}>
              삭제
            </Caption3>
          </div>
          <Caption3
            weight={'regular'}
            color={'gray06'}
            style={{
              marginLeft: 6,
              marginRight: 6,
            }}
          >
            |
          </Caption3>
          <div>
            <Caption3 weight={'regular'} color={'gray06'}>
              수정
            </Caption3>
          </div>
        </Row>
      </Row>
      <PopupModal
        isVisible={isPopup}
        onClose={closePopup}
        title1={popupText.title}
        leftClick={closePopup}
        leftText={'취소'}
        rightText={'확인'}
      />
    </Container>
  );
};

export default AutomaticInfo;
