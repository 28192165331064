import React, { useEffect, useState } from 'react';
import Title from 'components/atoms/Title/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import { isIOS } from 'react-device-detect';

import icSearch from 'assets/ic/ic_setting.svg';
import {
  Container,
  SearchInput,
  WrapSearch,
  SearchIcon,
  WrapMap,
  PhoneNumberContainer,
} from './styles';
import { MainContainerNoMargin } from 'components/styles';
import { Headline, SubHeadline } from 'styles/typography';
import Map from 'components/atoms/Map';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import PopUpModal from 'components/atoms/Modal/PopupModal';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import Row from 'components/atoms/Row';
import AddressSearch from 'containers/monthlyParking/AddressSearch';
import { IcCloseBlackSVG, IcCloseCircleGraySVG, IcDeleteTextSVG, IcSearchSVG } from 'assets/ic';

const AreaSettingPage: React.FC = () => {
  const navigate = useNavigate();

  const [addressText, setAddressText] = useState<string>(''); // 추가된 상태
  const [isPopup, setIsPopup] = useState(false);
  const [value, setValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState(false);
  const [searchResult, setSearchResult] = useState<any>({});

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const openPopup = () => {
    setIsPopup(true);
  };

  const closePopup = () => {
    setIsPopup(false);
  };

  const onSelectAddress = (result: any) => {
    setSearchResult(result);
    setAddressText(result.place_name); // 주소 설정
    setValue(result.place_name);
    setIsFocused(false);
  };

  const init = () => {
    setValue('');
    setIsFocused(false);
    setAddressText('');
    setSearchResult([]);
  };

  const popupText = {
    title: '지역 설정 안내',
    description: '· 월 주차 희망지역이 설정되었습니다.',
  };

  return (
    <MainContainerNoMargin style={{ height: isIOS ? 'calc(100vh - 30px)' : 'calc(100vh)' }}>
      <Title
        title="지역 설정"
        leftArrowButtonVisible
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      <Container>
        <PhoneNumberContainer>
          <Row>
            <Headline weight={'semibold'} color={'primary'}>
              월 주차 희망 지역
            </Headline>
            <Headline weight={'semibold'} color={'black1'}>
              을 알려주세요
            </Headline>
          </Row>

          <WrapSearch>
            {/* 주소 입력창 */}
            <SearchInput
              value={value}
              onChange={e => setValue(e.target.value)}
              placeholder={'주소, 장소 검색'}
              onFocus={() => setIsFocused(true)}
            />
            {value ? (
              <IcDeleteTextSVG
                onClick={() => {
                  init();
                }}
              />
            ) : (
              <IcSearchSVG />
            )}
          </WrapSearch>
        </PhoneNumberContainer>
        {isFocused ? <AddressSearch onSelectAddress={onSelectAddress} value={value} /> : null}

        <WrapMap
          style={{
            opacity: addressText && !isFocused ? 1 : 0,
          }}
        >
          <Map height={'100%'} address={addressText} draggable={false} zoomControl={false} />
          <Button
            style={{
              position: 'fixed',
              bottom: '30px',
              left: 20,
              height: 46,
              width: 'calc(100% - 40px)',
              zIndex: 1000,
            }}
            backgroundColor={colors.primary}
            onClick={openPopup}
          >
            <SubHeadline weight={'regular'} color={'white'}>
              이 위치로 설정
            </SubHeadline>
          </Button>
        </WrapMap>
      </Container>

      <PopUpModal
        isVisible={isPopup}
        onClose={closePopup}
        title1={'월 주차 희망 지역 등록 완료했어요.'}
        leftClick={async () => {
          closePopup();
          if (addressText) {
            if (userInfoStore.monthlyParkingDesiredArea) {
              userInfoStore.setMonthlyParkingDesiredArea([
                ...userInfoStore.monthlyParkingDesiredArea,
                searchResult,
              ]);
            } else {
              userInfoStore.setMonthlyParkingDesiredArea([searchResult]);
            }

            // await shareStore.fetchShareSlot('01012341234', addressText)
            navigate(-1);
          }
        }}
        leftText={'확인'}
      />
    </MainContainerNoMargin>
  );
};

export default AreaSettingPage;
