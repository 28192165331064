import React, { forwardRef, useEffect, useRef, useState } from 'react';

import { TimePickerWrapper, TimeColumn, TimeItem } from './styles';
import {
  Body,
  Caption1,
  Caption2,
  Caption3,
  Caption4,
  Headline,
  SubHeadline,
} from 'styles/typography';

interface TimePickerProps {
  onChange: (hour: string, minute: string) => void;
  minutes: string[];
  hours: string[];
}

const TimePicker = forwardRef<HTMLDivElement, TimePickerProps>(
  ({ onChange, hours, minutes }, ref) => {
    const [selectedHour, setSelectedHour] = useState<string>('99');
    const [selectedMinute, setSelectedMinute] = useState<string>('00');

    const hourRef = useRef<HTMLDivElement>(null);
    const minuteRef = useRef<HTMLDivElement>(null);

    let scrollTimeout: NodeJS.Timeout;

    useEffect(() => {
      if (ref && hourRef.current) {
        if (typeof ref === 'function') {
          ref(hourRef.current);
        } else {
          (ref as React.MutableRefObject<HTMLDivElement | null>).current = hourRef.current;
        }
      }
    }, [ref]);

    useEffect(() => {
      if (selectedHour === '99') {
        setSelectedHour(hours[0]); // 첫 번째 시간값을 선택
      }
    }, [hours]);

    // 스크롤 위치에 따라 선택된 값을 업데이트
    const handleScroll = (
      ref: React.RefObject<HTMLDivElement>,
      items: string[],
      setSelected: (value: string) => void,
    ) => {
      if (ref.current) {
        const scrollPosition = ref.current.scrollTop;
        const itemHeight = 48; // TimeItem의 높이와 일치
        const index = Math.round(scrollPosition / itemHeight); // 현재 스크롤 위치로 index 계산
        setSelected(items[index]);
      }
    };

    const handleScrollEnd = (
      ref: React.RefObject<HTMLDivElement>,
      items: string[],
      setSelected: (value: string) => void,
    ) => {
      if (ref.current) {
        const scrollPosition = ref.current.scrollTop;
        const itemHeight = 48; // TimeItem의 높이와 일치
        const index = Math.round(scrollPosition / itemHeight); // 가장 가까운 index 계산
        const targetPosition = index * itemHeight; // 정렬될 위치 계산

        // 부드럽게 스크롤 이동
        ref.current.scrollTo({
          top: targetPosition,
          behavior: 'smooth',
        });

        // 선택된 아이템 업데이트
        setSelected(items[index]);
      }
    };

    useEffect(() => {
      onChange(selectedHour, selectedMinute);
    }, [selectedHour, selectedMinute]);

    return (
      <TimePickerWrapper>
        {/* 시간 스크롤 */}
        <TimeColumn
          ref={hourRef}
          onScroll={() => {
            clearTimeout(scrollTimeout);
            handleScroll(hourRef, hours, setSelectedHour);
            scrollTimeout = setTimeout(() => {
              handleScrollEnd(hourRef, hours, setSelectedHour);
            }, 200); // 스크롤 종료 후 동작
          }}
          className="hourColumn"
        >
          {hours.map((hour, index) => (
            <TimeItem key={index}>
              <Body weight={'semibold'} color={hour === selectedHour ? 'black3' : 'gray05'}>
                {hour}
              </Body>
            </TimeItem>
          ))}
        </TimeColumn>

        <Body weight={'semibold'} color={'black3'} style={{ marginTop: 12 }}>
          :
        </Body>

        <TimeColumn
          ref={minuteRef}
          onScroll={() => {
            clearTimeout(scrollTimeout);
            handleScroll(minuteRef, minutes, setSelectedMinute);
            scrollTimeout = setTimeout(() => {
              handleScrollEnd(minuteRef, minutes, setSelectedMinute);
            }, 200); // 스크롤 종료 후 동작
          }}
          className="minuteColumn"
        >
          {minutes.map((minute, index) => (
            <TimeItem key={index}>
              <Body weight={'semibold'} color={minute === selectedMinute ? 'black3' : 'gray05'}>
                {minute}
              </Body>
            </TimeItem>
          ))}
        </TimeColumn>
      </TimePickerWrapper>
    );
  },
);

export default TimePicker;
