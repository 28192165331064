import {styled} from '@stitches/react';
import { pxToRem } from 'utils/common';

export const LogoContainer = styled('div', {
  height: 'auto',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center', // 가로 가운데 정렬
  alignItems: 'center', // 세로 가운데 정렬
});

export const ImgHalf = styled('img', {
  width: '140px',
  height: 'auto',
});

export const ImgHalf2 = styled('img', {
  maxWidth: '50%',
  height: 'auto',
  marginTop: 30
});

export const ImgSnsLogin = styled('img', {
  width: 'calc(100% - 40px)',
  marginTop: '8px'
});

export const MemberLoginContainer = styled('div', {
  whiteSpace: 'pre-wrap',
  marginTop: '15px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

// 스타일 컴포넌트 정의
export const SpanLink = styled('span', {
  cursor: 'pointer',
})

export const Separator = styled('span', {
  margin: '0 8px',
  color: '#666',
  fontSize: '14px',
})

export const ContentsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
});
