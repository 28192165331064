import { makeAutoObservable } from 'mobx';
import BaseError from 'models/baseError';
import authService from 'service/UserInfo/authService';

class IdentityVerificationStore {
  memberUID: number = 0;
  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchGetAuthCodeByTell(memberTell: string, iv: string, salt: string) {
    this.isLoading = true;

    const requestBody = {
      memberTell,
    };

    try {
      const response = await authService.getAuthCodeByTell(requestBody, iv, salt);

      if (response instanceof BaseError) {
        console.log(response.errorMessage);
      } else {
        if (response.data) {
          console.log(response.data, 1234);
          return true;
        } else {
          console.error('No account data found');
        }
      }
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
    } finally {
      this.isLoading = false;
    }
  }

  async fetchValidateAuthAndModifyMemberTell(
    memberTell: string,
    mLinkUID: number,
    authCode: string,
    iv: string,
    salt: string,
  ) {
    this.isLoading = true;

    const requestBody = {
      memberTell,
      mLinkUID,
      authCode,
    };

    try {
      const response = await authService.validateAuthAndModifyMemberTell(requestBody, iv, salt);

      if (response instanceof BaseError) {
        console.log(response.errorMessage);
      } else {
        if (response.data) {
          return response;
        } else {
          console.error('No account data found');
        }
      }
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
    } finally {
      this.isLoading = false;
    }
  }
}

export const identityVerificationStore = new IdentityVerificationStore();
