import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';

import { BottomContainer, Container, LottieWrap, TipWrap } from './styles';
import { Body, Caption1, Caption2, Caption3, Headline, SubHeadline } from 'styles/typography';
import Button from 'components/atoms/Button/Button';
import Row from 'components/atoms/Row';
import { IcPlusSVG } from 'assets/ic';
import { colors } from 'styles/colors';
import ParkingShare from 'assets/lottie/parking_share.json';
import { handleChatClick } from 'utils/common';
import { setDial } from 'utils/deviceUtils';

interface Props {
  setIsCarReductionBottomModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const AlreadyShared = ({ setIsCarReductionBottomModalVisible }: Props) => {
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ParkingShare,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Container>
      <LottieWrap>
        <Lottie options={defaultOptions} height={128} width={128} />
      </LottieWrap>

      <Body weight={'semibold'} color={'gray09'} style={{ marginBottom: 20 }}>
        이미 공유 신청한 주차장이 있어요.
      </Body>
      <Caption2 weight={'regular'} color={'gray06'}>
        담당 매니저가 신청 내역을 확인 후
      </Caption2>
      <Caption2 weight={'regular'} color={'gray06'}>
        평일 09시 ~ 18시에 순차적으로 연락드려요.
      </Caption2>
      <Button
        style={{
          height: '2.5rem',
          marginTop: 28,
          marginBottom: 40,
          paddingLeft: 12,
          paddingRight: 16,
        }}
        radius={20}
        borderColor={colors.gray03}
        onClick={() => {
          setIsCarReductionBottomModalVisible(true);
        }}
      >
        <Row>
          <IcPlusSVG fill={colors.primary} />
          <Caption1 weight={'medium'} color={'primary'} style={{ marginLeft: 4 }}>
            다른 주차장 공유하기
          </Caption1>
        </Row>
      </Button>

      <BottomContainer>
        <TipWrap>
          <Caption3 weight={'semibold'} color={'primary'} style={{ marginBottom: 8 }}>
            Tip.
          </Caption3>
          <Caption2 weight={'regular'} color={'gray07'}>
            고객센터 문의 시 빠른 상담이 가능해요.
          </Caption2>
          <Caption2 weight={'regular'} color={'gray07'}>
            평일 09시~18시까지 운영합니다.
          </Caption2>
          <Caption2 weight={'regular'} color={'gray07'}>
            (주만사 고객센터 : 1666-4369)
          </Caption2>
        </TipWrap>
        <Button
          radius={4}
          style={{
            width: '100%',
            height: '46px',
            marginBottom: 40,
            marginTop: 16,
            border: `1px solid ${colors.gray03}`,
          }}
          onClick={() => {
            setDial('1666-4369');
          }}
        >
          <SubHeadline weight={'semibold'} color={'gray07'}>
            고객센터 전화 연결
          </SubHeadline>
        </Button>
      </BottomContainer>
    </Container>
  );
};

export default AlreadyShared;
