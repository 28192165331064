import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, WrapMap } from './styles';
import { Caption1, Caption2 } from 'styles/typography';
import Row from 'components/atoms/Row';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';

const ParkingInfo = ({ type = '' }: { type?: string }) => {
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [map, setMap] = useState<any>(null);
  const [marker, setMarker] = useState<any>(null);

  useEffect(() => {
    // 카카오맵이 로드되었을 때만 실행하도록 확인
    if (window.kakao && containerRef.current) {
      // 맵을 생성합니다.
      const mapOptions = {
        center: new window.kakao.maps.LatLng(37.5665, 126.978), // 서울 시청 위치
        level: 3,
      };

      const kakaoMap = new window.kakao.maps.Map(containerRef.current, mapOptions);
      setMap(kakaoMap);

      window.kakao.maps.event.addListener(kakaoMap, 'zoom_changed', function () {
        if (marker) {
          const currentPosition = marker.getPosition();
          marker.setPosition(currentPosition); // 줌 변경 시 마커 위치 재설정
        }
      });
    }

    // 컴포넌트가 언마운트될 때 지도 및 리스너 정리
    return () => {
      if (map) {
        window.kakao.maps.event.removeListener(map, 'zoom_changed', () => {}); // 리스너 제거
      }
    };
  }, []);

  return (
    <Container>
      <Caption1 weight={'bold'} color={'gray07'}>
        주차장 정보
      </Caption1>

      <Row justifyContent={'space-between'} style={{ marginTop: 20 }}>
        <Caption1 weight={'bold'} color={'gray05'}>
          자리번호
        </Caption1>

        <Row>
          <Caption1 weight={'regular'} color={'black3'} style={{ marginRight: 6 }}>
            1-1
          </Caption1>
          {type === 'payment' ? null : (
            <Button radius={20} borderColor={colors.primary} vertical={0} horizontal={10}>
              <Caption2 weight={'regular'} color={'primary'}>
                변경
              </Caption2>
            </Button>
          )}
        </Row>
      </Row>

      <Row justifyContent={'space-between'} style={{ marginTop: 10 }}>
        <Caption1 weight={'bold'} color={'gray05'}>
          요금
        </Caption1>

        <Caption1 weight={'regular'} color={'black3'} style={{ marginRight: 6 }}>
          5분 당 100원
        </Caption1>
      </Row>

      <Row justifyContent={'space-between'} style={{ marginTop: 10 }}>
        <Caption1 weight={'bold'} color={'gray05'}>
          운영 마감 시간
        </Caption1>

        <Caption1 weight={'regular'} color={'black3'} style={{ marginRight: 6 }}>
          23:59:00 까지
        </Caption1>
      </Row>

      {type === 'payment' ? null : (
        <WrapMap>
          <div
            id={'map'}
            style={{
              width: '100%',
              height: '100%',
            }}
            ref={containerRef}
          />
        </WrapMap>
      )}
    </Container>
  );
};

export default ParkingInfo;
