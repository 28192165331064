import { styled } from '@stitches/react';

export const WrapContent = styled('div', {
  padding: '28px 20px',
});

export const DeleteAccountReasonContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  color: '#868686',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  textAlign: 'left',
  marginTop: '10px',
  lineHeight: 'normal',
});

export const BottomButtonContainer = styled('div', {
  margin: '0 20px 40px',
  width: 'calc(100% - 40px)',
  bottom: '0',
  left: '0',
  position: 'absolute',
  backgroundColor: '#fff',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: 10,
});

export const ButtonEnableDisable = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  width: '100%',
  height: '46px',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: 'normal',
  borderRadius: '5px',
  backgroundColor: '#8B63BB',
  fontFamily: 'Pretendard',
  color: '#fff',
  border: 'none',
  userSelect: 'none',
  marginBottom: 20,
  '&:disabled': {
    backgroundColor: '#d3d3d3',
    color: '#fff',
    cursor: 'not-allowed',
  },
});
