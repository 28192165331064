import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const DropdownContainer = styled('div', {
  position: 'relative',
  width: '100%',
});

export const DropdownHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px',
  border: `1px solid ${colors.gray02}`,
  borderRadius: '8px',
  backgroundColor: '#fff',
  cursor: 'pointer',
});


export const DropdownList = styled('div', {
  position: 'absolute',
  top: '100%',
  left: 0,
  right: 0,
  border: `1px solid ${colors.gray02}`,
  borderRadius: '8px',
  backgroundColor: '#fff',
  zIndex: 100,
   overflow: 'hidden',
});

export const DropdownItem = styled('div', {
  padding: '10px 16px',
});
