import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const CarNumberInputContainer = styled('div', {
  width: 'calc(100% - 40px)',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  color: '#6B6B6B',
  border: '1px solid #d3d3d3',
  marginTop: '20px',
  padding: '20px',

  variants: {
    isFocus: {
      true: {
        borderColor: '#8B63BB',
        backgroundColor: '#ffffff',
      },
      false: {
        border: 'none',
        backgroundColor: '#F8F8FA',
      },
    },
  },
});

export const CarNumberInput = styled('input', {
  border: '0px',
  outline: 'none',
  fontWeight: 500,
  fontSize: '18px',
  backgroundColor: '#F8F8FA',
  marginTop: 5,
  color: '#333333',

  '&::placeholder': {
    color: '#CCCCCC',
    fontSize: '17px',
    fontWeight: 400,
  },

  variants: {
    isFocus: {
      true: {
        borderColor: '#8B63BB',
        backgroundColor: '#ffffff',
      },
      false: {
        border: 'none',
        backgroundColor: '#F8F8FA',
      },
    },
  },
});

export const CarNumberErrorContainer = styled('div', {
  width: '100%',
  marginTop: '8px',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
});
