import React, { useEffect, useState } from 'react';

import { Text, WrapItem, WrapResult, WrapSearch, WrapSeeMore } from './styles';
import icArrowRightGrayMedium from 'assets/ic/ic_arrow_right_gray_medium.svg';
import Row from 'components/atoms/Row';
import { Caption1, Caption3, Caption4, SubHeadline } from 'styles/typography';
import Divider from 'components/atoms/Divider';

interface Props {
  onSelectAddress: (address: string) => void; // 주소를 선택했을 때 호출되는 함수
  value: string;
}

const AddressSearch: React.FC<Props> = ({ onSelectAddress, value }) => {
  const [places, setPlaces] = useState<any[]>([]);

  useEffect(() => {
    if (value !== '') {
      let ps = new window.kakao.maps.services.Places();

      ps.keywordSearch(value, placesSearchCB);

      // @ts-ignore
      function placesSearchCB(data: any, status: any) {
        if (status === window.kakao.maps.services.Status.OK) {
          setPlaces(data);
        }
      }
    }
  }, [value]);

  return (
    <>
      {places.length ? (
        <WrapResult>
          {places.slice(0, 15).map((el, index) => {
            return (
              <WrapItem
                key={index}
                onClick={() => {
                  onSelectAddress(el);
                }}
              >
                <Caption1 weight={'medium'} color={'black1'}>
                  {el.place_name}
                </Caption1>
                <Row style={{ marginTop: 4 }}>
                  <Caption3 weight={'regular'} color={'gray06'}>
                    {el.address_name}
                  </Caption3>
                </Row>
              </WrapItem>
            );
          })}
        </WrapResult>
      ) : null}
    </>
  );
};

export default AddressSearch;
