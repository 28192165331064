import React from 'react';
import { MainContainer } from 'components/styles';
import Title from '../../../components/atoms/Title/PageTitle';
import { useNavigate } from 'react-router-dom';
import { Caption2, Caption3, SubHeadline, TermsDetail } from 'styles/typography';
import { DetailContainer, MiddleContainer, MiddleContainer2, TopContainer } from 'pages/Policy/styles';
import { showWebBrowser } from 'utils/deviceUtils';
import { PAST_POLICY_SERVICE } from 'constants/baseConstants';
import {
  COUPON, EFFECTIVE_DATE,
  POINT_SAVED_MONEY,
  SEASON_TICKET,
  SERVICE_CHAPTER1,
  SERVICE_CHAPTER10,
  SERVICE_CHAPTER10_THIRD_DETAIL1,
  SERVICE_CHAPTER10_THIRD_DETAIL2,
  SERVICE_CHAPTER10_THIRD_DETAIL3, SERVICE_CHAPTER10_TITLE,
  SERVICE_CHAPTER11,
  SERVICE_CHAPTER11_EIGHTH_DETAIL,
  SERVICE_CHAPTER11_FOURTEENTH_DETAIL,
  SERVICE_CHAPTER11_REMAIN1,
  SERVICE_CHAPTER11_REMAIN2,
  SERVICE_CHAPTER11_REMAIN3,
  SERVICE_CHAPTER11_REMAIN4,
  SERVICE_CHAPTER11_SIXTEENTH_DETAIL,
  SERVICE_CHAPTER11_SIXTH_DETAIL,
  SERVICE_CHAPTER11_THIRTEENTH_DETAIL, SERVICE_CHAPTER11_TITLE,
  SERVICE_CHAPTER12,
  SERVICE_CHAPTER12_REMAIN,
  SERVICE_CHAPTER12_THIRD_DETAIL, SERVICE_CHAPTER12_TITLE,
  SERVICE_CHAPTER13,
  SERVICE_CHAPTER13_REMAIN1,
  SERVICE_CHAPTER13_REMAIN2,
  SERVICE_CHAPTER13_SECOND_DETAIL,
  SERVICE_CHAPTER13_THIRD_DETAIL, SERVICE_CHAPTER13_TITLE,
  SERVICE_CHAPTER14, SERVICE_CHAPTER14_TITLE,
  SERVICE_CHAPTER15, SERVICE_CHAPTER15_TITLE,
  SERVICE_CHAPTER16,
  SERVICE_CHAPTER16_REMAIN,
  SERVICE_CHAPTER16_THIRD_DETAIL, SERVICE_CHAPTER16_TITLE,
  SERVICE_CHAPTER17,
  SERVICE_CHAPTER17_FOURTH_DETAIL1,
  SERVICE_CHAPTER17_FOURTH_DETAIL2,
  SERVICE_CHAPTER17_FOURTH_DETAIL3,
  SERVICE_CHAPTER17_FOURTH_DETAIL4,
  SERVICE_CHAPTER17_FOURTH_DETAIL5,
  SERVICE_CHAPTER17_REMAIN,
  SERVICE_CHAPTER17_SECOND_DETAIL, SERVICE_CHAPTER17_TITLE,
  SERVICE_CHAPTER18,
  SERVICE_CHAPTER18_REMAIN1,
  SERVICE_CHAPTER18_REMAIN2,
  SERVICE_CHAPTER18_REMAIN3,
  SERVICE_CHAPTER18_SECOND_DETAIL,
  SERVICE_CHAPTER18_SEVENTH_DETAIL,
  SERVICE_CHAPTER18_SIXTH_DETAIL, SERVICE_CHAPTER18_TITLE,
  SERVICE_CHAPTER19, SERVICE_CHAPTER19_TITLE, SERVICE_CHAPTER1_TITLE,
  SERVICE_CHAPTER2,
  SERVICE_CHAPTER20, SERVICE_CHAPTER20_TITLE,
  SERVICE_CHAPTER21,
  SERVICE_CHAPTER21_ELEVENTH_DETAIL, SERVICE_CHAPTER21_TITLE,
  SERVICE_CHAPTER22, SERVICE_CHAPTER22_TITLE,
  SERVICE_CHAPTER23,
  SERVICE_CHAPTER23_FOURTH_DETAIL,
  SERVICE_CHAPTER23_REMAIN, SERVICE_CHAPTER23_TITLE,
  SERVICE_CHAPTER24, SERVICE_CHAPTER24_TITLE,
  SERVICE_CHAPTER25, SERVICE_CHAPTER25_TITLE,
  SERVICE_CHAPTER26, SERVICE_CHAPTER26_TITLE,
  SERVICE_CHAPTER2_DEFINE, SERVICE_CHAPTER2_TITLE,
  SERVICE_CHAPTER3, SERVICE_CHAPTER3_TITLE,
  SERVICE_CHAPTER4, SERVICE_CHAPTER4_TITLE,
  SERVICE_CHAPTER5, SERVICE_CHAPTER5_TITLE,
  SERVICE_CHAPTER6,
  SERVICE_CHAPTER6_REMAIN,
  SERVICE_CHAPTER6_THIRD_DETAIL, SERVICE_CHAPTER6_TITLE,
  SERVICE_CHAPTER7,
  SERVICE_CHAPTER7_SECOND_DETAIL, SERVICE_CHAPTER7_TITLE,
  SERVICE_CHAPTER8,
  SERVICE_CHAPTER8_REMAIN,
  SERVICE_CHAPTER8_SECOND_DETAIL, SERVICE_CHAPTER8_TITLE,
  SERVICE_CHAPTER9,
  SERVICE_CHAPTER9_FIFTH,
  SERVICE_CHAPTER9_FIFTH_DETAIL,
  SERVICE_CHAPTER9_FOURTH,
  SERVICE_CHAPTER9_FOURTH_DETAIL,
  SERVICE_CHAPTER9_REMAIN,
  SERVICE_CHAPTER9_THIRD_DETAIL, SERVICE_CHAPTER9_TITLE, SHARED_PARKING_TICKET, SUPPLEMENTARY, TIME_TICKET,
} from 'constants/policyService';

const PolicyServicePage = () => {
  const navigate = useNavigate();

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  return (
    <MainContainer>
      <Title
        title="주만사 서비스 이용약관"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
        titleColor={'darkPurple'}
        showSpacer={true}
      />
      <TopContainer>
        <SubHeadline weight={'semibold'}>{SERVICE_CHAPTER1_TITLE}</SubHeadline>
        <TermsDetail color={'gray09'} weight={'regular'} style={{ marginTop: '10px' }}>
          {SERVICE_CHAPTER1}
        </TermsDetail>

        <SubHeadline weight={'semibold'} style={{ marginTop: '40px' }}>
          {SERVICE_CHAPTER2_TITLE}
        </SubHeadline>

        <TermsDetail
          style={{
            marginTop: '10px',
          }}
          color={'gray09'}
          weight={'regular'}
        >
          {SERVICE_CHAPTER2_DEFINE}
        </TermsDetail>

        <MiddleContainer2>
          {SERVICE_CHAPTER2.map((chapter2, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter2}
            </TermsDetail>
          ))}
        </MiddleContainer2>

        <SubHeadline weight={'semibold'} style={{ marginTop: '40px' }}>
          {SERVICE_CHAPTER3_TITLE}
        </SubHeadline>
        <div style={{ marginTop: '10px' }}>
          {SERVICE_CHAPTER3.map((chapter3, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter3}
            </TermsDetail>
          ))}
        </div>

        <SubHeadline weight={'semibold'} style={{ marginTop: '40px' }}>
          {SERVICE_CHAPTER4_TITLE}
        </SubHeadline>
        <div style={{ marginTop: '10px' }}>
          {SERVICE_CHAPTER4.map((chapter4, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter4}
            </TermsDetail>
          ))}
        </div>

        <SubHeadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          {SERVICE_CHAPTER5_TITLE}
        </SubHeadline>

        <MiddleContainer>
          {SERVICE_CHAPTER5.map((chapter5, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter5}
            </TermsDetail>
          ))}
        </MiddleContainer>

        <SubHeadline weight={'semibold'} style={{ marginTop: '40px' }}>
          {SERVICE_CHAPTER6_TITLE}
        </SubHeadline>
        <div style={{ marginTop: '10px' }}>
          {SERVICE_CHAPTER6.map((chapter6, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter6}
            </TermsDetail>
          ))}

          <DetailContainer>
            {SERVICE_CHAPTER6_THIRD_DETAIL.map((thirdDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {thirdDetail}
              </TermsDetail>
            ))}
          </DetailContainer>

          <TermsDetail style={{ marginTop: '10px' }} color={'gray09'} weight={'regular'}>
            {SERVICE_CHAPTER6_REMAIN}
          </TermsDetail>
        </div>

        <SubHeadline weight={'semibold'} style={{ marginTop: '40px' }}>
          {SERVICE_CHAPTER7_TITLE}
        </SubHeadline>
        <MiddleContainer>
          {SERVICE_CHAPTER7.map((chapter7, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter7}
            </TermsDetail>
          ))}

          <DetailContainer>
            {SERVICE_CHAPTER7_SECOND_DETAIL.map((secondDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {secondDetail}
              </TermsDetail>
            ))}
          </DetailContainer>
        </MiddleContainer>

        <SubHeadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          {SERVICE_CHAPTER8_TITLE}
        </SubHeadline>
        <MiddleContainer>
          {SERVICE_CHAPTER8.map((chapter8, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter8}
            </TermsDetail>
          ))}

          <DetailContainer>
            {SERVICE_CHAPTER8_SECOND_DETAIL.map((secondDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {secondDetail}
              </TermsDetail>
            ))}
          </DetailContainer>

          {SERVICE_CHAPTER8_REMAIN.map((chapter8ReMain, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter8ReMain}
            </TermsDetail>
          ))}
        </MiddleContainer>

        <SubHeadline weight={'semibold'} style={{ marginTop: '40px' }}>
          {SERVICE_CHAPTER9_TITLE}
        </SubHeadline>
        <MiddleContainer>
          {SERVICE_CHAPTER9.map((chapter9, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter9}
            </TermsDetail>
          ))}

          <DetailContainer>
            {SERVICE_CHAPTER9_THIRD_DETAIL.map((thirdDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {thirdDetail}
              </TermsDetail>
            ))}
          </DetailContainer>

          <TermsDetail color={'gray09'} weight={'regular'} style={{ marginTop: '10px' }}>
            {SERVICE_CHAPTER9_FOURTH}
          </TermsDetail>

          <DetailContainer>
            <TermsDetail color={'gray09'} weight={'regular'}>
              {SERVICE_CHAPTER9_FOURTH_DETAIL}
            </TermsDetail>
          </DetailContainer>

          <TermsDetail color={'gray09'} weight={'regular'} style={{ marginTop: '10px' }}>
            {SERVICE_CHAPTER9_FIFTH}
          </TermsDetail>

          <DetailContainer>
            {SERVICE_CHAPTER9_FIFTH_DETAIL.map((fifthDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {fifthDetail}
              </TermsDetail>
            ))}
          </DetailContainer>

          {SERVICE_CHAPTER9_REMAIN.map((chapter9ReMain, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter9ReMain}
            </TermsDetail>
          ))}
        </MiddleContainer>

        <SubHeadline weight={'semibold'} style={{ marginTop: '40px' }}>
          {SERVICE_CHAPTER10_TITLE}
        </SubHeadline>
        <MiddleContainer>
          {SERVICE_CHAPTER10.map((chapter10, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter10}
            </TermsDetail>
          ))}

          <DetailContainer>
            <TermsDetail color={'gray09'} weight={'regular'}>
              {SEASON_TICKET}
            </TermsDetail>
            {SERVICE_CHAPTER10_THIRD_DETAIL1.map((thirdDetail1, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {thirdDetail1}
              </TermsDetail>
            ))}
          </DetailContainer>

          <DetailContainer>
            <TermsDetail color={'gray09'} weight={'regular'}>
              {SHARED_PARKING_TICKET}
            </TermsDetail>
            {SERVICE_CHAPTER10_THIRD_DETAIL2.map((thirdDetail2, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {thirdDetail2}
              </TermsDetail>
            ))}
          </DetailContainer>

          <DetailContainer>
            <TermsDetail color={'gray09'} weight={'regular'}>
              {TIME_TICKET}
            </TermsDetail>
            {SERVICE_CHAPTER10_THIRD_DETAIL3.map((thirdDetail3, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {thirdDetail3}
              </TermsDetail>
            ))}
          </DetailContainer>
        </MiddleContainer>

        <SubHeadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          {SERVICE_CHAPTER11_TITLE}
        </SubHeadline>

        <MiddleContainer>
          {SERVICE_CHAPTER11.map((chapter11, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter11}
            </TermsDetail>
          ))}

          <DetailContainer>
            {SERVICE_CHAPTER11_SIXTH_DETAIL.map((firstDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {firstDetail}
              </TermsDetail>
            ))}
          </DetailContainer>

          {SERVICE_CHAPTER11_REMAIN1.map((reMain1, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {reMain1}
            </TermsDetail>
          ))}

          <DetailContainer>
            {SERVICE_CHAPTER11_EIGHTH_DETAIL.map((eighthDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {eighthDetail}
              </TermsDetail>
            ))}
          </DetailContainer>

          {SERVICE_CHAPTER11_REMAIN2.map((reMain2, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {reMain2}
            </TermsDetail>
          ))}

          <DetailContainer>
            {SERVICE_CHAPTER11_THIRTEENTH_DETAIL.map((thirteenthDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {thirteenthDetail}
              </TermsDetail>
            ))}
          </DetailContainer>

          <TermsDetail color={'gray09'} weight={'regular'}>
            {SERVICE_CHAPTER11_REMAIN3}
          </TermsDetail>

          <DetailContainer>
            {SERVICE_CHAPTER11_FOURTEENTH_DETAIL.map((fourteenthDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {fourteenthDetail}
              </TermsDetail>
            ))}
          </DetailContainer>

          {SERVICE_CHAPTER11_REMAIN4.map((reMain4, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {reMain4}
            </TermsDetail>
          ))}

          <DetailContainer>
            {SERVICE_CHAPTER11_SIXTEENTH_DETAIL.map((sixteenthDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {sixteenthDetail}
              </TermsDetail>
            ))}
          </DetailContainer>
        </MiddleContainer>

        <SubHeadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          {SERVICE_CHAPTER12_TITLE}
        </SubHeadline>
        <MiddleContainer>
          {SERVICE_CHAPTER12.map((chapter12, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter12}
            </TermsDetail>
          ))}

          <DetailContainer>
            {SERVICE_CHAPTER12_THIRD_DETAIL.map((thirdDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {thirdDetail}
              </TermsDetail>
            ))}
          </DetailContainer>

          {SERVICE_CHAPTER12_REMAIN.map((reMain, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {reMain}
            </TermsDetail>
          ))}
        </MiddleContainer>

        <SubHeadline weight={'semibold'} style={{ marginTop: '40px' }}>
          {SERVICE_CHAPTER13_TITLE}
        </SubHeadline>
        <MiddleContainer>
          {SERVICE_CHAPTER13.map((chapter13, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter13}
            </TermsDetail>
          ))}

          <DetailContainer>
            {SERVICE_CHAPTER13_SECOND_DETAIL.map((secondDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {secondDetail}
              </TermsDetail>
            ))}
          </DetailContainer>

          <TermsDetail color={'gray09'} weight={'regular'}>
            {SERVICE_CHAPTER13_REMAIN1}
          </TermsDetail>

          <DetailContainer>
            {SERVICE_CHAPTER13_THIRD_DETAIL.map((thirdDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {thirdDetail}
              </TermsDetail>
            ))}
          </DetailContainer>

          {SERVICE_CHAPTER13_REMAIN2.map((reMain2, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {reMain2}
            </TermsDetail>
          ))}
        </MiddleContainer>

        <SubHeadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          {SERVICE_CHAPTER14_TITLE}
        </SubHeadline>
        <MiddleContainer>
          {SERVICE_CHAPTER14.map((chapter14, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {chapter14}
            </TermsDetail>
          ))}
        </MiddleContainer>

        <SubHeadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          {SERVICE_CHAPTER15_TITLE}
        </SubHeadline>
        <MiddleContainer>
          {SERVICE_CHAPTER15.map((chapter15, index) => (
            <TermsDetail weight={'regular'} color={'gray09'} key={index}>
              {chapter15}
            </TermsDetail>
          ))}
        </MiddleContainer>

        <SubHeadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          {SERVICE_CHAPTER16_TITLE}
        </SubHeadline>
        <MiddleContainer>
          {SERVICE_CHAPTER16.map((chapter16, index) => (
            <TermsDetail weight={'regular'} color={'gray09'} key={index}>
              {chapter16}
            </TermsDetail>
          ))}
          <DetailContainer>
            <TermsDetail color={'gray09'} weight={'regular'}>
              {SERVICE_CHAPTER16_THIRD_DETAIL}
            </TermsDetail>
          </DetailContainer>

          <TermsDetail color={'gray09'} weight={'regular'}>
            {SERVICE_CHAPTER16_REMAIN}
          </TermsDetail>
        </MiddleContainer>

        <SubHeadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          {SERVICE_CHAPTER17_TITLE}
        </SubHeadline>
        <MiddleContainer>
          {SERVICE_CHAPTER17.map((chapter17, index) => (
            <TermsDetail weight={'regular'} color={'gray09'} key={index}>
              {chapter17}
            </TermsDetail>
          ))}
          <DetailContainer>
            {SERVICE_CHAPTER17_SECOND_DETAIL.map((secondDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {secondDetail}
              </TermsDetail>
            ))}
          </DetailContainer>

          {SERVICE_CHAPTER17_REMAIN.map((reMain, index) => (
            <TermsDetail color={'gray09'} weight={'regular'} key={index}>
              {reMain}
            </TermsDetail>
          ))}

          <DetailContainer>
            <TermsDetail color={'gray09'} weight={'regular'}>
              {SEASON_TICKET}
            </TermsDetail>
            {SERVICE_CHAPTER17_FOURTH_DETAIL1.map((fourthDetail1, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {fourthDetail1}
              </TermsDetail>
            ))}
          </DetailContainer>

          <DetailContainer>
            <TermsDetail color={'gray09'} weight={'regular'}>
              {SHARED_PARKING_TICKET}
            </TermsDetail>
            {SERVICE_CHAPTER17_FOURTH_DETAIL2.map((fourthDetail2, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {fourthDetail2}
              </TermsDetail>
            ))}
          </DetailContainer>

          <DetailContainer>
            <TermsDetail color={'gray09'} weight={'regular'}>
              {TIME_TICKET}
            </TermsDetail>
            {SERVICE_CHAPTER17_FOURTH_DETAIL3.map((fourthDetail3, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {fourthDetail3}
              </TermsDetail>
            ))}
          </DetailContainer>

          <DetailContainer>
            <TermsDetail color={'gray09'} weight={'regular'}>
              {POINT_SAVED_MONEY}
            </TermsDetail>
            {SERVICE_CHAPTER17_FOURTH_DETAIL4.map((fourthDetail4, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {fourthDetail4}
              </TermsDetail>
            ))}
          </DetailContainer>

          <DetailContainer>
            <TermsDetail color={'gray09'} weight={'regular'}>
              {COUPON}
            </TermsDetail>
            <TermsDetail color={'gray09'} weight={'regular'}>
              {SERVICE_CHAPTER17_FOURTH_DETAIL5}
            </TermsDetail>
          </DetailContainer>
        </MiddleContainer>

        <SubHeadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          {SERVICE_CHAPTER18_TITLE}
        </SubHeadline>

        <MiddleContainer>
          {SERVICE_CHAPTER18.map((chapter18, index) => (
            <TermsDetail weight={'regular'} color={'gray09'} key={index}>
              {chapter18}
            </TermsDetail>
          ))}

          <DetailContainer>
            {SERVICE_CHAPTER18_SECOND_DETAIL.map((secondDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {secondDetail}
              </TermsDetail>
            ))}
          </DetailContainer>

          {SERVICE_CHAPTER18_REMAIN1.map((reMain1, index) => (
            <TermsDetail weight={'regular'} color={'gray09'} key={index}>
              {reMain1}
            </TermsDetail>
          ))}

          <DetailContainer>
            {SERVICE_CHAPTER18_SIXTH_DETAIL.map((sixthDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {sixthDetail}
              </TermsDetail>
            ))}
          </DetailContainer>

          <TermsDetail color={'gray09'} weight={'regular'}>
            {SERVICE_CHAPTER18_REMAIN2}
          </TermsDetail>

          <DetailContainer>
            {SERVICE_CHAPTER18_SEVENTH_DETAIL.map((seventhDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {seventhDetail}
              </TermsDetail>
            ))}
          </DetailContainer>

          <TermsDetail color={'gray09'} weight={'regular'}>
            {SERVICE_CHAPTER18_REMAIN3}
          </TermsDetail>
        </MiddleContainer>

        <SubHeadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          {SERVICE_CHAPTER19_TITLE}
        </SubHeadline>

        <MiddleContainer>
          {SERVICE_CHAPTER19.map((chapter19, index) => (
            <TermsDetail weight={'regular'} color={'gray09'} key={index}>
              {chapter19}
            </TermsDetail>
          ))}
        </MiddleContainer>

        <SubHeadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          {SERVICE_CHAPTER20_TITLE}
        </SubHeadline>

        <MiddleContainer>
          {SERVICE_CHAPTER20.map((chapter20, index) => (
            <TermsDetail weight={'regular'} color={'gray09'} key={index}>
              {chapter20}
            </TermsDetail>
          ))}
        </MiddleContainer>

        <SubHeadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          {SERVICE_CHAPTER21_TITLE}
        </SubHeadline>

        <MiddleContainer>
          {SERVICE_CHAPTER21.map((chapter21, index) => (
            <TermsDetail weight={'regular'} color={'gray09'} key={index}>
              {chapter21}
            </TermsDetail>
          ))}

          <DetailContainer>
            {SERVICE_CHAPTER21_ELEVENTH_DETAIL.map((eleventhDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {eleventhDetail}
              </TermsDetail>
            ))}
          </DetailContainer>
        </MiddleContainer>

        <SubHeadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          {SERVICE_CHAPTER22_TITLE}
        </SubHeadline>

        <MiddleContainer>
          {SERVICE_CHAPTER22.map((chapter22, index) => (
            <TermsDetail weight={'regular'} color={'gray09'} key={index}>
              {chapter22}
            </TermsDetail>
          ))}
        </MiddleContainer>

        <SubHeadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          {SERVICE_CHAPTER23_TITLE}
        </SubHeadline>

        <MiddleContainer>
          {SERVICE_CHAPTER23.map((chapter23, index) => (
            <TermsDetail weight={'regular'} color={'gray09'} key={index}>
              {chapter23}
            </TermsDetail>
          ))}

          <DetailContainer>
            {SERVICE_CHAPTER23_FOURTH_DETAIL.map((fourthDetail, index) => (
              <TermsDetail color={'gray09'} weight={'regular'} key={index}>
                {fourthDetail}
              </TermsDetail>
            ))}
          </DetailContainer>

          {SERVICE_CHAPTER23_REMAIN.map((reMain, index) => (
            <TermsDetail weight={'regular'} color={'gray09'} key={index}>
              {reMain}
            </TermsDetail>
          ))}
        </MiddleContainer>

        <SubHeadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          {SERVICE_CHAPTER24_TITLE}
        </SubHeadline>

        <MiddleContainer>
          <TermsDetail weight={'regular'} color={'gray09'}>
            {SERVICE_CHAPTER24}
          </TermsDetail>
        </MiddleContainer>

        <SubHeadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          {SERVICE_CHAPTER25_TITLE}
        </SubHeadline>

        <MiddleContainer>
          {SERVICE_CHAPTER25.map((chapter25, index) => (
            <TermsDetail weight={'regular'} color={'gray09'} key={index}>
              {chapter25}
            </TermsDetail>
          ))}
        </MiddleContainer>

        <SubHeadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          {SERVICE_CHAPTER26_TITLE}
        </SubHeadline>

        <MiddleContainer>
          {SERVICE_CHAPTER26.map((chapter26, index) => (
            <TermsDetail weight={'regular'} color={'gray09'} key={index}>
              {chapter26}
            </TermsDetail>
          ))}
        </MiddleContainer>

        <SubHeadline
          weight={'semibold'}
          style={{
            marginTop: '40px',
          }}
        >
          {SUPPLEMENTARY}
        </SubHeadline>

        <TermsDetail
          weight={'regular'}
          color={'gray09'}
          style={{
            marginTop: '10px',
          }}
        >
          {EFFECTIVE_DATE}
        </TermsDetail>

        <Caption2
          style={{
            marginTop: '28px',
          }}
          onClick={() => {
            showWebBrowser(PAST_POLICY_SERVICE);
          }}
          weight={'regular'}
          color={'blue'}
        >
          이전 버전 보러가기 &gt;
        </Caption2>
      </TopContainer>
    </MainContainer>
  )
}
export default PolicyServicePage;
