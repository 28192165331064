import { showWebBrowser } from 'utils/deviceUtils';
import { JSP_TEST_URL, JSP_URL } from 'constants/baseConstants';
import { useNavigate } from 'react-router-dom';

export const pxToRem = (px: number, base: number = 16): string => {
  return `${px / base}rem`;
};

export const handleChatClick = () => {
  showWebBrowser('https://pf.kakao.com/_UxdeGK');
};

export const idRegex = /^[a-z0-9_-]{5,20}$/;
export const passwordRegex =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,16}$/;

export function phoneNumberCheck(phone: string) {
  let result = /^(01[016789]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
  return result.test(phone);
}

export const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }
  return text;
};

export const moveJSP = () => {
  // window.location.href = JSP_URL;

  window.location.href = JSP_TEST_URL;
};
