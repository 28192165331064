import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { Container, WrapItem, } from './styles';
import Row from 'components/atoms/Row';
import { Body, Caption1, Caption2, Caption3, Caption4, Headline, SubHeadline } from 'styles/typography';
import { IcCheckGraySVG, IcCheckSVG, IcPen16SVG, IcSuccessCheckGreenSVG } from 'assets/ic';
import { colors } from 'styles/colors';
import GangseoShareTimeModal from 'components/atoms/Modal/GangseoShareTimeModal';
import { IShareResidentSlotShareTimeDetailData } from 'models/Share/shareResidentSlotShareTimeDetail';

export interface DaySchedule {
  day: string; // 요일 (e.g., "월", "화")
  startTime: string; // 시작 시간 (e.g., "09:00")
  endTime: string; // 종료 시간 (e.g., "18:00")
  isActive: string; // 활성화 여부 (true/false)
}

interface Props {
  data?: IShareResidentSlotShareTimeDetailData;
  list: DaySchedule[];
  setList: React.Dispatch<React.SetStateAction<DaySchedule[]>>;
}

const ShareParkingAddress: React.FC<Props> =({
                                             data,
                                             list,
                                             setList
} ) => {
  const navigate = useNavigate()


  const [selected, setSelected] = useState<number>(0)
  const [isShareTimeModal, setIsShareTimeModal] = useState(false)


  useEffect(() => {

    const transformToList = () => {
      const days = [
        { day:  '월',
          isActive: data?.mondayShareYN ?? 'Y',
          startTime: data?.mondayShareTime.split('|')[0] ?? '09:00',
          endTime: data?.mondayShareTime.split('|')[1] ?? '18:00',
        },
        {
          day: '화',
          isActive: data?.tuesdayShareYN ?? 'Y',
          startTime: data?.tuesdayShareTime.split('|')[0] ?? '09:00',
          endTime: data?.tuesdayShareTime.split('|')[1] ?? '18:00',
        },
        {
          day: '수',
          isActive: data?.wednesdayShareYN ?? 'Y',
          startTime: data?.wednesdayShareTime.split('|')[0] ?? '09:00',
          endTime: data?.wednesdayShareTime.split('|')[1] ?? '18:00',
        },
        {
          day: '목',
          isActive: data?.thursdayShareYN ?? 'Y',
          startTime: data?.thursdayShareTime.split('|')[0] ?? '09:00',
          endTime: data?.thursdayShareTime.split('|')[1] ?? '18:00',
        },
        {
          day: '금',
          isActive: data?.fridayShareYN ?? 'Y',
          startTime: data?.fridayShareTime.split('|')[0] ?? '09:00',
          endTime: data?.fridayShareTime.split('|')[1] ?? '18:00',
        },
        // {
        //   day: '토',
        //   isActive: data?.saturdayShareYN ?? 'Y',
        //   startTime: data?.saturdayShareTime.split('|')[0] ?? '09:00',
        //   endTime: data?.saturdayShareTime.split('|')[1] ?? '18:00',
        // },
        // {
        //   day: '일',
        //   isActive: data?.sundayShareYN ?? 'Y',
        //   startTime: data?.sundayShareTime.split('|')[0] ?? '09:00',
        //   endTime: data?.sundayShareTime.split('|')[1] ?? '18:00',
        // },
      ];
      const result = days.map((el, index) => {
        return {
          day: el.day,
          startTime: el.startTime,
          endTime: el.endTime,
          isActive: el.isActive
        };
      });
      setList(result);
    };

    transformToList();

  }, [ ]);


  const handleToggle = (index: number) => {
    const updatedList = list.map((item, idx) =>
      idx === index ? { ...item, isActive: item.isActive === 'Y' ? 'N' : 'Y' } : item
    );
    setList(updatedList);
  };

  const changeShareTime = (startTime: string, endTime: string) => {
    const updatedList = list.map((item, idx) =>
      idx === selected ? { ...item, startTime, endTime } : item
    );
    setList(updatedList);
  }

  const closeModal = () => {
    setIsShareTimeModal(false)
  }

  return (
    <Container>
      <Body weight={'bold'} color={'black1'} style={{ marginBottom: 20 }}>공유 요일</Body>
      {list.map((el, index) => {
        return (
          <WrapItem key={index} onClick={() => handleToggle(index)} isActive={el.isActive === 'Y'} >
            <Row justifyContent={'space-between'}>
              <Row>
                <IcCheckSVG stroke={el.isActive === 'Y' ? colors.primary : colors.gray04} />
                <SubHeadline weight={'semibold'} color={el.isActive === 'Y' ? 'primary' : 'gray05'} style={{ marginLeft: 8 }}>{el.day}</SubHeadline>
              </Row>
              <div
                onClick={(event) => {
                  event.stopPropagation(); // 이벤트 전파 중단
                  setSelected(index);
                  setIsShareTimeModal(true);
                }}
                style={{
                  padding: '16px'
                }}
              >
                <Row >
                  <Caption1 weight={'semibold'} color={el.isActive ? 'gray08' : 'gray05'} >{el.startTime}</Caption1>
                  <Caption1 weight={'semibold'} color={el.isActive ? 'gray08' : 'gray05'} style={{ whiteSpace: 'pre-wrap' }} > ~ </Caption1>
                  <Caption1 weight={'semibold'} color={el.isActive ? 'gray08' : 'gray05'} style={{ marginRight: 8 }}>{el.endTime}</Caption1>
                  <IcPen16SVG />
                </Row>
              </div>
            </Row>
          </WrapItem>
        )
      })}
      {list.length ? (
        <GangseoShareTimeModal
          onClose={closeModal}
          isVisible={isShareTimeModal}
          onSuccess={changeShareTime}
          selected={selected}
          data={list}
        />
      ) : null}


    </Container>

  )
}

export default ShareParkingAddress;