import React, { useEffect, useState } from 'react';
import Title from 'components/atoms/Title/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import icSearch from 'assets/ic/ic_setting.svg';
import AddressSearchModal from 'components/atoms/Modal/AddressSearchModal';
import { Container, SearchContainer, SearchIcon, WrapMap, PhoneNumberContainer } from './styles';
import { MainContainerNoMargin } from 'components/styles';
import { Caption1, Caption2, Caption3, Headline, SubHeadline } from 'styles/typography';
import Map from 'components/atoms/Map';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import PopUpModal from 'components/atoms/Modal/PopupModal';
import { useFetchUserInfo } from 'hooks/useFetchUserInfo';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import { shareStore } from 'stores/store/Share/shareStore';
import Row from 'components/atoms/Row';

const SignUpRegionSettingPage: React.FC = () => {
  const navigate = useNavigate();
  const { fetchUserInfo } = useFetchUserInfo();
  const { state } = useLocation();
  const { nextPage } = state;

  const [isModalVisible, setModalVisible] = useState(false);
  const [addressText, setAddressText] = useState<string>(''); // 추가된 상태
  const [isPopup, setIsPopup] = useState(false);
  const [isSkip, setIsSkip] = useState(false);

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const openPopup = () => {
    setIsPopup(true);
  };

  const closePopup = () => {
    setIsPopup(false);
  };

  const closeSkip = () => {
    setIsSkip(false);
  };

  const handleSelectAddress = (address: string) => {
    setAddressText(address); // 주소 설정
  };

  const description = () => {
    return (
      <div
        style={{
          color: '#868686',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Caption3 weight={'regular'} color={'gray06'} style={{ marginTop: 15 }}>
          메뉴 &gt; 마이페이지에서 재설정 가능합니다.
        </Caption3>
        <div
          style={{
            marginTop: '16px',
            textDecoration: 'underline',
          }}
          onClick={() => {
            navigate(nextPage);
          }}
        >
          건너뛰기
        </div>
      </div>
    );
  };

  const popupText = addressText
    ? {
        title: '지역 설정 안내',
        description: '· 월 주차 희망지역이 설정되었습니다.',
      }
    : {
        title: `월 주차 희망지역 설정을\n건너뛰기 할까요?`,
        description: description(),
      };

  return (
    <MainContainerNoMargin>
      <Title
        title="지역설정"
        leftArrowButtonVisible
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={true}
        rightOnClick={() => {
          setIsSkip(true);
        }}
        rightTitle={'건너뛰기'}
      />
      <Container>
        <PhoneNumberContainer>
          <Row>
            <SubHeadline weight={'semibold'} color={'primary'}>
              월 주차 희망 지역
            </SubHeadline>
            <SubHeadline weight={'semibold'} color={'black1'}>
              을 알려주세요
            </SubHeadline>
          </Row>
          <SubHeadline weight={'semibold'} color={'black3'}>
            희망 지역 인근 주차장이 있으면 알려드립니다!
          </SubHeadline>
          <SearchContainer onClick={openModal}>
            {addressText ? (
              <span>{addressText}</span>
            ) : (
              <SubHeadline weight={'bold'} color={'gray06'}>
                주소를 검색하세요
              </SubHeadline>
            )}{' '}
            {/* 주소 표시 */}
            <SearchIcon src={icSearch} alt={'검색아이콘'} />
          </SearchContainer>
        </PhoneNumberContainer>

        <WrapMap>
          <Map height={'80vh'} address={addressText} />
        </WrapMap>
        <Button
          style={{
            position: 'fixed',
            bottom: '30px',
            left: 20,
            height: 46,
            width: 'calc(100% - 40px)',
            zIndex: 1000,
          }}
          backgroundColor={colors.primary}
          onClick={openPopup}
        >
          <SubHeadline weight={'regular'} color={'white'}>
            이 위치로 설정
          </SubHeadline>
        </Button>
        <AddressSearchModal
          isVisible={isModalVisible}
          onClose={closeModal}
          onSelectAddress={handleSelectAddress} // 콜백 함수 전달
        />
        <PopUpModal
          isVisible={isPopup}
          onClose={closePopup}
          title1={popupText.title}
          description={popupText.description}
          leftClick={async () => {
            closePopup();
            if (addressText) {
              userInfoStore.setMonthlyParkingDesiredArea(addressText);
              // await shareStore.fetchShareSlot('01012341234', addressText)
              navigate(nextPage);
            }
          }}
          leftText={'확인'}
        />
        <PopUpModal
          isVisible={isSkip}
          onClose={closeSkip}
          title1={`월 주차 희망지역 설정을\n건너뛰기 할까요?`}
          description={description()}
          leftClick={async () => {
            closeSkip();
            // if (addressText) {
            //   await shareStore.fetchShareSlot('01012341234', addressText)
            //   navigate('/shareHistory')
            // }
          }}
          leftText={'계속 설정하기'}
        />
      </Container>
    </MainContainerNoMargin>
  );
};

export default SignUpRegionSettingPage;
