import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, WrapDropdown } from './styles';
import { Caption1, Caption2, Caption3, Title2 } from 'styles/typography';

import { useToast } from 'components/atoms/Toast/ToastProvider';
import { IcArrowBottomBlackSVG } from 'assets/ic';
import Row from 'components/atoms/Row';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import { METHODS } from 'utils/bankUtil';

interface Props {
  selected: number;
  setIsMethodModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const PaymentMethod: React.FC<Props> = ({ selected, setIsMethodModal }) => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  return (
    <Container>
      <Caption1 weight={'bold'} color={'gray07'}>
        결제 수단
      </Caption1>

      <WrapDropdown
        onClick={() => {
          setIsMethodModal(true);
        }}
      >
        <Row justifyContent={'space-between'}>
          <Caption2 weight={'regular'} color={'gray08'}>
            {METHODS[selected]}
          </Caption2>
          <IcArrowBottomBlackSVG width={8} height={5} />
        </Row>
      </WrapDropdown>

      <Row style={{ gap: '10px' }}>
        <Button
          backgroundColor={colors.white}
          radius={4}
          style={{
            height: 46,
            width: 'calc(100%)',
            marginTop: 20,
          }}
          onClick={() => {
            console.log(1);
          }}
          borderColor={colors.primary}
        >
          <Caption2 weight={'bold'} color={'primary'}>
            일반결제
          </Caption2>
        </Button>
        <Button
          backgroundColor={colors.primary}
          radius={4}
          style={{
            height: 46,
            width: 'calc(100%)',
            marginTop: 20,
          }}
          onClick={() => {
            console.log(2);
          }}
        >
          <Caption2 weight={'bold'} color={'white'}>
            자동결제 등록
          </Caption2>
        </Button>
      </Row>
    </Container>
  );
};

export default PaymentMethod;
