import { format } from 'date-fns'; // date-fns 라이브러리를 사용하는 경우

/**
 * 현재 날짜를 YYYY년 MM월 DD일 형식으로 포맷팅하는 함수
 * @returns {string} 포맷된 날짜 문자열
 */
export const getFormattedDate = (): string => {
  const today: Date = new Date();
  const year: number = today.getFullYear();
  const month: string = String(today.getMonth() + 1).padStart(2, '0');
  const day: string = String(today.getDate()).padStart(2, '0');

  return `${year}년 ${month}월 ${day}일`;
};

export function getFormattedDateHyphen(date?: Date): string {
  const d = date || new Date();
  return format(d, 'yyyy-MM-dd');
}

// 24.12.08 12시 00분
export function formatCommaDateTimeKoreanUnit(inputDateTime: string) {
  // 주어진 문자열을 날짜와 시간으로 분리
  const [datePart, timePart] = inputDateTime.split(' ');
  const [year, month, day] = datePart.split('-');
  const [hours, minutes] = timePart.split(':');

  // 연도에서 뒤 두 자리만 추출
  const formattedYear = year.slice(2);

  // 포맷팅된 문자열 반환
  return `${formattedYear}.${month}.${day} ${hours}시 ${minutes}분`;
}

// 24.12.08 12:00

export function formatCommaDateTimeColons(inputDateTime: string) {
  // 주어진 문자열을 날짜와 시간으로 분리
  const [datePart, timePart] = inputDateTime.split(' ');
  const [year, month, day] = datePart.split('-');
  const [hours, minutes] = timePart.split(':');

  // 연도에서 뒤 두 자리만 추출
  const formattedYear = year.slice(2);

  // 포맷팅된 문자열 반환
  return `${formattedYear}.${month}.${day} ${hours}:${minutes}`;
}

// 24.12.24 (화)
export const formatDateDay = (dateTime: string): string => {
  // 날짜 문자열을 Date 객체로 변환
  const date = new Date(dateTime);

  // 요일 배열
  const days = ['일', '월', '화', '수', '목', '금', '토'];

  // 연도, 월, 일, 요일 추출 및 포맷
  const year = String(date.getFullYear()).slice(2); // 연도 마지막 두 자리
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월 (2자리)
  const day = String(date.getDate()).padStart(2, '0'); // 일 (2자리)
  const dayOfWeek = days[date.getDay()]; // 요일

  // 포맷된 문자열 반환
  return `${year}.${month}.${day}(${dayOfWeek})`;
};

// 24.12.30(월) ~ 24.12.31(화)
export const formatDateRangeDay = (dateRange: string) => {
  // "2024-12-30 ~ 2024-12-31" 형식의 문자열을 분리
  const [startDate, endDate] = dateRange.split(' ~ ');

  // 날짜 포맷을 위한 함수
  const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);

    // 요일 배열 (0: 일요일, 1: 월요일, ..., 6: 토요일)
    const weekdays = ['일', '월', '화', '수', '목', '금', '토'];

    const year = String(date.getFullYear()).slice(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 01, 02, ..., 12
    const day = date.getDate().toString().padStart(2, '0'); // 01, 02, ..., 31
    const weekday = weekdays[date.getDay()]; // 해당 요일

    // "yyyy.MM.dd(요일)" 형식으로 반환
    return `${year}.${month}.${day}(${weekday})`;
  };

  // 시작 날짜와 끝 날짜 포맷 적용
  const startFormatted = formatDate(startDate);
  const endFormatted = formatDate(endDate);

  // 결과 반환
  return `${startFormatted} ~ ${endFormatted}`;
};

// 24.12.30(월) ~ 24.12.31
export const formatDateRange = (dateRange: string) => {
  // "2024-12-30 ~ 2024-12-31" 형식의 문자열을 분리
  const [startDate, endDate] = dateRange.split(' ~ ');

  // 날짜 포맷을 위한 함수
  const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);

    const year = String(date.getFullYear()).slice(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 01, 02, ..., 12
    const day = date.getDate().toString().padStart(2, '0'); // 01, 02, ..., 31

    // "yyyy.MM.dd" 형식으로 반환
    return `${year}.${month}.${day}`;
  };

  // 시작 날짜와 끝 날짜 포맷 적용
  const startFormatted = formatDate(startDate);
  const endFormatted = formatDate(endDate);

  // 결과 반환
  return `${startFormatted} ~ ${endFormatted}`;
};
