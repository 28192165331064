import { makeAutoObservable } from 'mobx';
import pointAccountRegiService from '../../../service/Point/pointAccountRegiService';
import BaseError from '../../../models/baseError';

class PointAccountRegiStore {
  isLoading = false
  error: string | null = null
  isAccountSuccessModal = false
  isAccountFailModal = false
  isErrorToast: boolean = false

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setIsAccountSuccessModal(value: boolean) {
    this.isAccountSuccessModal = value;
  }

  setIsAccountFailModal(value: boolean) {
    this.isAccountFailModal = value;
  }

  setIsErrorToast(errorToast: boolean) {
    this.isErrorToast = errorToast
  }

  constructor() {
    makeAutoObservable(this);
  }

  async fetchPointAccountRegi(
    mLinkUID: number,
    bankCode: string,
    bankName: string,
    accountNumber: string,
    accountHolder: string,
  ) {
    const requestBody = {
      mLinkUID: mLinkUID,
      bankCode: bankCode,
      bankName: bankName,
      accountNumber: accountNumber,
      accountHolder: accountHolder,
    }

    this.setLoading(true)

    try {
      const response = await pointAccountRegiService.postPointAccountRegiChange(requestBody)

      if (response instanceof BaseError) {
        this.setIsAccountFailModal(true)
        this.error = response.errorMessage
        return false
      } else {
        this.setIsAccountSuccessModal(true)
        return true;
      }
    } catch (error) {
      console.error('계좌 등록 처리 실패:', error)
      this.error = '계좌 등록에 실패했습니다.'
      this.setIsAccountFailModal(true)
      throw error
    } finally {
      this.setLoading(false)
    }
  }

  async fetchPointAccountDelete (
    mLinkUID: number,
    bankCode: string,
    bankName: string,
    accountNumber: string,
    accountHolder: string,
  ) {
    const requestBody = {
      mLinkUID: mLinkUID,
      bankCode: bankCode,
      bankName: bankName,
      accountNumber: accountNumber,
      accountHolder: accountHolder,
    }
    this.setLoading(true)

    try {
      const response = await pointAccountRegiService.postPointAccountDelete(requestBody)

      if (response instanceof BaseError) {
        console.log(response.errorMessage)
        this.setIsErrorToast(true)
        return false
      } else {
        this.setIsAccountSuccessModal(true)
        return true
      }
    } catch (error) {
      console.error('계좌 삭제 처리 실패:', error)
      this.error = '계좌 삭제 실패했습니다.'
      this.setIsAccountFailModal(true)
      throw error
    } finally {
      this.setLoading(false)
    }
  }
}

export const pointAccountRegiStore = new PointAccountRegiStore()