import { createStitches } from '@stitches/react';

export const { styled } = createStitches({
  theme: {
    colors: {
      gray: '#e0e0e0',
      purple: '#7a4bf8',
      black: '#333333',
      lightGray: '#f5f5f5',
      white: '#ffffff',
    },
  },
});

export const TimePickerWrapper = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '16px',
  justifyContent: 'center',

  marginTop: '16px',
});

export const TimeColumn = styled('div', {
  overflowY: 'scroll',
  textAlign: 'center',
  height: '144px',
  position: 'relative',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const TimeItem = styled('div', {
  height: '48px',
  padding: '0px 16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
