import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import {
  ButtonModify,
  ImgMyPage,
  ImgUserProfile,
  MyPageGreetingPhoneNumberContainer,
  MyPageSettingListContainer,
  MyPageUserInfoContainer,
  MyPageUserInfoPhotoContainer,
  MyPageUserInfoPhotoEllipseContainer,
} from './styles';
import icProfileUser from 'assets/ic/ic_profile_user.png';
import icProfile from 'assets/ic/ic_profile.svg';
import icLocation from 'assets/ic/ic_location.svg';
import icWallet from 'assets/ic/ic_wallet.svg';
import icCar from 'assets/ic/ic_car.svg';
import icDotPurple from 'assets/ic/ic_dot_purple.svg';
import { Caption2, SubHeadline, Title3 } from 'styles/typography';
import Row from 'components/atoms/Row';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import { JSP_LOGIN_URL } from 'constants/baseConstants';
import CustomBottomModal from 'components/atoms/Modal/CustomBottomModal';

const MyPageList = observer(() => {
  const navigate = useNavigate();

  const [isPhoneChangeModal, setIsPhoneChangeModal] = useState(false);

  const userInfoItems = [{ textSetting: '휴대폰 번호', textUserInfo: '010-1234-5678' }];

  const { monthlyParkingDesiredArea } = userInfoStore;

  const onClosePhoneChangeModal = () => {
    setIsPhoneChangeModal(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <div style={{ flex: 1, overflowY: 'auto' }}>
        <MyPageUserInfoContainer>
          <MyPageGreetingPhoneNumberContainer>
            <Caption2 weight={'regular'} color={'white'}>
              안녕하세요!
            </Caption2>
            <Row alignItems={'flex-end'}>
              <Title3
                weight={'bold'}
                color={'white'}
                style={{
                  fontWeight: 800,
                }}
              >
                01012345678
              </Title3>
              <Caption2
                weight={'regular'}
                color={'white'}
                style={{ marginBottom: 2, marginLeft: 4 }}
              >
                님
              </Caption2>
            </Row>
          </MyPageGreetingPhoneNumberContainer>
          <MyPageUserInfoPhotoContainer>
            <MyPageUserInfoPhotoEllipseContainer>
              <ImgUserProfile src={icProfileUser} alt={'프로필 타원 아이콘'} />
            </MyPageUserInfoPhotoEllipseContainer>
          </MyPageUserInfoPhotoContainer>
        </MyPageUserInfoContainer>
        <div>
          <div style={{ marginTop: 36 }}>
            <MyPageSettingListContainer>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ImgMyPage src={icProfile} alt="프로필 아이콘" />
                <SubHeadline weight={'regular'} color={'black1'}>
                  프로필
                </SubHeadline>
              </div>
              <ButtonModify
                onClick={() => {
                  setIsPhoneChangeModal(true);
                }}
              >
                수정
              </ButtonModify>
            </MyPageSettingListContainer>
            {userInfoItems.map((el, index) => {
              return (
                <Row justifyContent={'space-between'} style={{ marginTop: 10 }} key={index}>
                  <Row>
                    <img src={icDotPurple} alt={'점 아이콘'} style={{ marginRight: '8px' }} />
                    <Caption2 weight={'regular'} color={'gray06'}>
                      {el.textSetting}
                    </Caption2>
                  </Row>
                  <Caption2 weight={'regular'} color={'black1'}>
                    {el.textUserInfo}
                  </Caption2>
                </Row>
              );
            })}
          </div>
          <div style={{ marginTop: 40.5 }}>
            <MyPageSettingListContainer>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ImgMyPage src={icLocation} alt="로케이션 아이콘" />
                <SubHeadline weight={'regular'} color={'black1'}>
                  월 주차 희망지역
                </SubHeadline>
              </div>
              <ButtonModify
                onClick={() => {
                  navigate('/desiredAreaManagement', {
                    state: {
                      nextPage: '/myPage',
                    },
                  });
                }}
              >
                관리
              </ButtonModify>
            </MyPageSettingListContainer>
            <Row justifyContent={'flex-end'} style={{ marginTop: 10 }}>
              {monthlyParkingDesiredArea && monthlyParkingDesiredArea.length > 0 ? (
                <>
                  {monthlyParkingDesiredArea.length === 1 ? (
                    <Caption2 weight={'regular'} color={'black1'}>
                      {monthlyParkingDesiredArea[0].address_name}
                    </Caption2>
                  ) : (
                    <>
                      <Caption2 weight={'regular'} color={'black1'}>
                        {monthlyParkingDesiredArea[0].address_name}
                      </Caption2>
                      <Caption2 weight={'regular'} color={'black1'} style={{ marginLeft: 4 }}>
                        {'외 ' + (monthlyParkingDesiredArea.length - 1)}
                      </Caption2>
                    </>
                  )}
                </>
              ) : (
                <Caption2 weight={'regular'} color={'gray06'}>
                  월 주차 희망 지역이 없습니다.
                </Caption2>
              )}
            </Row>
          </div>
          <div style={{ marginTop: 40.5 }}>
            <MyPageSettingListContainer>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ImgMyPage src={icWallet} alt="지갑 아이콘" />
                <SubHeadline weight={'regular'} color={'black1'}>
                  빈자리 신청 내역
                </SubHeadline>
              </div>

              {/* 신청 내역이 있다면 보기 보여 주자 없다면 GONE 처리 */}
              <ButtonModify
                onClick={() => {
                  navigate('/emptyParkingArea');
                }}
              >
                보기
              </ButtonModify>
            </MyPageSettingListContainer>
            {/* 신청 내역이 있다면 - 빈자리가 생기면 알려드려요 신청 내역이 없다면 - 빈자리 신청 내역이 없습니다. */}
            <Row justifyContent={'flex-end'} style={{ marginTop: 10 }}>
              {/*<Caption2 weight={'regular'} color={'gray06'}>*/}
              {/*  빈자리 신청 내역이 없습니다*/}
              {/*</Caption2>*/}

              <Caption2 weight={'regular'} color={'black1'}>
                빈자리 생기면 알려드려요
              </Caption2>
            </Row>
          </div>

          <div style={{ marginTop: 40.5 }}>
            <MyPageSettingListContainer>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ImgMyPage src={icWallet} alt="지갑 아이콘" />
                <SubHeadline weight={'regular'} color={'black1'}>
                  정기결제 출금 계좌
                </SubHeadline>
              </div>
              {/*<ButtonModify>신청</ButtonModify>*/}
            </MyPageSettingListContainer>
            <Row justifyContent={'flex-end'} style={{ marginTop: 10 }}>
              <Caption2 weight={'regular'} color={'gray06'}>
                정기결제 신청 내역이 없습니다.
              </Caption2>
            </Row>
          </div>
          <div style={{ marginTop: 40.5 }}>
            <MyPageSettingListContainer>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ImgMyPage src={icCar} alt="자동차 아이콘" />
                <SubHeadline weight={'regular'} color={'black1'}>
                  차량 관리
                </SubHeadline>
              </div>
              <ButtonModify
                onClick={() => {
                  {
                    /* 등록된 차량이 있다면*/
                  }
                  // navigate('/carRegistration')

                  {
                    /* 등록된 차량이 없다면*/
                  }
                  navigate('/carInfo');
                }}
              >
                등록
              </ButtonModify>
            </MyPageSettingListContainer>
            <Row justifyContent={'flex-end'} style={{ marginTop: 10 }}>
              <Caption2 weight={'regular'} color={'gray06'}>
                등록된 차량이 없습니다
              </Caption2>
            </Row>
          </div>
        </div>
      </div>
      <span
        style={{
          textAlign: 'center',
          padding: '39px',
          cursor: 'pointer',
          fontFamily: 'Pretendard',
          fontSize: '13px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal',
          textDecoration: 'underline',
          color: '#868686',
        }}
        onClick={() => {
          //  로그아웃 처리 되면서 홈으로 이동
          // navigate(-1);
          window.location.href = JSP_LOGIN_URL;
        }}
      >
        로그아웃
      </span>

      <CustomBottomModal
        isVisible={isPhoneChangeModal}
        onClose={onClosePhoneChangeModal}
        title1={'휴대폰 번호를 변경하나요?'}
        description={'변경 시 변경한 휴대폰 번호로 본인인증을 해야 해요.'}
        leftClick={onClosePhoneChangeModal}
        leftText={'취소'}
        rightText={'번호 변경할래요'}
        rightClick={() => {
          // setIsIdentityVerificationModal(true);
          navigate('/identityVerification');
        }}
      />
    </div>
  );
});

export default MyPageList;
