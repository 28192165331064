import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import { Modal, StyledTextArea, TextAreaContainer, WrapDesc, WrapDropdown } from './styles';
import { Caption1, Caption2, Headline, SubHeadline } from 'styles/typography';
import Row from 'components/atoms/Row';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import Dropdown from 'components/atoms/Dropdown';
import CancelResaonStore from 'stores/store/CancelReason/CancelResaonStore';
import { useToast } from 'components/atoms/Toast/ToastProvider';

interface BottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  rightClick?: () => void;
  usingDate: string;
  title: string;
}

const MinganParkDisagreeModal = observer(
  ({ isVisible, onClose, rightClick, usingDate, title }: BottomModalProps) => {
    const { showToast } = useToast();
    const [value, setValue] = useState('');

    if (!isVisible) return null;

    const reasons = [
      '다른 지역으로 이동(이사, 회사 이전 등)',
      '차종이 조건에 맞지 않음',
      '해당 자리를 이용할 차량이 생김',
      '기타',
    ];

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const inputValue = e.target.value;

      // 조건 1: 아무것도 입력 안 된 상태에서 띄어쓰기 입력 방지
      if (inputValue.trimStart() === '' && inputValue.startsWith(' ')) {
        return;
      }

      // 조건 2: 띄어쓰기 연속 2번 이상 입력 방지
      if (/\s{2}/.test(inputValue)) {
        return;
      }

      // 조건 3: 최대 50자 입력 가능
      if (inputValue.length > 50) {
        // 토스트 메세지 호출 (예: alert 사용)
        showToast('최대 50자까지 입력 가능합니다.');
        return;
      }
      setValue(inputValue);
    };

    const isDisabled =
      CancelResaonStore.selectedReason === null || // selectedReason이 선택되지 않은 경우
      (CancelResaonStore.selectedReason === 4 && (value.length < 10 || value.length > 50));

    return (
      <>
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            zIndex: 9999,
          }}
          onClick={onClose}
        />
        <Modal isVisible={isVisible}>
          <Row style={{ whiteSpace: 'pre-wrap' }}>
            <Headline weight={'medium'} color={'black1'}>
              {title}
            </Headline>
          </Row>
          <Caption1
            weight={'regular'}
            color={'gray08'}
            style={{ marginTop: 16, wordBreak: 'keep-all' }}
          >
            거절 시 철회할 수 없으며, 운전자에게 바로 거절 알림 메시지가 갑니다.
          </Caption1>

          <WrapDesc>
            <Caption1 weight={'regular'} color={'primary'} style={{ wordBreak: 'keep-all' }}>
              {usingDate}
            </Caption1>
          </WrapDesc>

          <WrapDropdown>
            <Dropdown reasons={reasons} />
            {CancelResaonStore.selectedReason === 4 ? (
              <TextAreaContainer>
                <StyledTextArea
                  placeholder={'거절 사유를 입력해 주세요(10자 이상)'}
                  value={value}
                  onChange={handleChange}
                  rows={3}
                />
              </TextAreaContainer>
            ) : null}
          </WrapDropdown>

          <Row style={{ gap: '10px', position: 'fixed', bottom: 40, width: 'calc(100% - 40px)' }}>
            <Button
              backgroundColor={colors.gray01}
              radius={4}
              style={{
                height: 46,
                width: 'calc(100%)',
                marginTop: 28,
              }}
              onClick={() => {
                onClose();
              }}
            >
              <Caption2 weight={'semibold'} color={'gray06'}>
                취소
              </Caption2>
            </Button>
            <Button
              backgroundColor={isDisabled ? colors.gray05 : colors.primary}
              radius={4}
              style={{
                height: 46,
                width: 'calc(100%)',
                marginTop: 28,
              }}
              onClick={() => {
                if (CancelResaonStore.selectedReason === 4) {
                  CancelResaonStore.setReasonValue(value);
                } else {
                  CancelResaonStore.selectedReason &&
                    CancelResaonStore.setReasonValue(reasons[CancelResaonStore.selectedReason - 1]);
                }
                onClose();

                rightClick && rightClick();
              }}
              disabled={isDisabled}
            >
              <Caption2 weight={'semibold'} color={'white'}>
                거절하기
              </Caption2>
            </Button>
          </Row>
        </Modal>
      </>
    );
  },
);

export default MinganParkDisagreeModal;
