import React, { useEffect, useRef, useState } from 'react';

interface MapProps {
  height?: string;
  address: string;
  draggable?: boolean;
  zoomControl?: boolean;
}

const Map: React.FC<MapProps> = ({
  height = '100vh',
  address,
  draggable = true,
  zoomControl = true,
}) => {
  const [map, setMap] = useState<any>(null);
  const [marker, setMarker] = useState<any>(null);
  const containerRef = useRef<HTMLDivElement | null>(null); // 지도를 렌더링할 div

  useEffect(() => {
    // 카카오맵이 로드되었을 때만 실행하도록 확인
    if (window.kakao && containerRef.current) {
      // 맵을 생성합니다.
      const mapOptions = {
        center: new window.kakao.maps.LatLng(37.5665, 126.978), // 서울 시청 위치
        level: 3,
        draggable,
        zoomControl,
      };

      const kakaoMap = new window.kakao.maps.Map(containerRef.current, mapOptions);
      setMap(kakaoMap);

      window.kakao.maps.event.addListener(kakaoMap, 'zoom_changed', function () {
        if (marker) {
          const currentPosition = marker.getPosition();
          marker.setPosition(currentPosition); // 줌 변경 시 마커 위치 재설정
        }
      });
    }

    // 컴포넌트가 언마운트될 때 지도 및 리스너 정리
    return () => {
      if (map) {
        window.kakao.maps.event.removeListener(map, 'zoom_changed', () => {}); // 리스너 제거
      }
    };
  }, []);

  useEffect(() => {
    if (address !== '') {
      handleSelectAddress(address);
    }
  }, [address, map]);

  const handleSelectAddress = (address: string) => {
    let ps = new window.kakao.maps.services.Places();

    let callback = function (result: any, status: any) {
      if (status === window.kakao.maps.services.Status.OK && map) {
        let coords = new window.kakao.maps.LatLng(result[0].y, result[0].x);

        if (marker) {
          // @ts-ignore
          marker.setMap(null);
        }

        const imageSize = window.innerWidth - 60;
        const smallSize = 36;

        const image = draggable
          ? new window.kakao.maps.MarkerImage(
              'https://test.web.zoomansa.co.kr/static/media/img_marker_locate.5cf6bd30ab2fb09f9c4af68dd0194e1b.svg', // 마커 이미지 URL
              new window.kakao.maps.Size(smallSize, smallSize), // 마커 크기
              { offset: new window.kakao.maps.Point(smallSize / 2, smallSize / 2) }, // 마커 이미지의 기준점 설정
            )
          : new window.kakao.maps.MarkerImage(
              'https://test.web.zoomansa.co.kr/static/media/img_marker_locate.5cf6bd30ab2fb09f9c4af68dd0194e1b.svg', // 마커 이미지 URL
              new window.kakao.maps.Size(imageSize, imageSize), // 마커 크기
              { offset: new window.kakao.maps.Point(imageSize / 2, imageSize / 2) },
            );

        const newMarker = new window.kakao.maps.Marker({
          map: map,
          position: coords,
          image: image,
        });

        setMarker(newMarker);

        map.setCenter(coords);
      }
    };
    ps.keywordSearch(address, callback);
  };

  return (
    <div
      id={'map'}
      style={{
        width: '100%',
        height,
      }}
      ref={containerRef}
    ></div>
  );
};

export default Map;
