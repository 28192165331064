import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';

import {
  Container,
  PhoneNumberBorderContainer,
  UserInfoInput,
  CertificationContainer,
  BottomButtonContainer,
  ButtonEnableDisable,
} from './styles';
import { MainContainer } from 'components/styles';
import Title from 'components/atoms/Title/PageTitle';
import CheckModal from 'components/atoms/Modal/CheckModal';
import Row from 'components/atoms/Row';
import { Body, Body2, Caption2, SubHeadline } from 'styles/typography';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import { IcFailCheckRedSVG, IcSuvBelowSVG } from 'assets/ic';
import IcFalilCheckRed from 'assets/ic/ic_fail_check_red.svg';
import PopupModal from 'components/atoms/Modal/PopupModal';
interface IFormInputs {
  certification: string;
}

const DeleteAccount: React.FC = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const [isCertification, setIsCertification] = useState(false);
  const [time, setTime] = useState(180);
  const [isVerificationCodeTransmissionModal, setIsVerificationCodeTransmissionModal] =
    useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [popupText, setPopupText] = useState({
    title1: '본인 인증이 완료되었습니다.',
    description: '',
  });

  useEffect(() => {
    if (!isCertification) return;

    // 1초마다 타이머 업데이트
    const timerInterval = setInterval(() => {
      setTime(prevTime => {
        if (prevTime <= 0) {
          clearInterval(timerInterval);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    // 컴포넌트가 언마운트되면 타이머를 정리합니다.
    return () => clearInterval(timerInterval);
  }, [isCertification]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };
  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputs>({
    defaultValues: {
      certification: '',
    },
  });

  const certification = watch('certification', '');

  const onSubmit: SubmitHandler<IFormInputs> = data => {
    console.log('폼 데이터:', data);
    // if (data.certification === '123456') {
    //   showToast('인증번호가 틀렸어요.', IcFalilCheckRed);
    // } else if (time === 0) {
    //   showToast('인증 시간이 지났어요. [재전송]을 눌러 주세요.', IcFalilCheckRed);
    // } else {
    //   setPopupText({
    //     title1: '이용 중인 주차장이 있어요.',
    //     description: '고객센터를 통해 종료한 후에 다시 시도해 주세요',
    //   });
    //   setIsModal(true);
    // }
    navigate('/deleteAccountNotification');
  };

  return (
    <MainContainer>
      <Title
        title="회원 탈퇴"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      <Container>
        <Row>
          <Body weight={'medium'} color={'primary'}>
            안전한 서비스 이용
          </Body>
          <Body weight={'medium'} color={'black3'}>
            을 위해
          </Body>
        </Row>
        <Body weight={'medium'} color={'black3'}>
          본인인증을 먼저 진행해 주세요
        </Body>

        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <PhoneNumberBorderContainer>
            <SubHeadline weight={'bold'} color={'primary'}>
              휴대폰 번호
            </SubHeadline>
            <Body2 weight={'regular'} color={'black3'} style={{ marginTop: 7 }}>
              010-1234-1234
            </Body2>
          </PhoneNumberBorderContainer>

          <CertificationContainer isFocused={isFocused}>
            <Row justifyContent={'space-between'}>
              <SubHeadline weight={'bold'} color={'primary'}>
                인증 번호
              </SubHeadline>
              {isCertification ? (
                <Caption2 weight={'regular'} color={'gray06'}>
                  {formatTime(time)}
                </Caption2>
              ) : null}
            </Row>

            <Row justifyContent={'space-between'} style={{ marginTop: 8 }}>
              <UserInfoInput
                type="number"
                placeholder={isCertification ? '6자리를 입력해 주세요' : '[전송]을 눌러주세요'}
                id="certification"
                {...register('certification', { required: '필수 입력 항목입니다.' })}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
              />
              <Button
                horizontal={12}
                style={{
                  height: 32,
                }}
                backgroundColor={isCertification && time > 150 ? colors.gray05 : colors.primary}
                radius={4}
                onClick={() => {
                  setIsCertification(false);
                  console.log(123);
                  setTime(180);
                  setIsCertification(true);
                }}
                disabled={isCertification && time > 150}
              >
                <Caption2 weight={'semibold'} color={'white'}>
                  {isCertification ? '재전송' : '전송'}
                </Caption2>
              </Button>
            </Row>
          </CertificationContainer>
        </form>
        {isCertification && certification.length === 6 ? (
          <BottomButtonContainer>
            <ButtonEnableDisable onClick={handleSubmit(onSubmit)}>인증하기</ButtonEnableDisable>
          </BottomButtonContainer>
        ) : null}
      </Container>
      <CheckModal
        isVisible={isVerificationCodeTransmissionModal}
        onClose={() => {
          setIsVerificationCodeTransmissionModal(false);
        }}
        title={'인증번호가 발송되었습니다.'}
        content={''}
        buttonText={'확인'}
      />

      <PopupModal
        isVisible={isModal}
        onClose={() => {
          setIsModal(false);
        }}
        title1={popupText.title1}
        description={popupText.description}
        leftClick={() => {
          setIsModal(false);
        }}
        leftText={'확인'}
      />
    </MainContainer>
  );
};

export default DeleteAccount;
