import { styled } from '@stitches/react';

export const ModalOverlay = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 9999,
})

export const PurpleTwoButtonCheckModalContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  borderRadius: '8px',
  width: '80%',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  height: 'auto',
})

export const PurpleTwoButtonTitleContainer = styled('div', {
  margin: '20px 20px 8px', // 기본 margin

  variants: {
    hasContent: {
      true: {
        textAlign: 'left',
        marginBottom: '8px',
      },
      false: {
        textAlign: 'center',
        marginBottom: '0px',
      },
    },
  },

  defaultVariants: {
    hasContent: true, // 기본값 설정
  },
});

export const PurpleTwoButtonContentsContainer = styled('div', {
  margin: '0px 20px 20px'
})

export const PurpleTwoButtonContainer = styled('div', {
  display: 'flex',
  gap: 10
})

export const PurpleTwoButtonLeftButton = styled('button', {
  flex: 1,
  padding: '16px',
  fontSize: '14px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  color: '#868686',
  backgroundColor: '#F2F2F2',
  border: 'none',
  textAlign: 'center',
  borderRadius: '4px',
  marginBottom: '20px',
  marginLeft: '20px',
})

export const PurpleTwoButtonRightButton = styled('button', {
  flex: 1,
  padding: '16px',
  fontSize: '14px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  color: '#fff',
  backgroundColor: '#8B63BB',
  border: 'none',
  textAlign: 'center',
  marginBottom: '20px',
  marginRight: '20px',
  borderRadius: '4px',
})