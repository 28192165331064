import Title from '../../../components/atoms/Title/PageTitleUsually';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BlueCautionSpan,
  BlueColorSpan,
  ListCheckContainer,
  ListItemCheckIc,
  ListItemContainer,
  ListItemSpan,
  PaymentInfoContentContainer,
  PaymentItemContainer,
  PaymentValue,
  TerraceTowerContainer,
  ViewDivide1,
  ViewDivide2,
  WarningSpan,
} from '../styles';
import {
  AgreeButton,
  BottomButtonContainer,
  CarNumberErrorContainer,
  CarNumberInput,
  CarNumberInputContainer,
  CarNumberText,
  CautionTitleContainer,
  ExclamationIcon,
  PolicyPersonalCheckBoxContainer,
  PolicyPersonalClickSpan,
  PolicyPersonalContainer,
  RequiredText,
  StyledCheckbox,
} from './styles';
import { Controller, useForm } from 'react-hook-form';
import { ExclamationBlackIc } from '../TerraceTowerDetail/styles';
import icExclamationBlack from 'assets/ic/ic_exclamation_black.svg';
import icCheckBlue from 'assets/ic/ic_check_blue.svg';
import CarNumberCheckBottomModal from '../../../components/atoms/Modal/CarNumberCheckBottomModal';
import { terraceTowerUsageHistoryDetailStore } from 'stores/store/TerraceTower/terraceTowerUsageHistoryDetailStore';
import { terraceTowerProductOrderStore } from 'stores/store/TerraceTower/terraceTowerProductOrderStore';
import { terraceTowerProductStore } from 'stores/store/TerraceTower/terraceTowerProductStore';
import { observer } from 'mobx-react';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import { encTextWord, getRandomString } from 'utils/cryptoJS';
import useUserInfo from 'hooks/useUserInfo';
import { IUserInfo } from 'models/UserInfo/userInfoModel';
import { LINK_UID } from 'constants/userInfoConstants';
import { IPointSearch } from 'models/Point/pointHistoryModel';
import { pointSearchStore } from 'stores/store/Point/pointSearchStore';
import { Body, Caption1, Caption2, Caption3, SubHeadline } from 'styles/typography';
import { colors } from 'styles/colors';
import { moveInnoPay, webViewClose } from 'utils/deviceUtils';
import { TERRACE_PAYMENT_RETURN_URL } from 'constants/baseConstants';
import { MainContainerNoMargin } from 'components/styles';

interface IFormInputs {
  carNumber: string;
}

const TerraceTowerPaymentPage = observer(() => {
  const navigate = useNavigate();
  const [isCarInputFocus, setIsCarInputFocus] = useState(false);
  const [hasText, setHasText] = useState(false);
  const setIsPolicyPersonalCheckState = sessionStorage.getItem('isPolicyPersonalCheck');
  const [isChecked, setIsChecked] = useState(setIsPolicyPersonalCheckState === 'true');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const storedProductUID = sessionStorage.getItem('productUID') || '';

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const handleFocus = () => {
    setIsCarInputFocus(true);
  };

  const handleBlur = () => {
    setIsCarInputFocus(false);
  };

  const {
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<IFormInputs>({
    defaultValues: {
      carNumber: sessionStorage.getItem('carNumber') || '',
    },
  });

  const carNumber = watch('carNumber', '');
  const setIsNotFocus = sessionStorage.getItem('isNotFocus');
  const { iv, salt } = getRandomString();
  const { memberID, memberPW, loginType } = useUserInfo();
  const memberEncPw = encTextWord(memberPW, iv, salt);
  const [memberUID, setMemberUID] = useState<number | null>(null);

  useEffect(() => {
    if (setIsNotFocus !== 'true') {
      if (carNumberInputRef.current) {
        carNumberInputRef.current.focus();
        window.scrollTo(0, document.body.scrollHeight);
        sessionStorage.removeItem('isNotFocus');
      }
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('carNumber', carNumber);
  }, [carNumber]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (carNumber) {
      setHasText(true);
    } else {
      setHasText(false);
    }
  }, [carNumber]);

  const [isCarNumberCheckBottomModalVisible, setIsCarNumberCheckBottomModalVisible] =
    useState(false);

  const carNumberInputRef = useRef<HTMLInputElement | null>(null);

  const { productName, lotName, operationTime, salePrice } =
    terraceTowerProductStore.terraceTowerOrder || {};

  const paymentInfo = [
    { label: '상품명', value: productName },
    { label: '주차장명', value: lotName },
    { label: '이용가능 시간', value: operationTime },
  ];

  const cautionList = [
    '오늘 하루만 사용 가능한 주차권입니다.',
    '출차 후 재입차에는 사용 불가합니다.',
    '이용 시간 외 주차 시 주차권 적용은 취소되며, 이용시간 전체에 대하여 전액 현장 요금으로 부과됩니다.',
    '저공해차, 경차 등 별도 할인 없습니다.',
  ];

  useEffect(() => {
    const fetchTerraceTowerProduct = async () => {
      await terraceTowerProductStore.fetchTerraceTowerProduct(storedProductUID);
      console.log(storedProductUID);
    };
    fetchTerraceTowerProduct().catch(error => {
      console.log('주문정보 불러오는 중 에러', error);
    });
  }, []);

  const handleAgreeClick = () => {
    const newCheckState = !isChecked;
    setIsChecked(newCheckState);
    sessionStorage.setItem('isPolicyPersonalCheck', newCheckState.toString());
  };

  const onSubmit = async () => {
    if (isSubmitting) return;

    setIsSubmitting(true);

    try {
      const isValidCarNumber = /^([가-힣]{2}[0-9]{1,3}|[0-9]{2,3})[가-힣]{1}[0-9]{4}$/.test(
        carNumber,
      );

      if (isChecked && isValidCarNumber) {
        setIsCarNumberCheckBottomModalVisible(true);
      }
    } finally {
      setIsSubmitting(false); // 처리 완료 후 초기화
    }
  };

  useEffect(() => {
    const isValidCarNumber = /^([가-힣]{2}[0-9]{1,3}|[0-9]{2,3})[가-힣]{1}[0-9]{4}$/.test(
      carNumber,
    );

    if (carNumber && !isValidCarNumber) {
      setError('carNumber', { type: 'manual', message: '차량번호 형식이 올바르지 않아요.' });
    } else if (carNumber && isValidCarNumber) {
      clearErrors('carNumber');
    } else {
      clearErrors('carNumber');
    }
  }, [carNumber, setError, clearErrors]);

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  useEffect(() => {
    const fetchUserInfo = async () => {
      // 모든 값이 유효한지 확인
      if (!memberID || !memberPW || !loginType) {
        console.warn('Required values are missing:', {
          memberID,
          memberPW,
          loginType,
        });
        return; // 값이 없으면 실행 중단
      }

      const requestBody: IUserInfo = {
        memberID,
        memberPW: memberEncPw,
        loginType,
      };

      try {
        await userInfoStore.fetchUserInfoSearch(requestBody, iv, salt);

        if (userInfoStore.memberUID != null) {
          setMemberUID(userInfoStore.memberUID);
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchUserInfo().catch(error => console.error('Error in fetchData:', error));
  }, [memberID, memberPW, loginType]);

  const handlePayment = async () => {
    if (!window.innopay) {
      console.error('Innopay script is not loaded.');
      return;
    }

    if (memberUID == null) {
      console.error('memberUID is null or invalid.');
      return;
    }

    try {
      const res = await terraceTowerProductOrderStore.fetchTerraceTowerProductOrder(
        storedProductUID,
        memberUID,
        carNumber,
      );

      if (res) {
        const params = {
          PayMethod: 'CARD',
          MID: res?.sejongData?.mid,
          MerchantKey: res?.sejongData?.merchantKey,
          GoodsName: '주차장만드는사람들',
          Amt: res?.sejongData?.amt,
          BuyerName: '테스트사용자',
          BuyerTel: '01012345678',
          BuyerEmail: 'ars@zoomansa.com',
          ResultYN: 'N',
          Moid: res?.sejongData?.moid,
          ReturnURL: TERRACE_PAYMENT_RETURN_URL,
        };
        sessionStorage.setItem('getProductOrder', JSON.stringify(res));

        setIsCarNumberCheckBottomModalVisible(false);

        window.innopay.goPay(params);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <MainContainerNoMargin>
      <Title
        title="결제"
        leftArrowButtonVisible={true}
        leftOnClick={() => {
          navigate(-1);
        }}
      />
      <TerraceTowerContainer>
        <CautionTitleContainer>
          <ExclamationIcon src={icExclamationBlack} alt="검정 배경 느낌표" />
          <Caption2 weight={'regular'} color={'gray08'}>
            해당 주차권은 카드 결제만 가능하며, 구매한 날짜에만 사용 가능합니다.
          </Caption2>
        </CautionTitleContainer>
        <form>
          <CarNumberInputContainer isFocus={isCarInputFocus}>
            <CarNumberText>차량번호</CarNumberText>
            <Controller
              name="carNumber"
              control={control}
              render={({ field }) => (
                <CarNumberInput
                  type="text"
                  id="carNumber"
                  placeholder="예시 : 12가3456, 서울12가3456"
                  {...field}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      if (carNumberInputRef.current) {
                        carNumberInputRef.current.blur();
                      }
                    }
                  }}
                  ref={carNumberInputRef}
                  onChange={e => setValue('carNumber', e.target.value)}
                  hasText={hasText}
                  isFocus={isCarInputFocus}
                />
              )}
            />
          </CarNumberInputContainer>
          <CarNumberErrorContainer>
            {carNumber && errors.carNumber ? (
              <Caption3 weight={'regular'} color={'red'}>
                {errors.carNumber.message}
              </Caption3>
            ) : (
              carNumber &&
              !errors.carNumber && (
                <Caption3 weight={'regular'} color={'blue'}>
                  올바른 차량번호 형식이에요.
                </Caption3>
              )
            )}
          </CarNumberErrorContainer>
        </form>
      </TerraceTowerContainer>
      <ViewDivide1 />
      <TerraceTowerContainer>
        <PaymentInfoContentContainer>
          <SubHeadline weight={'semibold'} color={'black3'}>
            주차권 정보
          </SubHeadline>
        </PaymentInfoContentContainer>

        {paymentInfo.map((info, index) => (
          <PaymentItemContainer key={index} isFirst={index === 0}>
            <Caption1 weight={'regular'} color={'gray06'}>
              {info.label}
            </Caption1>
            <PaymentValue isUsePayment={index === Object.keys(info).length}>
              {info.value}
            </PaymentValue>
          </PaymentItemContainer>
        ))}
      </TerraceTowerContainer>
      <ViewDivide1 />
      <TerraceTowerContainer>
        <PaymentInfoContentContainer>
          <SubHeadline weight={'semibold'} color={'black3'}>
            결제 정보
          </SubHeadline>
          <Body weight={'bold'} color={'black3'}>
            {salePrice}
          </Body>
        </PaymentInfoContentContainer>
      </TerraceTowerContainer>
      <ViewDivide1 />
      <TerraceTowerContainer>
        <PaymentInfoContentContainer>
          <SubHeadline weight={'semibold'} color={'black3'}>
            유의해 주세요!
          </SubHeadline>
        </PaymentInfoContentContainer>
        <Caption2 weight={'regular'} color={'blue'} style={{ marginTop: 10 }}>
          미숙지로 인한 피해는 주만사에서 책임지지 않습니다.
        </Caption2>
        <ListCheckContainer>
          {cautionList.map((cautionItem, index) => (
            <ListItemContainer key={index} hasMargin={index !== 0}>
              <ListItemCheckIc src={icCheckBlue} alt="파란색 체크 아이콘" />
              <Caption1 weight={'regular'} color={'gray09'} style={{ marginLeft: 10 }}>
                {cautionItem}
              </Caption1>
            </ListItemContainer>
          ))}
        </ListCheckContainer>
        <ViewDivide2 />
      </TerraceTowerContainer>
      <PolicyPersonalContainer>
        <PolicyPersonalCheckBoxContainer onClick={handleAgreeClick}>
          <StyledCheckbox type="checkbox" checked={isChecked} readOnly />
          <RequiredText>(필수)</RequiredText>
          <Caption2 weight={'regular'} color={'gray09'}>
            서비스 이용약관 및 개인정보 수집 동의
          </Caption2>
        </PolicyPersonalCheckBoxContainer>
        <PolicyPersonalClickSpan
          onClick={() => {
            navigate('/policyPersonal');
          }}
        >
          보기
        </PolicyPersonalClickSpan>
      </PolicyPersonalContainer>
      <BottomButtonContainer onClick={onSubmit}>
        <AgreeButton
          state={
            isChecked && /^([가-힣]{2}[0-9]{1,3}|[0-9]{2,3})[가-힣]{1}[0-9]{4}$/.test(carNumber)
              ? 'agreeCheck'
              : 'notCheck'
          }
        >
          {productName} 결제하기
        </AgreeButton>
      </BottomButtonContainer>
      <CarNumberCheckBottomModal
        isVisible={isCarNumberCheckBottomModalVisible}
        onClose={() => setIsCarNumberCheckBottomModalVisible(false)}
        carNumber={carNumber}
        modifyCarNumber={() => {
          if (carNumberInputRef.current) {
            carNumberInputRef.current.focus();
          }
        }}
        handlePayment={handlePayment}
      />
    </MainContainerNoMargin>
  );
});

export default TerraceTowerPaymentPage;
