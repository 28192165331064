import React, { useEffect, useState } from 'react';
import Title from 'components/atoms/Title/PageTitle';
import { useNavigate } from 'react-router-dom';
import { isIOS } from 'react-device-detect';

import icSearch from 'assets/ic/ic_setting.svg';
import AddressSearchModal from 'components/atoms/Modal/AddressSearchModal';
import {
  Container,
  PhoneNumber,
  PhoneNumberContainer,
  PhoneNumberHeader,
  SearchContainer,
  SearchIcon,
  WrapMap,
} from './styles';
import { MainContainerNoMargin } from 'components/styles';
import { Caption1, Caption3, SubHeadline } from 'styles/typography';
import Map from 'components/atoms/Map';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import PopUpModal from 'components/atoms/Modal/PopupModal';
import { useFetchUserInfo } from 'hooks/useFetchUserInfo';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import { shareStore } from 'stores/store/Share/shareStore';
import { JSP_TEST_URL } from 'constants/baseConstants';

const ShareRequestMingan: React.FC = () => {
  const navigate = useNavigate();
  const { fetchUserInfo } = useFetchUserInfo();

  const [isModalVisible, setModalVisible] = useState(false);
  const [addressText, setAddressText] = useState<string>(''); // 추가된 상태
  const [isPopup, setIsPopup] = useState(false);

  const { phoneNumber } = userInfoStore;

  const popupText = addressText
    ? {
        title: '상담 신청이 완료되었어요.',
        description: '영업시간(평일 09시~18시) 내 \n' + '상담드리겠습니다.',
      }
    : {
        title: '신청할 주차장 위치를 선택해 주세요.',
        description: '',
      };

  const formattedNumber = phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  useEffect(() => {
    // setTimeout(() => {
    //   window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth',
    //   });
    // }, 0);
    //
    // // 페이지가 렌더링될 때 스크롤 막기
    // document.body.style.overflow = 'hidden';
    //
    // // 컴포넌트가 언마운트되거나 리렌더링될 때 스크롤 복원
    // return () => {
    //   document.body.style.overflow = 'auto'; // 다시 원래대로
    // };
  }, []);

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const openPopup = () => {
    setIsPopup(true);
  };

  const closePopup = () => {
    setIsPopup(false);

    if (addressText) {
      window.location.href = JSP_TEST_URL;
    }
  };

  const handleSelectAddress = (address: string) => {
    setAddressText(address); // 주소 설정
  };
  return (
    <MainContainerNoMargin style={{ height: isIOS ? 'calc(100vh - 30px)' : 'calc(100vh)' }}>
      <Title
        title="부설 주차장 신청"
        leftArrowButtonVisible
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      <Container>
        <PhoneNumberContainer>
          <PhoneNumberHeader>
            <Caption1 weight={'regular'} color={'gray06'}>
              휴대 전화번호
            </Caption1>
            <PhoneNumber>{formattedNumber}</PhoneNumber>
          </PhoneNumberHeader>
          <Caption3
            weight={'regular'}
            color={'gray06'}
            style={{ textAlign: 'right', marginTop: 6 }}
          >
            위 연락처로 신청 관련 안내가 진행됩니다.
          </Caption3>
          <SearchContainer onClick={openModal}>
            {addressText ? (
              <span>{addressText}</span>
            ) : (
              <SubHeadline weight={'bold'} color={'gray06'}>
                주소를 검색하세요
              </SubHeadline>
            )}
            {/* 주소 표시 */}
            <SearchIcon src={icSearch} alt={'검색아이콘'} />
          </SearchContainer>
        </PhoneNumberContainer>

        <WrapMap>
          <Map height={`100%`} address={addressText} />
        </WrapMap>

        <Button
          style={{
            position: 'fixed',
            bottom: '30px',
            left: 20,
            height: 46,
            width: 'calc(100% - 40px)',
            zIndex: 1000,
          }}
          backgroundColor={colors.primary}
          onClick={openPopup}
        >
          <SubHeadline weight={'regular'} color={'white'}>
            상담 신청
          </SubHeadline>
        </Button>
        <AddressSearchModal
          isVisible={isModalVisible}
          onClose={closeModal}
          onSelectAddress={handleSelectAddress} // 콜백 함수 전달
        />
        <PopUpModal
          isVisible={isPopup}
          onClose={closePopup}
          title1={popupText.title}
          description={popupText.description}
          leftClick={async () => {
            closePopup();
            if (addressText) {
              await shareStore.fetchShareSlot(phoneNumber, addressText);
              // navigate('/shareHistory');
            }
          }}
          leftText={'확인'}
        />
      </Container>
    </MainContainerNoMargin>
  );
};

export default ShareRequestMingan;
