import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  // display: 'flex',
  // flexDirection: 'column',
  position: 'relative',
  margin: '0px 20px',
  height: '100%',
  paddingBottom: 240,
});

export const WrapPhoneInfo = styled('div', {
  width: 'calc(100%)',
  marginTop: 20,
});

export const WrapInput = styled('div', {
  width: 'calc(100%)',
  marginTop: 40,
});

// 전화번호와 레이블을 가로로 정렬
export const PhoneNumberHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: '#868686',
});

// 전화번호 오른쪽 정렬
export const PhoneNumber = styled('div', {
  fontSize: '16px',
  textAlign: 'right',
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  fontFamily: 'Pretendard',
  textSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  color: '#8B63BB',
});

export const GeojuInput = styled('input', {
  fontWeight: 400,
  fontFamily: 'Pretendard',
  fontSize: '14px',
  lineHeight: '400',
  outline: 'none',
  width: '100%',
  border: 'none',
  background: 'transparent',
  height: '45px',
  '&::placeholder': {
    color: '#868686',
  },
});

export const WrapGeojuInput = styled('div', {
  width: 'calc(100% - 24px)',
  height: '45px',
  borderRadius: '5px',
  background: colors.gray01,
  marginTop: '4px',
  display: 'flex',
  padding: '0px 12px',
  alignItems: 'center',
});

export const StyledCheckbox = styled('div', {
  width: '20px',
  height: '20px',
  border: '2px solid #8B63BB',
  borderRadius: '4px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '8px', // 체크박스와 레이블 간격
  transition: 'background-color 0.2s ease, border-color 0.2s ease',

  variants: {
    checked: {
      true: {
        backgroundColor: '#8B63BB',
        border: '2px solid #8B63BB',
      },
    },
  },
});

export const CheckBoxLabel = styled('label', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '20px',
});

export const BottomContainer = styled('div', {
  backgroundColor: '#fff',
  bottom: 40,
  position: 'absolute',
  width: 'calc(100%)',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
});
