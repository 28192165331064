import { observer } from 'mobx-react';

import {
  DateSpan,
  EmptyHistoryContainer,
  PointDetailContainer,
  PointInfoContainer,
  PointStatusContainer,
  PointStatusSpan,
  ViewDivideContainer,
} from './styles';
import React, { useEffect, useRef } from 'react';
import { pointExpiresStore } from 'stores/store/Point/pointExpiresStore';
import { SpinnerContainer } from '../PointEarnExpectedList/styles';
import { Spinner } from 'components/atoms/LoadingSpinner/LoadingSpinnerImage/styles';
import { LINK_UID } from 'constants/userInfoConstants';
import { ICommentExpires } from 'models/Point/pointExpiresModel';
import { CommentKeySpan, CommentValue } from 'containers/point/PointHistoryBottomList/styles';

const PointLoseExpectedList = observer(() => {
  const containerRef = useRef<HTMLDivElement>(null);
  const mLinkUID = parseInt(sessionStorage.getItem(LINK_UID) || '0', 10);

  // 초기 데이터 로드
  useEffect(() => {
    const fetchInitExpireList = async () => {
      try {
        await pointExpiresStore.fetchPointExpires(mLinkUID, false);
      } catch (error) {
        console.error('소멸 예정 초기화 로드 에러:', error);
      }
      fetchInitExpireList().catch(error => {
        console.log('소멸 예정 포인트 초기화 로드 에러', error);
      });
    };
  }, []);

  // 스크롤시 하단 감지 다음 데이터 로드
  const handleScroll = async () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

    if (
      scrollTop + clientHeight >= scrollHeight - 5 &&
      !pointExpiresStore.isLoading &&
      !pointExpiresStore.isEnd
    ) {
      try {
        await pointExpiresStore.fetchPointExpires(mLinkUID, true);
      } catch (error) {
        console.error('데이터 로드 에러:', error);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div ref={containerRef}>
      {pointExpiresStore.isLoading && !pointExpiresStore.isScrollLoading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : pointExpiresStore.pointExpiresData.length === 0 ? (
        <EmptyHistoryContainer>소멸 예정 내역이 없어요.</EmptyHistoryContainer>
      ) : (
        pointExpiresStore.pointExpiresData.map((pointExpires, index) => {
          let parsedCommentExpires: ICommentExpires = {};

          if (typeof pointExpires.comment === 'string') {
            try {
              parsedCommentExpires = JSON.parse(pointExpires.comment);
            } catch (error) {
              console.error('Failed to parse comment:', error);
            }
          } else {
            console.error('포인트 소멸 예정 객체 스트링 변환 실패:', pointExpires.comment);
          }

          const comments = Object.entries(parsedCommentExpires).map(([key, value]) => (
            <div key={key}>
              <CommentKeySpan>{key}</CommentKeySpan>
              <CommentValue>{value}</CommentValue>
            </div>
          ));

          return (
            <PointInfoContainer key={index}>
              <DateSpan>{pointExpires.confirmed_at}</DateSpan>
              <PointStatusContainer>
                <PointStatusSpan>{pointExpires.status}</PointStatusSpan>
              </PointStatusContainer>
              <PointDetailContainer>{comments}</PointDetailContainer>
              <ViewDivideContainer />
            </PointInfoContainer>
          );
        })
      )}
    </div>
  );
});

export default PointLoseExpectedList;
