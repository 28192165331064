import { styled } from '@stitches/react';

export const Container = styled('div', {});

export const ReductionTitleContainer = styled('div', {
  color: '#333',
  fontFamily: 'Pretendard',
  fontSize: '22px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '30px',
  marginTop: 20,
});

export const PhotoContainer = styled('div', {
  width: '100%',
  height: '60px',
  flexShrink: 0,
  borderRadius: '10px',
  border: '2px dashed #CCC',
  opacity: 1,
  backgroundColor: '#fff',
  marginTop: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
});

export const ReductionChoiceContentsContainer = styled('div', {
  marginTop: '13px',
  marginLeft: '13px',
});

export const ReductionTypeText = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '22px',
  color: '#6B6B6B',
});

export const ReductionTypeChoiceContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const ReductionTypeChoiceText = styled(
  'span',
  {
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    marginTop: '5px',
    color: 'inherit',
  },
  {
    variants: {
      selected: {
        true: {
          color: '#000000', // Selected state color
        },
        false: {
          color: '#CCC', // Default color
        },
      },
    },
    defaultVariants: {
      selected: false,
    },
  },
);

export const BottomContainer = styled('div', {
  flex: '1',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
  bottom: 40,
  width: 'calc(100%)',
});

export const ReductionTypeArrowImg = styled('img', {
  marginRight: '15px',
  marginTop: '5px',
});

export const ReductionNotifyButtonContainer = styled('div', {});

export const ReductionNotifyTitleContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px',
});

export const ReductionNotifyTitleText = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontWeight: 700,
  color: '#8B63BB',
  fontStyle: 'normal',
  lineHeight: '20px',
});

export const ViewDivideContainer = styled('div', {
  width: '100%',
  height: '1px',
  backgroundColor: '#CCC',
  marginBottom: '13px',
});

export const ReductionNotifyNumberText = styled('span', {
  marginRight: '8px',
  color: '#8B63BB',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '18px',
});

export const ReductionNotifyNumberTitleText = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
  color: '#6B6B6B',
});

export const SeongdongGuCheckContainer = styled('div', {
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '16px',
  position: 'relative',
  paddingLeft: '26px',
});

export const Dot = styled('span', {
  color: '#868686',
  fontSize: '20px',
  marginRight: '8px',
});
