import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, WrapMap } from './styles';
import { Caption1, Caption2 } from 'styles/typography';
import Row from 'components/atoms/Row';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';

const GeojuUseInfo = ({ type = '' }: { type?: string }) => {
  const navigate = useNavigate();

  const contents = [
    {
      title: '차량번호',
      desc: '123가5678',
    },
    {
      title: '주차 시작 시간',
      desc: '2023-07-13 14:52:11',
    },
    {
      title: '주차 종료 시간',
      desc: '2023-07-13 14:52:11',
    },
    {
      title: '이용 시간',
      desc: '59분',
    },
    {
      title: '주차요금',
      desc: '1,200원',
    },
  ];

  return (
    <Container>
      <Caption1 weight={'bold'} color={'gray07'} style={{ marginBottom: 10 }}>
        이용 정보
      </Caption1>

      {contents.map((el, index) => {
        return (
          <Row key={index} justifyContent={'space-between'} style={{ marginTop: 10 }}>
            <Caption1 weight={'bold'} color={'gray05'}>
              {el.title}
            </Caption1>

            <Row>
              <Caption1 weight={'regular'} color={'black3'}>
                {el.desc}
              </Caption1>
            </Row>
          </Row>
        );
      })}
    </Container>
  );
};

export default GeojuUseInfo;
