import { styled } from '@stitches/react';

export const Modal = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  width: 'calc(100%)',
  height: '100%',
  // background: '#fff',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  // transition: 'transform 0.3s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  transform: 'translateY(100%)',
  zIndex: 10000,
  variants: {
    isVisible: {
      true: {
        transform: 'translateY(0)'
      },
      false: {
        transform: 'translateY(100%)',
      },
    },
  },
  alignItems: 'center'
});

export const Content = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100vh',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ImgClose = styled('img', {
  position: 'fixed',
  top: '20px',
  right: '20px',
  width: '20px',
  height: '20px',
  border: 'none',
  padding: '6px 10px',
  cursor: 'pointer',
  zIndex: '10',

});


export const Image = styled('img', {

  position: 'relative'
})
