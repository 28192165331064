// App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastProvider } from 'components/atoms/Toast/ToastProvider';
import { Provider } from 'mobx-react';

import Login from './pages/Login';
import ZMSLogin from './pages/ZMSLogin';
import AgreeTerms from './pages/AgreeTerms';
import Home from './pages/Home';
import SearchMyInfo from './pages/SearchMyInfo';
import NotificationCenter from './pages/NotificationCenter/NotificationCenterMain';
import ParkingUserList from './pages/ParkingUserList';
import ShareRequestMingan from './pages/Share/ShareRequestMingan';
import ParkingPhotoUpload from './pages/ParkingPhotoUpload';
import SettingMainPage from './pages/Setting/SettingMainPage';
import ShareRequestGeoju from './pages/Share/ShareRequestGeoju';
import MyPage from './pages/MyPage';
import PolicyServicePage from './pages/Policy/PolicyService';
import PolicyPersonalPage from './pages/Policy/PolicyPersonal';
import PolicyLocationPage from './pages/Policy/PolicyLocation';
import PolicyMarketingPage from './pages/Policy/PolicyMarketing';
import CarRegistration from './pages/Car/CarRegistration';
import ReductionCategory from './pages/Reduction/ReductionCategory';
import ReductionFileAttach from './pages/Reduction/ReductionFileAttach';
import ReductionApplySuccess from './pages/Reduction/ReductionApplySuccess';
import ReductionRegistrationSuccess from './pages/Reduction/ReductionRegistrationSuccess';
import ReductionRegistrationFail from './pages/Reduction/ReductionRegistrationFail';
import CarInfo from './pages/Car/CarInfo';
import CarInfoEdit from './pages/Car/CareInfoEdit';
import ParkingPhotoUploadDetail from './pages/ParkingPhotoUpload/ParkingPhotoUploadDetail';
import ParkingPhotoUploadComplete from './pages/ParkingPhotoUpload/ParkingPhotoUploadComplete';
import NotificationCenterGeneralMessageDetail from './pages/NotificationCenter/NotificationCenterGeneralMessageDetail';
import NotificationCenterImportantMessageDetail from './pages/NotificationCenter/NotificationCenterImportantMessageDetail';
import DeleteAccount from './pages/DeleteAccount/DeleteAccountMain';
import DeleteAccountNotification from './pages/DeleteAccount/DeleteAccountNotification';
import DeleteAccountReason from './pages/DeleteAccount/DeleteAccountReason';
import SignUpCert from './pages/SignUp/SignUpCert';
import SignUpCreateUserInfo from './pages/SignUp/SignUpCreateUserInfo';
import PaymentUserInfoCheck from './pages/Payment/PaymentUserInfoCheck';
import PointHistory from './pages/Point/PointHistory';
import PointAccountRegi from './pages/Point/PointAccountRegi';
import PointWithdrawalPassword from './pages/Point/PointWithdrawal/PointWithdrawalPassword';
import PointEarnExpected from './pages/Point/PointEarnExpected';
import PointLoseExpected from './pages/Point/PointLoseExpected';
import PointWithdrawalIdentityCert from './pages/Point/PointWithdrawal/PointWithdrawalIdentityCert';
import PointWithdrawalMoney from './pages/Point/PointWithdrawal/PointWithdrawalMoney';
import PointWithdrawalFinalCheck from './pages/Point/PointWithdrawalFinal/PointWithdrawalFinalCheck';
import PointWithdrawalFinalSuccess from './pages/Point/PointWithdrawalFinal/PointWithdrawalFinalSuccess';
import PointMallBrand from './pages/Point/PointMall/PointMallBrand';
import PointWithdrawalPasswordSetting from './pages/Point/PointWithdrawal/PointWithdrawalPasswordSetting';
import PointMallBrandDetail from 'pages/Point/PointMall/PointMallBrandDetail';
import PointMallStorageBox from 'pages/Point/PointMall/PointMallStorageBox';
import PointWithdrawalPasswordSettingRe from 'pages/Point/PointWithdrawal/PointWithdrawalPasswordSettingRe';
import { toggleStore } from 'stores/toggle/toggleStore';
import PaymentVirtualAccount from './pages/Payment/PaymentVirtualAccount';
import MonthParking from './pages/MonthParking';
import TerraceTowerDetailPage from './pages/TerraceTower/TerraceTowerDetail';
import TerraceTowerPaymentCancelRequestPage from './pages/TerraceTower/TerraceTowerPaymentCancelRequest';
import TerraceTowerReservationCautionPage from './pages/TerraceTower/TerraceTowerReservationCaution';
import TerraceTowerPaymentCancelPage from './pages/TerraceTower/TerraceTowerPaymentCancel';
import TerraceTowerPaymentPage from './pages/TerraceTower/TerraceTowerPayment';
import TerraceTowerPaymentResultPage from './pages/ExternalPayment';
import PolicyPersonalSupplyPage from 'pages/Policy/PolicyPersonalSupply';

import ShareHistoryPage from 'pages/Share/ShareHistoryPage';
import MinganProductDetailPage from 'pages/Share/MinganProductDetailPage';
import ResidentMyParkingPage from 'pages/Share/ResidentMyParkingPage';
import ResidentShareManagementPage from 'pages/Share/ResidentShareManagementPage';
import MinganMyParkingPage from 'pages/Share/MinganMyParkingPage';
import OperationInfoPage from 'pages/Share/OperationInfoPage';
import SignUpRegionSettingPage from 'pages/SignUp/SignUpRegionSettingPage';
import EmptyParkingArea from 'pages/EmptyParkingArea';
import EmptyParkingAreaPage from 'pages/EmptyParkingArea';
import IdentityVerificationPage from 'pages/MyPage/IdentityVerification';
import MyParkingMainPage from 'pages/MyParking/MyPakringMain';
import MyParkingPastHistoryPage from 'pages/MyParking/MyParkingPastHistory';

import GeojuParkingUsingPage from 'pages/GeojuParking/GeojuParkingUsingPage';
import GeojuParkingStopPage from 'pages/GeojuParking/GeojuParkingStopPage';
import GeojuParkingPaymentPage from 'pages/GeojuParking/GeojuParkingPaymentPage';
import GeojuParkingPaymentSuccessPage from 'pages/GeojuParking/GeojuParkingPaymentSuccessPage';
import DesiredAreaManagementPage from 'pages/MonthlyParkingDesired/DesiredAreaManagementPage';
import AreaSettingPage from 'pages/MonthlyParkingDesired/AreaSettingPage';
import SignUpMonthlyDesiredOnboardingPage from 'pages/SignUp/SignUpMonthlyDesiredOnboardingPage';

function App() {
  return (
    <ToastProvider>
      <Provider toggleStore={toggleStore}>
        <Router>
          <Routes>
            // 포인트
            <Route path="/pointHistory" element={<PointHistory />} />
            <Route path="/pointAccountRegi" element={<PointAccountRegi />} />
            <Route path="/pointWithdrawal" element={<PointWithdrawalPassword />} />
            <Route path="/pointEarnExpected" element={<PointEarnExpected />} />
            <Route path="/pointLoseExpected" element={<PointLoseExpected />} />
            <Route path="/pointWithdrawalIdentityCert" element={<PointWithdrawalIdentityCert />} />
            <Route path="/pointWithdrawalPassword" element={<PointWithdrawalPassword />} />
            <Route
              path="/pointWithdrawalPasswordSetting"
              element={<PointWithdrawalPasswordSetting />}
            />
            <Route
              path="/pointWithdrawalPasswordSettingRe"
              element={<PointWithdrawalPasswordSettingRe />}
            />
            <Route path="/pointWithdrawalMoney" element={<PointWithdrawalMoney />} />
            <Route path="/pointWithdrawalFinalCheck" element={<PointWithdrawalFinalCheck />} />
            <Route path="/pointWithdrawalFinalSuccess" element={<PointWithdrawalFinalSuccess />} />
            <Route path="/pointMallBrand" element={<PointMallBrand />} />
            <Route path="/pointMallBrandDetail/:brandName" element={<PointMallBrandDetail />} />
            <Route path="/pointMallStorageBox" element={<PointMallStorageBox />} />
            <Route path="/zmsLogin" element={<ZMSLogin />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/agreeTerms" element={<AgreeTerms />} />
            <Route path="/signUpCert" element={<SignUpCert />} />
            <Route path="/signUpCreateUserInfo" element={<SignUpCreateUserInfo />} />
            <Route path="/signUpRegionSetting" element={<SignUpRegionSettingPage />} />
            <Route path="/home" element={<Home />} />
            <Route path="/searchMyInfo" element={<SearchMyInfo />} />
            <Route path="/myParkingMain" element={<MyParkingMainPage />} />
            <Route path="/myParkingPastHistory" element={<MyParkingPastHistoryPage />} />
            <Route path="/shareRequestMingan" element={<ShareRequestMingan />} />
            <Route path="/shareRequestGeoju" element={<ShareRequestGeoju />} />
            <Route path="/notificationCenter" element={<NotificationCenter />} />
            <Route
              path="/notificationCenterGeneralMessageDetail"
              element={<NotificationCenterGeneralMessageDetail />}
            />
            <Route
              path="/notificationCenterImportantMessageDetail"
              element={<NotificationCenterImportantMessageDetail />}
            />
            <Route path="/settingMain" element={<SettingMainPage />} />
            <Route path="/myPage" element={<MyPage />} />
            <Route path="/identityVerification" element={<IdentityVerificationPage />} />
            <Route path="/policyPersonalSupply" element={<PolicyPersonalSupplyPage />} />
            <Route path="/policyService" element={<PolicyServicePage />} />
            <Route path="/policyPersonal" element={<PolicyPersonalPage />} />
            <Route path="/policyLocation" element={<PolicyLocationPage />} />
            <Route path="/policyMarketing" element={<PolicyMarketingPage />} />
            <Route path="/deleteAccount" element={<DeleteAccount />} />
            <Route path="/deleteAccountNotification" element={<DeleteAccountNotification />} />
            <Route path="/deleteAccountReason" element={<DeleteAccountReason />} />
            <Route path="/carRegistration" element={<CarRegistration />} />
            <Route path="/carInfo" element={<CarInfo />} />
            <Route path="/carInfoEdit" element={<CarInfoEdit />} />
            <Route path="/reductionCategory" element={<ReductionCategory />} />
            <Route path="/reductionFileAttach" element={<ReductionFileAttach />} />
            <Route path="/reductionApplySuccess" element={<ReductionApplySuccess />} />
            <Route
              path="/reductionRegistrationSuccess"
              element={<ReductionRegistrationSuccess />}
            />
            <Route path="/reductionRegistrationFail" element={<ReductionRegistrationFail />} />
            <Route path="/parkingPhotoUpload" element={<ParkingPhotoUpload />} />
            <Route path="/parkingPhotoUploadDetail" element={<ParkingPhotoUploadDetail />} />
            <Route path="/parkingPhotoUploadComplete" element={<ParkingPhotoUploadComplete />} />
            <Route path="/shareHistory" element={<ShareHistoryPage />} />
            <Route path="/minganMyParking" element={<MinganMyParkingPage />} />
            <Route path="/minganProductDetail" element={<MinganProductDetailPage />} />
            <Route path="/residentMyParking" element={<ResidentMyParkingPage />} />
            <Route path="/shareRequestMingan" element={<ShareRequestMingan />} />
            <Route path="/shareRequestGeoju" element={<ShareRequestGeoju />} />
            <Route path="/residentShareManagement" element={<ResidentShareManagementPage />} />
            <Route path="/operatingInfo" element={<OperationInfoPage />} />
            <Route path="/notificationCenter" element={<NotificationCenter />} />
            <Route
              path="/notificationCenterGeneralMessageDetail"
              element={<NotificationCenterGeneralMessageDetail />}
            />
            <Route
              path="/notificationCenterImportantMessageDetail"
              element={<NotificationCenterImportantMessageDetail />}
            />
            // 월주차
            <Route path="/monthParking" element={<MonthParking />} />
            // 결제
            <Route path="/paymentUserInfoCheck" element={<PaymentUserInfoCheck />} />
            <Route path="/paymentVirtualAccount" element={<PaymentVirtualAccount />} />
            // 테라스타워 상세
            <Route path="/terraceTowerDetail" element={<TerraceTowerDetailPage />} />
            // 테라스타워 결제 취소 요청
            <Route
              path="/terraceTowerPaymentCancelRequest"
              element={<TerraceTowerPaymentCancelRequestPage />}
            />
            // 테라스타워 결제 취소
            <Route path="/terraceTowerPaymentCancel" element={<TerraceTowerPaymentCancelPage />} />
            // 테라스타워 예약 전 주의
            <Route
              path="/terraceTowerReservationCaution"
              element={<TerraceTowerReservationCautionPage />}
            />
            // 테라스타워 결제
            <Route path="/terraceTowerPayment" element={<TerraceTowerPaymentPage />} />
            // 세종결제
            <Route path="/terraceTowerPaymentResult" element={<TerraceTowerPaymentResultPage />} />
            // 빈자리 신청 화면
            <Route path="/emptyParkingArea" element={<EmptyParkingAreaPage />} />
            //거주자 주차장 이용
            <Route path="/geojuParkingUsing" element={<GeojuParkingUsingPage />} />
            //거주자 주차장 이용 종료
            <Route path="/geojuParkingStop" element={<GeojuParkingStopPage />} />
            //거주자 주차장 이용 결제
            <Route path="/geojuParkingPayment" element={<GeojuParkingPaymentPage />} />
            //거주자 주차장 이용 결제 성공
            <Route
              path="/geojuParkingPaymentSuccess"
              element={<GeojuParkingPaymentSuccessPage />}
            />
            <Route path="/desiredAreaManagement" element={<DesiredAreaManagementPage />} />
            <Route path="/areaSetting" element={<AreaSettingPage />} />
            <Route
              path="/signUpMonthlyDesiredOnboarding"
              element={<SignUpMonthlyDesiredOnboardingPage />}
            />
          </Routes>
        </Router>
      </Provider>
    </ToastProvider>
  );
}

export default App;
