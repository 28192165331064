import React, { useState } from 'react';
import { RegionSelectModalContainer } from './styles';
import { IShareAreaListData } from 'models/Share/shareAreaList';
import { colors } from 'styles/colors';

interface BottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSelectRegion: (region: string, index: number) => void;
  regions: IShareAreaListData[];
}

const RegionSelectBottomModal: React.FC<BottomModalProps> = ({
  isVisible,
  onClose,
  onSelectRegion,
  regions,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const handleOverlayClick = () => {
    onClose();
  };

  const handleItemClick = (region: IShareAreaListData, index: number) => {
    setSelectedIndex(index); // 선택된 인덱스 업데이트
    onSelectRegion(region.areasggNm, index); // 부모 컴포넌트에 선택한 지역 전달
  };

  const handleModalContentClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  if (!isVisible) return null;

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 9999,
        }}
        onClick={handleOverlayClick}
      />
      <RegionSelectModalContainer isVisible={isVisible} onClick={handleOverlayClick}>
        <div
          onClick={handleModalContentClick}
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: 600,
            fontStyle: 'normal',
            lineHeight: '20px',
            color: colors.black1,
          }}
        >
          <span>지역 선택</span>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '0px',
              boxSizing: 'border-box',
              marginTop: '15px',
              fontFamily: 'Pretendard',
              fontSize: '17px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '22px',
              justifyContent: 'space-between',
            }}
          >
            {regions.map((region, index) => (
              <div
                key={index}
                style={{
                  flex: '1 0 calc(33.333% - 8px)',
                  padding: '15px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  userSelect: 'none',
                  boxSizing: 'border-box',
                  borderRadius: 10,
                  color: selectedIndex === index ? '#8B63BB' : '#6B6B6B',
                  backgroundColor: selectedIndex === index ? '#F5F5FF' : '#fff', // 선택된 아이템은 활성화 색상
                  // border: selectedIndex === index ? '2px solid #8B63BB' : '1px solid #ccc', // 활성화 테두리
                }}
                onClick={() => handleItemClick(region, index)}
              >
                {region.areasggNm}
              </div>
            ))}
          </div>
          <button
            style={{
              marginTop: '15px',
              height: '46px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              border: 'none',
              borderRadius: '4px',
              padding: '12px 0px',
              cursor: 'pointer',
              fontSize: 16,
              fontWeight: 700,
              color: selectedIndex !== null ? '#fff' : '#8B63BB',
              backgroundColor: selectedIndex !== null ? '#8B63BB' : '#F2F2F2',
            }}
            onClick={() => handleOverlayClick()}
            disabled={selectedIndex === null}
          >
            확인
          </button>
        </div>
      </RegionSelectModalContainer>
    </>
  );
};

export default RegionSelectBottomModal;
