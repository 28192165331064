import React, { CSSProperties, ReactNode } from 'react';
import { TitleContainer } from './styles';
import icArrowLeftPurple from '../../../../assets/ic/ic_arrow_left_purple.svg';
import icArrowLeftWhite from '../../../../assets/ic/ic_arrow_left_white.svg';
import { isIOS } from 'react-device-detect';

import Spacer from "../../Spacer/Spacer";
import { colors } from 'styles/colors';

interface IProps {
  leftOnClick?: () => void;
  rightOnClick?: () => void;
  title?: string | React.ReactNode;
  titleImage?: string | React.ReactNode;
  pageTitle?: string;
  pageTotalTitle?: string;
  style?: CSSProperties;
  leftArrowButtonVisible: boolean;
  rightTextButtonVisible: boolean;
  rightTitle?: string | React.ReactNode;
  rightTextCustomButton?: React.ReactNode;
  children?: React.ReactNode;
  background?: 'default' | 'purpleGradient' | 'gray' | 'lightGray';
  titleColor?: 'default' | 'white' | 'darkPurple';
  imgBackPurpleColor?: boolean;
  showSpacer?: boolean;
}

const TitleComponent: React.FC<IProps> = ({
                                            leftOnClick,
                                            rightOnClick,
                                            title,
                                            titleImage,
                                            pageTitle,
                                            pageTotalTitle,
                                            style,
                                            leftArrowButtonVisible,
                                            rightTextButtonVisible,
                                            rightTextCustomButton,
                                            rightTitle,
                                            children,
                                            background = 'default',
                                            titleColor = 'default',
                                            showSpacer = true,
                                            imgBackPurpleColor = true,
                                          }) => {
  // iOS-specific style adjustment
  const iosStyle: CSSProperties = isIOS
    ? {
      height: 'calc(100px + env(safe-area-inset-top))',
      paddingTop: '30px',
    }
    : {};

  const getTitleColor = () => {
    switch (titleColor) {
      case 'white':
        return colors.white;
      case 'default':
        return colors.black3;
      case 'darkPurple':
        return colors.darkPurple;
      default:
        return colors[titleColor] || colors.black3
    }
  };

  return (
    <>
      <div>
        <TitleContainer
          background={background}
          style={{ ...style, ...iosStyle }} // Combine styles
        >
          {leftArrowButtonVisible ? (
            <button
              type="button"
              style={{
                margin: 'auto 0px auto 0px',
                width: '30px',
                height: '30px',
                cursor: 'none',
                background: 'none',
                border: 'none',
                padding: 0,
              }}
              onClick={leftOnClick}
              data-id="back"
            >
              <img width={'100%'} src={imgBackPurpleColor ? icArrowLeftPurple : icArrowLeftWhite} alt="타이틀 뒤로가기 버튼" />
            </button>
          ) : (
            <div style={{
              margin: 'auto 0px auto 16px',
              width: '30px',
              height: '30px'
            }}
            />
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            {titleImage && typeof titleImage === 'string' ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                  flexDirection: 'column',
                  marginTop: '26px',
                }}
              >
                <img src={titleImage} alt="주만사 이미지" />
              </div>
            ) : (
              titleImage
            )}
            <div
              style={{
                color: getTitleColor(),
                fontFamily: 'Pretendard',
                fontStyle: 'normal',
                lineHeight: 'normal',
                fontSize: '18px',
                fontWeight: '700',
                margin: pageTitle ? '12px auto 0px 12px' : 'auto',
              }}
            >
              {title}
            </div>
            {pageTitle && (
              <div style={{ fontSize: '10px', margin: '4px auto 10px auto', letterSpacing: '2px' }}>
                {pageTitle}/{pageTotalTitle}
              </div>
            )}
          </div>
          {rightTextCustomButton || null}
          {rightTextButtonVisible ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  fontFamily: 'Pretendard',
                  fontStyle: 'normal',
                  color: colors.gray05,
                  lineHeight: 'normal',
                  margin: pageTitle ? '12px auto 0px 12px' : 'auto',
                }}
                onClick={rightOnClick}
              >
                {rightTitle}
              </div>
            </div>
          ) : (
            <div style={{
              margin: 'auto 16px auto 0px',
              width: '30px',
              height: '30px'
            }}
            />
          )}
        </TitleContainer>
        {showSpacer && <Spacer spacer={66} />}
      </div>
      {children}
    </>
  );
};

export default TitleComponent;
