import BaseService from "service/baseService";
import BaseError from "models/baseError";
import AxiosClient from "utils/axiosClient";
import ErrorHandler from "utils/error/errorHandler";
import {
  IMyParkingPastHistory,
  IMyParkingAllPastHistoryResponse,
  IMyParkingSeasonTicketHistoryResponse, IMyParkingTimeTicketHistoryResponse
} from "models/MyParking/myParkingPastHistoryModel";

interface MyParkingPastListServiceEndpoints {
  MY_PARKING_ALL_PAST_HISTORY: string
  MY_PARKING_SEASON_TICKET_PAST_HISTORY: string
  MY_PARKING_TIME_TICKET_PAST_HISTORY: string
}

class MyParkingPastHistoryService extends BaseService<MyParkingPastListServiceEndpoints> {
  apiEndpoints: MyParkingPastListServiceEndpoints = {
    MY_PARKING_ALL_PAST_HISTORY: 'getPreviousHistory',
    MY_PARKING_SEASON_TICKET_PAST_HISTORY: 'getRegularPreviousHistory',
    MY_PARKING_TIME_TICKET_PAST_HISTORY: 'getPartTimePreviousHistory'
  }

  getAllTicketPastHistory = async (
    requestBody: IMyParkingPastHistory,
    allPageNum: number
  ): Promise<IMyParkingAllPastHistoryResponse | BaseError> => {

    try {
      const response = await AxiosClient.create()
        .post()
        .data({...requestBody})
        .url(`${this.apiEndpoints.MY_PARKING_ALL_PAST_HISTORY}`)
        .params({ pageNum: allPageNum })
        .build<IMyParkingAllPastHistoryResponse>();

      if (response.data.res_code === 200) {
        return response.data
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  }

  getSeasonTicketPastHistory = async (
    requestBody: IMyParkingPastHistory,
    seasonPageNum: number
  ): Promise<IMyParkingSeasonTicketHistoryResponse | BaseError> => {

    try {
      const response = await AxiosClient.create()
        .post()
        .data({...requestBody})
        .url(`${this.apiEndpoints.MY_PARKING_SEASON_TICKET_PAST_HISTORY}`)
        .params({ pageNum: seasonPageNum })
        .build<IMyParkingSeasonTicketHistoryResponse>();

      if (response.data.res_code === 200) {
        return response.data
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  }

  getTimeTicketPastHistory = async (
    requestBody: IMyParkingPastHistory,
    timeTicketPageNum: number
  ): Promise<IMyParkingTimeTicketHistoryResponse | BaseError> => {

    try {
      const response = await AxiosClient.create()
        .post()
        .data({...requestBody})
        .url(`${this.apiEndpoints.MY_PARKING_TIME_TICKET_PAST_HISTORY}`)
        .params({ pageNum: timeTicketPageNum})
        .build<IMyParkingTimeTicketHistoryResponse>()

      if (response.data.res_code === 200) {
        return response.data
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError()
      }
    } catch (error) {
      console.error(error)
      return ErrorHandler.create(error).getError()
    }
  }
}

export default new MyParkingPastHistoryService