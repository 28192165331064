import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  backgroundColor: '#F3F4F6',
  padding: '28px 20px',
});

export const ItemContainer = styled('div', {
  padding: '16px',
  backgroundColor: colors.white,
  borderRadius: '10px',
});

export const WrapImage = styled('div', {
  width: 40,
  height: 40,
  borderRadius: '2px',
  marginRight: '8px',
});

export const Image = styled('img', {
  width: 40,
  height: 40,
  borderRadius: '2px',
  objectFit: 'cover'
});

export const ImageButton = styled('div', {
  width: 40,
  height: 40,
  borderRadius: '2px',
  backgroundColor: colors.primaryBackground,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});