import { styled } from '@stitches/react';

export const ReductionNotifyFirstListContainer = styled('ul', {
  paddingLeft: '20px',
  lineHeight: '1.5',
  marginLeft: '22px',
  paddingTop: '10px',
  paddingBottom: '10px',
  marginTop: '8px',
});

export const ReductionNotifyFirstContentsText = styled('li', {
  marginBottom: '2px',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '18px',
  color: '#555',
});

// export const UploadImgThumbnailContainer = styled('div', {
//   display: 'grid',
//   gridTemplateColumns: 'repeat(auto-fill, minmax(19vw, 1fr))',
//   gap: '3vw',
//   marginTop: '20px',
//   width: '100%'
// });
//
// export const UploadImgThumbnailItem = styled('div', {
//   height: '19vw',
//   position: 'relative',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   backgroundColor: '#f0f0f0', // 배경색 추가
// });

export const NotifyButtonContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '19px 16px',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '-0.4px',
  div: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
});

export const UploadImageSection = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
  gap: '20px',
  justifyContent: 'space-between',
  label: {
    width: '100%',
    aspectRatio: '1 / 1',
  },
});

export const UploadedImage = styled('img', {
  border: '1px solid #DDD',
  aspectRatio: '1 / 1',
  width: '100%',
  borderRadius: '4px',
});

export const UploadedImageWrapper = styled('div', {
  position: 'relative',
});

export const UploadedImageRemoveButton = styled('div', {
  position: 'absolute',
  top: 4,
  right: 4,

  backgroundColor: 'transparent',
  '&:active': {
    opacity: 0.8,
  },
});

export const UploadedImageFileSizeSpan = styled('span', {
  fontSize: '12px',
  lineHeight: '18px',
  marginBottom: '12px',
  fontWeight: 400,
});

// export const RemoveImageIcon = styled(XIcon, {
//   width: '24px',
//   height: '24px',
//   svg: {
//     backgroundColor: '#ffffff',
//   },
//   path: {
//     fill: '#BBBBBB',
//   }
// })
