import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  backgroundColor: '#fff',
  padding: '10px 20px 28px 20px',
});

export const WrapInfo = styled('div', {
  backgroundColor: '#F3F4F6',
  padding: '16px',
  borderRadius: '8px',
});