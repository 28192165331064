import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container } from './styles';
import { Body, Caption1, Caption2 } from 'styles/typography';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import { IcBellSVG } from 'assets/ic';
import Row from 'components/atoms/Row';
import Divider from 'components/atoms/Divider';
import { parkingLots } from 'models/Share/shareHistoryModel';
// import { ReactComponent as IcBellSVG } from './assets/ic/ic_bell.svg';

const MinganParking = ({ data }: { data: parkingLots }) => {
  const navigate = useNavigate();

  const caseType: number = data.shareYN === 'NO' ? 3 : data.completeDriversCnt === 0 ? 2 : 1;
  const isUse = data.completeDriversCnt === 0

  return caseType === 1 ? (
    <Container
      onClick={() =>
        navigate('/minganMyParking', {
          state: {
            slotUID: data.slotUID,
          },
        })
      }
    >
      <Body weight={'medium'} color={'black1'}>{`No.${data.slotUID} 주차장`}</Body>
      <Caption2 weight={'regular'} color={'gray07'} style={{ marginTop: 4 }}>
        {data.roadLotAddr}
      </Caption2>
      <Divider style={{ margin: '16px 0px' }} />
      <Caption1
        weight={'medium'}
        color={'gray09'}
      >{`${data.completeDriversCnt}대가 주차장 이용중이에요`}</Caption1>

      {data.waitingDriversCnt !== 0 ? (
        <Button
          backgroundColor={'#F2EEF8'}
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            marginTop: 12,
          }}
          vertical={8}
          horizontal={10}
        >
          <Row>
            <IcBellSVG width={20} height={20} fill={colors.primary} />
            <Caption2
              weight={'medium'}
              color={'primaryDark'}
              style={{ marginLeft: 4, marginRight: 4 }}
            >
              승인 대기 중인 운전자가
            </Caption2>
            <Caption2 weight={'bold'} color={'primaryDark'}>
              {data.waitingDriversCnt}
            </Caption2>
            <Caption2 weight={'medium'} color={'primaryDark'}>
              명 있어요
            </Caption2>
          </Row>
        </Button>
      ) : null}
    </Container>
  ) : caseType === 2 ? (
    <Container
      onClick={() =>
        navigate('/minganMyParking', {
          state: {
            slotUID: data.slotUID,
          },
        })
      }
    >
      <Body weight={'medium'} color={'black1'}>{`No.${data.slotUID} 주차장`}</Body>
      <Caption2 weight={'regular'} color={'gray07'} style={{ marginTop: 4 }}>
        {data.roadLotAddr}
      </Caption2>
      <Divider style={{ margin: '16px 0px' }} />
      {isUse ? (
        <Caption2 weight={'regular'} color={'gray06'}>
          이용 중인 차량이 없어요
        </Caption2>
      ) : (
        <Caption1
          weight={'medium'}
          color={'gray09'}
        >{`${data.completeDriversCnt}대가 주차장 이용중이에요`}</Caption1>
      )}
      {data.waitingDriversCnt !== 0 ? (
        <Button
          backgroundColor={'#F2EEF8'}
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            marginTop: 12,
          }}
          vertical={8}
          horizontal={10}
        >
          <Row>
            <IcBellSVG width={20} height={20} fill={colors.primary} />
            <Caption2
              weight={'medium'}
              color={'primaryDark'}
              style={{ marginLeft: 4, marginRight: 4 }}
            >
              승인 대기 중인 운전자가
            </Caption2>
            <Caption2 weight={'bold'} color={'primaryDark'}>
              {data.waitingDriversCnt}
            </Caption2>
            <Caption2 weight={'medium'} color={'primaryDark'}>
              명 있어요
            </Caption2>
          </Row>
        </Button>
      ) : null}
    </Container>
  ) : (
    <Container
      onClick={() =>
        navigate('/minganMyParking', {
          state: {
            slotUID: data.slotUID,
          },
        })
      }
    >
      <Caption1 weight={'semibold'} color={'red'} style={{ marginRight: 4 }}>
        판매 중지
      </Caption1>
      <Body
        weight={'medium'}
        color={'gray05'}
        style={{ marginTop: '8px' }}
      >{`No.${data.slotUID} 주차장`}</Body>
      <Caption2 weight={'regular'} color={'gray05'} style={{ marginTop: '8px' }}>
        {data.roadLotAddr}
      </Caption2>
    </Container>
  );
};

export default MinganParking;
