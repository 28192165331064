import React, { useEffect, useState } from 'react';
import {
  CarNumberBottomButtonContainer,
  CarNumberCheckModalContainer,
  CarNumberCheckModalMainContainer,
  CarNumberMistakeContainer,
  ModalOverlay,
  NumberCheckRightButton,
} from './styles';
import icDotBlack from 'assets/ic/ic_dot_black.svg';
import {
  CarNumberBackGroundContainer,
  CarNumberOutlineContainer,
  CarNumberText,
  LeftDotImg,
  RightDotImg,
} from 'pages/Car/styles';
import { Caption1, Headline } from 'styles/typography';

interface BottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  carNumber: string;
  onClick: () => void;
}

const VehicleRegistrationBottomModal: React.FC<BottomModalProps> = ({
  isVisible,
  onClose,
  carNumber,
  onClick,
}) => {
  const handleClose = () => {
    onClose();
  };

  if (!isVisible) return null;

  return (
    <>
      <ModalOverlay onClick={handleClose} />
      <CarNumberCheckModalContainer isVisible={isVisible}>
        <CarNumberCheckModalMainContainer>
          <Headline weight={'semibold'} color={'black1'}>
            차량번호를 확인해 주세요!
          </Headline>
          <CarNumberBackGroundContainer>
            <CarNumberOutlineContainer>
              <LeftDotImg src={icDotBlack} alt={'왼쪽 점 아이콘'} />
              <CarNumberText>{carNumber}</CarNumberText>
              <RightDotImg src={icDotBlack} alt={'점 아이콘2'} />
            </CarNumberOutlineContainer>
          </CarNumberBackGroundContainer>
          <CarNumberMistakeContainer>
            <Caption1 weight={'regular'} color={'red'}>
              차량번호 오기입, 합의 되지 않은 타인 명의의 차량번호
            </Caption1>
            <Caption1 weight={'regular'} color={'black3'}>
              등으로 인해 발생하는 민형사상의 법적 책임은 본인에게 있습니다.
            </Caption1>
          </CarNumberMistakeContainer>
          <CarNumberBottomButtonContainer>
            <NumberCheckRightButton
              onClick={() => {
                onClick();
              }}
            >
              확인했습니다
            </NumberCheckRightButton>
          </CarNumberBottomButtonContainer>
        </CarNumberCheckModalMainContainer>
      </CarNumberCheckModalContainer>
    </>
  );
};

export default VehicleRegistrationBottomModal;
