import { styled } from '@stitches/react';

export const YearSelectModalContainer = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  transform: 'translateY(100%)',
  display: 'flex',
  flexDirection: 'column',
  padding: '28px 20px 40px 20px',
  boxSizing: 'border-box',
  borderRadius: '20px 20px 0px 0px',

  zIndex: 10000,
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' },
      false: { transform: 'translateY(100%)' },
    },
    isScroll: {
      true: {
        height: '340px',
      },
      false: {
        height: 'auto',
      },
    },
  },
});

export const Overlay = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  zIndex: 9999,
});

export const ListItem = styled('div', {
  backgroundColor: '#fff',
  textAlign: 'center',
  userSelect: 'none',
  boxSizing: 'border-box',
  color: '#6B6B6B',
  fontFamily: 'Pretendard',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  padding: '0px 20px',
  height: '46px',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  variants: {
    isSelected: {
      true: {
        backgroundColor: '#F5F5FF',
        color: '#8B63BB',
        fontWeight: 700,
      },
    },
  },
});

export const ConfirmButton = styled('button', {
  // position: 'fixed',
  bottom: '40px',
  height: '46px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  border: 'none',
  backgroundColor: '#8B63BB',
  color: '#fff',
  borderRadius: '4px',
  padding: '10px 20px',
  boxSizing: 'border-box',
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  width: 'calc(100%)',
  variants: {
    disabled: {
      true: {
        backgroundColor: '#D3D3D3',
      },
    },
  },
});
