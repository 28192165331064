import { styled } from '@stitches/react';

export const Container = styled('div', {})

export const PhoneNumberBorderContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  borderBottom: '1px solid #dbdbdb',
  width: '100%',
})

export const UserInfoInput = styled('input', {
  padding: '5px',
  border: '0px',
  width: '100%',
  outline: 'none',
  fontWeight: 700,
  fontSize: '16px',
  backgroundColor: '#FFF',
  color: '#1A1A1A',
  marginTop: '20px',
  '&::placeholder': {
    color: '#868686',
  },
})

export const VerificationCodeInput = styled('input', {
  padding: '5px',
  border: '0px',
  width: '100%',
  outline: 'none',
  fontWeight: 700,
  color: '#1A1A1A',
  fontSize: '16px',
  backgroundColor: '#FFF',
  '&::placeholder': {
    color: '#868686',
  },
});


export const VerificationCodeInputContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '20px',
  position: 'relative',
  borderBottom: '1px solid #dbdbdb',
});

export const TimerContainer = styled('div', {
  position: 'absolute',
  right: '0',
  top: '50%',
  transform: 'translateY(-50%)',
  fontSize: '16px',
  fontWeight: 700,
  textAlign: 'right',
}, {
  variants: {
    expired: {
      true: { color: 'red' },
      false: { color: '#868686' }
    }
  }
})