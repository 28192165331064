import React, { ReactNode, CSSProperties } from 'react';

interface CustomButtonProps {
  onClick?: () => void; // React.js에서 사용되는 클릭 핸들러
  children: ReactNode;
  vertical?: number;
  horizontal?: number;
  backgroundColor?: string;
  borderWidth?: number;
  radius?: number;
  borderColor?: string;
  style?: CSSProperties;
  disabled?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  children,
  vertical = 4,
  horizontal = 8,
  backgroundColor = '#fff',
  borderWidth = 1,
  radius = 4,
  borderColor = 'transparent',
  onClick,
  style,
  disabled,
}) => {
  return (
    <button
      type={'button'}
      style={{
        padding: `${vertical}px ${horizontal}px`,
        backgroundColor: backgroundColor,
        borderWidth,
        borderColor: disabled ? 'transparent' : borderColor,
        borderRadius: radius,
        borderStyle: 'solid',
        cursor: disabled ? 'not-allowed' : 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...style,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default CustomButton;
