import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import {
  AccountInfoContainer,
  AccountInfoContentsContainer,
  AccountNumberSpan,
  AccumulationExpiresContainer,
  AmountPointContainer,
  AmountPointContentsContainer,
  BankAccountInfoContainer,
  BankAccountInfoContentsContainer,
  BankIcon,
  DisappearExpectedMoneyContainer,
  DisappearExpectedMoneyContentContainer,
  EarnDisappearExpectedMoneySpan,
  EarnExpectedContainer,
  EarnExpectedMoneyContainer,
  HaveMoneyPointNumberSpan,
  HaveMoneySpan,
  HavePointSpan,
  HoldAmountPointContainer,
  ModifyAccountContainer,
  ModifyContentsContainer,
  NotAccountContainer,
  NotAccountSpan,
  NotificationContentContainer,
  PointContainer,
  PointContentContainer,
  PointHistoryContainer,
  PointListContainer,
  QuestionIcon,
  RedeemPointContainer,
  RedeemPointContentContainer,
  RedeemPointSpan,
  RedeemPointWithdrawalContainer,
  RegiAccountContainer,
  RegiAccountSpan,
  ShoppingBackIcon,
  SpinnerContainer,
  UseAccountContainer,
  ViewDivideContainer,
  WithdrawalContainer,
  WithdrawalLimitModalContentContainer,
  WithdrawPointAmountContainer,
  ListText,
  WrapDescription,
} from './styles';
import icArrowPurple from 'assets/ic/ic_arrow_right_purple.svg';
import icQuestionWhite from 'assets/ic/ic_question_white.svg';
import icArrowRightPurpleMedium from 'assets/ic/ic_arrow_right_purple_medium.svg';
import icShoppingBag from 'assets/ic/ic_shopping_bag.svg';
import Title from 'components/atoms/Title/PageTitle';
import NotificationModal from 'components/atoms/Modal/NotificationModal';
import PurpleOneButtonCheckModal from 'components/atoms/Modal/PurpleOneButtonCheckModal';
import PurpleTwoButtonCheckModal from 'components/atoms/Modal/PurpleTwoButtonCheckModal';
import { IPointSearch } from 'models/Point/pointHistoryModel';
import { IUserInfo } from 'models/UserInfo/userInfoModel';
import icNotBank from 'assets/ic/ic_not_bank.svg';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import { encTextWord, getRandomString } from 'utils/cryptoJS';
import { pointSearchStore } from 'stores/store/Point/pointSearchStore';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import useUserInfo from 'hooks/useUserInfo';
import { LINK_UID } from 'constants/userInfoConstants';
import { pointAccountRegiStore } from 'stores/store/Point/pointAccountRegiStore';
import { JSP_URL } from 'constants/baseConstants';
import icSuccessCheckGreen from 'assets/ic/ic_success_check_green.svg';
import { Spinner } from 'components/atoms/LoadingSpinner/LoadingSpinnerImage/styles';
import icFailCheckRed from 'assets/ic/ic_fail_check_red.svg';
import PointHistoryBottomList from 'containers/point/PointHistoryBottomList';
import Divider from 'components/atoms/Divider';
import { colors } from 'styles/colors';
import AutomaticTransfer from 'containers/point/AutomaticTransfer';
import CustomBottomModal from 'components/atoms/Modal/CustomBottomModal';
import PopupModal from 'components/atoms/Modal/PopupModal';
import { Caption2, Caption3, Headline } from 'styles/typography';
import Row from 'components/atoms/Row';

const PointHistory = observer(() => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const [isHaveMoneyNotificationModal, setIsHaveMoneyNotificationModal] = useState(false);
  const [isHavePointNotificationModal, setIsHavePointNotificationModal] = useState(false);
  const [isImpossibleWithdrawalModal, setIsImpossibleWithdrawalModal] = useState(false);
  const [isWithdrawalLimitModal, setIsWithdrawalLimitModal] = useState(false);
  const [isAccountDeleteModal, setIsAccountDeleteModal] = useState(false);
  const [isAccountDeleted, setIsAccountDeleted] = useState(false);
  const [isAccountChangeModal, setIsAccountChangeModal] = useState(false);
  const [linkUID, setLinkUID] = useState<number | null>(null);
  const [isBottomListLoading, setIsBottomListLoading] = useState(true);
  const [isToggle, setIsToggle] = useState<boolean>(false);
  const [isPopup, setIsPopup] = useState<boolean>(false);
  const [popupContent, setPopupContent] = useState({
    title1: '',
    rightClick: () => {},
  });
  const [isAutomaticDescriptionModal, setIsAutomaticDescriptionModal] = useState(false);
  const [isAutomaticPopup, setIsAutomaticPopup] = useState(false);
  const [isAutomaticOffPopup, setIsAutomaticOffPopup] = useState(false);
  const [isUserInfoFetched, setIsUserInfoFetched] = useState(false);

  const { hasEmptyAccount, wdPassword } = userInfoStore;

  const DEFAULT_STATUS = '전체';
  const DEFAULT_MONTH_DIFF = 3;

  const descriptions = [
    '영업일 기준으로 매월 5일에 입급됩니다. 5일이 영업일이 아닌 경우, 다음 영업일에 이체됩니다. (평균 2~5일 소요)',
    '이체일 기준 영업일 1일 전 자정까지만 계좌 변경 가능합니다.',
    '등록된 계좌가 없는 경우 자동이체가 안 되니 유의해 주세요.',
    '5일에 적용한 경우 다음 달부터 적용돼요.',
  ];

  const moveZoomansaJSPMain = () => {
    window.location.href = JSP_URL;
  };

  const handleHaveMoneyClick = () => {
    setIsHaveMoneyNotificationModal(true);
  };

  const handleHavePointClick = () => {
    setIsHavePointNotificationModal(true);
  };

  const closePopup = () => {
    setIsPopup(false);
  };

  const closeAutomaticDescriptionModal = () => {
    setIsAutomaticDescriptionModal(false);
  };

  const closeAutomaticPopup = () => {
    setIsAutomaticPopup(false);
  };

  const closeAutomaticOffPopup = () => {
    setIsAutomaticOffPopup(false);
  };

  const { iv, salt } = getRandomString();
  const { memberID, memberPW, loginType } = useUserInfo();
  const memberEncPw = encTextWord(memberPW, iv, salt);

  const requestBody: IUserInfo = {
    memberID: memberID,
    memberPW: memberEncPw,
    loginType: loginType,
  };

  // 작업 완료 후 위의 주석 값으로 세팅
  // const requestBody: IUserInfo = {
  //   memberID: 'ohjjoa@naver.com',
  //   memberPW: memberEncPw,
  //   loginType: 'NAVER',
  // };

  const handleAccountDeleteConfirm = async () => {
    setIsAccountDeleteModal(false);
    setIsAccountDeleted(true);

    // const mLinkUID = userInfoStore.memberUID;

    // 작업 완료 후 위의 주석 값으로 세팅
    const mLinkUID = 533393;
    const bankCode = '';
    const bankName = '';
    const accountNumber = '';
    const accountHolder = '';

    try {
      await pointAccountRegiStore.fetchPointAccountDelete(
        mLinkUID,
        bankCode,
        bankName,
        accountNumber,
        accountHolder,
      );

      await userInfoStore.fetchUserInfoSearch(requestBody, iv, salt);

      if (userInfoStore.isErrorToast) {
        showToast('현재 통신이 원활하지 않습니다.\n앱 종료 후 다시 실행해주세요.', icFailCheckRed);
      }
    } catch (error) {
      console.error('계좌 삭제 요청 중 오류가 발생:', error);
      showToast('현재 통신이 원활하지 않습니다.\n앱 종료 후 다시 실행해주세요.', icFailCheckRed);
    }
  };

  useEffect(() => {
    const metaTag = document.createElement('meta');
    metaTag.name = 'viewport';
    metaTag.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no';

    document.head.appendChild(metaTag);

    return () => {
      document.head.removeChild(metaTag);
    };
  }, []);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        await userInfoStore.fetchUserInfoSearch(requestBody, iv, salt);

        if (userInfoStore.memberUID != null) {
          const currentYear = new Date().getFullYear();
          setLinkUID(userInfoStore.memberUID);
          sessionStorage.setItem(LINK_UID, userInfoStore.memberUID.toString());

          const pointRequestBody: IPointSearch = {
            mLinkUID: userInfoStore.memberUID,
            status: DEFAULT_STATUS,
            searchYear: currentYear,
            monthDiff: DEFAULT_MONTH_DIFF,
            pageNum: 1,
          };
          await pointSearchStore.fetchPointsData(pointRequestBody);
        }
      } catch (error) {
        console.error('Error fetching bank info:', error);
      }
    };

    fetchUserInfo().catch(error => console.error('Error in fetchData:', error));
  }, [memberID, memberPW, loginType, isUserInfoFetched]);

  useEffect(() => {
    window.onBtnBackClicked = function () {
      moveZoomansaJSPMain();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  useEffect(() => {
    if (linkUID) {
      setIsBottomListLoading(true);
      setTimeout(() => {
        setIsBottomListLoading(false);
      }, 0);
    }
  }, [linkUID]);

  const handleUseCouponMoveClick = () => {
    if (pointSearchStore.isLoading) {
      showToast(
        '사용 가능 포인트를 확인 중 입니다.\n잠시 후 다시 시도해 주세요.',
        icSuccessCheckGreen,
      );
      return;
    }

    navigate('/pointMallBrand');
  };

  const handleUserStatusMoveClick = () => {
    const hasAccount = hasEmptyAccount === 'true';
    const hasPassword = wdPassword === 'true';
    const remainingPay = pointSearchStore.remainingPay !== '0';

    console.log('계좌정보 유무:', hasEmptyAccount);
    console.log('출금 비밀번호 설정 유무:', wdPassword);
    console.log('남은 금액:', pointSearchStore.remainingPay);
    console.log('로딩 상태:', pointSearchStore.isLoading);

    if (pointSearchStore.isLoading) {
      showToast(
        '출금 가능 금액을 확인 중입니다.\n잠시 후 다시 시도해 주세요.',
        icSuccessCheckGreen,
      );
      return;
    }

    // 출금 금액이 0원이라면 무조건 모달
    // 0원이 아니고

    if (remainingPay) {
    }

    if (!hasAccount && !hasPassword && !remainingPay) {
      setIsImpossibleWithdrawalModal(true);
      return;
    }

    if (!hasAccount && !hasPassword) {
      navigate('/pointAccountRegi', { state: { withdrawCustomer: 'withdrawCustomer' } });
      return;
    }

    if (hasAccount && !hasPassword) {
      sessionStorage.setItem('isPwUpdateUser', 'false');
      navigate('/pointWithdrawalIdentityCert');
      return;
    }

    if (hasAccount && hasPassword && !remainingPay) {
      showToast('출금할 금액이 부족합니다.', icSuccessCheckGreen);
      return;
    }

    if (hasAccount && hasPassword && remainingPay) {
      const numericRemainingPay = parseInt(pointSearchStore.remainingPay.replace(/,/g, ''), 10);

      if (numericRemainingPay >= 1000000) {
        setIsWithdrawalLimitModal(true);
      } else {
        navigate('/pointWithdrawalMoney');
      }
      return;
    }
  };

  const onToggle = (state: boolean) => {
    if (!isToggle) {
      if (hasEmptyAccount === 'false') {
        setIsPopup(true);
        setPopupContent({
          title1: '계좌를 먼저 등록해 주세요.',
          rightClick: () => {
            navigatePointAccount();
          },
        });
      } else if (wdPassword === 'false') {
        setIsPopup(true);
        setPopupContent({
          title1: '출금 비밀번호를 등록해 주세요.',
          rightClick: () => {
            navigate('/pointWithdrawalPasswordSetting', {
              state: {
                navigateType: -2,
              },
            });
          },
        });
      } else {
        setIsAutomaticDescriptionModal(true);
      }
    } else {
      setIsAutomaticOffPopup(true);
    }
  };

  const navigatePointAccount = () => {
    navigate('/pointAccountRegi', {
      state: { withdrawCustomer: 'notAccountAndChangeCustomer' },
    });
  };

  return (
    <PointHistoryContainer>
      <Title
        title="주페이 내역"
        leftArrowButtonVisible={true}
        leftOnClick={() => {
          moveZoomansaJSPMain();
        }}
        background={'purpleGradient'}
        showSpacer={false}
        rightTextButtonVisible={false}
        titleColor={'white'}
        imgBackPurpleColor={false}
      />

      <div>
        <HoldAmountPointContainer>
          <AmountPointContainer>
            <AmountPointContentsContainer
              onClick={() => {
                handleHaveMoneyClick();
                setIsHavePointNotificationModal(false);
              }}
            >
              <HaveMoneySpan>보유 주머니</HaveMoneySpan>
              <QuestionIcon src={icQuestionWhite} alt="물음표 아이콘" />
            </AmountPointContentsContainer>
          </AmountPointContainer>

          {isHaveMoneyNotificationModal && (
            <NotificationModal
              isVisible={isHaveMoneyNotificationModal}
              onClose={() => {
                setIsHaveMoneyNotificationModal(false);
              }}
              title={'보유 주머니란?'}
              content={
                <NotificationContentContainer>
                  <span>
                    민간 주차 공간 공유하여 생기는 수익을
                    <br />
                    의미해요. 해당 포인트는 주만사몰에서
                    <br />
                    현금처럼 사용하거나, 내 계좌를 등록한
                    <br /> 후에 현금으로 수령 가능해요.
                  </span>
                </NotificationContentContainer>
              }
            />
          )}
          <WithdrawPointAmountContainer>
            <HaveMoneyPointNumberSpan>{pointSearchStore.remainingPay}원</HaveMoneyPointNumberSpan>
          </WithdrawPointAmountContainer>
          <ViewDivideContainer />

          <PointContainer>
            <PointContentContainer
              onClick={() => {
                handleHavePointClick();
                setIsHaveMoneyNotificationModal(false);
              }}
            >
              <HavePointSpan>보유 포인트</HavePointSpan>
              <QuestionIcon src={icQuestionWhite} alt="물음표 아이콘" />
            </PointContentContainer>
          </PointContainer>

          {isHavePointNotificationModal && (
            <NotificationModal
              isVisible={isHavePointNotificationModal}
              onClose={() => {
                setIsHavePointNotificationModal(false);
              }}
              title={'보유 포인트란?'}
              content={
                <NotificationContentContainer>
                  <span>
                    거주자 우선 주차 공간을 공유하여 생기는
                    <br />
                    수익을 의미해요. 해당 포인트는
                    <br />
                    주만사몰에서 현금처럼 사용 가능해요.
                  </span>
                </NotificationContentContainer>
              }
            />
          )}
          <WithdrawPointAmountContainer>
            <HaveMoneyPointNumberSpan>{pointSearchStore.remainingPoints}P</HaveMoneyPointNumberSpan>
          </WithdrawPointAmountContainer>
          <RedeemPointWithdrawalContainer>
            <RedeemPointContainer
              onClick={() => {
                handleUseCouponMoveClick();
              }}
            >
              <RedeemPointContentContainer>
                <ShoppingBackIcon src={icShoppingBag} alt="쇼핑백 아이콘" />
                <RedeemPointSpan
                  onClick={() => {
                    navigate('/');
                  }}
                >
                  사용하기
                </RedeemPointSpan>
              </RedeemPointContentContainer>
            </RedeemPointContainer>
            <WithdrawalContainer onClick={handleUserStatusMoveClick}>
              <span>출금하기</span>
            </WithdrawalContainer>
          </RedeemPointWithdrawalContainer>
        </HoldAmountPointContainer>
        <EarnExpectedContainer>
          <AccumulationExpiresContainer>
            <span>적립 예정</span>
            <EarnExpectedMoneyContainer
              onClick={() => {
                navigate('/pointEarnExpected');
              }}
            >
              <EarnDisappearExpectedMoneySpan>
                {pointSearchStore.pendingSum}
              </EarnDisappearExpectedMoneySpan>
              <img src={icArrowRightPurpleMedium} alt="보라색 화살표 오른쪽 아이콘" />
            </EarnExpectedMoneyContainer>
          </AccumulationExpiresContainer>
          <AccumulationExpiresContainer>
            <DisappearExpectedMoneyContentContainer
              onClick={() => {
                navigate('/pointLoseExpected');
              }}
            >
              <span>소멸 예정 (30일 이내)</span>
              <DisappearExpectedMoneyContainer>
                <EarnDisappearExpectedMoneySpan>
                  {pointSearchStore.expiringSum}
                </EarnDisappearExpectedMoneySpan>
                <img src={icArrowRightPurpleMedium} alt="보라색 화살표 오른쪽 아이콘" />
              </DisappearExpectedMoneyContainer>
            </DisappearExpectedMoneyContentContainer>
          </AccumulationExpiresContainer>
        </EarnExpectedContainer>

        {userInfoStore.hasEmptyAccount === 'false' ? (
          <NotAccountContainer
            onClick={() => {
              navigatePointAccount();
            }}
          >
            <NotAccountSpan>등록된 계좌가 없어요.</NotAccountSpan>
            <RegiAccountContainer>
              <RegiAccountSpan>계좌등록</RegiAccountSpan>
              <img src={icArrowPurple} alt="보라색 화살표 오른쪽 아이콘" />
            </RegiAccountContainer>
          </NotAccountContainer>
        ) : (
          <UseAccountContainer>
            <ModifyAccountContainer>
              <span>등록된 계좌</span>
              <ModifyContentsContainer>
                <span onClick={() => setIsAccountDeleteModal(true)}>삭제</span>
                <span>|</span>
                <span
                  onClick={() => {
                    setIsAccountChangeModal(true);
                  }}
                >
                  변경
                </span>
              </ModifyContentsContainer>
            </ModifyAccountContainer>

            <AccountInfoContainer>
              <AccountInfoContentsContainer>
                <BankIcon src={userInfoStore.bankIconSrc || icNotBank} alt="은행 아이콘" />
                <BankAccountInfoContainer>
                  <BankAccountInfoContentsContainer>
                    <span>{userInfoStore.bankName}</span>
                    <span>&nbsp;/&nbsp;</span>
                    <span>{userInfoStore.accountHolder}</span>
                  </BankAccountInfoContentsContainer>
                  <AccountNumberSpan>{userInfoStore.accountNumber}</AccountNumberSpan>
                </BankAccountInfoContainer>
              </AccountInfoContentsContainer>
            </AccountInfoContainer>
          </UseAccountContainer>
        )}

        <AutomaticTransfer isToggle={isToggle} onToggle={onToggle} />

        <Divider style={{ backgroundColor: colors.gray01, height: 8 }} />
        <PointListContainer>
          {isBottomListLoading ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            linkUID && <PointHistoryBottomList linkUID={linkUID} />
          )}
        </PointListContainer>
      </div>
      <PurpleOneButtonCheckModal
        isVisible={isImpossibleWithdrawalModal}
        onClose={() => {
          setIsImpossibleWithdrawalModal(false);
        }}
        title={'출금 가능한 금액이 없어요.'}
        content={''}
        buttonText={'확인'}
      />

      <PurpleOneButtonCheckModal
        isVisible={isWithdrawalLimitModal}
        onClose={() => {
          setIsWithdrawalLimitModal(false);
          navigate('/pointWithdrawalMoney');
        }}
        title={'100만원 이상 출금 시\n담당자 승인 후 출금 됩니다.'}
        content={
          <WithdrawalLimitModalContentContainer>
            '1회 300만원, 월 3,000만원 출금 가능'
          </WithdrawalLimitModalContentContainer>
        }
        buttonText={'확인'}
      />

      <PurpleTwoButtonCheckModal
        isVisible={isAccountDeleteModal}
        onClose={() => {
          setIsAccountDeleteModal(false);
        }}
        title={'등록된 계좌를 삭제하시겠습니까?'}
        content={''}
        leftButtonText={'취소'}
        rightButtonText={'삭제하기'}
        rightClick={async () => {
          await handleAccountDeleteConfirm();
        }}
      />

      <PurpleTwoButtonCheckModal
        isVisible={isAccountChangeModal}
        onClose={() => {
          setIsAccountChangeModal(false);
        }}
        title={'등록된 계좌를 변경하시겠습니까?'}
        content={''}
        leftButtonText={'취소'}
        rightButtonText={'변경하기'}
        rightClick={() => {
          navigatePointAccount();
        }}
      />

      <PopupModal
        isVisible={isPopup}
        onClose={() => {
          closePopup();
        }}
        title1={popupContent?.title1}
        description={''}
        leftClick={() => {
          closePopup();
        }}
        leftText={'취소'}
        rightText={'등록하기'}
        rightClick={popupContent?.rightClick}
      />

      <CustomBottomModal
        isVisible={isAutomaticDescriptionModal}
        onClose={closeAutomaticDescriptionModal}
        title1={'아래 내용을 확인해 주세요'}
        description={
          <>
            <ListText>
              자동이체 시 <span>영업일 기준 매월 5일</span>에 남아있는 주머니 금액을 등록된 계좌로
              이체해 드려요.
            </ListText>
            <WrapDescription>
              {descriptions.map((el, index) => {
                return (
                  <Row
                    key={index}
                    style={{ marginTop: index === 0 ? 0 : 8 }}
                    alignItems={'flex-start'}
                  >
                    <Caption2 weight={'regular'} color={'gray08'} style={{ marginRight: 8 }}>
                      ·
                    </Caption2>

                    <Caption2 weight={'regular'} color={'gray08'}>
                      {el}
                    </Caption2>
                  </Row>
                );
              })}
            </WrapDescription>
          </>
        }
        leftClick={closeAutomaticDescriptionModal}
        leftText={'돌아가기'}
        rightText={'자동이제 적용하기'}
        rightClick={() => {
          setIsAutomaticPopup(true);
          setIsToggle(true);
        }}
      />

      <PopupModal
        isVisible={isAutomaticPopup}
        onClose={() => {
          closeAutomaticPopup();
        }}
        title1={'자동이체 기능을 켰어요.'}
        description={'다음달 1일부터 출금하지 않은\n정산금이 자동으로 이체돼요.'}
        leftClick={() => {
          closeAutomaticPopup();
        }}
        leftText={'확인'}
      />

      <PopupModal
        isVisible={isAutomaticOffPopup}
        onClose={() => {
          closeAutomaticOffPopup();
        }}
        title1={'자동이체 기능을 멈추나요?'}
        description={'매월 5일에 자동 입금되던 기능이 멈춰요.'}
        leftClick={() => {
          closeAutomaticOffPopup();
        }}
        leftText={'취소'}
        rightClick={() => {
          setIsToggle(false);
          showToast('자동이체 기능을 멈췄어요.', icSuccessCheckGreen);
        }}
        rightText={'기능 멈추기'}
      />
    </PointHistoryContainer>
  );
});

export default PointHistory;
