import { styled } from '@stitches/react';
import { colors } from 'styles/colors';
import { pxToRem } from 'utils/common';

export const Modal = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: pxToRem(640),
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  transform: 'translateY(100%)',
  display: 'flex',
  flexDirection: 'column',
  padding: '28px 20px 40px 20px',
  boxSizing: 'border-box',
  borderRadius: '20px 20px 0px 0px',
  zIndex: 10000,
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' },
      false: { transform: 'translateY(100%)' },
    },
  },
});

export const WrapDesc = styled('div', {
  padding: '10px 16px',
  marginTop: '16px',
  borderRadius: '8px',
  backgroundColor: colors.primaryBackground,
});

export const WrapDropdown = styled('div', {
  marginTop: '28px',
});

export const TextAreaContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${colors.gray02}`,
  borderRadius: '8px',
  padding: '16px',
  boxSizing: 'border-box',
  width: '100%',
  marginTop: '10px',
});

export const StyledTextArea = styled('textarea', {
  width: '100%',
  border: 'none',
  outline: 'none',
  resize: 'none',
  fontSize: 14,
  fontWeight: 500,
  color: colors.gray09,

  fontFamily: 'inherit',
  background: 'transparent',
  '&::placeholder': {
    color: colors.gray05,
  },
});
