import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';

import {
  CommentKeySpan,
  CommentValue,
  DarkGrayColorSpan,
  DateSpan,
  GaryColorSpan,
  LottieSpan,
  PointDetailContainer,
  PointHistoryToggleFirstContainer,
  PointHistoryToggleFirstSpan,
  PointHistoryToggleSecondContainer,
  PointHistoryToggleSecondSpan,
  PointHistoryToggleThirdContainer,
  PointHistoryToggleThirdSpan,
  PointInfoContainer,
  PointStatusContainer,
  PointStatusSpan,
  SixMonthSpan,
  ThreeMonthSpan,
  ToggleButtonContainer,
  ViewDivideContainer,
  YearContainer,
  YearMonthContainer,
  YearMonthContentContainer,
} from './styles';
import lottieEmpty from 'assets/lottie/lottieEmpty80.json';
import icArrowBottomGray from 'assets/ic/ic_arrow_bottom_gray.svg';
import YearSelectBottomModal from 'components/atoms/Modal/YearSelectBottomModal';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import { observer } from 'mobx-react';
import { pointBottomListStore } from 'stores/store/Point/pointBottomListStore';
import { Spinner } from 'components/atoms/LoadingSpinner/LoadingSpinnerImage/styles';
import icSuccessCheckGreen from 'assets/ic/ic_success_check_green.svg';
import { IComment } from 'models/Point/pointHistoryModel';

interface PointHistoryBottomListProps {
  linkUID: number;
}

const year = ['2024', '2023', '2022', '2021', '2020', '2019', '2018'];

const PointHistoryBottomList: React.FC<PointHistoryBottomListProps> = observer(({ linkUID }) => {
  const [isYearSelectBottomModal, setIsYearSelectBottomModal] = useState(false);
  const currentYear = new Date().getFullYear();
  const isCurrentYear = pointBottomListStore.selectedYear === currentYear;
  const { showToast } = useToast();

  const [currentPage, setCurrentPage] = useState(1);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: lottieEmpty,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    const fetchInitBottomList = async () => {
      try {
        await pointBottomListStore.fetchPointHistoryBottomList(linkUID, false);
      } catch (error) {
        console.error('point history 초기화 로드 에러:', error);
      }
      setCurrentPage(1);
    };

    fetchInitBottomList().catch(error => {
      console.error('point history 초기화 로드 에러:', error);
    });

    // 스크롤 이벤트 핸들러
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

      // 스크롤이 하단
      if (scrollTop + clientHeight >= scrollHeight - 5 && !pointBottomListStore.isLoading) {
        // 추가 데이터를 로드
        setCurrentPage(prevPage => prevPage + 1);
      }
    };

    // 스크롤 이벤트 등록
    window.addEventListener('scroll', handleScroll);

    // 컴포넌트 언마운트 시 이벤트 제거
    return () => {
      window.removeEventListener('scroll', handleScroll);
      setCurrentPage(1);
    };
  }, [linkUID]);

  useEffect(() => {
    const fetchDataBottomList = async () => {
      if (currentPage === 1) {
        await pointBottomListStore.fetchPointHistoryBottomList(linkUID, false);
      } else {
        await pointBottomListStore.fetchPointHistoryBottomList(linkUID, true);
      }
    };

    fetchDataBottomList().catch(error => {
      console.error('point history bottom List:', error);
    });
    console.log(pointBottomListStore.getSelectedStatus());
  }, [currentPage, linkUID]);

  const handleSelectYear = async (year: number) => {
    pointBottomListStore.setSelectedYear(year);
    pointBottomListStore.isThreeMonthHistory = false;
    pointBottomListStore.isSixMonthHistory = false;
    pointBottomListStore.setScrollLoading(false);
    pointBottomListStore.setLoading(true);
    await pointBottomListStore.fetchPointHistoryBottomList(linkUID, true);
    pointBottomListStore.setLoading(false);
  };

  const handleAccountAllClick = async () => {
    pointBottomListStore.setAccountAllClicked();
    pointBottomListStore.setScrollLoading(false);
    pointBottomListStore.setLoading(true);
    await pointBottomListStore.fetchPointHistoryBottomList(linkUID, false);
    pointBottomListStore.setLoading(false);
  };

  const handleAccountEarnClick = async () => {
    pointBottomListStore.setAccountEarnClicked();
    pointBottomListStore.setScrollLoading(false);
    pointBottomListStore.setLoading(true); // 로딩 시작
    await pointBottomListStore.fetchPointHistoryBottomList(linkUID, false);
    pointBottomListStore.setLoading(false); // 로딩 종료
  };

  const handleAccountUseClick = async () => {
    pointBottomListStore.setAccountUseClicked();
    pointBottomListStore.setScrollLoading(false); // 스크롤 로딩이 아니므로 false 설정
    pointBottomListStore.setLoading(true); // 로딩 시작
    await pointBottomListStore.fetchPointHistoryBottomList(linkUID, false);
    pointBottomListStore.setLoading(false); // 로딩 종료
  };

  const handlePeriodClick = async (period: 'threeMonth' | 'sixMonth') => {
    if (!isCurrentYear) {
      showToast('현재 년도로 적용되었어요.', icSuccessCheckGreen);

      // 상태 변경이 완료될 때까지 기다림
      pointBottomListStore.setCurrentYear();
    }

    if (period === 'threeMonth') {
      pointBottomListStore.setThreeMonthHistory();
    } else if (period === 'sixMonth') {
      pointBottomListStore.setSixMonthHistory();
    }

    pointBottomListStore.pageNum = 1;

    pointBottomListStore.setScrollLoading(false);
    pointBottomListStore.setLoading(true);
    await pointBottomListStore.fetchPointHistoryBottomList(linkUID, false);
    pointBottomListStore.setLoading(false);
  };

  return (
    <>
      <ToggleButtonContainer>
        <PointHistoryToggleFirstContainer
          onClick={handleAccountAllClick}
          isAccountAllClicked={pointBottomListStore.isAccountAllClicked}
        >
          <PointHistoryToggleFirstSpan
            isAccountAllClicked={pointBottomListStore.isAccountAllClicked}
          >
            전체
          </PointHistoryToggleFirstSpan>
        </PointHistoryToggleFirstContainer>

        <PointHistoryToggleSecondContainer
          onClick={handleAccountEarnClick}
          isAccountEarnClicked={pointBottomListStore.isAccountEarnClicked}
        >
          <PointHistoryToggleSecondSpan
            isAccountEarnClicked={pointBottomListStore.isAccountEarnClicked}
          >
            적립
          </PointHistoryToggleSecondSpan>
        </PointHistoryToggleSecondContainer>

        <PointHistoryToggleThirdContainer
          onClick={handleAccountUseClick}
          isAccountUseClicked={pointBottomListStore.isAccountUseClicked}
        >
          <PointHistoryToggleThirdSpan
            isAccountUseClicked={pointBottomListStore.isAccountUseClicked}
          >
            사용
          </PointHistoryToggleThirdSpan>
        </PointHistoryToggleThirdContainer>
      </ToggleButtonContainer>

      <YearMonthContainer>
        <span>
          총<DarkGrayColorSpan>&nbsp;{pointBottomListStore.totalCount}&nbsp;</DarkGrayColorSpan>건
        </span>
        <YearMonthContentContainer>
          <ThreeMonthSpan
            onClick={() => handlePeriodClick('threeMonth')}
            isThreeMonthHistory={pointBottomListStore.isThreeMonthHistory}
            isDisabled={!isCurrentYear}
          >
            &nbsp;3개월
          </ThreeMonthSpan>
          <GaryColorSpan>&nbsp;|&nbsp;</GaryColorSpan>
          <SixMonthSpan
            onClick={() => handlePeriodClick('sixMonth')}
            isSixMonthHistory={pointBottomListStore.isSixMonthHistory}
            isDisabled={!isCurrentYear}
          >
            &nbsp;6개월
          </SixMonthSpan>
          <GaryColorSpan>&nbsp;|&nbsp;</GaryColorSpan>
          <YearContainer onClick={() => setIsYearSelectBottomModal(true)}>
            <span>&nbsp;{pointBottomListStore.selectedYear}&nbsp;</span>
            <img src={icArrowBottomGray} alt="보라색 화살표 아래 아이콘" />
          </YearContainer>
        </YearMonthContentContainer>
      </YearMonthContainer>

      {pointBottomListStore.isLoading && !pointBottomListStore.isScrollLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '48px' }}>
          <Spinner />
        </div>
      ) : pointBottomListStore.pointsData.length === 0 ? (
        <div style={{ marginTop: '28px' }}>
          <Lottie options={defaultOptions} height={80} width={80} />
          {pointBottomListStore.isAccountAllClicked && (
            <LottieSpan>아직 주머니 내역이 없어요.</LottieSpan>
          )}
          {pointBottomListStore.isAccountEarnClicked && (
            <LottieSpan>주머니 적립 내역이 없어요.</LottieSpan>
          )}
          {pointBottomListStore.isAccountUseClicked && (
            <LottieSpan>주머니 사용 내역이 없어요.</LottieSpan>
          )}
        </div>
      ) : (
        pointBottomListStore.pointsData.map((point, index) => {
          let parsedComment: IComment = {};

          console.log('point.comment:', point.comment);

          if (typeof point.comment === 'string') {
            try {
              parsedComment = JSON.parse(point.comment);
            } catch (error) {
              console.error('Failed to parse comment:', error);
            }
          } else {
            console.warn('포인트 데이터 객체 스트링 변환 실패:', point.comment);
          }

          const comments = Object.entries(parsedComment).map(([key, value]) => {
            return (
              <div key={key}>
                <CommentKeySpan>{key}</CommentKeySpan>
                <CommentValue>{value}</CommentValue>
              </div>
            );
          });

          return (
            <PointInfoContainer key={index}>
              <DateSpan>{point.confirmed_at}</DateSpan>
              <PointStatusContainer>
                <PointStatusSpan>{point.status}</PointStatusSpan>
              </PointStatusContainer>
              <PointDetailContainer>{comments}</PointDetailContainer>
              <ViewDivideContainer />
            </PointInfoContainer>
          );
        })
      )}

      <YearSelectBottomModal
        isVisible={isYearSelectBottomModal}
        onClose={() => {
          pointBottomListStore.setScrollLoading(false);
          setIsYearSelectBottomModal(false);
        }}
        onSelectYear={handleSelectYear}
        year={year}
      />
    </>
  );
});

export default PointHistoryBottomList;
