import React, { useEffect } from 'react';
import { MainContainer } from 'components/styles';
import Title from '../../../components/atoms/Title/PageTitle';
import { useNavigate } from 'react-router-dom';
import {
  AllIndexTable,
  ContentContainer,
  ContentTable,
  TableContentText,
  TableTitleText,
  TitleContainer,
} from './styles';
import { Caption2 } from 'styles/typography';
import { showWebBrowser } from 'utils/deviceUtils';
import { PAST_POLICY_PERSONAL } from 'constants/baseConstants';
import { TopContainer } from 'pages/Policy/styles';

const PolicyPersonalPage = () => {
  const navigate = useNavigate();

  const handleLeftOnClick = () => {
    sessionStorage.setItem('isNotFocus', 'true');
    navigate(-1);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  return (
    <MainContainer>
      <Title
        title="개인정보 처리방침"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
        titleColor={'darkPurple'}
        showSpacer={true}
      />

      <TopContainer>
        <TitleContainer>개인정보 처리방침</TitleContainer>
        <ContentContainer
          style={{
            marginTop: '10px',
          }}
        >
          주식회사 주만사(이하 “회사”라 함)는 정보주체의 자유와 권리 보호를 위해 「개인정보 보호법」 및 관계 법령이 정한 바를 준수하여,
          적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보 보호법」제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고,
          이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
        </ContentContainer>

        <AllIndexTable>
          <thead>
            <tr>
              <TableTitleText colSpan={2}>[ 목차 ]</TableTitleText>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TableContentText>
                제1조 개인정보의 처리 목적 개인정보 수집 항목 및 이용목적, 보유기간
              </TableContentText>
              <TableContentText>
                제2조 만 14세 미만 아동의 개인정보 처리에 관한 사항
              </TableContentText>
            </tr>
            <tr>
              <TableContentText>제3조 개인정보의 제3자 제공에 관한 사항</TableContentText>
              <TableContentText>제4조 개인정보 처리업무의 위탁에 관한 사항</TableContentText>
            </tr>
            <tr>
              <TableContentText>제5조 개인정보의 파기 절차 및 방벙에 관한 사항</TableContentText>
              <TableContentText>제6조 미이용자의 개인정보 파기 등에 관한 조치</TableContentText>
            </tr>
            <tr>
              <TableContentText>
                제7조 정보주체와 법정대리인의 권리·의무 및 행사방법에 관한 사항
              </TableContentText>
              <TableContentText>제8조 개인정보의 안정성 확보조치에 관한 사항</TableContentText>
            </tr>
            <tr>
              <TableContentText>
                제9조 개인정보를 자동으로 수집하는 장치의 설치 운영 및 그 거부에 관한 사항
              </TableContentText>
              <TableContentText>
                제10조 행태정보의 수집·이용·제공 및 거부 등에 관한 사항
              </TableContentText>
            </tr>
            <tr>
              <TableContentText>제11조 개인정보 보호책임자에 관한 사항</TableContentText>
              <TableContentText>제12조 개인정보 처리방침의 변경에 관한 사항</TableContentText>
            </tr>
          </tbody>
        </AllIndexTable>

        <TitleContainer
          style={{
            marginTop: '40px',
          }}
        >
          제1조 개인정보 수집 항목 및 이용목적, 보유기간
          <br />
          <ContentContainer
            style={{
              marginTop: '10px',
            }}
          >
            회사는 서비스 제공에 필요한 최소한의 개인정보만 수집하고, 수집한 개인정보는 고지한 범위
            내에서만 처리합니다. 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라
            별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다. 회사는 법령에 따른 개인정보
            보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간
            내에서 개인정보를 처리·보유합니다.
          </ContentContainer>
          <ContentTable>
            <thead>
              <tr>
                <TableTitleText>서비스</TableTitleText>
                <TableTitleText>수집 항목</TableTitleText>
                <TableTitleText>수집 목적</TableTitleText>
                <TableTitleText>보유 기간</TableTitleText>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableContentText>회원가입</TableContentText>
                <TableContentText>
                  [필수] 아이디, 비밀번호, 연락처(핸드폰번호), 포인트 [선택] 이메일 주소, 성별,
                  생년월일
                </TableContentText>
                <TableContentText>
                  회원가입의사 확인, 본인인증 및 후불결제 주차장 이용 후 미 결제 시 안내,
                  공유&이용내역 고지
                </TableContentText>
                <TableContentText>회원탈퇴 후 3개월</TableContentText>
              </tr>
              <tr>
                <TableContentText>주차장 이용</TableContentText>
                <TableContentText>
                  [필수] 연락처, 주차장 위치(주소), 이용 예상 시간, 이용시작 시간, 이용종료 시간
                </TableContentText>
                <TableContentText>
                  회원가입 의사 확인, 본인 인증, 후불결제 주차장 이용
                </TableContentText>
                <TableContentText>회원탈퇴 후 3개월</TableContentText>
              </tr>
              <tr>
                <TableContentText>네이버 로그인</TableContentText>
                <TableContentText>
                  [필수] 회원이름, 이메일주소, 성별, 생일, 출생연도, 휴대전화번호
                </TableContentText>
                <TableContentText>
                  회원가입, 본인인증 및 후불결제 주차장 이용 후 미 결제 시 안내, 공유&이용내역 안내
                </TableContentText>
                <TableContentText>회원탈퇴 후 3개월</TableContentText>
              </tr>
              <tr>
                <TableContentText>Apple 로그인</TableContentText>
                <TableContentText>[필수] 이메일</TableContentText>
                <TableContentText>
                  회원가입, 본인인증 및 후불결제 주차장 이용 후 미 결제 시 안내, 공유&이용내역 안내
                </TableContentText>
                <TableContentText>회원탈퇴 후 3개월</TableContentText>
              </tr>
              <tr>
                <TableContentText>결제</TableContentText>
                <TableContentText>
                  [필수] 차량번호, 차종, 신용카드, 결제일시, 결제수단, 요금
                </TableContentText>
                <TableContentText>
                  주차장 이용 가능 차량 여부 확인 및 호스트 안내, 결제
                </TableContentText>
                <TableContentText>결제일로부터 5년</TableContentText>
              </tr>
              <tr>
                <TableContentText>환불 및 정산</TableContentText>
                <TableContentText>[필수] 은행, 계좌번호, 예금주명</TableContentText>
                <TableContentText>환불 및 정산</TableContentText>
                <TableContentText>수집일로부터 3년</TableContentText>
              </tr>
              <tr>
                <TableContentText>공유 신청</TableContentText>
                <TableContentText>
                  [필수](거주자우선주차장) 차량번호, 구획번호, 지역, [필수](부설 주차장) 주소
                </TableContentText>
                <TableContentText>공유 신청 및 주차장 정보 등록</TableContentText>
                <TableContentText>회원탈퇴 후 3개월</TableContentText>
              </tr>
              <tr>
                <TableContentText>자동수집</TableContentText>
                <TableContentText>IP주소, 쿠키</TableContentText>
                <TableContentText>방문기록 분석, 마케팅 및 맞춤 서비스 제공</TableContentText>
                <TableContentText>수집일로부터 6개월</TableContentText>
              </tr>
            </tbody>
          </ContentTable>
        </TitleContainer>

        <ContentContainer
          style={{
            marginTop: '28px',
          }}
        >
          다만, 관계 법령 상 보존이 필요한 경우에는 해당 법령에서 요구하는 기간 동안 개인정보를
          보관할 수 있습니다.
          <ContentTable>
            <thead>
              <tr>
                <TableTitleText>관계법령</TableTitleText>
                <TableTitleText>보관정보</TableTitleText>
                <TableTitleText>보유기간</TableTitleText>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableContentText rowSpan={3}>
                  전자상거래 등에서의 소비자 보호에 관한 법률
                </TableContentText>
                <TableContentText>표시·광고에 관한 기록</TableContentText>
                <TableContentText>6개월</TableContentText>
              </tr>
              <tr>
                <TableContentText>
                  계약 또는 청약철회, 대금결제, 재화 등의 공급기록
                </TableContentText>
                <TableContentText>5년</TableContentText>
              </tr>
              <tr>
                <TableContentText>소비자 불만 또는 분쟁처리에 관한 기록</TableContentText>
                <TableContentText>3년</TableContentText>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>통신비밀보호법</td>
                <td>컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료</td>
                <td>3개월</td>
              </tr>
            </tbody>
          </ContentTable>
        </ContentContainer>

        <TitleContainer
          style={{
            marginTop: '40px',
          }}
        >
          제2조 만 14세 미만 아동의 개인정보 처리에 관한 사항
          <ContentContainer
            style={{
              marginTop: '10px',
            }}
          >
            회사는 만 14세 미만 아동에 대한 개인정보를 수집하지 않습니다.
          </ContentContainer>
        </TitleContainer>

        <TitleContainer
          style={{
            marginTop: '40px',
          }}
        >
          제3조 개인정보의 제3자 제공에 관한 사항
          <ContentContainer
            style={{
              marginTop: '10px',
            }}
          >
            회사는 정보주체의 개인정보를 개인정보의 처리 목적에서 명시한 범위 내에서만 처리하며,
            정보주체의 동의 없이 범위를 초과하여 이용하거나 제3자에게 제공하지 않습니다.
            <br />
            다만, 근거법령에 의거하여 정보의 제공을 요청 받은 경우에는 정보주체의 동의 없이
            관계기관에 개인정보를 제공할 수 있습니다.
            <br />이 경우 회사는 적법한 절차에 따라 필요한 최소한의 개인정보만을 제공하며, 목적과
            다르게 제공하지 않겠습니다.
          </ContentContainer>
        </TitleContainer>

        <TitleContainer
          style={{
            marginTop: '40px',
          }}
        >
          제4조 개인정보 처리업무의 위탁에 관한 사항
          <ContentContainer
            style={{
              marginTop: '10px',
            }}
          >
            ① 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고
            있습니다
          </ContentContainer>
          <ContentTable>
            <thead>
              <tr>
                <TableTitleText>위탁받은 자</TableTitleText>
                <TableTitleText>위탁 업무</TableTitleText>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableContentText>세종텔레콤(주), KCP, 카카오페이, SC제일은행</TableContentText>
                <TableContentText>요금결제 및 신용카드 인증</TableContentText>
              </tr>
            </tbody>
          </ContentTable>
          <ContentContainer
            style={{
              marginTop: '28px',
            }}
          >
            ② 회사는 위탁계약 체결 시 「개인정보 보호법」 제26조에 따라 위탁업무 수행목적외 개인정보
            처리금지, 기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등
            책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를
            감독하고 있습니다.
            <br />③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을
            통하여 공개하도록 하겠습니다.
          </ContentContainer>
        </TitleContainer>

        <TitleContainer
          style={{
            marginTop: '40px',
          }}
        >
          제5조 개인정보의 파기 절차 및 방법에 관한 사항
          <ContentContainer
            style={{
              marginTop: '10px',
            }}
          >
            ① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는
            지체없이 해당 개인정보를 파기합니다.
            <br />
            ② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도
            불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를
            별도의 데이터 베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
            <br />③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
            <div
              style={{
                margin: '10px 12px',
              }}
            >
              1. 파기절차
              <br />
              회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을
              받아 개인정보를 파기합니다.
              <br />
              2. 파기방법
              <br />
              회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며,
              종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
            </div>
          </ContentContainer>
        </TitleContainer>

        <TitleContainer
          style={{
            marginTop: '40px',
          }}
        >
          제6조 미이용자의 개인정보 파기 등에 관한 조치
          <ContentContainer
            style={{
              marginTop: '10px',
            }}
          >
            ① 회사는 1년간 서비스를 이용하지 않은 이용자의 정보를 파기하고 있습니다.
            <br />
            다만, 다른 법령에서 정한 보존기간이 경과할 때까지 다른 이용자의 개인정보와 분리하여
            별도로 저장·관리할 수 있습니다.
            <br />
            ② 회사는 개인정보의 파기 30일 전 까지 개인정보가 파기되는 사실, 기간 만료일 및 파기되는
            개인정보의 항목을 이메일, 문자 등 이용자에게 통지 가능한 방법으로 알리고 있습니다.
            <br />③ 개인정보의 파기를 원하지 않으시는 경우, 기간 만료 전 서비스 로그인을 하시면
            됩니다.
          </ContentContainer>
        </TitleContainer>

        <TitleContainer
          style={{
            marginTop: '40px',
          }}
        >
          제7조 정보주체와 법정대리인의 권리의무 및 행사방법에 관한 사항
          <ContentContainer
            style={{
              marginTop: '10px',
            }}
          >
            ① 정보주체는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를
            행사할 수 있습니다.
            <br />
            ② 권리 행사는 회사에 대해 「개인정보 보호법」 시행령 제41조 제1항에 따라 서면, 전자우편,
            모사전송(FAX) 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체없이 조치하겠습니다.
            <br />
            ③ 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수도
            있습니다.
            <br />
            이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을
            제출하셔야 합니다.
            <br />
            ④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에
            의하여 정보주체의 권리가 제한될 수 있습니다.
            <br />
            ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는
            경우에는 그 삭제를 요구할 수 없습니다.
            <br />
            ⑥ 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등
            요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
            <br />⑦ 회사는 원칙적으로 고객이 만 14세 미만 아동(이하 ‘아동’)일 경우 개인정보를
            수집하지 않습니다. 다만, 1:1 상담 및 문의 서비스를 통해 부득이하게 아동의 개인정보가
            수집된 경우 해당 개인정보를 지체 없이 파기하도록 하겠습니다.
          </ContentContainer>
        </TitleContainer>

        <TitleContainer
          style={{
            marginTop: '40px',
          }}
        >
          제8조 개인정보의 안정성 확보조치에 관한 사항
          <ContentContainer
            style={{
              marginTop: '10px',
            }}
          >
            회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
            <div
              style={{
                margin: '10px 12px',
              }}
            >
              1. 관리적 조치 : 내부관리계획 수립·시행 , 전담조직 운영 , 개인정보 취급자 최소화 및
              정기적 직원 교육
              <br />
              2. 기술적 조치 : 개인정보의 암호화, 보안프로그램 설치 및 갱신
            </div>
            위와 같은 노력 이외에 정보주체 스스로도 제3자에게 비밀번호 등이 노출되지 않도록 주의해야
            합니다. 특히 비밀번호 등이 공공장소에 설치한 PC를 통해 유출되지 않도록 항상 유의하시기
            바랍니다. 정보주체의 ID와 비밀번호는 반드시 본인만 사용하고 비밀번호를 자주 바꿔주는
            것이 좋습니다.
          </ContentContainer>
        </TitleContainer>

        <TitleContainer
          style={{
            marginTop: '40px',
          }}
        >
          제9조 개인정보를 자동으로 수집하는 장치의 설치 운영 및 그 거부에 관한 사항
          <ContentContainer
            style={{
              marginTop: '10px',
            }}
          >
            ① 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용 정보를 저장하고 수시로
            불러오는 ‘쿠키(cookie)’를 사용합니다.
            <br />② 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에게 보내는
            소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
            <div
              style={{
                margin: '10px 12px',
              }}
            >
              1. 쿠키의 사용목적 : 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과
              관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟
              마케팅 및 개인 맞춤 서비스 제공
              <br />
              2. 쿠키의 설치·운영 및 거부 : 웹브라우저 상단의 도구&gt;인터넷 옵션&gt;개인정보 메뉴의
              옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.
              <br />
              3. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
            </div>
            회사는 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용하지
            않습니다.
          </ContentContainer>
        </TitleContainer>

        <TitleContainer
          style={{
            marginTop: '40px',
          }}
        >
          제10조 행태정보의 수집·이용·제공 및 거부 등에 관한 사항
          <ContentContainer
            style={{
              marginTop: '10px',
            }}
          >
            ① 회사는 서비스 이용과정에서 정보주체에게 최적화된 맞춤형 서비스를 제공하기 위하여
            행태정보를 수집·이용하고 있습니다.
            <br />② 회사는 다음과 같이 행태정보를 수집합니다.
          </ContentContainer>
          <ContentTable>
            <thead>
              <tr>
                <TableTitleText>수집하는 행태정보의 항목</TableTitleText>
                <TableTitleText>행태정보 수집 방법</TableTitleText>
                <TableTitleText>행태정보 수집 목적</TableTitleText>
                <TableTitleText>보유·이용기간 및 이후 정보처리 방법</TableTitleText>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableContentText>웹사이트 / 앱 방문이력</TableContentText>
                <TableContentText>이용자의 웹 사이트 및 앱 방문/실행 시 자동 수집</TableContentText>
                <TableContentText>개인 맞춤형 서비스 제공</TableContentText>
                <TableContentText>수집일로부터 2년 후 파기</TableContentText>
              </tr>
            </tbody>
          </ContentTable>
          <ContentContainer
            style={{
              marginTop: '28px',
            }}
          >
            ③ 회사는 개인 맞춤형 서비스 제공 등에 필요한 최소한의 행태정보만을 수집하며, 사상, 신념,
            가족 및 친인척관계, 학력·병력, 기타 사회활동 경력 등 개인의 권리·이익이나 사생활을
            뚜렷하게 침해할 우려가 있는 민감한 행태정보를 수집하지 않습니다.
            <br />④ 회사는 모바일 앱에서 개인 맞춤형 서비스 제공을 위하여 광고식별자를 수집·이용
            합니다. 정보주체는 모바일 단말기의 설정 변경을 통해 앱의 맞춤형 광고를 차단·허용할 수
            있습니다.
            <div
              style={{
                margin: '10px 12px',
              }}
            >
              스마트폰의 광고식별자 차단/허용
              <br />
              1. (안드로이드) ① 설정 → ② 개인정보보호 → ③ 광고 → ③ 광고 ID 재설정 또는 광고ID 삭제
              <br />
              2. (아이폰) ① 설정 → ② 개인정보보호 → ③ 추적 → ④ 앱이 추적을 요청하도록 허용 끔
              <br />※ 모바일 OS 버전에 따라 메뉴 및 방법이 다소 상이할 수 있습니다.
            </div>
            ⑤ 정보주체는 웹브라우저의 쿠키 설정 변경 등을 통해 개인 맞춤형 서비스 제공을 일괄적으로
            차단·허용할 수 있습니다. 다만, 쿠키 설정 변경은 웹사이트 자동로그인 등 일부 서비스의
            이용에 영향을 미칠 수 있습니다.
            <div
              style={{
                margin: '10px 12px',
              }}
            >
              웹브라우저를 통한 맞춤형 광고 차단/허용
              <br />
              1. 인터넷 익스플로러(Windows 10용 Internet Explorer 11)
              <br />
              - Internet Explorer에서 도구 버튼을 선택한 다음 인터넷 옵션을 선택
              <br />
              - 개인 정보 탭을 선택하고 설정에서 고급을 선택한 다음 쿠키의 차단 또는 허용을 선택
              <br />
              2. Microsoft Edge
              <br />
              - Edge에서 오른쪽 상단 ‘…’ 표시를 클릭한 후, 설정을 클릭합니다.
              <br />
              - 설정 페이지 좌측의 ‘개인정보, 검색 및 서비스’를 클릭 후 「추적방지」 섹션에서
              ‘추적방지’ 여부 및 수준을 선택합니다.
              <br />
              - ‘InPrivate를 검색할 때 항상 "엄격" 추적 방지 사용’ 여부를 선택합니다.
              <br />
              - 아래 「개인정보」 섹션에서 ‘추적 안함 요청보내기’ 여부를 선택합니다.
              <br />
              3. 크롬 브라우저
              <br />
              - Chrome에서 오른쪽 상단 ‘ ’ 표시(chrome 맞춤설정 및 제어)를 클릭한 후, 설정 표시를
              클릭합니다.
              <br />
              - 설정 페이지 하단에 ‘고급 설정 표시’를 클릭하고 「개인정보」 섹션에서 콘텐츠 설정을
              클릭합니다.
              <br />
              - 쿠키 섹션에서 ‘타사 쿠키 및 사이트 데이터 차단’의 체크박스를 선택합니다웹브라우저를
              통한 맞춤형 광고 차단/허용
              <br />
            </div>
            ⑥ 정보주체는 제11조의 연락처로 행태정보와 관련하여 궁금한 사항과 거부권 행사, 피해 신고
            접수 등을 문의할 수 있습니다.
          </ContentContainer>
        </TitleContainer>

        <TitleContainer
          style={{
            marginTop: '40px',
          }}
        >
          제11조 개인정보 보호책임자에 관한 사항
          <ContentContainer
            style={{
              marginTop: '10px',
            }}
          >
            ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한
            정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보보호책임자를 지정하고
            있습니다.
          </ContentContainer>
          <ContentTable>
            <thead>
              <tr>
                <TableTitleText>구분</TableTitleText>
                <TableTitleText>개인정보보호책임자</TableTitleText>
                <TableTitleText>개인정보보호 담당부서</TableTitleText>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableContentText>성명</TableContentText>
                <TableContentText>김성환</TableContentText>
                <TableContentText>-</TableContentText>
              </tr>
              <tr>
                <TableContentText>부서</TableContentText>
                <TableContentText>-</TableContentText>
                <TableContentText>-</TableContentText>
              </tr>
              <tr>
                <TableContentText>직책</TableContentText>
                <TableContentText>대표</TableContentText>
                <TableContentText>-</TableContentText>
              </tr>
              <tr>
                <TableContentText>연락처</TableContentText>
                <TableContentText>
                  070-7932-7997
                  <br />
                  ars@zoomansa.com
                </TableContentText>
                <TableContentText>-</TableContentText>
              </tr>
            </tbody>
          </ContentTable>
          <ContentContainer
            style={{
              marginTop: '28px',
            }}
          >
            ② 정보주체는 회사의 서비스를 이용하시면서 발생한 모든 개인정보보호 관련 문의, 불만처리,
            피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의할 수 있습니다. 회사는
            정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.
            <br />
            ③ 정보주체는 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를 개인정보보호
            담당부서에 할 수 있습니다.
            <br />
            ④ 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기
            바랍니다.
            <br />
            <div
              style={{
                margin: '10px 12px',
              }}
            >
              1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
              <br />
              2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
              <br />
              3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
              <br />
              4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
            </div>
          </ContentContainer>
        </TitleContainer>

        <TitleContainer
          style={{
            marginTop: '40px',
          }}
        >
          제12조 개인정보 처리방침의 변경에 관한 사항
          <ContentContainer
            style={{
              marginTop: '10px',
            }}
          >
            ① 이 개인정보 처리방침은 2024. 12. 26부터 적용됩니다.
            <br />② 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.
          </ContentContainer>
        </TitleContainer>
        <Caption2
          style={{
            marginTop: '28px',
          }}
          onClick={() => {
            showWebBrowser(PAST_POLICY_PERSONAL)
          }}
          weight={'regular'}
          color={'blue'}
        >
          이전 버전 보러가기 &gt;
        </Caption2>
      </TopContainer>
    </MainContainer>
  );
};

export default PolicyPersonalPage;
