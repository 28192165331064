import { styled } from '@stitches/react';

export const Container = styled('div', {
  backgroundColor: '#fff',
  position: 'relative',
  marginTop: 20,
});

export const BottomContainer = styled('div', {
  flex: '1',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'fixed',
  bottom: 0,
  width: 'calc(100% - 40px)',
  backgroundColor: '#fff',
});
