export const isBankNotOperationTime = (): boolean => {
  const now = new Date();
  const currentHour = now.getHours();
  const currentMinutes = now.getMinutes();

  // 점검 시간 확인 (23:30~00:30)
  return (currentHour === 23 && currentMinutes >= 30) || (currentHour === 0 && currentMinutes < 30);
};

export const METHODS = ['신용(체크)카드 결제', '카카오페이'];
