import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  backgroundColor: '#fff',
  position: 'relative',
  marginTop: 28,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

export const PhoneNumberContainer = styled('div', {
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  marginLeft: '20px',
  marginRight: '20px',
  marginBottom: '30px',
});

export const SearchInput = styled('input', {
  border: 'none',
  background: 'transparent',
  fontSize: '15px',
  fontWeight: 400,
  color: '#333',
  width: '100%',
  outline: 'none',

  '&::placeholder': {
    color: '#B0B0B0',
    fontSize: '15px',
    fontWeight: 400,
  },
});

export const WrapSearch = styled('div', {
  width: 'calc(100%)',
  display: 'flex',
  backgroundColor: colors.primaryBackground,
  height: 45,
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px 16px',
  marginTop: 28,
  boxSizing: 'border-box',
  borderRadius: 4,
});

export const SearchIcon = styled('img', {
  width: '20px',
  height: '20px',
});

export const WrapMap = styled('div', {
  marginTop: '20px',
  flexGrow: 1,
});
