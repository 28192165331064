import {
  ListCancelUseItemContainer,
  PaymentCancelLeftButton,
  RequestPaymentCancelContainer,
  ReturnLeftButton,
  TerraceCancelDetailSpan,
  TerraceCancelRequestCheckSpan,
} from './styles';
import Title from '../../../components/atoms/Title/PageTitle';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CarNumberParkingDateSpan,
  CarParkingInfoContainer,
  ListCheckContainer,
  ListItemCheckIc,
  ListItemContainer,
  ListItemSpan,
  ParkingInfoContainer,
  SubTitleSpan,
  TerraceTowerBottomContentsContainer,
  TerraceTowerContainer,
  TicketSpan,
  TicketTimeInfoContainer,
  ViewDivide1,
  ViewDivide3,
} from '../styles';
import icCheckBlue from 'assets/ic/ic_check_blue.svg';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import icSuccessCheckGreen from 'assets/ic/ic_success_check_green.svg';
import icFailCheckRed from 'assets/ic/ic_fail_check_red.svg';
import { terraceTowerUsageHistoryDetailStore } from 'stores/store/TerraceTower/terraceTowerUsageHistoryDetailStore';
import { observer } from 'mobx-react';
import { Caption1, Caption2, SubHeadline } from 'styles/typography';
import { terraceTowerUsageHistoryCancelStore } from 'stores/store/TerraceTower/terraceTowerUsageHistoryCancelStore';
import { MainContainerNoMargin } from 'components/styles';

const TerraceTowerPaymentCancelRequestPage = observer(() => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { lotName, productName, carNumber, paymentAmount } =
    terraceTowerUsageHistoryDetailStore.usageHistoryDetail || {};

  const ticketInfoList = [
    { label: '차량정보', value: carNumber },
    { label: '주차장명', value: lotName },
    { label: '결제금액', value: paymentAmount },
  ];

  const cancelList = [
    '결제 취소 후 해당 주차장은 이용이 불가하며 주차권은 재사용이 어렵습니다.',
    '현장 요금 결제에 대한 취소는 고객센터로 별도로 문의 해주세요.',
    '카드사 업무 처리 절차에 따라 취소일로부터 영업일 기준 3~5일 소요될 수 있습니다.',
  ];

  const cancelUseList = [
    '필요 없어짐',
    '다른 주차장 이용 및 주차장 변경',
    '차량번호 잘못 입력',
    '기타',
  ];

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setSelectedIndex(index === selectedIndex ? null : index);
  };

  const saleHistoryUID = sessionStorage.getItem('saleHistoryUID') || '';

  if (saleHistoryUID !== null) {
    console.log(`불러온 값: ${saleHistoryUID}`);
  } else {
    console.log('세션스토리지에 값이 없습니다.');
  }

  const handleCancelPayment = async () => {
    if (selectedIndex === null) {
      return;
    }
    const comment = cancelUseList[selectedIndex];

    const success = await terraceTowerUsageHistoryCancelStore.fetchTerraceTowerUsageHistoryCancel(
      saleHistoryUID,
      comment,
    );

    if (success) {
      showToast('결제 취소되었습니다.', icSuccessCheckGreen);
      navigate('/terraceTowerPaymentCancel');
    } else {
      showToast('결제 취소 중 문제가 발생했습니다.', icFailCheckRed);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchTerraceTowerUsageHistoryDetail = async () => {
      await terraceTowerUsageHistoryDetailStore.fetchTerraceTowerUsageHistoryDetail(saleHistoryUID);
    };
    fetchTerraceTowerUsageHistoryDetail().catch(error => {
      console.log('테라스 타워 주차정 정보 가져오는 중 에러 발생', error);
    });
  }, []);

  return (
    <MainContainerNoMargin>
      <Title
        title="결제 취소 요청"
        leftArrowButtonVisible={true}
        leftOnClick={() => {
          navigate(-1);
        }}
        rightTextButtonVisible={false}
      />
      <div>
        <TerraceTowerContainer>
          <TerraceCancelRequestCheckSpan>결제 취소 전 확인해 주세요!</TerraceCancelRequestCheckSpan>
          <Caption1 weight={'regular'} css={{ color: '#555', marginTop: '20px' }}>
            결제 취소 시 바로 취소 처리됩니다. 되돌릴 수 없으니 신중하게 진행해 주세요.
          </Caption1>

          <TicketTimeInfoContainer>
            <SubHeadline weight={'semibold'} css={{ color: '#333' }}>
              {productName}
            </SubHeadline>
            <ViewDivide3 />
            <CarParkingInfoContainer>
              {ticketInfoList.map((ticketItem, index) => (
                <ParkingInfoContainer key={index} style={{ marginTop: index === 0 ? 0 : '10px' }}>
                  <Caption1 weight={'regular'} css={{ color: '#868686' }}>
                    {ticketItem.label}
                  </Caption1>
                  <Caption1 weight={'medium'} css={{ color: '#555555' }}>
                    {ticketItem.value}
                  </Caption1>
                </ParkingInfoContainer>
              ))}
            </CarParkingInfoContainer>
          </TicketTimeInfoContainer>
          <ListCheckContainer>
            {cancelList.map((cancelItem, index) => (
              <ListItemContainer key={index} hasMargin={index !== 0}>
                <ListItemCheckIc src={icCheckBlue} alt="파란색 체크 아이콘" />
                <Caption2 weight={'regular'} style={{ color: '#6b6b6b', marginLeft: '8px' }}>
                  {cancelItem}
                </Caption2>
              </ListItemContainer>
            ))}
          </ListCheckContainer>
        </TerraceTowerContainer>
        <ViewDivide1 />
        <TerraceTowerBottomContentsContainer>
          <SubTitleSpan>취소사유</SubTitleSpan>
          <Caption1 weight={'regular'} style={{ color: '#555', marginTop: '16px' }}>
            알려주신 정보로 더발전하는 주만사가 되겠습니다:)
          </Caption1>

          <ListCheckContainer>
            {cancelUseList.map((cancelUseItem, index) => (
              <ListCancelUseItemContainer
                key={index}
                hasMargin={index !== 0}
                onClick={() => handleClick(index)} // 클릭 시 handleClick 호출
                style={{
                  backgroundColor: selectedIndex === index ? '#F5F5FF' : '#F8F8FA',
                  color: selectedIndex === index ? '#8B63BB' : '#333333',
                  border: selectedIndex === index ? '2px solid #8B63BB' : '1px solid #F8F8FA',
                }}
              >
                <ListItemSpan>{cancelUseItem}</ListItemSpan>
              </ListCancelUseItemContainer>
            ))}
          </ListCheckContainer>

          <RequestPaymentCancelContainer>
            <ReturnLeftButton
              onClick={() => {
                navigate(-1);
              }}
            >
              돌아가기
            </ReturnLeftButton>
            <PaymentCancelLeftButton
              onClick={() => {
                handleCancelPayment();
              }}
              state={selectedIndex !== null ? 'active' : 'inactive'}
              disabled={selectedIndex === null}
            >
              결제 취소하기
            </PaymentCancelLeftButton>
          </RequestPaymentCancelContainer>
        </TerraceTowerBottomContentsContainer>
      </div>
    </MainContainerNoMargin>
  );
});

export default TerraceTowerPaymentCancelRequestPage;
