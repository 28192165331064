import { styled } from '@stitches/react';

export const CarNumberCheckModalContainer = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  padding: '40px 20px',
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  transform: 'translateY(100%)',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  borderRadius: '20px 20px 0px 0px',
  zIndex: 10000,
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' },
      false: { transform: 'translateY(100%)' },
    },
  },
});

export const ModalOverlay = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  zIndex: 9999,
});

export const CarNumberCheckModalMainContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  fontFamily: 'Pretendard',
  fontSize: '18px',
  fontWeight: 600,
  fontStyle: 'normal',
  lineHeight: '20px',
});

export const CarNumberMistakeContainer = styled('div', {
  textAlign: 'left',
  marginTop: '15px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '22px',
  wordBreak: 'keep-all',
});

export const CarNumberBottomButtonContainer = styled('div', {
  width: '100%',
  height: '46px',
  display: 'flex',
  marginTop: '28px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  boxSizing: 'border-box',
});

export const NumberCheckRightButton = styled('button', {
  flex: 1,
  width: '100%',
  padding: '14px',
  fontSize: '17px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  color: '#FFFFFF',
  border: 'none',
  backgroundColor: '#8B63BB',
  borderRadius: '4px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  cursor: 'none',
});
