import React, {useEffect, useRef, useState} from 'react';
import {Body, Caption1, Caption2, Caption3} from 'styles/typography';
import {useNavigate} from 'react-router-dom';
import {
  NotUseParkingContainer,
  SpinnerContainer,
  TicketContentsContainer,
  TicketContentsDetailContainer,
  TicketItemContainer,
  TicketTopContainer,
  TicketTypeContainer,
} from 'pages/MyParking/styles';
import {colors} from 'styles/colors';
import {myParkingUseHistoryStore} from "stores/store/MyParking/myParkingUseListStore";
import {observer} from "mobx-react";
import {Spinner} from "components/atoms/LoadingSpinner/LoadingSpinnerImage/styles";
import Button from "components/atoms/Button/Button";
import Row from "components/atoms/Row";
import {JSP_URL} from "constants/baseConstants";
import {IUserInfo} from "models/UserInfo/userInfoModel";
import {userInfoStore} from "stores/store/UserInfo/userInfoStore";
import {encTextWord, getRandomString} from "utils/cryptoJS";
import useUserInfo from "hooks/useUserInfo";

const MyParkingUseList = observer(() => {
  const navigate = useNavigate();
  const isFirstOrder = true

  const moveZoomansaJSPMain = () => {
    window.location.href = JSP_URL
  }

  const { isLoading, currentPage, parkingUseHistoryList, totalCount, itemsPerPage } = myParkingUseHistoryStore;

  const { iv, salt } = getRandomString();
  const { memberID, memberPW, loginType } = useUserInfo()
  const memberEncPw = encTextWord(memberPW, iv, salt)
  const [memberUID, setMemberUID] = useState<number | null>(3)
  const bottomRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (!memberID || !memberPW || !loginType) {
        console.warn('Required values are missing:', {
          memberID,
          memberPW,
          loginType,
        });
        return
      }

      const requestBody: IUserInfo = {
        memberID,
        memberPW: memberEncPw,
        loginType,
      }

      try {
        await userInfoStore.fetchUserInfoSearch(requestBody, iv, salt);

        if (userInfoStore.memberUID != null) {
          setMemberUID(userInfoStore.memberUID)
        }
      } catch (error) {
        console.error('Error fetching user info:', error)
      }
    }
    fetchUserInfo().catch(error => console.error('Error in fetchData:', error))
  }, [memberID, memberPW, loginType])

  useEffect(() => {
    const fetchData = async () => {
      if (memberUID == null) {
        console.warn("memberUID is null, skipping fetch.");
        return
      }

      try {
        const response = await myParkingUseHistoryStore.fetchGetMyParkingUseHistory(memberUID, 1)
        console.log('Response:', response)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData().catch((error) => {
      console.error('point history bottom List:', error)
    })
  }, [])

  useEffect(() => {
    if (memberUID == null) {
      console.warn("memberUID is null, skipping fetch.");
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(async (entry) => {
          if (entry.isIntersecting && !isLoading && currentPage * itemsPerPage < totalCount) {
            try {
              // 비동기 요청을 처리하기 위해 await을 사용
              await myParkingUseHistoryStore.fetchGetMyParkingUseHistory(memberUID, currentPage + 1);
            } catch (error) {
              console.error("Error fetching parking history:", error);
            }
          }
        });
      },
      { threshold: 1.0 } // 하단이 100% 보일 때만 실행
    );

    // 하단에 해당하는 ref가 있을 때만 observer 시작
    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }

    // 컴포넌트 언마운트 시 observer 해제
    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current);
      }
    };
  }, [isLoading, currentPage, totalCount, itemsPerPage, memberUID]);

  const mapTicketTypeToVariant = (ticketType: string) => {
    switch (ticketType) {
      case '정기권':
        return 'seasonTicket';
      case '시간권':
        return 'default';
      case '시간제':
        return 'default';
      default:
        return 'default';
    }
  }

  return (
    <div style={{backgroundColor: colors.gray10}}>
      {isLoading ? (
        <SpinnerContainer>
          <Spinner/>
        </SpinnerContainer>
      ) : parkingUseHistoryList.length === 0 ? (
        <NotUseParkingContainer>
          <Caption1 weight={'medium'} color={'gray09'}>
            아직 이용한 주차장이 없어요.
          </Caption1>
          <Button
            style={{height: '2.5rem', marginTop: 12, marginBottom: 40, paddingLeft: 12, paddingRight: 16}}
            radius={20}
            onClick={() => {
              moveZoomansaJSPMain()
            }}
          >
            <Row>
              <Caption1 weight={'medium'} color={'primary'} style={{marginLeft: 4}}>
                이용권 보러가기
              </Caption1>
            </Row>
          </Button>
        </NotUseParkingContainer>
      ) : (
        <div>
          {parkingUseHistoryList.map((item, index) => (
            <TicketItemContainer
              ref={bottomRef}
              key={index}
              onClick={() => {
                // JSP로 넘기자 시간권 빼고
                if (item.passType === '정기권' && item.status === '결제대기' && !isFirstOrder) {
                  alert('jsp 결제 페이지')
                  return
                }

                if (item.passType === '정기권') {
                  navigate('/myParkingSeasonTicketMain');
                  return
                }

                if (item.passType === '시간권') {
                  navigate('/terraceTowerDetail')

                  return
                }

                if (item.passType === '시간제') {
                  navigate('/myParkingPartTimeMain');
                  return
                }
              }}
            >
              <TicketTopContainer>
                {item.status === '결제대기' ? (
                  <Caption2 weight="semibold" color="red">
                    {item.status}
                  </Caption2>
                ) : (
                  <Caption2 weight="semibold" color="primary">
                    {item.status}
                  </Caption2>
                )}
                <TicketTypeContainer ticketType={mapTicketTypeToVariant(item.passType)}>
                  {item.passType === '정기권' ? (
                    <Caption3 weight="bold" color="primary">
                      {item.passType}
                    </Caption3>
                  ) : (
                    <Caption3 weight="bold" color="blue">
                      {item.passType}
                    </Caption3>
                  )}
                </TicketTypeContainer>
              </TicketTopContainer>

              <Body weight={'medium'} color={'black1'}>
                {item.passType === '시간제' ? item.regionRealCode : item.productName}
              </Body>

              <TicketContentsContainer>
                <TicketContentsDetailContainer>
                  <Caption2
                    style={{width: '28px',}} weight={'regular'} color={'gray06'}>
                    주소
                  </Caption2>
                  <Caption2 style={{marginLeft: '8px'}} weight={'medium'} color={'gray08'}>
                    {item.passType === '시간제' ? item.lotName : item.lotAddress}
                  </Caption2>
                </TicketContentsDetailContainer>

                <TicketContentsDetailContainer style={{marginTop: '8px'}}>
                  <Caption2 style={{
                    width: '28px'
                  }} weight={'regular'} color={'gray06'}>
                    차량
                  </Caption2>
                  <Caption2 style={{marginLeft: '8px'}} weight={'medium'} color={'gray08'}>
                    {item.carNumber}
                  </Caption2>
                </TicketContentsDetailContainer>

                <TicketContentsDetailContainer
                  style={{ marginTop: '8px', }}
                >
                  <Caption2 style={{
                    width: '28px'
                  }} weight={'regular'} color={'gray06'}>
                    이용
                  </Caption2>
                  <div style={{
                    marginLeft: '8px'
                  }}>
                    {
                      item.passType !== "시간제" ? (
                        <Caption2 weight="medium" color="primary">
                          {item.usingTime}
                        </Caption2>
                      ) : (
                        <Caption2 weight="medium" color="primary">
                          {item.parkingStartTime?.split(' ')[0] ?? ''}
                        </Caption2>
                      )
                    }
                  </div>
                </TicketContentsDetailContainer>

                {
                  item.passType === "시간제" && (
                    <Caption2 style={{marginTop: '8px', marginLeft: '36px'}} weight={'medium'} color={'primary'}>
                      {item.parkingStartTime?.split(' ')[1] ?? ''} 부터 주차 시작
                    </Caption2>
                  )
                }

                {
                  item.reductionInfo && (
                    <TicketContentsDetailContainer
                      style={{marginTop: '8px'}}
                    >
                      <Caption2 weight={'regular'} color={'gray06'}>
                        감면
                      </Caption2>
                      <Caption2
                        style={{
                          marginLeft: '8px',
                        }}
                        weight={'medium'}
                        color={'gray08'}
                      >
                        {item.reductionInfo}
                      </Caption2>
                    </TicketContentsDetailContainer>
                  )
                }
                {
                  item.refundDates && (
                    <TicketContentsDetailContainer style={{
                      marginTop: '20px',
                      backgroundColor: '#F2F4F7',
                      borderRadius: '4px'
                    }}>
                      <div style={{
                        margin: '10px'
                      }}>
                        <Caption3 weight={'semibold'} color={'gray07'}>부분환불</Caption3>
                        <Caption3 color={'gray07'} style={{marginTop: '8px'}}
                                  weight={'regular'}>{item.refundDates}</Caption3>
                      </div>
                    </TicketContentsDetailContainer>
                  )
                }
              </TicketContentsContainer>
            </TicketItemContainer>
          ))}
        </div>
      )}
    </div>
  )
})
export default MyParkingUseList;
