import React, { useState } from 'react';
import { ToggleCircle, ToggleSwitch, ToggleWrapper } from 'components/atoms/CustomToggle/styles';

interface CustomToggleProps {
  isOn?: boolean; // 초기 상태
  onToggle?: (isOn: boolean) => void; // 토글 상태 변경 콜백
  toggleStyle?: React.CSSProperties;
  circleStyle?: React.CSSProperties;
}

const CustomToggle: React.FC<CustomToggleProps> = ({
  isOn = false,
  onToggle,
  toggleStyle,
  circleStyle,
}) => {
  const handleToggle = () => {
    const newState = !isOn;
    if (onToggle) {
      onToggle(newState); // 토글 변경 시 외부로 콜백 호출
    }
  };

  return (
    <ToggleWrapper onClick={handleToggle}>
      <ToggleSwitch isOn={isOn} style={toggleStyle}>
        <ToggleCircle isOn={isOn} style={circleStyle} />
      </ToggleSwitch>
    </ToggleWrapper>
  );
};

export default CustomToggle;
