import { styled } from '@stitches/react';

export const CheckModalContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  textAlign: 'center',
  backgroundColor: '#fff',
  borderRadius: '8px',
  width: 'calc(100% - 40px)',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  height: 'auto',
});

export const CheckModalTitleSpan = styled('span', {
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  marginBottom: '14px',
  color: '#514263',
})

export const CheckModalButton = styled('button', {
  fontSize: '14px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  color: '#fff',
  backgroundColor: '#8B63BB', // 버튼 색상
  borderRadius: '0px 0px 6px 6px',
  border: 'none',
  cursor: 'pointer',
  textAlign: 'center',
  width: '100%',
  padding: '15px 0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

