import BaseError from 'models/baseError';
import AxiosClient from 'utils/axiosClient';
import ErrorHandler from 'utils/error/errorHandler';
import BaseService from 'service/baseService';
import { IAuthCodeByTell, IAuthCodeByTellResponse } from 'models/Auth/AuthCodeByTellModel';
import {
  IAuthModifyMemberTell,
  IAuthModifyMemberTellResponse,
} from 'models/Auth/AuthModifyMemberTell';

interface AuthApiEndpoint {
  AUTH_CODE_BY_TELL: string;
  AUTH_VALIDATE_MODIFY_MEMBER_TELL: string;
}

class AuthService extends BaseService<AuthApiEndpoint> {
  apiEndpoints: AuthApiEndpoint = {
    AUTH_CODE_BY_TELL: 'getAuthCodeByTell',
    AUTH_VALIDATE_MODIFY_MEMBER_TELL: 'validateAuthAndModifyMemberTell',
  };

  constructor() {
    super();
  }

  getAuthCodeByTell = async (
    requestBody: IAuthCodeByTell,
    iv: string,
    salt: string,
  ): Promise<IAuthCodeByTellResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .headers({
          'Content-Type': 'application/json',
          iv,
          salt,
        })
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.AUTH_CODE_BY_TELL}`)
        .build<IAuthCodeByTellResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  validateAuthAndModifyMemberTell = async (
    requestBody: IAuthModifyMemberTell,
    iv: string,
    salt: string,
  ): Promise<IAuthModifyMemberTellResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .headers({
          'Content-Type': 'application/json',
          iv,
          salt,
        })
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.AUTH_VALIDATE_MODIFY_MEMBER_TELL}`)
        .build<IAuthModifyMemberTellResponse>();
      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };
}

export default new AuthService();
