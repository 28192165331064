import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, Text } from './styles';
import { Caption2, Title2 } from 'styles/typography';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';

const Footer = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Text>
        {`본 서비스는 회원제로 운영되지 않으며 입력 된 정보는 주차 이용 목적 외에 활용되지 않습니다.개인정보 취급, 요금부과기준 및 부정주차관련 단속근거 등은 이용약관을 확인해 주세요.\n\n주만사 / 통신판매업신고 제 2019-서울영등포-2230호`}
      </Text>

      <Button
        radius={50}
        backgroundColor={colors.primaryBackground}
        onClick={() => {
          navigate('/policyPersonal');
        }}
        style={{
          width: 119,
          marginTop: 17,
          border: '0.5px solid #868686',
        }}
      >
        <Text>이용약관 및 개인정보 처리방침</Text>
      </Button>
    </Container>
  );
};

export default Footer;
