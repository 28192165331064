export const SERVICE_CHAPTER1_TITLE = '제 1조 (목적)'
export const SERVICE_CHAPTER2_TITLE = '제 2조 (용어의 정의)'
export const SERVICE_CHAPTER3_TITLE = '제 3조 (약관의 게시와 개정)'
export const SERVICE_CHAPTER4_TITLE = '제 4조 (약관의 해석)'
export const SERVICE_CHAPTER5_TITLE = '제 5조 (회원에 대한 통지)'
export const SERVICE_CHAPTER6_TITLE = '제 6조 (이용계약의 성립)'
export const SERVICE_CHAPTER7_TITLE = '제 7조 (회원가입)'
export const SERVICE_CHAPTER8_TITLE = '제 8조 (회원 탈퇴 및 자격 상실)'
export const SERVICE_CHAPTER9_TITLE = '제 9조 (서비스의 내용)'
export const SERVICE_CHAPTER10_TITLE = '제 10조 (이용자의 서비스의 이용)'
export const SERVICE_CHAPTER11_TITLE = '제 11조 (공유자의 서비스 이용)'
export const SERVICE_CHAPTER12_TITLE = '제 12조 (서비스의 변경)'
export const SERVICE_CHAPTER13_TITLE = '제 13조 (포인트와 적립금)'
export const SERVICE_CHAPTER14_TITLE = '제 14조 (쿠폰)'
export const SERVICE_CHAPTER15_TITLE = '제 15조 (현금영수증 및 세금계산서)'
export const SERVICE_CHAPTER16_TITLE = '제 16조 (보증금)'
export const SERVICE_CHAPTER17_TITLE = '제 17조 (환불)'
export const SERVICE_CHAPTER18_TITLE = '제18조 (페널티)'
export const SERVICE_CHAPTER19_TITLE = '제19조 (회사의 의무)'
export const SERVICE_CHAPTER20_TITLE = '제20조 (회원의 의무)'
export const SERVICE_CHAPTER21_TITLE = '제21조 (회원의 금지사항)'
export const SERVICE_CHAPTER22_TITLE = '제22조 (손해배상)'
export const SERVICE_CHAPTER23_TITLE = '제23조 (면책)'
export const SERVICE_CHAPTER24_TITLE = '제24조 (고충처리)'
export const SERVICE_CHAPTER25_TITLE = '제25조 (서비스의 종료)'
export const SERVICE_CHAPTER26_TITLE = '제26조 (분쟁해결 및 관할법원)'

export const SEASON_TICKET = '가. 월정기권'
export const SHARED_PARKING_TICKET = '나. 공유주차권'
export const TIME_TICKET = '다. 제휴주차권'
export const POINT_SAVED_MONEY = '라. 포인트와 적립금'
export const COUPON = '마. 쿠폰'

export const SERVICE_CHAPTER1 =
  '본 이용약관은(이하 “약관”)은 주식회사 주만사(이하 “회사”)가 “주만사”에서 제공하는 모든 서비스를 이용함에 있어 “주차공간 제공자” 및 “이용자”의 권리, 의무, 책임 사항 및 기타 부수 사항을 규정하는 것을 목적으로 합니다.'

export const SERVICE_CHAPTER2_DEFINE =
  '회사의 약관에서 사용하는 주요 용어의 정의는 다음과 같습니다.'

export const SERVICE_CHAPTER2 = [
  '1. “주만사”이라 함은 단말기에 구분 없이 온라인 웹, ARS 서비스, 모바일 애플리케이션 등 정보통신 소프트웨어를 이용한 “주차공간” 중개서비스와 이와 관련한 부가 서비스 일체(이하 “서비스”)를 통칭합니다.',
  '2. “주차공간”이란 자동차의 주차를 위한 시설로서 주차장법에 의한 주차장을 의미합니다.',
  '3. “주차공간 제공자”란 “주만사”를 통해 “주차공간”의 정보를 “서비스”에 등록하여 “주차공간”을 제공하는 자를 말하며, “공유자”, “배정자”를 통칭합니다.',
  '4. “공유자”란 세입자, 세대주, 건물주 등 소유 혹은 관리 및 운영 권한이 있는 “주차공간”을 “이용자”가 사용할 수 있도록 제공하는 주체로서, “호스트(Host)”라고 하기도 합니다.',
  '5. “배정자”란 지방자치단체에서 운영하는 거주자우선주차장에 배정된 자로 거주자우선 공유 주차 사업이 협약된 지방자치단체의 공유주차장 사업에 참여한 자를 의미합니다.',
  '6. “이용자”란 “회사”가 제공하는 모든 “서비스”를 이용하기 위해 본 약관에 동의하고 “회사”가 제공하는 서비스를 이용하는 자를 의미합니다.',
  '7. “회원”이란 “회사”가 제공하는 “서비스”를 지속해서 이용하기 위해 “회사”가 정한 회원가입 절차를 완료한 “주차공간 제공자” 또는 “이용자”로서 “주만사”의 정보를 지속해서 제공받으며 “서비스”를 이용할 수 있는 자를 의미합니다.',
  '8. “비회원”이란 회원등록 없이 “회사”가 제공하는 “서비스”를 이용하는 “주차공간 제공자” 또는 “이용자”를 의미합니다.',
  '9. “매니저”란 “주차공간 제공자”와 “이용자”가 원활한 “서비스”를 이용할 수 있도록 “회사”에 소속된 직원을 의미합니다.',
  '10. “주차상품”이란 “이용자”가 “주차공간”을 사용할 수 있도록 제공하는 재화로 “주차공간”의 사용 기간, 이용 가능 시간 등에 따라 월정기권, 시간권, 일일권, 공유주차권, 제휴주차권 등으로 구분합니다.',
  '11. “정산금”이란 “회사”가 “주차공간 제공자”에게 “이용자”의 “주차공간” 사용에 대해 “이용자”로부터 주차요금을 징수하여 대가성으로 지급하는 수익을 의미합니다.',
  '12. “포인트”란 “주차공간 제공자”의 사전 동의를 거쳐 “주차공간 제공자”에게 “주차공간” 사용에 대해 전자적으로 지급하는 수익을 의미합니다.',
  '13. “적립금”이란 지방자치단체별로 협약된 내용에 따라 거주자 우선주차 공유 사업에 참여한 “배정자”에게 지급하는 수익을 의미합니다.',
  '14. 본 약관에 명시하지 않은 용어의 정의는 관계 법령 및 서비스 별 안내에서 정하는 바에 의합니다.',
]

export const SERVICE_CHAPTER3 = [
  '1. 회사는 본 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 팩스, 전자우편주소, 사업자등록번호 등을 “주차공간 제공자” 또는 “이용자”가 확인할 수 있도록 “주만사” 초기 서비스화면(전면)에 게시합니다.',
  '2. 회사는 「약관의 규제에 관한 법률」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 등 관련 법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.',
  '3. 본 약관의 내용은 서비스화면에 게시하거나 기타의 방법으로 “서비스”에 가입하여 이용하고자 하는 자에게 공지하고, 이에 “주차공간 제공자” 또는 “이용자”가 동의함으로써 효력이 발생합니다.',
  '4. 회사는 필요하다고 판단될 때 본 약관을 변경할 수 있으며, 회사가 약관을 변경하는 경우에는 적용 일자 및 변경 사유를 명시하고 제3항과 같은 방법으로 효력 발생일 최소 7일 전에 공지합니다. 다만, ”주차공간 제공자” 또는 “이용자”에게 불리하거나 중대한 변경인 경우 효력 발생일 최소 30일 전에 개정 약관을 공지하고 E-mail, SMS, App Push, 카카오톡을 통해 “주차공간 제공자” 또는 “이용자”에게 개별 통지합니다. 단, “주차공간 제공자” 또는 “이용자”가 연락처 등을 기재하지 않았거나 변경 후 미수정 등으로 인해 개별 통지가 어려운 경우에 한해 본 조 제3항과 같은 방법으로 공지함으로써 개별 통지한 것으로 간주합니다.',
  '5. “주차공간 제공자” 또는 ”이용자”는 변경된 이용약관에 동의하지 않을 시에는 “서비스” 이용을 중단하고 계약을 해지할 수 있습니다. 약관 변경 적용일까지 거부 의사를 표시하지 않을 경우, 약관의 변경에 동의한 것으로 간주한다는 내용을 공지 또는 통지하였음에도 “주차공간 제공자” 또는 ”이용자”가 명시적으로 약관 변경에 대한 거부 의사를 표시하지 아니하면, 변경된 약관에 동의한 것으로 간주합니다.',
]

export const SERVICE_CHAPTER4 = [
  '1. 약관의 해석은 한국어로 작성된 내용을 기준으로 하며, 영어를 비롯한 다른 언어로 번역되어 한국어 내용과 상이하게 해석되는 경우, 한국어 해석을 우선하여 따릅니다.',
  '2. 회사는 개별 “서비스”에 대해서는 별도의 개별약관 및 개별정책을 둘 수 있으며, 본 약관과 상충하는 내용이 있는 경우에는 개별약관 및 개별정책의 내용을 우선하여 적용됩니다.',
  '3. 본 약관 및 개별약관, 개별정책에 명시되지 않은 사항에 대해서는 관계 법령 및 상관례에 따릅니다.',
]

export const SERVICE_CHAPTER5 = [
  '1. 회사는 ”회원”이 등록한 E-mail, SMS, App Push, 카카오톡 등으로 통지할 수 있습니다. 다만, 회사가 불특정 다수 “회원”에 대해 통지를 하는 경우 서비스화면에 게시하는 방법 등을 활용할 수 있습니다.',
  '2. 회사는 “서비스”를 운영함에 있어 “서비스” 관련 각종 정보를 개별 서비스화면에 게시하거나 제1항에 정한 방법으로 “회원”에게 제공할 수 있습니다.',
  '3. 본 조 제1항에서 등록이라 함은 ”회원”이 회사에 “서비스”와 관련된 사항에 대해 요청할 경우도 해당합니다.',
  '4. 회사는 “회원”이 수신거부 의사를 밝힐 경우 그 즉시 발송을 중단합니다.',
  '5. 본 조 제4항에 따라 발생하는 “회원”의 불이익에 대해서는 회사는 어떤 책임도 지지 않습니다.',
  '6. 회사는 “서비스”를 운영하면서 회사 또는 제휴사의 각종 광고를 서비스화면에 게시하거나 “회원”의 동의를 얻어 본 조 제1항에 정한 방법으로 “회원”에게 제공할 수 있습니다.',
  '7. “회원”의 연락처 미기재, 변경 후 미수정 등으로 개별 통지가 어려운 경우 제1항 단서의 공지를 함으로써 개별 통지를 한 것으로 봅니다.',
]

export const SERVICE_CHAPTER6 = [
  '1. 이용계약은 “주차공간 제공자” 또는 “이용자”의 본 약관에 대한 동의와 이용 신청에 대해 회사가 이용을 승낙함으로써 성립합니다.',
  '2. 회사는 본 조 제1항에 따른 가입 절차의 진행 과정에서 본인확인을 위하여 실명 확인 또는 본인인증 등 절차의 진행을 요구할 수 있습니다. 이 경우 “주차공간 제공자” 또는 “이용자”는 해당 인증을 수행하는 등 가입 절차를 완료하여야 합니다.',
  '3. 회사는 원칙적으로 본 조 제1항에 따른 이용 신청을 승낙합니다. 다만, 회사는 각호에 해당하는 사유가 있는 경우 “주차공간 제공자” 또는 “이용자” 이용신청에 대한 승낙을 유보하거나 거절할 수 있습니다.',
]

export const SERVICE_CHAPTER6_THIRD_DETAIL = [
  '가. 기술적 문제로 “서비스” 제공이 불가능한 경우',
  '나. 타인의 명의를 이용하여 이용 신청을 한 경우',
  '다. 회사가 요구하는 실명 확인 또는 본인인증 등 절차를 거치지 않았거나 허위 또는 부적절한 방법으로 거친 경우',
  '라. 이용신청 과정에서 허위의 정보를 기재하였거나, 회사가 요구하는 기재 항목을 기재하지 않거나 오기한 경우',
  '마. 이전에 어떠한 사유로 회원자격을 박탈당하였거나 이용계약을 해지 당하였던 자가 이용신청을 한 경우',
  '바. 본 약관 또는 관련 법령 등에 위배되거나 회사가 정한 이용 신청 요건을 충족하지 못하는 경우',
  '사. 천재지변, 긴급한 행사 또는 그 밖에 이에 준하는 사유가 있는 경우',
  '아. 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청한 경우',
  '자. 만 14세 미만 아동이 “서비스” 이용 신청을 한 경우',
  '차. 회사의 경영 사정상 또는 긴급한 사정으로 “주차공간 제공자” 또는 “이용자”의 “서비스” 이용을 제한하고자 하는 경우',
  '카. 기타 회사가 필요하다고 인정하는 경우',
]

export const SERVICE_CHAPTER6_REMAIN =
  '4. 회사는 “비회원”에게 “서비스”의 이용을 제한하거나 일부 “서비스”를 제공하지 않을 수 있으며, “비회원”은 이에 대해 이의를 제기할 수 없습니다.';

export const SERVICE_CHAPTER7 = [
  '1. “주차공간 제공자” 또는 “이용자”는 회사가 정한 가입 양식에 따라 회원정보를 기입한 후 본 약관에 동의한다는 의사표시를 함으로써 회원가입을 신청합니다.',
  '2. 회사는 본 조 제1항과 같이 “회원”으로 가입할 것을 신청한 이용자 중 다음 각호에 해당하지 않는 한 “회원”으로 등록합니다.',
]

export const SERVICE_CHAPTER7_SECOND_DETAIL = [
  '가. 등록 내용에 허위, 기재 누락, 오타가 있는 경우',
  '나. 가입 신청자가 본 약관 제8조 제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단 제8조 제2항에 의한 회원자격 상실 후 3년이 경과한 자로서 회사의 “회원” 재가입 승낙을 얻은 경우',
  '다. 악성 프로그램 및 버그를 이용하거나 시스템 취약점을 악용하는 등 부정한 방법을 “서비스”에 사용한 경우',
  '라. 관계법령의 위반을 목적으로 신청하거나 그러한 행위를 한 경우',
  '마. 사회의 안녕질서 또는 미풍양속을 저해하거나, 저해할 목적으로 신청한 경우',
  '바. 기타 회사가 정한 회원가입 요건이 만족하지 않았을 경우',
]

export const SERVICE_CHAPTER8 = [
  '1. “회원”은 회사에 언제든지 탈퇴를 요청할 수 있으며 “회원” 본인이 직접 회사가 제공하는 경로를 통하여 “회원” 탈퇴 신청을 해야 합니다.',
  '2. “회원”이 다음 각호의 사유에 해당하는 경우, 회사는 회원자격을 제한 및 정지시킬 수 있습니다.',
]

export const SERVICE_CHAPTER8_SECOND_DETAIL = [
  '가. 가입 신청 시 허위 내용을 등록한 경우',
  '나. 회사가 제공하는 “서비스”를 이용하여 구입한 재화 등의 대금, 기타 “서비스” 이용과 관련하여 “회원”이 부담하는 채무를 기일에 지급하지 않는 경우',
  '다. 다른 사람의 “서비스” 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우',
  '라. 본 약관 제20조에 규정된 “회원”의 의무를 지키지 않는 경우',
  '마. “서비스”를 이용하여 법령 또는 본 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우',
]

export const SERVICE_CHAPTER8_REMAIN = [
  '3. 회사가 회원자격을 제한·정지시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내 그 사유가 시정되지 아니하는 경우 회사는 회원자격을 상실시킬 수 있습니다.',
  '4. 회원자격이 상실되는 경우, 회사는 관계법령, 개인정보처리방침 및 회사의 규정에 의해 보존할 필요가 있는 경우 법령에서 규정한 보존기간 동안 최소한의 기본정보를 보유합니다.',
]

export const SERVICE_CHAPTER9 = [
  '1. 회사가 “회원”에게 제공하는 “서비스”는 기본서비스와 정산서비스, 부가서비스 등으로 구분하며, “서비스”의 종류와 내용은 회사가 공지나 “서비스” 이용안내에서 별도로 정하는 바에 의합니다.',
  '2. “서비스”는 회사의 업무상 또는 기술상 장애 등 특별한 지장이 없는 한 연중무휴, 1일 24시간을 제공함을 원칙으로 합니다. 단, 정기점검 등의 필요로 인하여 “서비스”를 중단할 수 있습니다. “서비스”를 중단할 경우에는 사전공고를 실시하며, 천재지변과 같은 불가피한 경우 사전공고는 생략될 수 있습니다.',
  '3. 회사가 “이용자”에게 제공하는 “서비스”는 다음 각호와 같습니다.',
];

export const SERVICE_CHAPTER9_THIRD_DETAIL = [
  '가. “이용자”의 위치, 설정 또는 검색한 위치 주변의 “주차공간” 이름, 이용시간, 판매금액 정보 등 제공',
  '나. “주차상품”에 대한 정보(상품 종류, 이용시간, 위치, 판매 금액) 제공',
  '다. “주차상품” 등 유료상품 이용신청 및 결제',
  '마. 기타 회사가 정하는 일체의 서비스',
];

export const SERVICE_CHAPTER9_FOURTH =
  '4. 회사가 “주차공간 제공자”에게 제공하는 “서비스”는 다음 각호와 같습니다.';
export const SERVICE_CHAPTER9_FOURTH_DETAIL = '가. 기타 회사가 정하는 일체의 서비스';
export const SERVICE_CHAPTER9_FIFTH =
  '5. 회사가 “회원”에게 제공하는 “서비스”는 다음 각호와 같습니다.';

export const SERVICE_CHAPTER9_FIFTH_DETAIL = [
  '가. 푸쉬 알림 및 전자 우편 서비스',
  '나. 기타 회사가 정하는 일체의 서비스',
]

export const SERVICE_CHAPTER9_REMAIN = [
  '6. 회사는 “회원”의 “서비스” 이용에 있어 “회원”의 편의를 도모하고 “서비스” 이용의 효율을 높이기 위해 다양한 유료 “서비스”를 구성하여 제공할 수 있습니다.',
  '7. 회사는 본 조 제3항과 제4항, 제5항에 따라 “서비스”만 제공하며, “주차공간”의 이용 조건 및 이용방법은 “주차공간 제공자”가 정하는 바에 따릅니다.',
  '8. 회사는 통신판매중개업자로서 “주차공간”의 설치, 관리자가 아니며, 주차장법에 따라 “주차공간” 설치, 관리자에게 적용되는 관련 법률상의 규율, 의무 및 책임은 적용되지 않습니다.',
]

export const SERVICE_CHAPTER10 = [
  '1. “이용자”는 회사가 제공하는 “서비스” 내에서 주차장 검색 등을 통해 “주차공간”, “주차상품” 등 정보를 확인할 수 있습니다.',
  '2. 본 약관에 동의한 ”이용자”는 “주차상품” 등 유료 “서비스”에 대해 카드 결제, 계좌이체, 쿠폰 등 회사가 제공하는 결제 수단을 통해 결제하고 유료 “서비스”를 이용할 수 있습니다. 단, “주차공간”의 제공은 “주차공간 제공자”가 제공하므로 결제 수단 및 이용 조건은 변경될 수 있습니다.',
  '3. “이용자”는 회사가 제공하는 “서비스” 내에서 “주차상품” 등 유료 “서비스”의 구매를 통해 “주차공간”을 이용할 수 있습니다. 주차요금과 이용방법은 회사가 제공하는 각호의 “주차상품”의 유형에 따라 상이합니다. 단, 회사 또는 “주차공간 제공자”의 사정에 따라 이용 방법이 상이할 수 있습니다.',
]

export const SERVICE_CHAPTER10_THIRD_DETAIL1 = [
  'a. 월정기권은 회사가 “공유자”와 이용계약에 따라 운영하는 “주차공간”에 지정된 기간 이용할 수 있는 “주차상품”을 의미합니다.',
  'b. ”이용자”는 월정기권이 사용이 개시되는 이용시작일을 월정기권 구매 시 선택할 수 있으며, 이용시작 가능일은 결제일로부터 최대 7일 이내로 선택할 수 있습니다. 단, 일부 “주차공간”의 경우 “주차공간 제공자”에 따라 이용시작 가능일이 조정될 수 있습니다.',
  'c. 회사가 제공하는 월정기권은 이용 시작일로부터 한 달 동안 특정 “주차공간”을 이용할 수 있는 30일권과 이용 시작일부터 말일까지 특정 “주차공간”을 이용할 수 있는 한달권으로 나누어집니다.',
  'd. 이용 시작일로부터 한 달 동안 특정 “주차공간”을 이용할 수 있는 30일권은 이용 시점과 관계없이 사전 안내된 주차요금을 전액 결제하며, 일부 “주차공간”의 경우 “주차공간 제공자”에 따라 사용 기간이 다르게 설정될 수 있습니다.',
  'e. 이용시작일부터 말일까지 특정 “주차공간”을 이용하는 한달권은 이용 시작일로부터 이용 시작월 말 일까지 “주차공간”을 이용할 수 있는 상품으로 시작일이 1일인 경우 서비스화면에 사전 안내된 주차요금을 전액 결제하며, 이용 시작월 기준 1일이 초과한 경우 이용 시작일부터 말일까지 일할 계산하여 요금으로 산정하여 결제합니다. 단, 이용시작일이 이용 시작월 기준 18일을 넘은 경우 익월 분의 주차요금까지 합산하여 결제합니다.',
  'f. 월정기권은 결제일을 기준으로 5일 이내 “주차공간 제공자”의 사용 여부 승인에 따라 “주차공간” 사용 여부가 최종 확정되며, “주차공간 제공자”의 사용 여부 거절 또는 무응답 시 월 정기권의 이용은 자동으로 취소되며 결제한 금액은 전액 환불됩니다. 이 경우 환불은 결제 수단과 동일한 방법 또는 현금 등 다른 수단으로 진행됩니다.',
  'g. 월정기권은 기본적으로 권 당 사전에 협의가 이루어진 1대의 등록된 차량만 “주차공간”을 이용할 수 있습니다.',
  'h. 일부 월정기권의 경우 회사가 ”이용자”로부터 안내한 지정된 주차 면에 한해서만 주차가 가능하며, 이를 어길 경우 회사는 “서비스” 이용을 제한 또는 중단시킬 수 있습니다.',
  'i. “이용자”는 “주차공간” 시설 노후, 낙석, 오염물, 누구 등에 대한 문제로 “주차공간”을 이용하지 못할 경우 회사 측으로 문제를 증명할 수 있는 자료를 제출할 수 있으며, 회사는 제17조에 따라 이용하지 못한 잔여기간에 대해 환불할 수 있습니다. 단, 만차의 사유로 타 “주차공간”을 이용하여 발생한 요금은 회사에 청구할 수 없습니다.',
]

export const SERVICE_CHAPTER10_THIRD_DETAIL2 = [
  'a. 공유주차권은 회사와 관할 지방자치단체와 협약을 통해 공영주차장 또는 거주자우선주차장 등의 주차장 공유주차 사업과 관련되는 “주차상품”을 의미합니다.',
  'b. 회사가 지방자치단체와 협약을 통해 제공하는 공유주차권는 최소 5분 단위로 이용할 수 있으며, 이용한 시간에 대하여 후불 요금으로 결제가 가능합니다.',
  'c. 회사가 제공하는 ARS, APP 등의 등록 수단을 통하여 주차 등록을 한 시점부터 “서비스”를 이용을 시작한 것으로 보며, “이용자”의 직접 출차 등록, 운영 시간 종료 또는 출차 요청에 의한 강제 출차가 된 시간을 기준으로 최종 요금이 산정됩니다.',
  'd. 공유주차권은 “이용자”의 주차 등록과 출차 등록을 기준으로 최종 요금이 산정되기 때문에 출차 등록을 하지 않거나 주차를 하지 않고 먼저 주차 등록을 하는 등으로 인해 실제 이용한 시간보다 요금이 초과되어 부과될 수 있으며, “이용자”의 과실로 인해 초과한 요금은 “이용자”가 직접 부담하여야 합니다.',
  'e. 이용 방법과 요금은 협약된 지방자치단체의 정책에 따라 상이할 수 있습니다.',
  'f. “이용자”의 차량번호, 자리번호 등 주차등록에 필요한 정보를 오기입 하거나 주차 등록을 하지 않고 주차하는 행위, 회사 또는 지방자치단체 등의 정당한 출차 요청 거부 등으로 부정주차 단속이 된 경우, 회사는 이를 책임지지 않으며, “이용자”에게 부과된 과태료, 견인료, 보관료 등은 회사가 부담하지 않습니다.',
  'g. 저공해차, 경차 등의 주차요금 감면은 일부 협약된 지방자치단체에 한하여 공유주차장 이용 요금에 대해 감면 받을 수 있으며, 회사는 “이용자”에게 서울특별시 주차장 설치 및 관리 조례에 따라 감면에 대한 증빙 자료를 요청할 수 있습니다. 단, 주차요금 감면 비율 및 운영 정책은 지방자치단체의 정책에 따라 상이할 수 있습니다.',
]

export const SERVICE_CHAPTER10_THIRD_DETAIL3 = [
  'a. 제휴주차권은 건물 관리 주체 또는 주차장 관리 업체 등 “주차공간 제공자”와 회사와 협약을 통해 제공하는 “주차공간”을 이용할 수 있는 상품으로 정기권, 시간권, 당일권, 연박권 등으로 구분되며, “주차공간 제공자”가 정한 기준에 따라 운영 방식 및 요금이 산정됩니다.',
  'b. 정기권은 이용 시작일로부터 한 달 동안 특정 “주차공간”을 이용할 수 있는 상품으로, 일부 “주차공간”의 경우 “주차공간 제공자”에 따라 사용 기간이 다르게 설정될 수 있습니다. 또한 정기권은 권 당 사전에 협의가 이루어진 1대의 등록된 차량에 한하여 “주차공간”을 이용할 수 있습니다.',
  'c. 시간권은 “주차공간”에 입차한 시점으로부터 특정 시간 동안 이용할 수 있는 상품으로 원칙적으로 상품을 구매한 당일에 한하여 일회성으로 사용이 가능합니다. 단, 유효기간 등 구체적인 상품 내용은 “주차공간 제공자”에 따라 변경될 수 있습니다.',
  'd. 당일권은 구매일로부터 특정 기간 이용할 수 있는 상품으로 당일 원칙적으로 상품을 구매한 당일에 한하여 일회성으로 사용이 가능합니다. 단, 유효기간 등 구체적인 상품내용은 “주차공간 제공자”에 따라 변경될 수 있습니다.',
  'e. 연박권은 구매일로부터 최소 2일 이상 이용할 수 있는 상품으로 원칙적으로 상품을 구매한 당일에 한하여 일회성으로 사용이 가능합니다. 단, 유효기간 등 구체적인 상품 내용은 “주차공간 제공자”에 따라 변경될 수 있습니다.',
  'f. 제휴주차권을 사용하고자 하는 “이용자”는 “주차공간 제공자”가 정한 이용수칙에 맞춰 “주차공간”을 이용해야 하며, 이를 어길 경우 주차권의 사용이 제한됩니다. “주차공간 제공자”가 정한 이용수칙을 준수하지 않아 발생한 입차 제안, 주차권 사용 제한 등에 대해서는 회사가 책임지지 않습니다.',
  'g. 제휴주차권은 선불권으로 입차 후 결제 및 사용은 불가능하며 해당 “주차공간”의 무인 정산기 또는 정산원을 통해 현장 요금을 결제하여야 합니다.',
  'h. 제휴주차권의 유효 시간을 초과하여 주차를 하는 경우 초과한 시간만큼 회사 또는 해당 “주차공간”의 무인정산기, 정산원 등을 통하여 현장요금 기준의 주차요금을 결제하여야 합니다.',
  'i. 모든 주차권은 회사와 사전 협의 없이 임의로 2개 이상의 주차권을 구매하여 연속하여 사용할 수 없습니다.',
  'j. ”이용자”는 “서비스”를 통해 구매한 “주차상품”을 타인에게 대여, 양도, 또는 매매할 수 없습니다.',
  'k. 회사에서 제공하는 “주차공간” 관련 정보 제공과 관련하여 회사는 정확한 정보를 제공하기 위해 노력합니다. 다만, 세부적인 이용 조건은 “주차공간 제공자”에 따라 변경될 수 있고, 이러한 변경으로 인한 정보 불일치에 대해서는 회사는 고의 또는 중대한 과실이 없는 이상 책임을 지지 않습니다.',
];

export const SERVICE_CHAPTER11 = [
  '1. 회사는 “공유자”가 “주차공간”의 판매를 요청한 경우 “주차공간”에 대한 정당한 사용 권한이 있는지 확인할 수 있고, 이에 대한 확인이 완료될 때까지 해당 상품의 판매를 중지하거나 판매를 취소할 수 있습니다.',
  '2. 회사는 “공유자”가 신청한 “주차공간”의 유형에 따라 회사가 제공하는 “서비스” 내 월정기권, 시간권, 일일권 등 “주차상품”으로 판매할 수 있습니다.',
  '3. “공유자”는 회사가 정한 “주차공간” 등록을 위한 정보를 회사 측으로 제공하면 회사는 이를 확인 후 “주차공간” 등록이 완료되며, 이용계약이 체결된 것으로 봅니다. 단, “공유자”가 제공한 정보가 실제 사실과 불일치하는 경우 “주차공간” 등록이 거절될 수 있습니다.',
  '4. 이용계약 체결 후 “공유자”가 제공한 정보의 변경이 있는 경우 상당한 기간 내에 회사에 변경사항을 알려야 하며, 변경된 정보를 회사에 알리지 않아 발생하는 손해에 관한 책임은 “공유자”에게 있습니다.',
  '5. “공유자”는 “주차공간” 판매에 따라 유료 “이용자”의 “주차공간” 사용에 대해 대가성으로 지급하는 수익을 포인트 또는 현금으로 지급받을 수 있으며, 현금으로 수령하는 경우 회사로부터 정산금을 수령할 계좌를 지정하여야 합니다. “공유자”가 지정한 입금계좌의 예금주는 “공유자”와 동일인임을 원칙으로 합니다. 단, “공유자”의 요청에 따라 회사와 사전 협의된 경우 “공유자”가 아닌 타인 또는 타 법인 사업자의 입금계좌 등록이 가능합니다.',
  '6. “공유자”는 “주차공간” 등록 시 “이용자”가 “주차공간” 및 “주차상품”에 대한 정보를 확인할 수 있도록 아래와 같은 사항을 회사 측으로 정확하게 제공하여야 합니다.',
]

export const SERVICE_CHAPTER11_SIXTH_DETAIL = [
  '가. “주차공간”의 주소',
  '나. “주차공간”의 권리 소유자',
  '다. 희망 정산금',
  '라. 주차 가능 면 수',
  '마. 주차 가능 위치',
  '바. 이용 가능 시간, 기간',
  '사. 기타 “주차공간” 등록 및 판매를 위해 필요하다고 판단되는 정보',
]

export const SERVICE_CHAPTER11_REMAIN1 = [
  '7. “공유자”는 “주차공간” 및 “주차상품”이 등록된 이후에도 정보를 수정할 수 있습니다. 단, 수정되기 이전에 판매된 건에 대해서는 판매 시점의 정보가 적용됩니다.',
  '8. 회사는 “공유자”가 제공한 “주차공간” 및 “주차상품”에 대해서는 별도의 기한 없이 판매를 지속할 수 있습니다. 다만, 각 호에 해당하는 회사는 판매 가능 여부를 재검토 후 판매가 중지되거나 이용계약의 해지 및 취소될 수 있습니다.',
]

export const SERVICE_CHAPTER11_EIGHTH_DETAIL = [
  '가. “공유자”의 이사 또는 토지, 건물의 소유권 이전',
  '나. 입주민과의 분쟁',
  '다. “주차공간” 폐쇄',
  '라. “공유자”의 일신상 사유',
  '마. 회사를 통하지 않고 “공유자”와 “이용자” 간의 개인 거래를 하는 경우.',
  '바. 본 약관에서 정한 바에 따라 회원자격이 정지되거나 이용제한, 기타 필요한 조치가 취해지는 경우',
]

export const SERVICE_CHAPTER11_REMAIN2 = [
  '9. 회사는 “이용자”가 “주차상품”을 결제한 이후 유선 전화, 문자 등의 알림 채널을 통하여 “공유자”에게 “이용자”의 차량 정보와 이용 정보를 통지합니다. “공유자”는 회사가 발송하는 알림 서비스를 통하여 차량 정보와 이용 정보를 확인할 수 있습니다.',
  '10. “공유자”는 “이용자”가 “주차상품”을 결제한 이후 “주차공간”의 사용 여부를 승인 또는 거절할 수 있습니다.',
  '11. “공유자”가 10항에 따라 승인을 선택한 경우 “이용자”의 차량 종류 및 “주차공간” 이용 시작일로부터 주차함에 동의를 한 것으로 간주하고 원활한 “주차공간” 이용을 위해 회사와 “이용자”에게 최대한 협조하여야 합니다.',
  '12. “공유자”가 10항에 따라 거절 또는 상당한 기간 응답하지 않는 경우 “이용자”의 “주차공간”의 이용은 자동으로 취소되며 회사는 “이용자”가 결제한 금액을 해당 “이용자”에게 반환합니다.',
  '13. 회사가 제공하는 결제 서비스로 “주차상품” 등을 결제한 “이용자”가 본 약관 제10조에 따라 “주차공간”을 사용하고 정상적으로 이용이 종료된 후, 회사는 판매 수수료를 공제한 금액을 “공유자”에게 포인트 또는 정산금으로 “주차공간” 사용에 대한 수익을 지급합니다. 단, 아래와 같이 “공유자”의 귀책사유로 “이용자”가 “주차공간”을 사용하지 못한 기간만큼 일할 계산하여 공제한 후 지급하거나 경중에 따라 수익을 지급하지 않을 수 있습니다.',
]

export const SERVICE_CHAPTER11_THIRTEENTH_DETAIL = [
  '가. “주차공간” 만차로 인한 “주차공간” 이용 불가능한 경우',
  '나. 건물 시설 노후로 인해 차량 파손이 우려되는 경우',
  '다. “공유자”의 의사로 더는 “주차공간”을 공유하지 않는 경우',
  '라. 기타 회사가 “주차상품” 판매의 유지가 더이상 불가능하다고 판단하는 경우',
]

export const SERVICE_CHAPTER11_REMAIN3 =
  '14. “공유자”가 회사로 제공한 사실과 다르게 “주차공간”을 통해 정산금 또는 포인트를 받은 경우에는 회사는 부당이득반환청구를 위한 “공유자”에 대한 모든 법적 조치를 할 수 있습니다(신고자의 명확한 증거자료가 확실한 경우). 이때 발생한 모든 비용은 회사에서 “공유자”에게 예외적으로 청구할 수 있습니다.';

export const SERVICE_CHAPTER11_FOURTEENTH_DETAIL = [
  '가. 회원에게 “주차공간” 사용에 대한 주차요금을 직접 수령한 경우',
  '나. “공유자”가 제공한 “주차공간” 정보가 실제 정보와 상이한 경우',
  '다. “공유자”가 허위 사실로 포인트 또는 정산금을 수령한 경우',
  '라. “공유자”가 제공한 “주차공간”이 해당 주차장 관리 업체 규약에 따라 제삼자 타인에게 제공할 수 없는 경우',
]

export const SERVICE_CHAPTER11_REMAIN4 = [
  '15. “공유자”는 “주차공간” 판매 과정에서 알게 된 “이용자” 등 다른 “이용자”의 개인 정보를 법률·약관 또는 회사의 개인정보처리방침에서 정한 목적 외의 용도로 사용할 수 없습니다. “공유자”의 고의 또는 과실로 인하여 다른 “이용자” 또는 제3자의 개인 정보가 침해된 경우, 회사는 그에 관하여 책임을 지지 않으며 “공유자”가 자신의 노력과 비용으로 회사를 면책시켜야 합니다.',
  '16. “공유자”는 회사로부터 정산금을 지급받을 경우, 소득세법 제21조(기타소득)에 따라 자진신고 해야 하며, 소득세법 제84조(기타소득의 과세최저한)의 규정 또한 준수하여야 합니다. 또한, 정산금과 개인 기타소득의 합이 연간 500만 원 미만인 경우 기타소득으로 분류되며, 500만 원 이상인 경우 사업 소득으로 분류되며 아래의 자진 신고 방법에 따라 소득에 대한 신고가 가능하고, 회사는 원천징수를 하지 않습니다. “공유자”가 자진 신고를 하지 아니하여 발생하는 불이익은 “공유자”가 부담하고, 회사는 이에 대한 책임을 부담하지 않습니다.',
]

export const SERVICE_CHAPTER11_SIXTEENTH_DETAIL = [
  '가. 기타소득으로 분류되는 경우 회사 측으로부터 지급받은 정산내역서를 종합소득세 신고 시 제출하여야 합니다.',
  '나. 사업소득으로 분류될 경우 소득 신고가 필요하며 사업자등록 신고 또는 업종 추가를 해야 하며, “공유자”는 정산금 수령 시 세금계산서 발행이 필요합니다. 이때 “공유자”는 회사의 정산금을 매출로 인식하여 계산서를 회사 측으로 발행하거나 회사로부터 판매 수수료만큼의 금액을 매입으로 인식하여 역발행 받을 수 있습니다.',
]

export const SERVICE_CHAPTER12 = [
  '1. 회사는 상당한 이유가 있는 경우 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부의 “서비스”를 변경할 수 있으며 변경된 내용이 중대하거나 “회원”에게 불리한 경우 본 약관에서 정한 방법으로 개별 통지합니다.',
  '2. 회사는 전항에 따른 “서비스” 변경에 대한 동의를 거절한 “회원”에 대해서는 변경 전 “서비스”를 제공합니다. 다만, 변경 전 “서비스” 제공이 불가능할 경우 해당 “서비스”의 제공을 중지하거나 이용계약을 해지할 수 있습니다.',
  '3. 회사는 다음 각 호에 해당하는 경우 “서비스”의 전부 또는 일부를 제한하거나 중단할 수 있습니다.',
]

export const SERVICE_CHAPTER12_THIRD_DETAIL = [
  '가. 통신, 전력 등의 공급이 중단되거나 정보통신설비의 보수점검, 증설, 교체, 이전, 고장, 장애 등 기술상 상당한 이유',
  '나. 회사의 “서비스” 변경 등 회사의 운영상 또는 회사가 통제하기 곤란한 사정으로 불가피하게 “서비스”의 제한 또는 중단이 필요하다고 판단되는 경우',
  '다. 회사가 파산 또는 회생절차 개시, 법원 또는 수사기관의 결정 또는 명령 등으로 인하여 정상적인 영업활동이 곤란한 경우',
  '라. 기타 천재지변, 국가비상사태 등 체결된 사유가 있는 경우',
]

export const SERVICE_CHAPTER12_REMAIN = [
  '4. 회사는 본 조에 따른 “서비스”의 변경, 제한, 중단 등으로 발생하는 문제에 대해서 회사에 귀책사유가 없으면 책임을 부담하지 않습니다.',
  '5. 회사는 정기점검을 시행할 수 있고, 정기점검 시 “서비스”의 전부 또는 일부가 중단될 수 있습니다. 회사는 점검시간 등 상세사항을 서비스화면에 게시하거나 기타의 방법으로 통지하고 필요한 경우 “이용자”가 등록한 휴대전화번호, 이메일, 서신 및 기타의 방법으로 할 수 있습니다.',
]

export const SERVICE_CHAPTER13 = [
  '1. 회사가 제공하는 포인트, 적립금은 아래와 같이 구분됩니다. 단, 세부적인 내용은 회사 또는 관할 지방자치단체 사정에 따라 변경될 수 있습니다.',
  '2. 회사가 제공하는 포인트는 다음 각 호에 따라 사용할 수 있습니다.',
]

export const SERVICE_CHAPTER13_SECOND_DETAIL = [
  '가. “공유자”의 “주차공간” 판매에 따라 유료 “이용자”의 “주차공간” 사용에 대해 대가성으로 지급하는 수익인 포인트는 회사가 정한 이용규정에 따라 회사가 제공하는 온라인 몰(Mall)에서 사용하거나 “회원” 본인 명의의 계좌로 환급받을 수 있습니다.',
  '나. 포인트의 환급은 “회원”이 인출을 요청하면 요청한 날로부터 1영업일 이내 지정한 계좌로 환급됩니다.',
  '다. 회사는 “공유자”가 포인트 사용 및 환급이 어렵다고 판단되는 경우 예외적으로 포인트가 아닌 현금으로 수익을 “회원”에게 지급할 수 있으며, 정산금 지급은 유료 “이용자”의 당월 주차 사용이 끝나고 익월에 회사가 사전 안내한 일자에 맞춰 “공유자”가 요청한 계좌로 지급합니다.',
  '라. 회사는 포인트의 현금 환급을 위한 계좌를 인출 요청 전 회사 측으로 제출해야 하며 회사는 전화, 문자메시지, 카카오톡 등으로 계좌를 요청할 수 있습니다.',
  '마. 포인트의 유효기간은 지급 등으로 인한 사유발생 일자로부터 5년이며, 연장은 불가능하며 유효기간이 경과할 경우 지급일을 기점으로 상시소멸시효에 따라 순차적으로 소멸됩니다.',
  '바. 유효 기간의 만료, 착오로 인한 지급, 포인트의 사용 등으로 인하여 소멸 된 포인트는 복구할 수 없습니다.',
]

export const SERVICE_CHAPTER13_REMAIN1 =
  '3. 회사가 제공하는 적립금은 다음 각 호에 따라 사용할 수 있습니다.';

export const SERVICE_CHAPTER13_THIRD_DETAIL = [
  '가. 지방자치단체별로 협약 된 내용에 따라 거주자 우선주차 공유 사업에 참여한 “배정자”에게 전자적으로 지급하는 수익인 적립금은 회사가 정한 이용규정에 따라 회사가 제공하는 온라인 몰(Mall)에서 사용 가능합니다.',
  '나. 적립금의 유효 기간은 적립금이 적립된 날로부터 1년이며 연장이 불가능합니다. 적립금의 유효기간이 경과할 경우 적립일을 기점으로 순차적으로 소멸 됩니다.',
  '다. 유효 기간의 만료, 착오로 인한 적립, 적립금 사용 등으로 인하여 소멸 된 적립금은 복구할 수 없습니다.',
  '라. 적립금은 “회원” 탈퇴 시 자동으로 소멸 되며 소멸 된 이후에는 복구할 수 없습니다.',
  '마. 적립금은 어떠한 현금성 수단으로도 교환 및 환전을 할 수 없습니다.',
]

export const SERVICE_CHAPTER13_REMAIN2 = [
  '4. 포인트, 적립금 등을 회사가 제공하는 온라인 몰(Mall)에서 사용 시 유효기간 만료일이 먼저 도래하는 적립금, 포인트 순서로 사용됩니다.',
  '5. 회사는 “회원”이 “서비스”를 통해 포인트, 적립금을 사용하는 경우 휴대폰인증, I-PIN 인증 등 회사가 정한 절차를 거치도록 할 수 있습니다.',
]

export const SERVICE_CHAPTER14 = [
  '1. 회사 및 제휴사는 회사 및 제휴사의 “서비스” 정책에 따라 “회원”에게 쿠폰을 부여할 수 있습니다.',
  '2. 회사 및 제휴사는 사용처, 품목, 거래금액 등에 따라 쿠폰의 사용 및 사용 기간을 제한할 수 있습니다.',
  '3. 쿠폰의 부여 및 사용에 관한 상세한 사항은 회사 및 제휴사업자가 정한 정책에 따르며, 회사는 서비스화면 내 또는 쿠폰을 통하여 이를 “회원”에게 안내합니다.',
  '4. “회원”이 부당 또는 부정한 방법으로 쿠폰을 취득한 경우 “회원”은 쿠폰을 사용할 수 없으며, 회사는 이를 회수 할 수 있습니다.',
  '5. “회원”은 쿠폰을 본인의 거래에 대해서만 사용할 수 있으며, 어떠한 경우라도 쿠폰을 타인에게 매매 또는 양도할 수 없습니다.',
  '6. 쿠폰은 유효기간 만료 시 기간연장이 불가하며 별도의 고지 없이 사용이 만료됩니다.',
  '7. 쿠폰의 사용 만료, 분실 등 “회원”의 부주의로 인해 발생한 피해에 대해서 회사는 책임을 지지 않습니다',
  '8. 쿠폰은 현금으로 환불 또는 환급이 불가합니다.',
  '9. “회원” 탈퇴 시 보유하고 있는 쿠폰은 자동적으로 소멸하며, 복구가 불가합니다.',
]

export const SERVICE_CHAPTER15 = [
  '1. 회사는 “회원”의 요청에 따라 현금영수증 또는 세금계산서를 발행할 수 있습니다.',
  '2. 회사는 현금영수증 의무 발행 업종에 해당하지 않으므로 “이용자”가 직접 현금영수증 발행을 요청한 경우에만 발행하는 것을 원칙으로 합니다.',
  '3. 현금영수증은 결제가 발생한 날짜를 기준으로 영업일 기준 2일 이내 발행하는 것을 원칙으로 하며, 현금영수증은 과거 날짜로 소급 적용은 불가능합니다.',
  '4. 세금계산서는 결제가 발생한 월을 기준으로 익월 10일 내 발급하는 것을 원칙으로 하며, 과거 날짜로 소급 적용은 불가능합니다.',
  '5. 세금계산서는 “회원”이 요청한 경우에만 발행하는 것을 원칙으로 하며, 사업자등록증 사본, 이메일 등 세금계산서 발행에 필요한 정보를 회사로 제출하여야 합니다.',
  '6. “회원”은 현금영수증 또는 세금계산서 발행 정보 변경 시 회사에 즉시 변경 사항을 고지해야 합니다.',
]

export const SERVICE_CHAPTER16 = [
  '1. 회사는 일부 “주차상품”에 대해 “주차공간” 이용 과정에서 필요한 열쇠, 방문주차증, 스티커 등의 분실 또는 훼손을 방지하기 위해 “이용자”에게 선불로 보증금을 받을 수 있습니다.',
  '2. 보증금은 예치금으로 가상계좌, 계좌이체 등 회사가 안내하는 결제 수단을 통해 현금으로만 납부가 가능합니다.',
  '3. “이용자”가 “주차공간” 이용계약 종료 후 열쇠, 방문주차증, 스티커 등을 반납하면, 회사는 “이용자”에게 보증금 전액을 “이용자”가 요청한 계좌로 반환합니다. 단, 다음 각 호의 경우 보증금에서 일부 또는 전체 금액을 공제하고 반환합니다.',
]

export const SERVICE_CHAPTER16_THIRD_DETAIL =
  '가. 지급 받은 열쇠, 방문주차증, 스티커 등을 분실 또는 훼손한 경우'

export const SERVICE_CHAPTER16_REMAIN =
  '4. 보증금의 금액은 물품의 종류 및 용도에 따라 회사 측으로, 서비스화면에 게시하거나 기타의 방법으로 “이용자”에게 사전에 안내합니다.';

export const SERVICE_CHAPTER17 = [
  '1. “이용자”의 귀책사유 없이 시스템 오류 등 정상적으로 “서비스”를 이용하지 못하거나 초과결제, 중복결제 등의 문제가 발생한 경우, “이용자”의 정상적인 “서비스” 이용을 전제로 부과된 이용 요금 또는 잘못 결제된 이용요금, 취소수수료의 전부 또는 일부를 환불합니다.',
  '2. “이용자”는 구매한 “주차상품” 등에 대하여 구매일로부터 7일 이내 환급을 요청할 수 있습니다. 단, 아래의 경우에는 요청이 불가합니다.',
]

export const SERVICE_CHAPTER17_SECOND_DETAIL = [
  '가. 구매한 “주차상품”을 제3자에게 재판매 하는 등 부정 사용하는 경우',
  '나. 차량번호, 차종 등 “주차공간” 이용에 필요한 정보의 오기입으로 인해 현장 요금이 발생 한 경우',
  '다. 구매한 주차권의 이용 가능 시간 이외 입출차하여 추가 요금이 발생 된 경우',
  '라. 구매한 “주차상품”의 이용 가능 기한을 초과하는 경우',
  '마. 이미 주차권 등 유료 “서비스”를 이용한 경우',
  '바. 1회 입출차가 가능한 “주차상품”에서 입차 후 취소를 요청하는 경우',
]

export const SERVICE_CHAPTER17_REMAIN = [
  '3. 회사는 “이용자”가 지불한 결제수단과 동일한 수단으로 환불하는 것을 원칙으로 합니다. 다만 동일한 수단으로 환불이 불가능한 경우 현금 등 다른 수단으로 환불할 수 있습니다.',
  '4. 회사는 별도의 개별약관 및 개별정책에 따른 환불 규정을 정할 수 있으며, 이 경우 개별약관 및 개별정책의 사용할 우선 적용됩니다. 서비스별 환불 규정은 아래와 같습니다.',
]

export const SERVICE_CHAPTER17_FOURTH_DETAIL1 = [
  'a. “이용자”는 전자상거래 등에서의 소비자보호에 관한 법률 제17조 등에 따라 정기권의 구매일로부터 7일 이내에 청약철회를 하고 구매액을 부분 환불 받을 수 있습니다.',
  'b. 환불 금액은 “주차공간” 사용 여부와 관계없이 신청일 기준 다음 일자부터 이용 종료일까지 일할 계산하여 산정합니다.',
  'c. 이용 시작 후 7일 차에 “주차공간”을 사용 중인 경우 환불이 불가능합니다. (당월 18일 이후 시작하여 익월분을 함께 결제한 경우 당월 시작일 7일 경과 시 익월분 또한 환불이 불가능함)',
  'd. “공유자”의 귀책사유로 월정기권의 사용이 불가능하다고 판단될 경우 이용 시작 후 7일이 경과하였더라도 남은 잔여기간에 대해 일할 계산하여 환불이 가능합니다. 단, “공유자”의 귀책사유가 명확하게 증명될 수 없는 경우는 제외됩니다.',
]

export const SERVICE_CHAPTER17_FOURTH_DETAIL2 = [
  'a. 지방자치단체와 협약을 통해 운영되는 공유주차권은 “주차공간”의 입출차 이후 후불로 결제가 진행되므로 원칙적으로 환불 신청은 불가능합니다',
  'b. 부정주차, 시스템 오류, “배정자”의 출차 요청 등 “이용자”의 불가항력적인 사유로 인해 실제 공유주차장을 이용한 시간과 결제한 공유주차권의 이용 시간이 다른 경우, 이용하지 못한 시간에 대한 환불 신청이 가능합니다. 이 경우 환불은 결제 수단과 동일한 방법 또는 현금 등 다른 수단으로 진행됩니다.',
  'c. 환불 신청은 결제일로부터 7일이 경과한 경우에는 불가능합니다.',
]

export const SERVICE_CHAPTER17_FOURTH_DETAIL3 = [
  'a. 제휴주차권은 제휴사의 이용약관 및 환불 정책 등에 따릅니다.',
  'b. 회사는 “이용자”가 구매한 정기권, 시간권, 당일권, 연박권 상품에 대한 환불 처리 시, 원칙적으로 카드결제 취소 방식으로 환불을 진행합니다. 단, 환불 처리 시점이 카드결제 취소가 가능한 기간을 경과 했거나 기타 사유로 인해 취소 처리가 불가한 경우, 회사는 “이용자” 명의의 계좌로 환불 금액을 송금합니다.',
]

export const SERVICE_CHAPTER17_FOURTH_DETAIL4 = [
  'a. 현금으로 충전된 포인트는 충전일로부터 7일 이내에 가능합니다. ‘무상 또는 “주차공간” 제공, 상품 판매 등으로 충전된 포인트’, ‘유상으로 충전된 충전금 중 충전일로부터 7일이 경과한 충전금’은 환불이 불가합니다.',
  'b. 적립금은 본 약관에서 달리 정하지 않는 이상 금전이나 기타 결제수단으로 환급 내지 전환되지 않습니다.',
]

export const SERVICE_CHAPTER17_FOURTH_DETAIL5 = 'a. 쿠폰은 현금으로 환불 또는 환급이 불가합니다.';

export const SERVICE_CHAPTER18 = [
  '1. 회사는 “서비스”의 안전성과 신뢰성을 위하여 페널티 정책을 도입하여 운영할 수 있으며, 페널티 조치는 회사가 제공하는 “서비스”를 이용하는 모든 “회원”에게 적용될 수 있습니다.',
  '2. “서비스”를 이용하는 “회원”은 다음 각 호에 해당하는 행위를 해서는 안 되며, 위반 시 “서비스” 이용 제한 및 기타 필요한 조치가 취해질 수 있습니다.',
]

export const SERVICE_CHAPTER18_SECOND_DETAIL = [
  '가. 회사가 정하는 “서비스” 이용 방식을 지키지 않고 “서비스”의 운영을 방해하는 경우',
  '나. 회사의 임직원에게 욕설, 폭언, 성희롱 등 반복적인 민원 통해 업무를 방해하는 경우',
  '다. 회사가 제공하는 “주차공간”을 회사와 사전 협의 없이 직접 결제 유도 및 직접 결제를 하는 경우',
  '라. 연락 두절·회피 행위',
  '마. 권리침해 행위',
  '바. 그 외 약관 또는 법령에 위반되거나 위법·부당한 행위로 의심될 만한 상당한 이유가 있는 경우',
]

export const SERVICE_CHAPTER18_REMAIN1 = [
  '3. 회사는 패널티로 이용제한 및 기타 필요한 조치를 취할 경우 사전에 “회원”에게 유선 전화, 문자 등을 통하여 통보합니다. 다만 회사가 빠른 조치가 필요하다고 판단하는 경우에는 선 조치 후 통보할 수 있습니다.',
  '4. 회사의 페널티 조치에 대하여 “회원”은 소명 및 이의제기의 권리가 있으며 회사는 페널티 조치를 받은 “회원”의 소명 및 이의제기를 충분히 수렴하고 이의제기가 타당하다고 판단되는 경우 페널티 조치를 해제할 수 있습니다.',
  '5. 페널티 조치 이력은 자동으로 삭제되지 않으며, 개인정보처리방침에 의거하여 탈퇴 후에도 ‘부정이용 기록’은 5년간 보관됩니다.',
  '6. 페널티 조치는 1단계부터 4단계까지 아래의 단계별 페널티 절차가 진행됩니다. 단, 조치 방법 및 기준에 따라 3단계, 4단계는 생략될 수 있습니다.',
]

export const SERVICE_CHAPTER18_SIXTH_DETAIL = [
  '1단계 [주의] : 회사가 “회원”의 규정 위반 사유를 인지하고 “서비스” 이용 이력과 조치 이력 등을 종합적으로 검토하여 페널티 조치 여부와 방법을 결정합니다.',
  '2단계 [경고] : 대상자에게 유선 전화, 문자 등을 통하여 조치 및 조치 예정 안내를 통보합니다.',
  '3단계 [소명 접수] : 대상자는 조치 예정 안내에 포함된 조치 항목과 내용, 소명 기한을 확인 후 소명 내용을 작성하여 회사에 회신할 수 있으며, 회사는 소명 내용을 검토하고 최종 조치 여부와 방법을 결정하여 답변을 보내 드립니다. 단, 소명 기한 내 회신이 없는 경우에는 안내해 드린 내용에 따라 최종 페널티가 적용됩니다.',
  '4단계 [조치 적용] : 1~3단계에 따른 최종 조치가 적용됩니다.',
]

export const SERVICE_CHAPTER18_REMAIN2 =
  '7. 페널티 조치에 따른 페널티 조치 방법은 아래와 같습니다.';

export const SERVICE_CHAPTER18_SEVENTH_DETAIL = [
  '주요 조치 : 회사가 제공 중인 모든 “서비스”의 이용이 제한됩니다.',
  '공유자의 1회 공유 취소 : 등록된 “주차상품”의 판매가 중지되며 페널티 조치일로부터 3개월간 판매가 불가능합니다.',
  '공유자의 2회 공유 취소 : 등록된 “주차상품”의 판매가 중지되며 페널티 조치일로부터 6개월간 판매가 불가능합니다.',
  '공유자의 3회 공유 취소 : 등록된 “주차상품”의 판매가 중지되며 페널티 조치일로부터 영구적으로 판매가 불가능합니다.',
]

export const SERVICE_CHAPTER18_REMAIN3 =
  '8. “공유자”의 공유 취소에 대한 페널티 조치는 약관의 다른 조항 및 관련 법령의 영향을 받지 않으며, 복구가 불가능합니다. 단, 이사나 부고 등과 같은 불가항력적인 사유로 인한 취소에는 페널티가 적용되지 않습니다.';

export const SERVICE_CHAPTER19 = [
  '1. 회사는 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 본 약관이 정하는 바에 따라 지속적이고, 안정적으로 “서비스”를 제공하기 위해서 노력 합니다.',
  '2. 회사는 “회원”이 안전하게 인터넷으로 “서비스”를 이용할 수 있도록 “회원”의 개인정보(신용정보 포함) 보호를 위한 보안 시스템을 갖추어야 합니다.',
  '3. 회사는 “회원”의 개인 신상 정보를 본인의 승낙 없이 타인에게 누설, 배포하지 않습니다. 단, 전기통신기본법 등 법률의 규정에 의해 국가기관의 요구가 있는 경우, 범죄에 대한 수사상의 목적이 있거나 또는 기타 관계법령에서 정한 절차에 의한 요청이 있을 경우에는 그러하지 아니합니다.',
  '4. 회사의 고의나 중과실로 인하여 “회원”에게 손해가 발생한 경우에 한하여 책임을 부담하며, 그 책임의 범위는 통상손해에 한합니다',
  '5. 회사는 “회원”의 귀책사유로 인한 “서비스” 이용 장애와 피해에 대하여 책임을 지지 않습니다.',
  '6. 회사는 “회원”이 원하지 않는 영리목적의 광고성 전자우편, 문자메시지 등을 발송하지 않습니다.',
]

export const SERVICE_CHAPTER20 = [
  '1. 회원가입 시에 요구되는 정보는 정확하게 기재하여야 합니다. 또한 이미 제공된 귀하에 대한 정보가 정확한 정보가 되도록 유지, 갱신하여야 하며, “회원”은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안 됩니다.',
  '2. “회원”은 “서비스”를 이용하기 전 개별 서비스, 유료 서비스, 제휴 서비스 등의 이용방법 및 조건 등을 확인해야 합니다. 이용방법 및 조건 등을 확인하지 않고 “서비스”를 이용하여 발생한 손실, 손해에 관한 책임은 “회원” 본인이 부담하여야 합니다.',
  '3. 회사가 제공하는 “서비스”와 관련하여 “회원”이 제공한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 “회원”이 부담하여야 합니다.',
  '4. “회원”은 회사가 “서비스”를 안전하게 제공할 수 있도록 협조하여야 하며, 회사가 “회원”의 본 약관 위반 등 부적절한 행위를 발견하여 해당 행위에 대해 “회원”에게 소명을 요청할 경우 “회원”은 회사의 요청에 적극 응하여야 합니다.',
  '5. “회원”은 “서비스” 이용과 관련하여 분쟁이 발생한 경우 분쟁의 해결을 위하여 성실히 임하여야 하며, 분쟁해결에 대한 “회원”의 불성실로 인하여 “주차공간 제공자” 또는 회사의 손해가 발생한 경우 “회원”은 이에 관한 책임을 부담하여야 합니다.',
  '6. “회원”은 회사가 정하고 인정하는 방식에 따라 “주차상품” 및 혜택 등 취득, 사용하여야 합니다.'
]

export const SERVICE_CHAPTER21 = [
  '회사가 제공하는 “서비스”의 신뢰성을 높이고 안전한 거래가 이루어질 수 있도록 “회원”의 다음 각 호의 행위를 금지합니다.',
  '1. 다른 “회원”의 ID를 부정 사용하거나 타인의 정보를 도용하는 행위',
  '2. 회원가입 신청 또는 회원정보 변경 시 허위 사실을 기재하거나, 제3자의 명의, 결제정보 등을 도용 또는 부정하게 사용하거나, 타인의 이동전화를 부정하게 사용하여 “서비스”를 이용하는 행위',
  '3. “서비스” 이용 과정에서 공공질서 및 미풍양속에 위반되는 내용 또는 허위의 정보, 문장, 도형, 음성 등을 적시하거나 유포하는 행위',
  '4. 회사가 제공하는 “서비스”를 통하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통하거나 상업적으로 이용하는 행위',
  '5. 회사가 제공하는 “서비스”의 정당한 이용방법에 의하지 않고, “서비스”에 게시된 정보를 변경하는 등 비정상적인 방법으로 “서비스”를 이용하거나 시스템 등에 접근하는 행위',
  '6. 회사 또는 제3자의 명예를 훼손하거나 각종 권리를 침해하거나 부적절한 행위로 인하여 손해를 입히는 행위',
  '7. “서비스”와 관련된 시스템 및 설비 등의 오동작이나 정보 등의 파괴 및 혼란을 유발시키는 소프트웨어 바이러스, 기타 컴퓨터 코드, 파일, 프로그램 등을 등록 또는 유포하는 행위',
  '8. 타인의 개인정보를 수집, 저장, 공개하는 행위',
  '9. 회사의 직원이나 매니저를 가장 또는 사칭하거나, 타인의 명의를 도용하여 “서비스”와 관련된 글을 홈페이지 또는 인터넷 사이트 등에 게시하거나 회사 또는 제3자에게 E-mail 등을 발송하는 행위',
  '10. 회사가 정한 정당한 이용방법에 의하지 않고 부정한 방법으로 정산금 또는 포인트 등을 취득하거나 사용하는 행위',
  '11. “서비스” 관련 아래 각 호에 해당하는 행위'
]

export const SERVICE_CHAPTER21_ELEVENTH_DETAIL = [
  '가. 이용의사 없이 반복적인 결제 대기 및 결제, 취소 등을 통해 회사의 “서비스” 제공을 방해하는 행위',
  '나. 고의적으로 결제 미수를 발생시키거나 미수가 장기간 발생되어 정상적인 “서비스” 이용으로 보기 어려운 행위',
  '다. 회사의 사전 동의 없이 회사가 제공하는 “주차공간”을 제3자에게 유상으로 제공(주차권 양도 등을 포함하며 이에 한정되지 않음)하는 행위',
  '라. 회사가 제공하는 “주차공간”에 회사의 사전 승인 없이 지정된 “주차공간”을 벗어나 주차를 하는 행위',
  '마. 회사의 사전 승인 없이 이용계약 시 협의되지 않은 차량 등을 회사가 제공하는 “주차공간”에 주차하는 경우',
  '바. 회사와 이용계약된 기간이 아닌 이용계약이 체결되지 않은 다른 날짜에 차량을 주차하는 행위',
  '사. 관련 법령 또는 본 약관 및 이용정책 등을 위반하는 행위',
  '아. 기타 불법적이거나 부적절한 행위'
]

export const SERVICE_CHAPTER22 = [
  '1. 회사와 “이용자”는 “서비스” 이용과 관련하여 고의 또는 과실로 상대방에게 손해를 끼친 경우에는 이를 배상하여야 합니다. 단, 무료로 제공되는 “서비스” 이용과 관련하여 관련법에 특별한 규정이 없는 한 회사는 책임을 지지 않습니다.',
  '2. 회사는 회사의 고의 또는 중대한 과실이 없는 한 “이용자” 상호 간 또는 “이용자”와 제3자 상호 간에 “서비스”를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.',
  '3. 회사는 “주차장소 제공자” 또는 “이용자”가 “서비스”를 이용함으로써 기대되는 수익을 얻지 못하거나 “서비스”를 통해 얻은 자료를 이용하여 발생한 손해에 대해서는 책임을 부담하지 않습니다.',
  '4. “이용자”는 “서비스” 이용 종료 시 수령한 물품(리모컨, 스티커, 열쇠 등)을 전달받은 당사자(지정된 장소)에 반납하여야 하며, 수령물품의 분실, 파손 등 재사용이 불가하다는 “공유자”의 판단이 있는 경우 이때 발생하는 모든 책임은 “이용자”에게 있으며, 발생되는 재구매 비용은 “이용자”가 “공유자”에게 배상하여야 합니다.',
  '5. 회사가 천재지변 또는 이에 준하는 불가항력으로 인하여 “서비스”를 제공할 수 없는 경우에는 관련 책임이 면제됩니다.',
  '6. 회사는 고의 또는 중대한 과실이 없는 한 “이용자”의 귀책사유로 인한 “서비스” 이용의 장애에 대해서는 책임을 지지 않습니다.',
  '7. “이용자”가 본 약관에 따른 금전 채무의 이행을 지체한 경우, 연 15%의 약정이율을 계산한 지연손해금을 지급해야 합니다.'
]

export const SERVICE_CHAPTER23 = [
  '1. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 “서비스”를 제공할 수 없는 경우에는 “서비스” 제공에 관한 책임이 면제됩니다.',
  '2. 회사는 고의 또는 중대한 과실이 없는 한 “회원”의 귀책사유로 인한 “서비스” 이용의 장애에 대하여는 책임을 지지 않습니다.',
  '3. 회사 이외의 타 사업자가 제공하는 정보에 의해 발생하는 피해나 “서비스”의 장애로 인한 경우에 대하여는 회사는 책임을 지지 않습니다.',
  '4. 회사는 “이용자”의 “주차공간” 사용 중 발생하는 각 호에 대해서는 회사는 책임지지 않습니다.'
]

export const SERVICE_CHAPTER23_FOURTH_DETAIL = [
  '가. 차내 귀중품 분실 및 도난 발생',
  '나. “이용자”가 “주차공간” 내 주차시설 또는 타인의 차량을 훼손하는 경우',
  '다. “주차공간” 이용 중 건물 외 외부요인에 의해 차량이 파손되는 경우',
  '라. “주차공간” 이용 중 발생한 차량 파손 등 사고(제삼자에 의한 모든 사고 포함)에 대한 민형사상의 책임이나 손해배상이 발생한 경우(무단주차로 인한 견인 등 피해의 경우도 포함)',
  '마. “주차공간” 사용 중 발생한 사고가 회사와 관련이 있다고 하더라도 회사의 고의 또는 중대한 과실이 없는 경우'
]

export const SERVICE_CHAPTER23_REMAIN = [
  '5. “주차공간 제공자”가 제공한 “주차공간”은 “주차공간 제공자”의 책임 하에 관리 운영되고, 회사는 통신판매중개자로 “서비스” 운영상의 문제를 제외한 “주차공간”의 하자·부실·각종 사고 등으로 인한 책임은 “주차공간 제공자”에게 귀속되며 회사는 책임이 면책됩니다.',
  '6. 회사는 서비스화면에 게재한 각종 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여 책임을 지지 않습니다.',
  '7. 회사가 제공한 “서비스”를 통해 취득한 정보나 서비스화면에 게시된 다른 “회원”의 게시물을 통해 법률적 또는 의학적, 기타 재정적 사항에 관하여 필요한 결정을 하고자 할 때에는 반드시 사전에 전문가와 상의할 것을 권고합니다. 서비스화면 내에서 “회원”이 게시한 정보, 의견 및 자료 등은 회사와 아무런 관련이 없으며, 게시물의 내용과 관련하여 발생한 법적 책임은 전적으로 해당 게시물을 게시한 “회원” 및 이를 열람한 “회원”에게 있습니다. 회사는 고의 또는 중대한 과실이 없는 한 “회원” 간 또는 “회원”과 제3자 간에 발생한 분쟁에 관여할 법적 의무가 없으며, 이와 관련하여 어떠한 책임도 지지 않습니다.',
  '8. 본 약관의 적용은 이용계약을 체결한 “주차장소 제공자” 및 “이용자”에 한하며, 제3자에게 어떠한 배상, 소송 등에 대하여 회사는 책임을 면합니다.'
]

export const SERVICE_CHAPTER24 =
  '회사는 전화, 서면, 전자우편 또는 인터넷 홈페이지 등을 통하여 개인정보와 관련한 “회원”의 의견을 수렴하고 불만사항을 접수하여 이를 처리합니다.'

export const SERVICE_CHAPTER25 = [
  '1. 회사는 다음 각 호의 사유가 발생한 경우 “서비스”를 종료할 수 있으며 “서비스” 종료 전에 “이용자”에게 본 약관에서 정한 방법으로 미리 통지합니다.',
  '2. 이 경우 아직 사용하지 않은 “주차상품”이나, 사용 중이지만 기간이 잔존하는 월정기권, 공유주차권, 제휴주차권은 잔여기간에 해당하는 만큼 일할 계산하여 “이용자”에게 환불합니다.'
]

export const SERVICE_CHAPTER26 = [
  '1. 회사와 “회원” 사이에 “서비스” 이용 관련 분쟁이 발생한 경우에는 양측이 원만하게 최대한 해결이 될 수 있도록 회사와 “회원”은 성실히 협의를 진행합니다.',
  '2. 회사는 본 조 제1항에도 불구하고 분쟁이 해결되지 않을 경우 민사소송법상 관할법원에 소를 제기할 수 있습니다.'
]

export const SUPPLEMENTARY = '부칙'
export const EFFECTIVE_DATE = '본 약관은 2024년 12월 26일로부터 시행합니다.'
