import { MainContainerNoMargin } from 'components/styles';
import Title from 'components/atoms/Title/PageTitle';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import EmptyParkingApplyHistoryList from 'containers/emptyParkingArea/EmptyParkingApplyHistoryList';

const EmptyParkingAreaPage = () => {
  const navigate = useNavigate();

  const handleLeftOnClick = () => {
    navigate(-1)
  }

  return(
    <MainContainerNoMargin>
      <Title
        title="빈 자리 신청 내역"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      <EmptyParkingApplyHistoryList/>
    </MainContainerNoMargin>
  )
}

export default EmptyParkingAreaPage