import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

// 각 스타일을 @stitches/react 스타일 시스템으로 변환
export const Caption4 = styled('p', {
  fontFamily: 'Pretendard', // 공통으로 사용되는 fontFamily

  variants: {
    weight: {
      regular: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: 'normal',
      },
      medium: {
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: 'normal',
      },
      semibold: {
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: 'normal',
      },
      bold: {
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: 'normal',
      },
    },
    color: {
      default: { color: colors.black3 },
      white: { color: colors.white },
      black3: { color: colors.black3 },
      black1: { color: colors.black1 },
      sky: { color: colors.sky },
      red: { color: colors.red },
      primary: { color: colors.primary },
      primaryDark: { color: colors.primaryDark },
      primarySub: { color: colors.primarySub },
      primaryBackground: { color: colors.primaryBackground },
      primarySubSub: { color: colors.primarySubSub },
      gray01: { color: colors.gray01 },
      gray02: { color: colors.gray02 },
      gray03: { color: colors.gray03 },
      gray04: { color: colors.gray04 },
      gray05: { color: colors.gray05 },
      gray06: { color: colors.gray06 },
      gray07: { color: colors.gray07 },
      gray08: { color: colors.gray08 },
      gray09: { color: colors.gray09 },
      blue: { color: colors.blue },
    },
  },
});

export const Caption3 = styled('p', {
  fontFamily: 'Pretendard', // 공통으로 사용되는 fontFamily

  variants: {
    weight: {
      regular: {
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: 'normal',
      },
      medium: {
        fontSize: '13px',
        fontWeight: 500,
        lineHeight: 'normal',
      },
      semibold: {
        fontSize: '13px',
        fontWeight: 600,
        lineHeight: 'normal',
      },
      bold: {
        fontSize: '13px',
        fontWeight: 700,
        lineHeight: 'normal',
      },
    },
    color: {
      default: { color: colors.black3 },
      white: { color: colors.white },
      black3: { color: colors.black3 },
      black1: { color: colors.black1 },
      sky: { color: colors.sky },
      red: { color: colors.red },
      primary: { color: colors.primary },
      primaryDark: { color: colors.primaryDark },
      primarySub: { color: colors.primarySub },
      primaryBackground: { color: colors.primaryBackground },
      primarySubSub: { color: colors.primarySubSub },
      gray01: { color: colors.gray01 },
      gray02: { color: colors.gray02 },
      gray03: { color: colors.gray03 },
      gray04: { color: colors.gray04 },
      gray05: { color: colors.gray05 },
      gray06: { color: colors.gray06 },
      gray07: { color: colors.gray07 },
      gray08: { color: colors.gray08 },
      gray09: { color: colors.gray09 },
      blue: { color: colors.blue },
    },
  },
});

export const Caption2 = styled('p', {
  fontFamily: 'Pretendard', // 공통으로 사용되는 fontFamily

  variants: {
    weight: {
      regular: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: 'normal',
      },
      medium: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: 'normal',
      },
      semibold: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: 'normal',
      },
      bold: {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: 'normal',
      },
    },
    color: {
      default: { color: colors.black3 },
      white: { color: colors.white },
      black3: { color: colors.black3 },
      black1: { color: colors.black1 },
      sky: { color: colors.sky },
      red: { color: colors.red },
      primary: { color: colors.primary },
      primaryDark: { color: colors.primaryDark },
      primarySub: { color: colors.primarySub },
      primaryBackground: { color: colors.primaryBackground },
      primarySubSub: { color: colors.primarySubSub },
      gray01: { color: colors.gray01 },
      gray02: { color: colors.gray02 },
      gray03: { color: colors.gray03 },
      gray04: { color: colors.gray04 },
      gray05: { color: colors.gray05 },
      gray06: { color: colors.gray06 },
      gray07: { color: colors.gray07 },
      gray08: { color: colors.gray08 },
      gray09: { color: colors.gray09 },
      blue: { color: colors.blue },
    },
  },
});

export const Caption1 = styled('p', {
  fontFamily: 'Pretendard', // 공통으로 사용되는 fontFamily

  variants: {
    weight: {
      regular: {
        fontSize: '15px',
        fontWeight: 400,
        lineHeight: 'normal',
      },
      medium: {
        fontSize: '15px',
        fontWeight: 500,
        lineHeight: 'normal',
      },
      semibold: {
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: 'normal',
      },
      bold: {
        fontSize: '15px',
        fontWeight: 700,
        lineHeight: 'normal',
      },
    },
    color: {
      default: { color: colors.black3 },
      white: { color: colors.white },
      black3: { color: colors.black3 },
      black1: { color: colors.black1 },
      sky: { color: colors.sky },
      red: { color: colors.red },
      primary: { color: colors.primary },
      primaryDark: { color: colors.primaryDark },
      primarySub: { color: colors.primarySub },
      primaryBackground: { color: colors.primaryBackground },
      primarySubSub: { color: colors.primarySubSub },
      gray01: { color: colors.gray01 },
      gray02: { color: colors.gray02 },
      gray03: { color: colors.gray03 },
      gray04: { color: colors.gray04 },
      gray05: { color: colors.gray05 },
      gray06: { color: colors.gray06 },
      gray07: { color: colors.gray07 },
      gray08: { color: colors.gray08 },
      gray09: { color: colors.gray09 },
      blue: { color: colors.blue },
    },
  },
});

export const SubHeadline = styled('p', {
  fontFamily: 'Pretendard', // 공통으로 사용되는 fontFamily

  variants: {
    weight: {
      regular: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: 'normal',
      },
      medium: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: 'normal',
      },
      semibold: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: 'normal',
      },
      bold: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: 'normal',
      },
    },
    color: {
      default: { color: colors.black3 },
      white: { color: colors.white },
      black3: { color: colors.black3 },
      black1: { color: colors.black1 },
      sky: { color: colors.sky },
      red: { color: colors.red },
      primary: { color: colors.primary },
      primaryDark: { color: colors.primaryDark },
      primarySub: { color: colors.primarySub },
      primaryBackground: { color: colors.primaryBackground },
      primarySubSub: { color: colors.primarySubSub },
      gray01: { color: colors.gray01 },
      gray02: { color: colors.gray02 },
      gray03: { color: colors.gray03 },
      gray04: { color: colors.gray04 },
      gray05: { color: colors.gray05 },
      gray06: { color: colors.gray06 },
      gray07: { color: colors.gray07 },
      gray08: { color: colors.gray08 },
      gray09: { color: colors.gray09 },
      blue: { color: colors.blue },
    },
  },
});

export const Body = styled('p', {
  fontFamily: 'Pretendard', // 공통으로 사용되는 fontFamily

  variants: {
    weight: {
      regular: {
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: 'normal',
      },
      medium: {
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: 'normal',
      },
      semibold: {
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: 'normal',
      },
      bold: {
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: 'normal',
      },
    },
    color: {
      default: { color: colors.black3 },
      white: { color: colors.white },
      black3: { color: colors.black3 },
      black1: { color: colors.black1 },
      sky: { color: colors.sky },
      red: { color: colors.red },
      primary: { color: colors.primary },
      primaryDark: { color: colors.primaryDark },
      primarySub: { color: colors.primarySub },
      primaryBackground: { color: colors.primaryBackground },
      primarySubSub: { color: colors.primarySubSub },
      gray01: { color: colors.gray01 },
      gray02: { color: colors.gray02 },
      gray03: { color: colors.gray03 },
      gray04: { color: colors.gray04 },
      gray05: { color: colors.gray05 },
      gray06: { color: colors.gray06 },
      gray07: { color: colors.gray07 },
      gray08: { color: colors.gray08 },
      gray09: { color: colors.gray09 },
      blue: { color: colors.blue },
    },
  },
});

export const Body2 = styled('p', {
  fontFamily: 'Pretendard', // 공통으로 사용되는 fontFamily

  variants: {
    weight: {
      regular: {
        fontSize: '17px',
        fontWeight: 400,
        lineHeight: 'normal',
      },
      medium: {
        fontSize: '17px',
        fontWeight: 500,
        lineHeight: 'normal',
      },
      semibold: {
        fontSize: '17px',
        fontWeight: 600,
        lineHeight: 'normal',
      },
      bold: {
        fontSize: '17px',
        fontWeight: 700,
        lineHeight: 'normal',
      },
    },
    color: {
      default: { color: colors.black3 },
      white: { color: colors.white },
      black3: { color: colors.black3 },
      black1: { color: colors.black1 },
      sky: { color: colors.sky },
      red: { color: colors.red },
      primary: { color: colors.primary },
      primaryDark: { color: colors.primaryDark },
      primarySub: { color: colors.primarySub },
      primaryBackground: { color: colors.primaryBackground },
      primarySubSub: { color: colors.primarySubSub },
      gray01: { color: colors.gray01 },
      gray02: { color: colors.gray02 },
      gray03: { color: colors.gray03 },
      gray04: { color: colors.gray04 },
      gray05: { color: colors.gray05 },
      gray06: { color: colors.gray06 },
      gray07: { color: colors.gray07 },
      gray08: { color: colors.gray08 },
      gray09: { color: colors.gray09 },
      blue: { color: colors.blue },
    },
  },
});

export const Headline = styled('p', {
  fontFamily: 'Pretendard', // 공통으로 사용되는 fontFamily

  variants: {
    weight: {
      regular: {
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: 'normal',
      },
      medium: {
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: 'normal',
      },
      semibold: {
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: 'normal',
      },
      bold: {
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: 'normal',
      },
    },
    color: {
      default: { color: colors.black3 },
      white: { color: colors.white },
      black3: { color: colors.black3 },
      black1: { color: colors.black1 },
      sky: { color: colors.sky },
      red: { color: colors.red },
      primary: { color: colors.primary },
      primaryDark: { color: colors.primaryDark },
      primarySub: { color: colors.primarySub },
      primaryBackground: { color: colors.primaryBackground },
      primarySubSub: { color: colors.primarySubSub },
      gray01: { color: colors.gray01 },
      gray02: { color: colors.gray02 },
      gray03: { color: colors.gray03 },
      gray04: { color: colors.gray04 },
      gray05: { color: colors.gray05 },
      gray06: { color: colors.gray06 },
      gray07: { color: colors.gray07 },
      gray08: { color: colors.gray08 },
      gray09: { color: colors.gray09 },
      blue: { color: colors.blue },
    },
  },
});

export const Title3 = styled('p', {
  fontFamily: 'Pretendard', // 공통으로 사용되는 fontFamily

  variants: {
    weight: {
      regular: {
        fontSize: '22px',
        fontWeight: 400,
        lineHeight: 'normal',
      },
      medium: {
        fontSize: '22px',
        fontWeight: 500,
        lineHeight: 'normal',
      },
      semibold: {
        fontSize: '22px',
        fontWeight: 600,
        lineHeight: 'normal',
      },
      bold: {
        fontSize: '22px',
        fontWeight: 700,
        lineHeight: 'normal',
      },
    },
    color: {
      default: { color: colors.black3 },
      white: { color: colors.white },
      black3: { color: colors.black3 },
      black1: { color: colors.black1 },
      sky: { color: colors.sky },
      red: { color: colors.red },
      primary: { color: colors.primary },
      primaryDark: { color: colors.primaryDark },
      primarySub: { color: colors.primarySub },
      primaryBackground: { color: colors.primaryBackground },
      primarySubSub: { color: colors.primarySubSub },
      gray01: { color: colors.gray01 },
      gray02: { color: colors.gray02 },
      gray03: { color: colors.gray03 },
      gray04: { color: colors.gray04 },
      gray05: { color: colors.gray05 },
      gray06: { color: colors.gray06 },
      gray07: { color: colors.gray07 },
      gray08: { color: colors.gray08 },
      gray09: { color: colors.gray09 },
      blue: { color: colors.blue },
    },
  },
});

export const Title2 = styled('p', {
  fontFamily: 'Pretendard', // 공통으로 사용되는 fontFamily

  variants: {
    weight: {
      regular: {
        fontSize: '24px',
        fontWeight: 400,
        lineHeight: 'normal',
      },
      medium: {
        fontSize: '24px',
        fontWeight: 500,
        lineHeight: 'normal',
      },
      semibold: {
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: 'normal',
      },
      bold: {
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: 'normal',
      },
    },
    color: {
      default: { color: colors.black3 },
      white: { color: colors.white },
      black3: { color: colors.black3 },
      black1: { color: colors.black1 },
      sky: { color: colors.sky },
      red: { color: colors.red },
      primary: { color: colors.primary },
      primaryDark: { color: colors.primaryDark },
      primarySub: { color: colors.primarySub },
      primaryBackground: { color: colors.primaryBackground },
      primarySubSub: { color: colors.primarySubSub },
      gray01: { color: colors.gray01 },
      gray02: { color: colors.gray02 },
      gray03: { color: colors.gray03 },
      gray04: { color: colors.gray04 },
      gray05: { color: colors.gray05 },
      gray06: { color: colors.gray06 },
      gray07: { color: colors.gray07 },
      gray08: { color: colors.gray08 },
      gray09: { color: colors.gray09 },
      blue: { color: colors.blue },
    },
  },
});

export const Title1 = styled('p', {
  fontFamily: 'Pretendard', // 공통으로 사용되는 fontFamily

  variants: {
    weight: {
      regular: {
        fontSize: '26px',
        fontWeight: 400,
        lineHeight: 'normal',
      },
      medium: {
        fontSize: '26px',
        fontWeight: 500,
        lineHeight: 'normal',
      },
      semibold: {
        fontSize: '26px',
        fontWeight: 600,
        lineHeight: 'normal',
      },
      bold: {
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: 'normal',
      },
    },
    color: {
      default: { color: colors.black3 },
      white: { color: colors.white },
      black3: { color: colors.black3 },
      black1: { color: colors.black1 },
      sky: { color: colors.sky },
      red: { color: colors.red },
      primary: { color: colors.primary },
      primaryDark: { color: colors.primaryDark },
      primarySub: { color: colors.primarySub },
      primaryBackground: { color: colors.primaryBackground },
      primarySubSub: { color: colors.primarySubSub },
      gray01: { color: colors.gray01 },
      gray02: { color: colors.gray02 },
      gray03: { color: colors.gray03 },
      gray04: { color: colors.gray04 },
      gray05: { color: colors.gray05 },
      gray06: { color: colors.gray06 },
      gray07: { color: colors.gray07 },
      gray08: { color: colors.gray08 },
      gray09: { color: colors.gray09 },
      blue: { color: colors.blue },
    },
  },
});

export const LargeTitle3 = styled('p', {
  fontFamily: 'Pretendard', // 공통으로 사용되는 fontFamily

  variants: {
    weight: {
      regular: {
        fontSize: '28px',
        fontWeight: 400,
        lineHeight: 'normal',
      },
      medium: {
        fontSize: '28px',
        fontWeight: 500,
        lineHeight: 'normal',
      },
      semibold: {
        fontSize: '28px',
        fontWeight: 600,
        lineHeight: 'normal',
      },
      bold: {
        fontSize: '28px',
        fontWeight: 700,
        lineHeight: 'normal',
      },
    },
    color: {
      default: { color: colors.black3 },
      white: { color: colors.white },
      black3: { color: colors.black3 },
      black1: { color: colors.black1 },
      sky: { color: colors.sky },
      red: { color: colors.red },
      primary: { color: colors.primary },
      primaryDark: { color: colors.primaryDark },
      primarySub: { color: colors.primarySub },
      primaryBackground: { color: colors.primaryBackground },
      primarySubSub: { color: colors.primarySubSub },
      gray01: { color: colors.gray01 },
      gray02: { color: colors.gray02 },
      gray03: { color: colors.gray03 },
      gray04: { color: colors.gray04 },
      gray05: { color: colors.gray05 },
      gray06: { color: colors.gray06 },
      gray07: { color: colors.gray07 },
      gray08: { color: colors.gray08 },
      gray09: { color: colors.gray09 },
      blue: { color: colors.blue },
    },
  },
});

export const LargeTitle2 = styled('p', {
  fontFamily: 'Pretendard', // 공통으로 사용되는 fontFamily

  variants: {
    weight: {
      regular: {
        fontSize: '30px',
        fontWeight: 400,
        lineHeight: 'normal',
      },
      medium: {
        fontSize: '30px',
        fontWeight: 500,
        lineHeight: 'normal',
      },
      semibold: {
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: 'normal',
      },
      bold: {
        fontSize: '30px',
        fontWeight: 700,
        lineHeight: 'normal',
      },
    },
    color: {
      default: { color: colors.black3 },
      white: { color: colors.white },
      black3: { color: colors.black3 },
      black1: { color: colors.black1 },
      sky: { color: colors.sky },
      red: { color: colors.red },
      primary: { color: colors.primary },
      primaryDark: { color: colors.primaryDark },
      primarySub: { color: colors.primarySub },
      primaryBackground: { color: colors.primaryBackground },
      primarySubSub: { color: colors.primarySubSub },
      gray01: { color: colors.gray01 },
      gray02: { color: colors.gray02 },
      gray03: { color: colors.gray03 },
      gray04: { color: colors.gray04 },
      gray05: { color: colors.gray05 },
      gray06: { color: colors.gray06 },
      gray07: { color: colors.gray07 },
      gray08: { color: colors.gray08 },
      gray09: { color: colors.gray09 },
      blue: { color: colors.blue },
    },
  },
});

export const LargeTitle1 = styled('p', {
  fontFamily: 'Pretendard', // 공통으로 사용되는 fontFamily

  variants: {
    weight: {
      regular: {
        fontSize: '32px',
        fontWeight: 400,
        lineHeight: 'normal',
      },
      medium: {
        fontSize: '32px',
        fontWeight: 500,
        lineHeight: 'normal',
      },
      semibold: {
        fontSize: '32px',
        fontWeight: 600,
        lineHeight: 'normal',
      },
      bold: {
        fontSize: '32px',
        fontWeight: 700,
        lineHeight: 'normal',
      },
    },
    color: {
      default: { color: colors.black3 },
      white: { color: colors.white },
      black3: { color: colors.black3 },
      black1: { color: colors.black1 },
      sky: { color: colors.sky },
      red: { color: colors.red },
      primary: { color: colors.primary },
      primaryDark: { color: colors.primaryDark },
      primarySub: { color: colors.primarySub },
      primaryBackground: { color: colors.primaryBackground },
      primarySubSub: { color: colors.primarySubSub },
      gray01: { color: colors.gray01 },
      gray02: { color: colors.gray02 },
      gray03: { color: colors.gray03 },
      gray04: { color: colors.gray04 },
      gray05: { color: colors.gray05 },
      gray06: { color: colors.gray06 },
      gray07: { color: colors.gray07 },
      gray08: { color: colors.gray08 },
      gray09: { color: colors.gray09 },
      blue: { color: colors.blue },
    },
  },
});

export const TermsDetail = styled('p', {
  fontFamily: 'Pretendard', // 공통으로 사용되는 fontFamily

  variants: {
    weight: {
      regular: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
      },
      medium: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
      },
      semibold: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '20px',
      },
      bold: {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
      },
    },
    color: {
      gray09: { color: colors.gray09 },
    },
  },
})

export const SmallText = styled('p', {
  fontFamily: 'Pretendard',

  variants: {
    weight: {
      regular: {
        fontSize: '10px',
        fontWeight: 400,
        lineHeight: '12px',
      },
      medium: {
        fontSize: '10px',
        fontWeight: 500,
        lineHeight: '12px',
      },
      semibold: {
        fontSize: '10px',
        fontWeight: 600,
        lineHeight: '12px',
      },
      bold: {
        fontSize: '10px',
        fontWeight: 700,
        lineHeight: '12px',
      },
    },
    color: {
      default: { color: colors.black3 },
      white: { color: colors.white },
      black3: { color: colors.black3 },
      black1: { color: colors.black1 },
      sky: { color: colors.sky },
      red: { color: colors.red },
      primary: { color: colors.primary },
      primaryDark: { color: colors.primaryDark },
      primarySub: { color: colors.primarySub },
      primaryBackground: { color: colors.primaryBackground },
      primarySubSub: { color: colors.primarySubSub },
      gray01: { color: colors.gray01 },
      gray02: { color: colors.gray02 },
      gray03: { color: colors.gray03 },
      gray04: { color: colors.gray04 },
      gray05: { color: colors.gray05 },
      gray06: { color: colors.gray06 },
      gray07: { color: colors.gray07 },
      gray08: { color: colors.gray08 },
      gray09: { color: colors.gray09 },
      blue: { color: colors.blue },
    },
  },
})

