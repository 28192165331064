import React, { useState, useEffect, MouseEvent } from 'react';
import { ModalContainer, Overlay, ListItem, ConfirmButton } from './styles';
import { Caption2, Headline } from 'styles/typography';
import Row from 'components/atoms/Row';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors'; // styled components를 import 합니다.

interface YearSelectBottomModalProps {
  isVisible: boolean;
  title: string;
  onClose: () => void;
  onSelectItem: (year: number) => void;
  data: string[];
  onSuccess?: () => void;
}

const startYear = 2024;

const CustomListModal: React.FC<YearSelectBottomModalProps> = ({
  isVisible,
  title,
  onClose,
  onSelectItem,
  data,
  onSuccess,
}) => {
  const [selected, setSelected] = useState<number | null>(null);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isVisible]);

  if (!isVisible) return null;

  const handleListItemClick = (event: MouseEvent<HTMLDivElement>, item: number) => {
    event.stopPropagation();
    setSelected(item);
    onSelectItem(item);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Overlay onClick={handleClose} />
      <ModalContainer isVisible={isVisible} isScroll={data.length > 4}>
        <Headline weight={'medium'} color={'black1'} style={{ textAlign: 'center' }}>
          {title}
        </Headline>
        <div
          style={{
            flex: 1,
            overflowY: 'auto',
            paddingBottom: '16px',
            marginBottom: '28px',
            height: data.length > 4 ? 240 : 'auto',
            marginTop: 16,
          }}
        >
          {data.map((year, index) => (
            <ListItem
              key={year}
              isSelected={selected === index}
              onClick={event => handleListItemClick(event, index)}
            >
              {year}
            </ListItem>
          ))}
        </div>

        {onSuccess ? (
          <Row style={{ gap: '10px' }}>
            <Button
              backgroundColor={colors.gray01}
              radius={4}
              style={{
                height: 46,
                width: 'calc(100%)',
              }}
              onClick={() => {
                onClose();
              }}
            >
              <Caption2 weight={'semibold'} color={'gray06'}>
                취소
              </Caption2>
            </Button>
            <Button
              backgroundColor={selected !== null ? colors.primary : colors.gray06}
              radius={4}
              style={{
                height: 46,
                width: 'calc(100%)',
              }}
              onClick={() => {
                onClose();
                onSuccess();
              }}
              disabled={selected === null}
            >
              <Caption2 weight={'semibold'} color={'white'}>
                확인
              </Caption2>
            </Button>
          </Row>
        ) : (
          <Button
            backgroundColor={selected !== null ? colors.primary : colors.gray06}
            radius={4}
            style={{
              height: 46,
              width: 'calc(100%)',
            }}
            onClick={() => {
              onClose();
            }}
            disabled={selected === null}
          >
            <Caption2 weight={'semibold'} color={'white'}>
              확인
            </Caption2>
          </Button>
        )}
      </ModalContainer>
    </>
  );
};
export default CustomListModal;
