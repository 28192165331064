import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  backgroundColor: '#fff',
  padding: '28px 20px 28px 20px',
});

export const WrapTime = styled('div', {
  padding: '0px 16px',
  borderRadius: '8px',
  border: `1px solid ${colors.gray02}`,
  marginTop: '16px',
  marginBottom: '20px',
  height: `48px`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
});