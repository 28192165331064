import { styled } from '@stitches/react';

export const SettingServiceTitleSpan = styled('span', {
  fontSize: '13px',
  fontWeight: '400',
  lineHeight: 'normal',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  color: '#868686',
});

export const SettingServiceSubTitleSpan = styled('span', {
  fontSize: '9px',
  fontWeight: '400',
  lineHeight: 'normal',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  color: '#868686',
});

export const ArrowIcon = styled('img', {
  width: '5px',
  height: '8px',
  marginLeft: '10px',
  alignSelf: 'center',
});

export const ToggleContainer = styled('div', {
  width: '40px',
  height: '20px',
  borderRadius: '20px',
  backgroundColor: '#ccc',
  position: 'relative',
  transition: 'background-color 0.3s',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ToggleButton = styled('div', {
  width: '18px',
  height: '18px',
  borderRadius: '50%',
  backgroundColor: 'white',
  position: 'absolute',
  top: '1px',
  left: '1px',
  transition: 'left 0.3s',
});

export const SettingServiceContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  borderBottom: '1px solid #E6E6E6',
  paddingBottom: '10px',
  marginTop: '12px',
  fontFamily: 'Pretendard',
  fontWeight: 400,
  fontSize: '14px',
  fontStyle: 'normal',
  lineHeight: 'normal',
});

export const SettingRowContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  boxSizing: 'border-box',
  variants: {
    cursor: {
      pointer: { cursor: 'pointer' },
      default: { cursor: 'default' },
    },
  },
});

// 콘텐츠 컨테이너
export const ContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
});
