import React from 'react';
import {
  ReductionFileSubmitContentsText,
  ReductionFileSubmitListContainer,
  StyledBottomModal,
  ListText,
} from './styles';
import icNoticeCaution from '../../../../assets/ic/ic_notice_caution.svg';
import Row from 'components/atoms/Row';
import { Caption2, Headline } from 'styles/typography';

interface CheckModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const ReductionFileSubmitBottomModal: React.FC<CheckModalProps> = ({ isVisible, onClose }) => {
  if (!isVisible) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
      }}
      onClick={onClose}
    >
      <StyledBottomModal isVisible={isVisible}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            lineHeight: '20px',
          }}
        >
          <img
            src={icNoticeCaution}
            style={{
              width: '68px',
              height: '68px',
              marginBottom: '16px', // Add margin below image
            }}
            alt="공지 아이콘"
          />
          <div
            style={{
              textAlign: 'center',
              fontFamily: 'Pretendard',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '25px',
            }}
          >
            <span>
              아래 <span style={{ color: '#8B63BB' }}>유의사항</span>을 꼭<br />
              확인해주세요!
            </span>
          </div>
          <ReductionFileSubmitListContainer>
            <Row alignItems={'flex-start'} style={{ wordBreak: 'keep-all' }}>
              <Headline weight={'regular'} color={'primary'} style={{ marginRight: 8 }}>
                ·
              </Headline>
              <ListText>
                공영주차장 요금 감면 대상자 신청은 등록 완료까지 <span>최대 3일까지 소요</span>될 수
                있습니다.
              </ListText>
            </Row>
            <Row alignItems={'flex-start'} style={{ wordBreak: 'keep-all' }}>
              <Headline weight={'regular'} color={'primary'} style={{ marginRight: 8 }}>
                ·
              </Headline>
              <ListText>
                현재는 <span>일부 지역(성동구) 지정된 주차장</span>에서만 감면 혜택 적용이
                가능합니다.
              </ListText>
            </Row>
          </ReductionFileSubmitListContainer>
        </div>
        <button
          style={{
            width: '100%',
            height: '46px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            border: 'none',
            backgroundColor: '#8B63BB',
            color: '#fff',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '16px',
            fontWeight: 700,
          }}
        >
          유의사항을 확인했습니다
        </button>
      </StyledBottomModal>
    </div>
  );
};

export default ReductionFileSubmitBottomModal;
