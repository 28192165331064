import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Container } from './styles';
import Row from 'components/atoms/Row';
import { Body2, Caption2, Caption3, Caption4, SubHeadline } from 'styles/typography';
import Title from 'components/atoms/Title/PageTitle';
import {
  BottomButtonContainer,
  ButtonEnableDisable,
  CertificationContainer,
  PhoneNumberBorderContainer,
  UserInfoInput,
} from 'pages/DeleteAccount/DeleteAccountMain/styles';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import { IcDeleteTextSVG } from 'assets/ic';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import IcFailCheckRed from 'assets/ic/ic_fail_check_red.svg';
import { handleChatClick, moveJSP, phoneNumberCheck } from 'utils/common';
import { MainContainerNoMargin } from 'components/styles';
import { JSP_URL } from 'constants/baseConstants';
import { identityVerificationStore } from 'stores/store/Auth/IdentityVerificationStore';
import { encTextWord, getRandomString } from 'utils/cryptoJS';
import CustomBottomModal from 'components/atoms/Modal/CustomBottomModal';
import { IUserInfo } from 'models/UserInfo/userInfoModel';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import useUserInfo from 'hooks/useUserInfo';

interface IFormInputs {
  certification: string;
  phone: string;
}

const IdentityVerificationPage: React.FC = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { iv, salt } = getRandomString();

  const [isCertification, setIsCertification] = useState(false);
  const [time, setTime] = useState(180);
  const [isFocused, setIsFocused] = useState(false);
  const [isCertificationFocused, setIsCertificationFocused] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [memberUID, setMemberUID] = useState<number | null>(null);

  const { memberID, memberPW, loginType } = useUserInfo();
  const memberEncPw = encTextWord(memberPW, iv, salt);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputs>({
    defaultValues: {
      certification: '',
    },
  });

  const certification = watch('certification', '');
  const phone = watch('phone', '');

  const phoneEnc = encTextWord(phone, iv, salt);

  useEffect(() => {
    if (!isCertification) return;

    // 1초마다 타이머 업데이트
    const timerInterval = setInterval(() => {
      setTime(prevTime => {
        if (prevTime <= 0) {
          clearInterval(timerInterval);

          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    // 컴포넌트가 언마운트되면 타이머를 정리합니다.
    return () => clearInterval(timerInterval);
  }, [isCertification]);

  useEffect(() => {
    if (time === 0) {
      showToast('인증 시간이 지났어요. [전송]을 눌러 주세요.', IcFailCheckRed);
      (document.activeElement as HTMLElement).blur();
    }
  }, [time]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      // 모든 값이 유효한지 확인
      if (!memberID || !memberPW || !loginType) {
        console.warn('Required values are missing:', {
          memberID,
          memberPW,
          loginType,
        });
        return; // 값이 없으면 실행 중단
      }

      const requestBody: IUserInfo = {
        memberID,
        memberPW: memberEncPw,
        loginType,
      };

      try {
        await userInfoStore.fetchUserInfoSearch(requestBody, iv, salt);

        if (userInfoStore.memberUID != null) {
          setMemberUID(userInfoStore.memberUID);
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchUserInfo().catch(error => console.error('Error in fetchData:', error));
  }, [memberID, memberPW, loginType]);

  const handleLeftOnClick = () => {
    window.location.href = JSP_URL;
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const onSubmit: SubmitHandler<IFormInputs> = async data => {
    console.log('폼 데이터:', data);

    console.log(certification);
    if (!isCertification) {
      getCode();
      setIsCertification(true);
    } else {
      const res = await identityVerificationStore.fetchValidateAuthAndModifyMemberTell(
        phoneEnc,
        memberUID ?? 0,
        data.certification,
        iv,
        salt,
      );
      if (res?.data === 'DUPLICATE TELL EXIST') {
        setIsDuplicate(true);
      } else if (res?.data === 'VALIDATE FAIL') {
        showToast('인증번호가 틀렸어요.', IcFailCheckRed);
      } else if (res?.data === 'SUCCESS') {
        alert('비밀번호가 변경되었습니다.');
        moveJSP();
      }
    }
  };

  const getCode = async () => {
    await identityVerificationStore.fetchGetAuthCodeByTell(phoneEnc, iv, salt);
  };

  const closeModal = () => {
    setIsDuplicate(false);
  };

  return (
    <MainContainerNoMargin>
      <Title
        title="본인 인증"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      <Container>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', marginTop: 20 }}>
          <CertificationContainer isFocused={isFocused}>
            <Row justifyContent={'space-between'}>
              <SubHeadline weight={'bold'} color={'primary'}>
                휴대폰 번호
              </SubHeadline>
            </Row>
            <Row justifyContent={'space-between'} style={{ marginTop: 8 }}>
              <UserInfoInput
                type="number"
                placeholder={'숫자만 입력해 주세요'}
                id="phone"
                {...register('phone', { required: '필수 입력 항목입니다.' })}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
              />
              {phone ? (
                <div
                  onClick={() => {
                    setValue('phone', '');
                  }}
                >
                  <IcDeleteTextSVG />
                </div>
              ) : null}
            </Row>
          </CertificationContainer>
          {isCertification ? (
            <CertificationContainer isFocused={isCertificationFocused}>
              <Row justifyContent={'space-between'}>
                <SubHeadline weight={'bold'} color={'primary'}>
                  인증 번호
                </SubHeadline>

                {time !== 0 ? (
                  <Caption2 weight={'regular'} color={'gray06'}>
                    {formatTime(time)}
                  </Caption2>
                ) : null}
              </Row>
              <Row
                justifyContent={'space-between'}
                style={{ marginTop: 8, width: '100%' }}
                // alignItems={'flex-start'}
              >
                <UserInfoInput
                  type="number"
                  placeholder={isCertification ? '6자리를 입력해 주세요' : '[전송]을 눌러주세요'}
                  id="certification"
                  {...register('certification', { required: '필수 입력 항목입니다.' })}
                  onFocus={() => setIsCertificationFocused(true)}
                  onChange={() => setIsCertificationFocused(false)}
                />
                <Button
                  horizontal={12}
                  style={{
                    height: 32,
                  }}
                  backgroundColor={isCertification && time > 150 ? colors.gray05 : colors.primary}
                  radius={4}
                  onClick={() => {
                    setValue('certification', '');
                    setTime(180);
                    getCode();
                  }}
                  disabled={isCertification && time > 150}
                >
                  <Caption2 weight={'semibold'} color={'white'}>
                    {isCertification && time !== 0 ? '재전송' : '전송'}
                  </Caption2>
                </Button>
              </Row>
            </CertificationContainer>
          ) : null}
        </form>
        {phoneNumberCheck(phone) && !isCertification ? (
          <BottomButtonContainer>
            <ButtonEnableDisable onClick={handleSubmit(onSubmit)}>
              인증번호 보내기
            </ButtonEnableDisable>
          </BottomButtonContainer>
        ) : null}

        {isCertification && time !== 0 ? (
          <BottomButtonContainer>
            <ButtonEnableDisable onClick={handleSubmit(onSubmit)}>인증하기</ButtonEnableDisable>
          </BottomButtonContainer>
        ) : null}
      </Container>

      <CustomBottomModal
        isVisible={isDuplicate}
        onClose={closeModal}
        title1={'고객센터 문의가 필요해요.'}
        description={
          '해당 번호는 이전에 가입했던 회원의 휴대폰 번호예요.\n이 번호의 실제 사용자라면 문의해 주세요.'
        }
        leftClick={closeModal}
        leftText={'취소'}
        rightClick={() => {
          handleChatClick();
          closeModal();
        }}
        rightText={'문의하러 가기'}
      />
    </MainContainerNoMargin>
  );
};

export default IdentityVerificationPage;
