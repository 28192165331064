export const API_URL = 'https://mobil.zoomansa.com:444/api';
export const JSP_URL = 'https://parking.zoomansa.com/mobileAPI/service/main.jsp';
export const JSP_LOGIN_URL = 'https://parking.zoomansa.com/mobileAPI/service/login.jsp';
export const JQUERY_SCRIPT_URL = 'https://code.jquery.com/jquery-3.6.0.min.js';
export const INNOPAY_SCRIPT_URL = 'https://pg.innopay.co.kr/ipay/js/innopay-2.0.js';
export const TERRACE_PAYMENT_RETURN_URL = 'https://web.zoomansa.co.kr/terraceTowerPaymentResult';

// 주만사 개인정보 처리 방침 이전 버전
export const PAST_POLICY_PERSONAL =
  'https://zooparking.notion.site/2023-12-01-ver-14ab9c4fccce80619f56d26d271f92f0';
// 주만사 서비스 이용약관 이전 버전
export const PAST_POLICY_SERVICE =
  'https://zooparking.notion.site/2024-12-16-13bb9c4fccce80c297d1c2af9ac2f97d';
// export const TEST_URL = 'http://192.168.0.198:8090/api';
// export const TEST_URL = 'http://192.168.0.97:8090/api';
export const TEST_URL = 'http://test.zoomansa.com/api';
export const JSP_TEST_URL = 'http://ars.zoomansa.com/mobileAPI/service/main.jsp';
