import { styled } from '@stitches/react';

export const StyledBottomModal = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: 'auto',
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  transform: 'translateY(100%)',
  display: 'flex',
  flexDirection: 'column',
  padding: '30px 25px 40px 25px',
  boxSizing: 'border-box',
  borderRadius: '20px 20px 0px 0px',

  zIndex: 10000,
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' },
      false: { transform: 'translateY(100%)' },
    },
  },
});

export const ReductionFileSubmitListContainer = styled('ul', {
  padding: '0', // Remove padding for proper left alignment
  marginTop: '23px',
  marginLeft: '25px', // Remove margin-left for proper alignment
  width: '100%',
});

export const ReductionFileSubmitContentsText = styled('li', {
  marginBottom: '10px', // Increased margin-bottom for better spacing
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '22px',
  color: '#333',
  textAlign: 'left', // Align text to the left
});

export const ListText = styled('div', {
  marginBottom: '10px', // Increased margin-bottom for better spacing
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '22px',
  color: '#333',
  textAlign: 'left',
  wordBreak: 'keep-all',
  '& span': {
    color: '#ED1659',
  },
});
