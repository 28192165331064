import { styled } from '@stitches/react';

export const MainContainer = styled('div', {
  display: 'block',
  margin: 'auto 16px auto 16px',

  '@media screen and (max-height: 480px)': {
    padding: '20px',
  },
});

export const UserInfoInputContainer = styled('div', {
  width: '100%',
  background: '#FFFFFF',
  borderRadius: '12px',
  display: 'flex',
  textAlign: 'center',
});


export const LogoContainer = styled('div', {
  height: 'auto',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center', // 가로 가운데 정렬
  alignItems: 'center', // 세로 가운데 정렬
  marginTop: 37
});

