import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

import { MainContainer, MainContainerNoMargin } from 'components/styles';
import Title from 'components/atoms/Title/PageTitle';
import ToggleButton from 'components/atoms/Toggle';
import {
  PhoneNumberBorderContainer,
  UserInfoInput,
  TimerContainer,
  VerificationCodeInput,
  VerificationCodeInputContainer,
} from 'components/atoms/UserInfoInputsContainers/styles';
import { Body, Caption2, SubHeadline } from 'styles/typography';
import Row from 'components/atoms/Row';
import { Container } from './styles';
import PopupModal from 'components/atoms/Modal/PopupModal';
import { phoneNumberCheck } from 'utils/common';

interface IFormInputs {
  phone: string;
  certification: string;
  password: string;
  passwordVerify: string;
}

interface PopupModalProps {
  title1: string;
  leftClick: () => void;
  leftText: string;
  description?: string;
  rightClick?: () => void;
  rightText?: string;
  title2?: string;
}

const defaultPopup: PopupModalProps = {
  title1: '',
  leftText: '',
  leftClick: () => {},
  rightText: '',
  rightClick: undefined,
  description: '',
  title2: '',
};

const SearchMyInfo: React.FC = () => {
  const navigate = useNavigate();
  const { register, setValue, handleSubmit, watch } = useForm<IFormInputs>();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isCertification, setIsCertification] = useState(false);
  const [time, setTime] = useState(180);
  const [phoneError, setPhoneError] = useState('');
  const [isPopup, setIsPopup] = useState(false);
  const [popup, setPopup] = useState<PopupModalProps>(defaultPopup);
  const [isPassword, setIsPassword] = useState(false);

  const phone = watch('phone', '');
  const certification = watch('certification', '');
  const password = watch('password', '');
  const passwordVerify = watch('passwordVerify', '');

  useEffect(() => {
    if (!isCertification) return;

    // 1초마다 타이머 업데이트
    const timerInterval = setInterval(() => {
      setTime(prevTime => {
        if (prevTime <= 0) {
          clearInterval(timerInterval);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    // 컴포넌트가 언마운트되면 타이머를 정리합니다.
    return () => clearInterval(timerInterval);
  }, [isCertification]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const onSubmit: SubmitHandler<IFormInputs> = data => {
    if (selectedIndex === 0) {
      if (phoneNumberCheck(phone)) {
        setPhoneError('올바른 휴대폰 번호를 입력해주세요');
        return;
      } else if (!isCertification) {
        setPhoneError('');
        setIsCertification(true);
        return;
      } else {
        // 인증 맞는 경우

        // setPopup({
        //   title1: '아이디 찾기',
        //   description: '고객님의 아이디는 입니다',
        //   leftText: '비밀번호 찾기',
        //   leftClick: () => {
        //     closePopup();
        //     handleToggle(1)
        //   },
        //   rightText: '로그인 화면',
        //   rightClick: () => {
        //     navigate('/zmsLogin')
        //   }
        // })
        setPopup({
          title1: '소셜로그인 계정입니다\nNAVER 로그인 해주세요',
          leftText: '확인',
          leftClick: () => {
            closePopup();
          },
          rightClick: () => {},
        });

        setIsPopup(true);
        // 인증 틀린 경우
      }
    } else if (selectedIndex === 1 && !isPassword) {
      if (phone.length !== 11) {
        setPhoneError('올바른 휴대폰 번호를 입력해주세요');
        return;
      } else if (!isCertification) {
        setPhoneError('');
        setIsCertification(true);
        return;
      } else {
        setIsPassword(true);
      }
    } else {
      setPopup({
        title1: '비밀번호가 초기화되었습니다',
        description: '로그인을 진행해주세요',
        leftText: '확인',
        leftClick: () => {
          closePopup();
          navigate('/zmsLogin');
        },
        rightClick: () => {},
      });

      setIsPopup(true);
    }

    console.log(data);
    console.log('로그인 화면 이동');
  };

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const handleToggle = (index: number) => {
    setSelectedIndex(index);
  };

  const closePopup = () => {
    setIsPopup(false);
  };

  const buttonStyle = {
    padding: '0px',
    width: 'calc(100% - 40px)',
    height: '46px',
    backgroundColor: certification.length === 6 ? '#8B63BB' : '#fff',
    border: '1px solid #8B63BB',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: 'normal',
    borderRadius: '4px',
    cursor: phone ? 'pointer' : 'not-allowed',
  };

  return (
    <MainContainer>
      <Title
        title="아이디/비밀번호 찾기"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      <div style={{ marginTop: 20 }}>
        <ToggleButton
          options={['아이디 찾기', '비밀번호 찾기']}
          selectedIndex={selectedIndex}
          content={[
            <div key="아이디 찾기">
              <Container>
                <div
                  style={{
                    marginTop: '37px',
                  }}
                >
                  <Body weight={'semibold'} color={'black3'}>
                    회원가입 시 입력한
                  </Body>
                  <Row>
                    <Body weight={'semibold'} color={'primary'}>
                      휴대폰 번호
                    </Body>
                    <Body weight={'semibold'} color={'black3'}>
                      를 입력 해주세요
                    </Body>
                  </Row>

                  <div style={{ marginTop: '37px' }}>
                    <PhoneNumberBorderContainer>
                      <UserInfoInput
                        type="number"
                        id="phone"
                        {...register('phone', { required: '필수 입력 항목입니다.' })}
                        placeholder="휴대폰 번호"
                      />
                    </PhoneNumberBorderContainer>
                    {phoneError ? (
                      <Caption2 weight={'bold'} color={'red'} style={{ marginTop: 7 }}>
                        {phoneError}
                      </Caption2>
                    ) : null}
                  </div>

                  {isCertification ? (
                    <div>
                      <VerificationCodeInputContainer>
                        <VerificationCodeInput
                          type="number"
                          placeholder="인증번호 입력"
                          id="certification"
                          {...register('certification', { required: '필수 입력 항목입니다.' })}
                        />
                        <TimerContainer>{formatTime(time)}</TimerContainer>
                      </VerificationCodeInputContainer>
                      {certification.length !== 6 ? (
                        <Caption2 weight={'bold'} color={'red'} style={{ marginTop: 7 }}>
                          인증 번호를 입력해 주세요
                        </Caption2>
                      ) : null}
                    </div>
                  ) : null}
                </div>

                <button
                  style={{
                    ...buttonStyle,
                    position: 'fixed',
                    bottom: 40,
                  }}
                  onClick={handleSubmit(onSubmit)}
                  disabled={!phone}
                >
                  <SubHeadline
                    weight={'regular'}
                    color={certification.length === 6 ? 'white' : 'primary'}
                  >
                    {!isCertification
                      ? '인증번호 요청'
                      : certification.length === 6
                        ? '확인'
                        : '인증번호 다시 받기'}
                  </SubHeadline>
                </button>
              </Container>
            </div>,

            <div key="비밀번호 찾기">
              {isPassword ? (
                <Container>
                  <div
                    style={{
                      marginTop: '37px',
                    }}
                  >
                    <Row>
                      <Body weight={'semibold'} color={'primary'}>
                        새로운 비밀번호
                      </Body>
                      <Body weight={'semibold'} color={'black3'}>
                        를 입력 해주세요
                      </Body>
                    </Row>
                    <div style={{ marginTop: '37px' }}>
                      <PhoneNumberBorderContainer>
                        <UserInfoInput
                          type="password"
                          id="password"
                          {...register('password', {
                            required: '필수 입력 항목입니다.',
                          })}
                          placeholder="비밀번호"
                        />
                      </PhoneNumberBorderContainer>
                    </div>

                    <div>
                      <VerificationCodeInputContainer>
                        <VerificationCodeInput
                          type="password"
                          placeholder="비밀번호"
                          id="passwordVerify"
                          {...register('passwordVerify', { required: '필수 입력 항목입니다.' })}
                        />
                      </VerificationCodeInputContainer>
                      {passwordVerify !== password || !passwordVerify ? (
                        <Caption2 weight={'bold'} color={'red'} style={{ marginTop: 7 }}>
                          {passwordVerify !== password
                            ? '비밀번호가 일치하지 않습니다'
                            : '비밀번호를 입력해 주세요'}
                        </Caption2>
                      ) : null}
                    </div>
                  </div>
                  <button
                    style={{
                      ...buttonStyle,
                      position: 'fixed',
                      bottom: 40,
                    }}
                    onClick={handleSubmit(onSubmit)}
                    disabled={!phone}
                  >
                    <SubHeadline
                      weight={'regular'}
                      color={certification.length === 6 ? 'white' : 'primary'}
                    >
                      {!isCertification
                        ? '인증번호 요청'
                        : certification.length === 6
                          ? '확인'
                          : '인증번호 다시 받기'}
                    </SubHeadline>
                  </button>
                </Container>
              ) : (
                <Container>
                  <div
                    style={{
                      marginTop: '37px',
                    }}
                  >
                    <Body weight={'semibold'} color={'black3'}>
                      회원가입 시 입력한
                    </Body>
                    <Row>
                      <Body weight={'semibold'} color={'primary'}>
                        휴대폰 번호
                      </Body>
                      <Body weight={'semibold'} color={'black3'}>
                        를 입력 해주세요
                      </Body>
                    </Row>
                    <div style={{ marginTop: '37px' }}>
                      <PhoneNumberBorderContainer>
                        <UserInfoInput
                          type="number"
                          id="phone"
                          {...register('phone', {
                            required: '필수 입력 항목입니다.',
                          })}
                          placeholder="휴대폰 번호"
                        />
                      </PhoneNumberBorderContainer>
                      {phoneError ? (
                        <Caption2 weight={'bold'} color={'red'} style={{ marginTop: 7 }}>
                          {phoneError}
                        </Caption2>
                      ) : null}
                    </div>
                    {isCertification ? (
                      <div>
                        <VerificationCodeInputContainer>
                          <VerificationCodeInput
                            type="number"
                            placeholder="인증번호 입력"
                            id="certification"
                            {...register('certification', { required: '필수 입력 항목입니다.' })}
                          />
                          <TimerContainer>{formatTime(time)}</TimerContainer>
                        </VerificationCodeInputContainer>
                        {certification.length !== 6 ? (
                          <Caption2 weight={'bold'} color={'red'} style={{ marginTop: 7 }}>
                            인증 번호를 입력해 주세요
                          </Caption2>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  <button
                    style={{
                      ...buttonStyle,
                      position: 'fixed',
                      bottom: 40,
                    }}
                    onClick={handleSubmit(onSubmit)}
                    disabled={!phone}
                  >
                    <SubHeadline
                      weight={'regular'}
                      color={certification.length === 6 ? 'white' : 'primary'}
                    >
                      {!isCertification
                        ? '인증번호 요청'
                        : certification.length === 6
                          ? '확인'
                          : '인증번호 다시 받기'}
                    </SubHeadline>
                  </button>
                </Container>
              )}
            </div>,
          ]}
          onToggle={handleToggle}
        />
        <PopupModal
          isVisible={isPopup}
          onClose={closePopup}
          title1={popup.title1}
          description={popup.description ?? ''}
          leftClick={popup.leftClick}
          leftText={popup.leftText}
          rightText={popup.rightText ?? ''}
          rightClick={popup.rightClick}
        />
      </div>
    </MainContainer>
  );
};

export default SearchMyInfo;
