import { CarNumberErrorContainer, CarNumberInput, CarNumberInputContainer } from './styles';
import { Controller, useForm } from 'react-hook-form';
import { Caption1, Caption3 } from 'styles/typography';
import React, { useEffect, useState } from 'react';

interface IFormInputs {
  carNumber: string;
}

const VehicleRegistration = ({ setCarNumber }: { setCarNumber: React.Dispatch<string> }) => {
  const [isCarInputFocus, setIsCarInputFocus] = useState(false);

  const {
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<IFormInputs>({
    defaultValues: {
      carNumber: sessionStorage.getItem('carNumber') || '',
    },
  });

  const carNumber = watch('carNumber', '');

  useEffect(() => {
    const isValidCarNumber = /^([가-힣]{2}[0-9]{1,3}|[0-9]{2,3})[가-힣]{1}[0-9]{4}$/.test(
      carNumber,
    );

    if (carNumber && !isValidCarNumber) {
      setError('carNumber', { type: 'manual', message: '차량번호 형식이 올바르지 않아요.' });
    } else if (carNumber && isValidCarNumber) {
      setCarNumber(carNumber);
      clearErrors('carNumber');
    } else {
      clearErrors('carNumber');
    }
  }, [carNumber, setError, clearErrors]);

  const handleFocus = () => {
    setIsCarInputFocus(true);
  };

  const handleBlur = () => {
    setIsCarInputFocus(false);
  };

  return (
    <form>
      <CarNumberInputContainer isFocus={isCarInputFocus}>
        <Caption1 weight={'regular'} color={'gray08'}>
          차량번호
        </Caption1>
        <Controller
          name="carNumber"
          control={control}
          render={({ field }) => (
            <CarNumberInput
              type="text"
              id="carNumber"
              placeholder="예시 : 12가3456, 서울12가3456"
              {...field}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={e => setValue('carNumber', e.target.value)}
              isFocus={isCarInputFocus}
            />
          )}
        />
      </CarNumberInputContainer>
      <CarNumberErrorContainer>
        {carNumber && errors.carNumber ? (
          <Caption3 weight={'regular'} color={'red'}>
            {errors.carNumber.message}
          </Caption3>
        ) : (
          carNumber &&
          !errors.carNumber && (
            <Caption3 weight={'regular'} color={'blue'}>
              올바른 차량번호 형식이에요.
            </Caption3>
          )
        )}
      </CarNumberErrorContainer>
    </form>
  );
};

export default VehicleRegistration;
