import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { MainContainerNoMargin } from 'components/styles';
import { Container } from './styles';
import Row from 'components/atoms/Row';
import Logo from 'assets/images/img_zoomansa_logo.png';
import Divider from 'components/atoms/Divider';
import { colors } from 'styles/colors';
import { Caption1, Caption2, Caption4, Headline, SubHeadline } from 'styles/typography';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import Footer from 'components/atoms/Footer';
import CustomListModal from 'components/atoms/Modal/CustomListModal';
import { METHODS } from 'utils/bankUtil';
import GeojuSuccessUseInfo from 'containers/geojuParking/GeojuSuccessUseInfo';
import GeojuPaymentSuccessInfo from 'containers/geojuParking/GeojuPaymentSucessInfo';
import Button from 'components/atoms/Button/Button';

const GeojuParkingPaymentSuccessPage = observer(() => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const [carNumber, setCarNumber] = useState('');
  const [isMethodModal, setIsMethodModal] = useState(false);

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  useEffect(() => {
    // 데이터를 가져오는 함수 정의
    const fetchData = async () => {
      // try {
      //   await shareStore.fetchGetProductDetailOperInfo(productUID);
      // } catch (error) {
      //   console.error('Error fetching data:', error);
      // }
    };

    fetchData(); // 데이터 가져오기 호출
  }, []);

  // if (isLoading) {
  //   return (
  //     <div
  //       style={{
  //         display: 'flex',
  //         alignItems: 'center',
  //         justifyContent: 'center',
  //         height: '50vh',
  //       }}
  //     >
  //       <Spinner />
  //     </div>
  //   );
  // }

  return (
    <MainContainerNoMargin>
      <Container>
        <Row style={{ margin: '24px 0px' }} alignItems={'center'} justifyContent={'center'}>
          <img alt={'주만사 로고'} width={79} height={19} src={Logo} />
        </Row>
      </Container>

      <Headline
        weight={'bold'}
        color={'primary'}
        style={{
          marginTop: 32,
          textAlign: 'center',
        }}
      >
        결제가 완료되었습니다
      </Headline>
      <Divider
        style={{
          backgroundColor: colors.gray07,
          width: 'calc(100% - 50px)',
          marginTop: 30,
          marginLeft: 25,
        }}
      />
      <GeojuSuccessUseInfo />

      <GeojuPaymentSuccessInfo />

      <Button
        backgroundColor={colors.primary}
        radius={4}
        style={{
          height: 46,
          width: 'calc(100% - 50px)',
          marginTop: 28,
          marginLeft: 25,
          marginBottom: 20,
        }}
        onClick={() => {}}
      >
        <SubHeadline weight={'bold'} color={'white'}>
          영수증 확인하기
        </SubHeadline>
      </Button>

      <Row
        style={{
          marginLeft: 16,
          marginBottom: 20,
        }}
      >
        <Caption4 weight={'regular'} color={'gray06'}>
          ・ 계좌이체 증빙 내역은 고객센터(1666-6248)로 문의 주세요.
        </Caption4>
      </Row>
      <Footer />
    </MainContainerNoMargin>
  );
});

export default GeojuParkingPaymentSuccessPage;
