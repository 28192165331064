import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { MainContainerNoMargin } from 'components/styles';
import { Container, WrapBottom, WrapDesc, WrapTitle } from './styles';
import { Body, Caption2, SubHeadline, Title1 } from 'styles/typography';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import { IcClose48SVG } from 'assets/ic';
import Row from 'components/atoms/Row';
import IMAGE from 'assets/images/img_monthly_desired_onboarding.png';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';

const SignUpMonthlyDesiredOnboardingPage = observer(() => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const handleLeftOnClick = () => {
    navigate('-1');
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  useEffect(() => {
    // 데이터를 가져오는 함수 정의
    const fetchData = async () => {
      // try {
      //   await shareStore.fetchGetProductDetailOperInfo(productUID);
      // } catch (error) {
      //   console.error('Error fetching data:', error);
      // }
    };

    fetchData(); // 데이터 가져오기 호출
  }, []);

  // if (isLoading) {
  //   return (
  //     <div
  //       style={{
  //         display: 'flex',
  //         alignItems: 'center',
  //         justifyContent: 'center',
  //         height: '50vh',
  //       }}
  //     >
  //       <Spinner />
  //     </div>
  //   );
  // }

  return (
    <MainContainerNoMargin>
      <Container>
        <Row justifyContent={'flex-end'} style={{ marginTop: 10, marginRight: 4 }}>
          <IcClose48SVG onClick={() => navigate('/home')} />
        </Row>
        <WrapTitle>
          <Title1 weight={'bold'} color={'black3'}>
            주차 고민 끝,
          </Title1>
          <Title1 weight={'bold'} color={'black3'}>
            주만사 가입을 환영해요!
          </Title1>
        </WrapTitle>

        <WrapDesc>
          <Body weight={'medium'} color={'black3'}>
            월 주차 희망 지역을 등록하면
          </Body>
          <Body weight={'semibold'} color={'black3'}>
            맞춤 주차장 정보를 보내드려요.
          </Body>
        </WrapDesc>
        <Row justifyContent={'flex-end'}>
          <img
            src={IMAGE}
            width={'calc(100%  - 45px)'}
            height={210}
            style={{
              marginTop: 107,
            }}
          />
        </Row>

        <WrapBottom>
          <Button
            backgroundColor={colors.primary}
            radius={4}
            style={{
              height: 46,
              width: 'calc(100% - 40px)',
              marginLeft: 20,
            }}
            onClick={() => {
              navigate('/desiredAreaManagement', {
                state: {
                  nextPage: '/home',
                },
              });
            }}
          >
            <SubHeadline weight={'bold'} color={'white'}>
              희망 지역 등록 바로가기
            </SubHeadline>
          </Button>

          <Button
            backgroundColor={'transparent'}
            radius={4}
            style={{
              width: 'calc(100%)',
              marginTop: 20,
            }}
            onClick={() => {
              navigate('/home');
            }}
          >
            <Caption2
              weight={'regular'}
              color={'gray09'}
              style={{
                textDecorationLine: 'underline',
              }}
            >
              다음에 등록할래요
            </Caption2>
          </Button>
        </WrapBottom>
      </Container>
    </MainContainerNoMargin>
  );
});

export default SignUpMonthlyDesiredOnboardingPage;
