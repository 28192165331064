import { styled } from '@stitches/react';
import { colors } from 'styles/colors';
import { pxToRem } from 'utils/common';

export const Container = styled('div', {
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',  // 세로로 배치
});

export const TextContainer = styled('div', {
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});

export const ParkingButtonListContainer = styled('div', {
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  margin: '0px 20px',
});

export const ParkingButtonContainer = styled('div', {
  padding: '16px',
  backgroundColor: '#F3F4F6',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'space-between',

  variants: {
    isFirst: {
      true: { marginTop : 0 },
      false: { marginTop: 20 },
    },
  },
});

export const Description = styled('div', {
  display: 'flex',
  // flex: 1,
  flexDirection: 'column',
  justifyContent:'space-between',
});

export const Image = styled( 'img', {
  borderRadius: '5px',
  width: pxToRem(120),
  height: pxToRem(90)
})