import { Body, Caption1, Caption2, Caption3, SubHeadline } from 'styles/typography';
import Row from 'components/atoms/Row';
import React, { useState } from 'react';
import icExclamationBlackSVG from 'assets/ic/ic_exclamation_black.svg';
import icCloseBlack28 from 'assets/ic/ic_close_black_28.svg';
import { colors } from "styles/colors";
import { TicketContainer, ViewDivide } from "containers/emptyParkingArea/EmptyParkingApplyHistoryList/styles";
import Lottie from "react-lottie";
import lottieEmpty from "assets/lottie/lottieEmpty80.json";
import PurpleTwoButtonCheckModal from "components/atoms/Modal/PurpleTwoButtonCheckModal";
import {useToast} from "components/atoms/Toast/ToastProvider";
import icSuccessCheckGreen from "assets/ic/ic_success_check_green.svg";

interface ParkingDataItem {
  ticketType: string;
  location: string;
  date: string;
  isInactive: boolean,
}

const parkingDataInitial: ParkingDataItem[] = [
  {
    ticketType: '전일권',
    location: '서울 노원구 덕릉로 124길',
    date: '12.09(월)',
    isInactive: false,
  },
  {
    ticketType: '전일권',
    location: '서울 노원구 덕릉로 124길',
    date: '12.09(월)',
    isInactive: false,
  },
  {
    ticketType: '평일권',
    location: '서울 노원구 덕릉로 124길',
    date: '12.09(월)',
    isInactive: true,
  },
  {
    ticketType: '평일권',
    location: '서울 노원구 덕릉로 124길',
    date: '12.09(월)',
    isInactive: true,
  },
]

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: lottieEmpty,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const EmptyParkingApplyHistoryList = () => {

  const [isNotificationCancelModal, setIsNotificationCancelModal] = useState(false)
  const [selectedTicketType, setSelectedTicketType] = useState<string>('')
  const [selectedLocation, setSelectedLocation] = useState<string>('')
  const [parkingData, setParkingData] = useState(parkingDataInitial)

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const handleCloseButtonClick = (index: number) => {
    setParkingData((prevData) => {
      const newData = [...prevData]
      newData.splice(index, 1)
      return newData
    })
  }

  const { showToast } = useToast()

  return (
    <>
      {parkingData.length === 0 ? (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Lottie options={defaultOptions} height={80} width={80} />
          <Caption2 weight={'regular'} color={'gray07'}>빈자리 신청한 내역이 없어요.</Caption2>
        </div>
      ) : (
        parkingData.map((item, index) => (
          <div key={index} style={{ margin: '20px 20px 0px' }}>
            <Row justifyContent={'space-between'}>
              <SubHeadline
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: item.isInactive ? colors.gray06 : colors.black3
                }}
                weight={'medium'}
                color={'gray07'}
              >
                {item.ticketType}
              </SubHeadline>
              <img
                onClick={() => {
                  if (item.isInactive) {
                    handleCloseButtonClick(index)
                  } else {
                    setSelectedIndex(index)
                    setSelectedTicketType(item.ticketType)
                    setSelectedLocation(item.location)
                    setIsNotificationCancelModal(true)
                  }
                }}
                src={icCloseBlack28}
                alt={'닫기 아이콘'}
              />
            </Row>

            <TicketContainer>
              <Row style={{
                marginBottom: 8
              }}>
                <Caption3 weight={'regular'} color={'gray06'} style={{ width: 50 }}>
                  위치
                </Caption3>
                <Caption3 weight={'medium'} color={'gray07'} style={{ marginLeft: 8 }}>
                  {item.location}
                </Caption3>
              </Row>
              <Row>
                <Caption3 weight={'regular'} color={'gray06'} style={{ width: 50 }}>
                  신청일자
                </Caption3>
                <Caption3 weight={'medium'} color={'gray07'} style={{ marginLeft: 8 }}>
                  {item.date}
                </Caption3>
              </Row>
            </TicketContainer>
            {item.isInactive && (
              <Caption3
                weight={'medium'}
                color={'red'}
                style={{
                  marginTop: '10px'
                }}>미운영중인 상품이에요.</Caption3>
            )}
            {index !== parkingData.length - 1 && <ViewDivide />}
          </div>
        ))
      )}
      {parkingData.length !== 0 && (
        <Row style={{
          margin: '30px 20px 40px',
          borderRadius: '4px',
          border: `1px solid ${colors.gray02}`,
          padding: '10px 12px',
        }}
        >
          <img src={icExclamationBlackSVG} alt={'검정 배경 느낌표'} />
          <Caption2 style={{ marginLeft: 8 }} color={'gray09'} weight={'regular'}>
            주차장이 판매중으로 변경되면 알림을 보내요. 선착순으로 이용신청 받으니 유의해 주세요.
          </Caption2>
        </Row>
      )}

      <PurpleTwoButtonCheckModal
        isVisible={isNotificationCancelModal}
        onClose={() => {
          setIsNotificationCancelModal(false);
        }}
        title={'빈자리 알림을 취소하시나요?'}
        content={
          <Caption1 weight={'regular'} color={'gray08'}>
            [{selectedTicketType}] {selectedLocation}
          </Caption1>
        }
        leftButtonText={'취소'}
        rightButtonText={'네, 취소할래요'}
        rightClick={() => {
          if (selectedIndex !== null) {
            handleCloseButtonClick(selectedIndex)
            showToast('빈자리 알림을 취소 완료했어요.', icSuccessCheckGreen)
          }
          setIsNotificationCancelModal(false)
        }}
      />
    </>
  );
};

export default EmptyParkingApplyHistoryList;
