import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useNavigationType } from 'react-router-dom';
import { observer } from 'mobx-react';

import Title from 'components/atoms/Title/PageTitle';
import { MainContainerNoMargin } from 'components/styles';
import MinganParking from 'containers/share/MinganParking';
import { Container } from 'pages/Share/ShareHistoryPage/styles';
import Button from 'components/atoms/Button/Button';
import Row from 'components/atoms/Row';
import { Caption1 } from 'styles/typography';
import { IcPlusSVG } from 'assets/ic';
import { colors } from 'styles/colors';
import NewTabBar from 'components/atoms/NewTabBar';
import ResidentParking from 'containers/share/ResidentParking';
import NotSharedParking from 'containers/share/NotSharedParking';
import AlreadyShared from 'containers/share/AlreadyShared';
import SharedParkingBottomModal from 'components/atoms/Modal/SharedParkingBottomModal';
import { shareStore } from 'stores/store/Share/shareStore';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import { encTextWord, getRandomString } from 'utils/cryptoJS';
import useUserInfo from 'hooks/useUserInfo';
import { IUserInfo } from 'models/UserInfo/userInfoModel';
import { Spinner } from 'components/atoms/LoadingSpinner/LoadingSpinnerImage/styles';
import { moveJSP } from 'utils/common';

const ShareHistoryPage = observer(() => {
  const navigate = useNavigate();
  const observerRef = useRef<HTMLDivElement>(null);
  const navigationType = useNavigationType();

  const [currentTab, setCurrentTab] = useState(0);
  const [isCarReductionBottomModalVisible, setIsCarReductionBottomModalVisible] = useState(false);
  const [selectedReduction, setSelectedReduction] = useState<string | null>(null);
  const [isComplete, setIsComplete] = useState<boolean | null>(null);
  const [isReceipt, setIsReceipt] = useState(false);

  // 286634, 336773 ,346161
  const [memberUID, setMemberUID] = useState<number | null>(null);
  const [memberPhone, setMemberPhone] = useState<string | null>(null);
  const [pageNum, setPageNum] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isMinganEmpty, setIsMinganEmpty] = useState<boolean | null>(null);
  const [isResidentEmpty, setIsResidentEmpty] = useState<boolean | null>(null);

  const { phoneNumber } = userInfoStore;
  const { iv, salt } = getRandomString();
  const { memberID, memberPW, loginType } = useUserInfo();

  const { matchingValue, parkingLotInfo, isMatchingValueLoading } = shareStore;

  const memberEncPw = encTextWord(memberPW, iv, salt);

  useEffect(() => {
    const fetchUserInfo = async () => {
      // 모든 값이 유효한지 확인
      if (!memberID || !memberPW || !loginType) {
        console.warn('Required values are missing:', {
          memberID,
          memberPW,
          loginType,
        });
        return; // 값이 없으면 실행 중단
      }

      const requestBody: IUserInfo = {
        memberID,
        memberPW: memberEncPw,
        loginType,
      };

      try {
        await userInfoStore.fetchUserInfoSearch(requestBody, iv, salt);

        if (userInfoStore.memberUID != null) {
          setMemberUID(userInfoStore.memberUID);
          setMemberPhone(userInfoStore.phoneNumber);
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchUserInfo().catch(error => console.error('Error in fetchData:', error));
  }, [memberID, memberPW, loginType]);

  const fetchData = async () => {
    if (isLoading || !hasMore) return;

    setIsLoading(true);
    if (memberUID) {
      try {
        const response = await shareStore.fetchGetShareSlotList(memberUID, pageNum);
        if (response) {
          setIsMinganEmpty(response.parkingLotInfo?.parkingLotTotalCnt === 0);
          setIsResidentEmpty(response.residentParkingLotInfo?.parkingLotTotalCnt === 0);
          const totalCount = Math.max(
            response.parkingLotInfo?.parkingLotTotalCnt || 0,
            response.residentParkingLotInfo?.parkingLotTotalCnt || 0,
          );
          const currentCount = Math.max(
            parkingLotInfo?.parkingLotInfo?.parkingLots.length || 0,
            parkingLotInfo?.residentParkingLotInfo?.parkingLots.length || 0,
          );

          setHasMore(currentCount < totalCount);
          if (currentCount < totalCount) {
            setPageNum(pageNum + 1);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 0); // 렌더링 완료 후 실행
  }, []);

  useEffect(() => {
    // 데이터를 가져오는 함수 정의
    if (memberUID && memberPhone) {
      setHasMore(true); // 필요한 기본값으로 초기화
      setPageNum(1);
      setIsLoading(false);
      // 534206
      shareStore.fetchGetSlotSharePageMatchingValue(memberUID, '1');
      fetchData();
      // shareStore.fetchGetShareSlotList(534206);
    }
  }, [memberUID]);
  // }, []);

  useEffect(() => {
    let debounceTimer: NodeJS.Timeout | null = null;

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(async entry => {
          if (entry.isIntersecting && !debounceTimer) {
            debounceTimer = setTimeout(() => {
              fetchData();
              debounceTimer = null; // Timer 초기화ㄴ
            }, 500); // 300ms debounce
          }
        });
      },
      { threshold: 1 },
    );

    if (observerRef.current) observer.observe(observerRef.current);

    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current);
      if (debounceTimer) clearTimeout(debounceTimer); // Timer 정리
    };
  }, [observerRef.current, isLoading, hasMore]);
  // }, []);

  useEffect(() => {
    // @ts-ignore
    if (matchingValue === 'COMPLETEEXIST') {
      setIsComplete(true);
      // @ts-ignore
    } else if (matchingValue === 'WAITINGEXISTCOMPLETENONE') {
      setIsReceipt(true);
    }
  }, [matchingValue]);

  useEffect(() => {
    window.onBtnBackClicked = function () {
      moveJSP();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  const tab = [
    {
      name: '부설 주차장',
      content: parkingLotInfo?.parkingLotInfo.parkingLotTotalCnt,
    },
    {
      name: '거주자 우선 주차장',
      content: parkingLotInfo?.residentParkingLotInfo.parkingLotTotalCnt,
    },
  ];

  const handleTabClick = (index: number) => {
    setCurrentTab(index);
  };

  const handleConfirm = (item: string) => {
    setSelectedReduction(item);
  };

  const RenderButton = ({ empty }: { empty: boolean }) => {
    return (
      <Button
        style={{
          height: '2.5rem',
          marginTop: empty ? 20 : 8,
          marginBottom: 40,
          paddingLeft: 12,
          paddingRight: 16,
        }}
        radius={20}
        onClick={() => {
          setIsCarReductionBottomModalVisible(true);
        }}
      >
        <Row>
          <IcPlusSVG fill={colors.primary} />
          <Caption1 weight={'medium'} color={'primary'} style={{ marginLeft: 4 }}>
            {empty ? '주차장 공유하기' : '다른 주차장 공유하기'}
          </Caption1>
        </Row>
      </Button>
    );
  };

  return (
    <MainContainerNoMargin
      css={{ minHeight: isComplete ? '100vh' : 'auto', height: isComplete ? 'auto' : '100vh' }}
    >
      <Title
        title="주차장 공유내역"
        leftArrowButtonVisible={true}
        leftOnClick={moveJSP}
        rightTextButtonVisible={false}
      />
      {/*<img width={100} height={100} src={Marker} alt={'1'} />*/}
      {/*
        1. 부설, 거주자 둘 모두 0인지
        2. 신청 접수 내용이 있는지 (검수 단계? 어드민 단계)
        3. 둘 중 한개만 0인 경우 0의 해당하는탭 empty
      */}

      <div />

      {matchingValue === null ||
      isMatchingValueLoading ||
      isMinganEmpty === null ||
      isResidentEmpty === null ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '50vh',
          }}
        >
          <Spinner />
        </div>
      ) : isComplete ? (
        <>
          <NewTabBar menuArr={tab} currentTab={currentTab} onClick={handleTabClick} />
          {currentTab == 0 ? (
            <Container isEmpty={isMinganEmpty}>
              {isMinganEmpty ? (
                <>
                  <Caption1 weight={'medium'} color={'gray09'}>
                    아직 공유한 주차장이 없어요.
                  </Caption1>
                  <RenderButton empty={isMinganEmpty} />
                </>
              ) : (
                <>
                  {parkingLotInfo?.parkingLotInfo.parkingLots.map((el, index) => {
                    return <MinganParking data={el} key={index} />;
                  })}
                  {isLoading ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '10vh',
                      }}
                    >
                      <Spinner />
                    </div>
                  ) : null}
                  <div ref={observerRef}></div>

                  <RenderButton empty={isMinganEmpty} />
                </>
              )}
            </Container>
          ) : (
            <Container isEmpty={isResidentEmpty}>
              {isResidentEmpty ? (
                <>
                  <Caption1 weight={'medium'} color={'gray09'}>
                    아직 공유한 주차장이 없어요.
                  </Caption1>
                  <RenderButton empty={isResidentEmpty} />
                </>
              ) : (
                <>
                  {parkingLotInfo?.residentParkingLotInfo.parkingLots.map((el, index) => {
                    return <ResidentParking data={el} key={index} />;
                  })}
                  {isLoading ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '10vh',
                      }}
                    >
                      <Spinner />
                    </div>
                  ) : null}
                  <div ref={observerRef}></div>

                  <RenderButton empty={isResidentEmpty} />
                </>
              )}
            </Container>
          )}
        </>
      ) : isReceipt ? (
        <AlreadyShared setIsCarReductionBottomModalVisible={setIsCarReductionBottomModalVisible} />
      ) : (
        <NotSharedParking />
      )}
      <SharedParkingBottomModal
        isVisible={isCarReductionBottomModalVisible}
        onClose={() => setIsCarReductionBottomModalVisible(false)}
        onConfirm={handleConfirm}
        selectedItem={selectedReduction} // 현재 선택된 항목 세팅
      />
    </MainContainerNoMargin>
  );
});

export default ShareHistoryPage;
