import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Container, WrapEmptyItem, WrapItem } from './styles';
import { Body2, Caption1, Caption2, Caption3, SubHeadline } from 'styles/typography';
import { IcCloseBlack28, IcCloseBlackSVG, IcPlusSVG } from 'assets/ic';
import { colors } from 'styles/colors';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import PopupModal from 'components/atoms/Modal/PopupModal';
import { truncateText } from 'utils/common';
import MonthlyDesiredAreaBottomModal from 'components/atoms/Modal/MonthlyDesiredAreaBottomModal';

const DesiredAreaRegisterList = observer(() => {
  const navigate = useNavigate();

  const [isPopup, setIsPopup] = useState(false);
  const [selected, setSelected] = useState(0);
  const [isModal, setIsModal] = useState(false);

  const { monthlyParkingDesiredArea, setMonthlyParkingDesiredArea } = userInfoStore;

  const openPopup = () => {
    setIsPopup(true);
  };

  const closePopup = () => {
    setIsPopup(false);
  };

  const closeModal = () => {
    setIsModal(false);
  };

  const EmptyItem = ({}) => (
    <WrapEmptyItem onClick={() => navigate('/areaSetting')}>
      <IcPlusSVG fill={colors.primary} style={{ marginRight: 8 }} />
      <SubHeadline weight="medium" color="black3">
        희망 지역 등록하기
      </SubHeadline>
    </WrapEmptyItem>
  );

  if (!monthlyParkingDesiredArea || monthlyParkingDesiredArea.length === 0) {
    return (
      <Container>
        <EmptyItem />
        <EmptyItem />
      </Container>
    );
  }

  const contents = monthlyParkingDesiredArea.map(area => ({
    title: area?.place_name ?? '',
    desc: area?.address_name ?? '',
  }));

  return (
    <Container>
      {contents.length === 0 ? (
        // 값이 없을 경우
        <>
          <EmptyItem />
          <EmptyItem />
        </>
      ) : (
        // 값이 있을 경우
        <>
          {contents.map((el, index) => (
            <WrapItem
              key={el.title + index} // 고유한 key 생성
              onClick={() => {
                setIsModal(true);
                setSelected(index);
              }}
            >
              <div>
                <Caption1 weight="medium" color="black3">
                  {el.title ?? el.desc}
                </Caption1>
                {el.title ? (
                  <Caption3 weight="regular" color="gray06" style={{ marginTop: 4 }}>
                    {el.desc}
                  </Caption3>
                ) : null}
              </div>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                }}
                onClick={e => {
                  e.stopPropagation();
                  openPopup();
                  setSelected(index);
                }}
              >
                <IcCloseBlack28 />
              </div>
            </WrapItem>
          ))}
          {contents.length === 1 && <EmptyItem />}
        </>
      )}

      <PopupModal
        isVisible={isPopup}
        onClose={closePopup}
        title1={
          selected !== null ? `${truncateText(contents[selected].title, 15)}을 삭제하시나요?` : ''
        }
        leftClick={closePopup}
        leftText={'취소'}
        rightText={'삭제하기'}
        rightClick={() => {
          closePopup();

          if (
            Array.isArray(monthlyParkingDesiredArea) &&
            selected >= 0 &&
            selected < monthlyParkingDesiredArea.length
          ) {
            userInfoStore.setMonthlyParkingDesiredArea(
              monthlyParkingDesiredArea.filter(
                item => item?.id !== monthlyParkingDesiredArea[selected]?.id,
              ),
            );
          }
        }}
      />
      <MonthlyDesiredAreaBottomModal
        isVisible={isModal}
        onClose={closeModal}
        title={contents[selected].title}
      />
    </Container>
  );
});

export default DesiredAreaRegisterList;
